import '@expo/match-media';
import React, { memo } from 'react';
import { Dimensions, ScrollView, StyleSheet } from 'react-native';
import { Layout, Text, Button, Divider } from '@ui-kitten/components';
import * as Yup from 'yup';
import { useIsMobile } from '../core/responsive.utils';
import { FieldArray, Formik, getIn } from 'formik';
import { UIStatusWrapper } from './ui-status';
import { inventoryStore } from '../store';
import { observer } from 'mobx-react-lite';
import { warehousesStore } from '../store/index';
import InlineTable from './InlineTable';
import { TableCellInput } from './TableCellInput';
import { TableCellSelect } from './TableCellSelect';
import { unifiedAlert } from '../core/utils/utils';

const windowHeight = Dimensions.get('window').height;

const AddSkuMappingsForm = observer(
  ({ onSubmit, onDismiss, stores, enabledStoreIds, size = 'medium', warehouseAccount }) => {
    const isMobileDevice = useIsMobile();

    const warehouses = warehousesStore.warehousesByAccountId[warehouseAccount.id] || [];

    const skus = inventoryStore.items?.map((s) => s.sku_code) || [];

    return (
      <ScrollView keyboardShouldPersistTaps={'handled'} style={styles.formContainer}>
        <Layout style={styles.form}>
          <Text category="h5" style={styles.title}>
            Add SKU Mappings
          </Text>
          <Divider />

          <UIStatusWrapper
            status={{
              error: inventoryStore.error,
              indeterminate: inventoryStore.loading,
              empty: skus.length === 0,
            }}>
            <Formik
              initialValues={{
                skuMappings: [
                  {
                    storeId: '',
                    warehouseSku: '',
                    storeSku: '',
                    storeProductId: '',
                    qty: 1,
                    storeProductName: '',
                    warehouseCode: '',
                  },
                ],
              }}
              isInitialValid={false}
              validationSchema={Yup.object({
                skuMappings: Yup.array().of(
                  Yup.object().shape(
                    {
                      storeId: Yup.string(),
                      warehouseSku: Yup.string().required('Required').trim(),
                      warehouseCode: Yup.string().trim(),
                      qty: Yup.number()
                        .required('Required')
                        .integer('Not a integer')
                        .min(1, 'Min quantity must be larger than 1'),
                      storeSku: Yup.string()
                        .when('storeProductId', {
                          is: (val) => !val,
                          then: Yup.string().required('Product ID or store SKU is required'),
                          otherwise: Yup.string(),
                        })
                        .matches(
                          /^[a-zA-Z0-9\-\_\#\*\/\&\+\?\.\(\)]+$/,
                          'Only allow letters, numbers, -, _, #, *, /, &, +, ?, ., ( and ). All letters are saved in capital cases.',
                        ),
                      storeProductId: Yup.string()
                        .when('storeSku', {
                          is: (val) => !val,
                          then: Yup.string().required('Product ID or store SKU is required'),
                          otherwise: Yup.string(),
                        })
                        .matches(
                          /^[a-zA-Z0-9\-\_\#\*\/\&\+\?\.\(\)]+$/,
                          'Only allow letters, numbers, -, _, #, *, /, &, +, ?, ., ( and ). All letters are saved in capital cases.',
                        ),
                      storeProductName: Yup.string().min(3, 'Must be longer than 3 characters'),
                    },
                    [['storeSku', 'storeProductId']],
                  ),
                ),
              })}
              onSubmit={async (values, formikActions) => {
                formikActions.setSubmitting(true);
                try {
                  await onSubmit(values.skuMappings, warehouseAccount.id);
                  formikActions.resetForm();
                } catch (e) {
                  unifiedAlert(error);
                }
                // Important: Make sure to setSubmitting to false so our loading indicator
                // goes away.
                formikActions.setSubmitting(false);
              }}>
              {(props) => {
                return (
                  <>
                    <FieldArray
                      name="skuMappings"
                      render={(arrayProps) => (
                        <>
                          <InlineTable
                            onRemoveItem={(i) => arrayProps.remove(i)}
                            items={props.values.skuMappings}
                            columnByKey={{
                              storeId: {
                                title: 'Store',
                                size: 'large',
                                formatter: (key, item, index) => {
                                  return (
                                    <TableCellSelect
                                      size="medium"
                                      placeholder="select store"
                                      name={`skuMappings[${index}].${key}`}
                                      options={stores.map((s) => ({
                                        key: s.id,
                                        val: s.name,
                                      }))}
                                      value={props.values.skuMappings[index].storeId}
                                      error={getIn(props.errors, `skuMappings[${index}].storeId`)}
                                      touched={getIn(
                                        props.touched,
                                        `skuMappings[${index}].storeId`,
                                      )}
                                      onChange={(val) => {
                                        props.setFieldValue(
                                          `skuMappings[${index}].storeId`,
                                          val.key,
                                        );
                                      }}
                                      {...props}
                                    />
                                  );
                                },
                              },
                              storeSku: {
                                title: 'Store SKU',
                                size: 'large',
                                formatter: (key, item, index) => (
                                  <TableCellInput
                                    placeholder="store_sku_123"
                                    name={`skuMappings[${index}].${key}`}
                                    {...props}
                                  />
                                ),
                              },
                              storeProductId: {
                                title: 'Store product ID',
                                size: 'large',
                                formatter: (key, item, index) => (
                                  <TableCellInput
                                    placeholder="store_product_123"
                                    name={`skuMappings[${index}].${key}`}
                                    {...props}
                                  />
                                ),
                              },
                              storeProductName: {
                                title: 'Store product name',
                                size: 'large',
                                formatter: (key, item, index) => (
                                  <TableCellInput
                                    placeholder="store_product_name"
                                    name={`skuMappings[${index}].${key}`}
                                    {...props}
                                  />
                                ),
                              },
                              warehouseSku: {
                                title: 'Warehouse SKU',
                                size: 'large',
                                formatter: (key, item, index) => (
                                  <TableCellInput
                                    placeholder="warehouse_sku_123"
                                    name={`skuMappings[${index}].${key}`}
                                    {...props}
                                  />
                                ),
                              },
                              qty: {
                                title: 'Quantity',
                                size: 'medium',
                                formatter: (key, item, index) => (
                                  <TableCellInput
                                    placeholder="1"
                                    name={`skuMappings[${index}].${key}`}
                                    error={getIn(props.errors, `skuMappings[${index}].${key}`)}
                                    {...props}
                                  />
                                ),
                              },
                              warehouseCode: {
                                title: 'Warehouse code',
                                size: 'large',
                                formatter: (key, item, index) => (
                                  <TableCellSelect
                                    size="medium"
                                    placeholder="select warehouse"
                                    name={`skuMappings[${index}].warehouseCode`}
                                    options={warehouses.map((s) => ({
                                      key: s.warehouse_code,
                                      val: s.warehouse_name_en,
                                    }))}
                                    value={props.values.skuMappings[index].warehouseCode}
                                    error={getIn(
                                      props.errors,
                                      `skuMappings[${index}].warehouseCode`,
                                    )}
                                    touched={getIn(
                                      props.touched,
                                      `skuMappings[${index}].warehouseCode`,
                                    )}
                                    onChange={(val) => {
                                      props.setFieldValue(
                                        `skuMappings[${index}].warehouseCode`,
                                        val.key,
                                      );
                                    }}
                                    {...props}
                                  />
                                ),
                              },
                            }}
                          />
                          <Divider />
                          <Layout
                            style={{
                              flexDirection: 'row',
                              justifyContent: 'space-around',
                              marginVertical: '0.25rem',
                            }}>
                            <Button
                              style={{ width: '15rem' }}
                              appearance="outline"
                              size="medium"
                              onPress={() => arrayProps.push({})}>
                              Add row
                            </Button>
                          </Layout>
                        </>
                      )}
                    />

                    <Divider />
                    <Layout
                      style={
                        isMobileDevice
                          ? styles.mobileButtonContainer
                          : styles.desktopButtonContainer
                      }>
                      <Button
                        status="primary"
                        loading={props.isSubmitting}
                        onPress={props.handleSubmit}
                        disabled={props.isSubmitting || !props.isValid}
                        style={styles.button}>
                        Submit
                      </Button>
                      <Button
                        status="basic"
                        loading={props.isSubmitting}
                        onPress={onDismiss}
                        disabled={props.isSubmitting}
                        style={styles.button}>
                        Cancel
                      </Button>
                      <Button
                        status="danger"
                        appearance="ghost"
                        disabled={props.isSubmitting}
                        onPress={props.handleReset}
                        style={styles.button}>
                        Reset
                      </Button>
                    </Layout>
                  </>
                );
              }}
            </Formik>
          </UIStatusWrapper>
        </Layout>
      </ScrollView>
    );
  },
);

const styles = StyleSheet.create({
  title: {
    marginVertical: 10,
  },
  invisible: {
    display: 'none',
  },
  form: {
    alignSelf: 'center',
    marginVertical: 8,
  },
  input: {
    flex: 1,
    flexDirection: 'column',
    marginRight: 5,
    minWidth: '200px',
  },
  qtyInput: {
    flex: 1,
    flexDirection: 'column',
    marginRight: 5,
    maxWidth: '80px',
  },
  mobileInput: {
    maxWidth: '200px',
    minWidth: '200px',
    marginHorizontal: '5px',
  },
  rowContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginVertical: 8,
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 800,
  },
  mobileRowFlex: {
    marginHorizontal: 'auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  desktopRowFlex: {
    flexDirection: 'row',
    width: '1250px',
  },
  button: {
    maxHeight: 32,
    marginHorizontal: '5px',
  },
  desktopButtonContainer: {
    marginTop: '0.4em',
    flexDirection: 'row-reverse ',
  },
  mobileButtonContainer: {
    marginTop: '0.4em',
    flexDirection: 'column',
  },
  formContainer: {
    height: windowHeight - 300,
    overflowY: 'auto',
  },
  btnContainer: {
    width: 130,
  },
});

export default memo(AddSkuMappingsForm);
