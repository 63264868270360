import React, { memo, useEffect, useState, useMemo } from 'react';
import { StyleSheet, ScrollView, useWindowDimensions } from 'react-native';
import { DataTable, Modal, Portal, FAB, Snackbar } from 'react-native-paper';
import { useIsMobile } from '../core/responsive.utils';
import { paperNativeTheme } from 'src/core/theme';
import { path, sortWith, descend, ascend, prop } from 'ramda';
import { UIStatusWrapper } from './ui-status';
import { CheckBox, Layout, Text } from '@ui-kitten/components';
import { sanitize } from '../core/utils/utils';
import { all, any } from 'ramda';
import { observer } from 'mobx-react-lite';
import TooltipCell from './TooltipCell';
import EzPagination from './EzPagination';

const PAGINATION = {
  itemsPerPage: 20,
};

const styles = StyleSheet.create({
  fab: {
    backgroundColor: paperNativeTheme.colors.primary,
  },

  checkbox: {
    borderColor: paperNativeTheme.colors.primary,
  },

  dataSection: {
    padding: '1em',
    alignItems: 'center',
  },

  firstColumn: {
    flex: 0.1,
    minWidth: 35,
  },

  snackbar: {
    backgroundColor: paperNativeTheme.colors.primary,
    bottom: 70,
  },

  modalContainer: {
    backgroundColor: 'white',
    padding: 20,
    margin: 20,
  },

  emptyTable: {
    padding: '1em',
    alignItems: 'center',
  },
});

const SORT_AT = {
  FRONTEND: 'frontend',
};

const Table = observer(
  ({
    items,
    getItems,
    getId = (item) => item.id,
    titleByKey = {},
    displayKeys,
    rowOnClick = undefined,
    rowOnClickContent = undefined,
    fabActions = undefined,
    formatterByKey = {},
    sort,
    sortAt = SORT_AT.FRONTEND,
    onSortChange = undefined,
    styleByKey = {},
    isFabVisible = true,
    emptyDataMsg,
    totalItemNum,
    onPageChange,
    heightOffset,
    currentPage,
    itemsPerPage = PAGINATION.itemsPerPage,
    setPageSize,
    defaultSortOption,
    tableStyle,
  }) => {
    const windowHeight = useWindowDimensions().height;
    const isMobile = useIsMobile();
    const [visibleModal, setVisibleModal] = useState(-1);
    const [curPage, setCurPage] = useState(0);
    const [selectedIds, setSelectedIds] = useState(new Set());
    const [fabState, setFABState] = useState({ open: false });
    const displayItems = items || getItems();
    const activatePage = useMemo(() => currentPage || curPage, [currentPage, curPage]);
    const total = useMemo(
      () => totalItemNum || displayItems.length,
      [totalItemNum, displayItems.length],
    );
    // If true, use props to control pagination
    const isPaginationControlled = useMemo(() => {
      return (
        !!itemsPerPage && totalItemNum !== undefined && !!onPageChange && currentPage !== undefined
      );
    }, [itemsPerPage, onPageChange, totalItemNum, currentPage]);

    useEffect(() => {
      setSelectedIds(new Set());
    }, [total]);

    const [visibleSnackbar, setVisibleSnackbar] = React.useState(false);
    const toggleMap = new Map([
      [null, 'ascending'],
      ['ascending', 'descending'],
      ['descending', null],
    ]);
    const [sortOptions, setSortOptions] = useState(defaultSortOption || []);

    const onShowSnackBar = () => setVisibleSnackbar(true);
    const onDismissSnackBar = () => setVisibleSnackbar(false);

    const totalPages = Math.ceil(total / itemsPerPage);
    const sortedItems = useMemo(() => {
      if (sortAt === SORT_AT.FRONTEND) {
        const sortFunction = sortWith(
          sortOptions.map(({ direction, key }) =>
            direction === 'ascending'
              ? ascend(path(key.split('.')))
              : descend(path(key.split('.'))),
          ),
        );
        return sortFunction(displayItems);
      } else {
        return displayItems;
      }
    }, [displayItems, sortAt, sortOptions]);

    const handlePageChange = (pageNumber) => {
      const newPage = pageNumber - 1;
      setCurPage(newPage);
      if (onPageChange) {
        onPageChange(newPage);
      }
    };

    const isLastRow = (itemIndex) => itemIndex === displayItems.length - 1;
    const handleItemsPerPageChange = (newItemsPerPage) => {
      if (onPageChange) {
        onPageChange(0);
      }
      if (setPageSize) {
        setPageSize(newItemsPerPage);
      }
    };

    useEffect(() => {
      if (onSortChange) {
        onSortChange(sortOptions, sortedItems);
      }
    }, [displayItems, sortOptions]);

    useEffect(() => {
      if (!isPaginationControlled) {
        if (curPage * itemsPerPage > total) {
          setCurPage(0);
        }
      }
    }, [setCurPage, curPage, total, isPaginationControlled]);

    const from = Math.min(total, activatePage * itemsPerPage);
    const to = Math.min(total, (activatePage + 1) * itemsPerPage);

    const isTableEmpty = !sortedItems || total === 0;

    const renderCellContent = (k, d) => {
      const cellValue = path(k.split('.'), d);
      const formattedValue = formatterByKey[k] ? sanitize(formatterByKey[k](cellValue, d)) : null;
      return formattedValue || sanitize(cellValue);
    };

    const dataSection = (
      <Layout style={isTableEmpty && styles.emptyTable}>
        <UIStatusWrapper status={{ empty: isTableEmpty }} emptyDataMsg={emptyDataMsg}>
          <DataTable style={tableStyle}>
            {!isMobile && (
              <DataTable.Header>
                {fabActions ? (
                  <DataTable.Title style={styles.firstColumn}>
                    <CheckBox
                      style={{ color: paperNativeTheme.colors.primary }}
                      checked={all((d) => selectedIds.has(getId(d)), displayItems)}
                      indeterminate={
                        !all((d) => selectedIds.has(getId(d)), displayItems) &&
                        any((d) => selectedIds.has(getId(d)), displayItems)
                      }
                      onChange={() => {
                        if (all((d) => selectedIds.has(getId(d)), displayItems)) {
                          setSelectedIds(new Set());
                        } else {
                          setSelectedIds(new Set(displayItems.map(getId)));
                          onShowSnackBar();
                        }
                      }}
                    />
                  </DataTable.Title>
                ) : null}

                {displayKeys.map((k) => (
                  <DataTable.Title
                    style={styleByKey[k]}
                    sortDirection={sort && sortOptions.find(({ key }) => key === k)?.direction}
                    key={`title-${k}`}
                    onPress={() => {
                      const selectedCol = sortOptions.find(({ key }) => key === k);
                      if (sort === 'single') {
                        setSortOptions([
                          {
                            key: k,
                            direction: selectedCol
                              ? toggleMap.get(selectedCol.direction)
                              : 'ascending',
                          },
                        ]);
                      }
                      if (sort === 'multi') {
                        if (selectedCol) {
                          const updatedSortOptions = sortOptions.map((option) =>
                            option.key !== k
                              ? option
                              : {
                                  ...option,
                                  direction: toggleMap.get(option.direction),
                                },
                          );
                          setSortOptions(
                            updatedSortOptions.filter(({ direction }) => direction !== null),
                          );
                        } else {
                          setSortOptions([...sortOptions, { key: k, direction: 'ascending' }]);
                        }
                      }
                    }}>
                    <Text category="s1">{titleByKey[k] || k}</Text>
                  </DataTable.Title>
                ))}
              </DataTable.Header>
            )}

            <ScrollView style={{ maxHeight: windowHeight - (heightOffset || 220) }}>
              {((isPaginationControlled && sortedItems) || sortedItems.slice(from, to)).map(
                (d, itemIndex) => (
                  <DataTable.Row
                    key={`row-${itemIndex}`}
                    onPress={() => {
                      if (selectedIds.size === 0) {
                        if (rowOnClick) rowOnClick(d);
                        else
                          rowOnClickContent &&
                            setVisibleModal(activatePage * itemsPerPage + itemIndex);
                      } else {
                        setSelectedIds((s) => {
                          const clonedSet = new Set(s);
                          const id = getId(d);
                          if (clonedSet.has(id)) {
                            clonedSet.delete(id);
                          } else {
                            clonedSet.add(id);
                          }

                          return clonedSet;
                        });
                        onShowSnackBar();
                      }
                    }}>
                    {fabActions ? (
                      <DataTable.Cell style={styles.firstColumn}>
                        <CheckBox
                          style={{ color: paperNativeTheme.colors.primary }}
                          checked={selectedIds.has(getId(d))}
                          onChange={() => {
                            setSelectedIds((s) => {
                              const clonedSet = new Set(s);
                              const id = getId(d);
                              if (clonedSet.has(id)) {
                                clonedSet.delete(id);
                              } else {
                                clonedSet.add(id);
                              }

                              return clonedSet;
                            });
                            onShowSnackBar();
                          }}
                        />
                      </DataTable.Cell>
                    ) : null}

                    {isMobile ? (
                      <DataTable.Cell>
                        {displayKeys.map((k, kIndex) => (
                          <p key={kIndex}>
                            <strong>{titleByKey[k] || k}</strong>: {renderCellContent(k, d)}
                          </p>
                        ))}
                      </DataTable.Cell>
                    ) : (
                      displayKeys.map((k, keyIndex) => (
                        <DataTable.Cell key={keyIndex} style={{ ...styleByKey[k], zIndex: 'auto' }}>
                          <TooltipCell
                            content={renderCellContent(k, d)}
                            showTooltipOnRight={isLastRow(itemIndex)}
                            itemIndex={itemIndex}></TooltipCell>
                        </DataTable.Cell>
                      ))
                    )}
                  </DataTable.Row>
                ),
              )}
            </ScrollView>

            <DataTable.Header>
              <EzPagination
                totalPages={totalPages}
                currentPage={activatePage + 1}
                onPageChange={handlePageChange}
                onItemsPerPageChange={handleItemsPerPageChange}
                itemsPerPage={itemsPerPage}
                total={total}
              />
            </DataTable.Header>
          </DataTable>
        </UIStatusWrapper>
      </Layout>
    );

    return (
      <>
        <Portal>
          {rowOnClickContent ? (
            <Modal
              visible={visibleModal >= 0}
              onDismiss={() => setVisibleModal(-1)}
              contentContainerStyle={styles.modalContainer}>
              <ScrollView style={{ maxHeight: windowHeight - 220 }}>
                {visibleModal >= 0 && rowOnClickContent(sortedItems[visibleModal])}
              </ScrollView>
            </Modal>
          ) : null}

          <Snackbar
            style={styles.snackbar}
            visible={visibleSnackbar}
            onDismiss={onDismissSnackBar}
            action={
              selectedIds.size > 0
                ? {
                    label: 'Action',
                    onPress: () => {
                      setFABState({ open: true });
                      onDismissSnackBar();
                    },
                  }
                : {}
            }>
            {selectedIds.size} {selectedIds.size > 1 ? 'rows are' : 'row is'} selected
          </Snackbar>
        </Portal>
        {dataSection}
        <Portal>
          {fabActions && fabActions?.length && visibleModal < 0 ? (
            <FAB.Group
              visible={isFabVisible && fabActions(selectedIds).length > 0}
              fabStyle={styles.fab}
              open={fabState.open}
              icon={fabState.open ? 'chevron-down' : 'chevron-up'}
              actions={fabActions(selectedIds)}
              onStateChange={({ open }) => setFABState({ open })}
              onPress={() => {
                if (open) {
                  onDismissSnackBar();
                }
              }}
            />
          ) : null}
        </Portal>
      </>
    );
  },
);

export default Table;
