import '@expo/match-media';
import React, { memo } from 'react';
import { paperNativeTheme } from 'src/core/theme';
import { StyleSheet } from 'react-native';
import {
  Layout,
  Input,
  Text,
  Autocomplete,
  AutocompleteItem,
  Button,
  ButtonGroup,
  Icon,
} from '@ui-kitten/components';
import * as Yup from 'yup';
import { useIsMobile } from '../core/responsive.utils';
import { Formik } from 'formik';
import { UIStatusWrapper } from './ui-status';
import { inventoryStore } from '../store';
import { observer } from 'mobx-react-lite';

const renderOption = (item) => <AutocompleteItem key={item} title={item} />;
const filter = (item, query) => item.toLowerCase().includes(query.toLowerCase());

const SkuQtyInput = observer(
  ({
    label,
    itemLabel,
    qtyLabel,
    onSubmit,
    size = 'small',
    validateStock = true,
    style = {},
    onCancel,
    warehouseAccountId,
    warehouse_code,
    skuList,
  }) => {
    const isMobileDevice = useIsMobile();

    const skus =
      skuList ||
      (warehouseAccountId
        ? inventoryStore.getItemsByWarehouseAccount(warehouseAccountId)
        : inventoryStore.items);

    return (
      <Layout
        style={[
          styles.rowContainer,
          isMobileDevice ? styles.mobileRowFlex : styles.desktopRowFlex,
          style,
        ]}
        level="1">
        <UIStatusWrapper
          status={{
            error: inventoryStore.error,
            emtpy: skus.length === 0,
            indeterminate: inventoryStore.loading,
          }}>
          {label ? (
            <Text
              category={size === 'large' ? 's1' : size === 'medium' ? 's2' : 'label'}
              style={isMobileDevice ? styles.mobileLabel : styles.desktopLabel}>
              {label}
            </Text>
          ) : null}
          <Formik
            initialValues={{
              sku: '',
              qty: 1,
            }}
            isInitialValid={false}
            validationSchema={Yup.object({
              sku: Yup.string()
                .required()
                .oneOf(
                  skus.map((s) => s.sku_code),
                  'SKU not found',
                ),
              qty: Yup.number()
                .required()
                .min(1)
                .when('sku', (sku, schema) => {
                  if (!validateStock) {
                    return schema.max(Number.MAX_SAFE_INTEGER);
                  } else {
                    const matchingSku = skus.find(
                      (s) =>
                        s.sku_code === sku && warehouse_code && s.warehouse_code === warehouse_code,
                    );
                    const available_stock = matchingSku?.available_stock || 0;
                    return schema.max(
                      available_stock,
                      available_stock > 0
                        ? `must be no greater than the currently available stock of ${available_stock}`
                        : 'no avaialble stock',
                    );
                  }
                }),
            })}
            onSubmit={(values, formikActions) => {
              onSubmit({
                ...values,
                qty: Number(values.qty),
                productName: skus.find((s) => s.sku_code === values.sku)?.sku_name,
              });
              // Important: Make sure to setSubmitting to false so our loading indicator
              // goes away.
              formikActions.setSubmitting(false);
              formikActions.resetForm();
            }}>
            {(props) => {
              const data = [...new Set(skus.map((s) => s.sku_code))].filter((sku) =>
                filter(sku, props.values.sku),
              );
              return (
                <Layout style={[isMobileDevice ? '' : styles.desktopInput, styles.input]}>
                  <Autocomplete
                    size={size}
                    value={props.values.sku}
                    placeholder={itemLabel}
                    style={[styles.skuInput, isMobileDevice ? styles.mobileInput : '']}
                    selectionColor={paperNativeTheme.colors.primary}
                    status={props.touched.sku && props.errors.sku ? 'danger' : 'primary'}
                    onChangeText={(value) => {
                      props.setFieldValue('sku', value);
                    }}
                    caption={props.errors.sku}
                    onSelect={(index) => props.setFieldValue('sku', data[index])}
                    onBlur={() => props.setFieldTouched('sku', true)}>
                    {data.map(renderOption)}
                  </Autocomplete>

                  <Input
                    size={size}
                    style={[styles.qtyInput, isMobileDevice ? styles.mobileInput : '']}
                    value={props.values.qty}
                    keyboardType="numeric-pad"
                    status={props.touched.qty && props.errors.qty ? 'danger' : 'primary'}
                    placeholder={qtyLabel}
                    caption={props.errors.qty}
                    onChange={props.handleChange('qty')}
                    onBlur={props.handleBlur('qty')}
                  />

                  {onCancel ? (
                    <ButtonGroup appearance="ghost" size={size} style={styles.button}>
                      <Button
                        appearance="ghost"
                        status="primary"
                        accessoryLeft={(props) => <Icon {...props} name="save-outline" />}
                        loading={props.isSubmitting}
                        disabled={props.isSubmitting || !props.isValid}
                        onPress={props.handleSubmit}
                      />
                      <Button
                        appearance="ghost"
                        status="danger"
                        accessoryLeft={(innerProps) => (
                          <Icon {...innerProps} name="close-circle-outline" />
                        )}
                        loading={props.isSubmitting}
                        disabled={props.isSubmitting}
                        onPress={onCancel}
                      />
                    </ButtonGroup>
                  ) : (
                    <Button
                      size={size}
                      appearance="ghost"
                      style={styles.button}
                      status="primary"
                      accessoryLeft={(innerProps) => <Icon {...innerProps} name="save-outline" />}
                      loading={props.isSubmitting}
                      disabled={props.isSubmitting || !props.isValid}
                      onPress={props.handleSubmit}
                    />
                  )}
                </Layout>
              );
            }}
          </Formik>
        </UIStatusWrapper>
      </Layout>
    );
  },
);

const styles = StyleSheet.create({
  input: {
    flex: 1,
    width: '100%',
  },
  skuInput: {
    flex: 1,
    marginRight: 5,
    minWidth: '150px',
  },
  qtyInput: {
    flex: 1,
    maxWidth: '40px',
  },
  mobileInput: {
    maxWidth: '200px',
    minWidth: '200px',
    marginHorizontal: '5px',
  },
  mobileLabel: {
    flex: 1,
    maxWidth: '100px',
    minWidth: '100px',
  },
  desktopLabel: {
    flex: 1,
    minWidth: '150px',
    maxWidth: '150px',
  },
  rowContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  mobileRowFlex: {
    marginHorizontal: 'auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  desktopRowFlex: {
    flexDirection: 'row',
    minWidth: '400px',
  },
  desktopInput: {
    flexDirection: 'row',
    width: '380px',
  },
  button: {
    maxHeight: 32,
    marginHorizontal: '5px',
  },
});

export default memo(SkuQtyInput);
