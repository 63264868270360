import React from 'react';
import { Text, Card, List, ListItem, Layout, Modal } from '@ui-kitten/components';
import { View } from 'react-native';
import { ezTheme } from '../core/theme';

export const SkuMappingWarningModal = ({ visible, skus, onBackdropPress }) => {
  return (
    <Modal
      visible={visible}
      backdropStyle={{ backgroundColor: ezTheme.backdropModalColor }}
      onBackdropPress={onBackdropPress}>
      <Layout style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Card
          disabled={true}
          header={(props) => (
            <View {...props}>
              <Text category="h5">Unmapped SKUs</Text>
            </View>
          )}>
          <List
            style={{ maxHeight: '50vh' }}
            data={skus}
            renderItem={({ item }) => <ListItem title={item} key={item} />}></List>
        </Card>
      </Layout>
    </Modal>
  );
};
