import { Card, Avatar } from 'react-native-paper';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, ButtonGroup, CheckBox, Icon, Input } from '@ui-kitten/components';
import { useMutation } from '@apollo/client';
import { useIsMobile } from '../core/responsive.utils';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { zipObj, transpose, omit, pick } from 'ramda';
import LineItemCard from './LineItemCard';
import { paperNativeTheme } from 'src/core/theme';
import { FULFILL_ORDER, unifiedAlert } from '../core/utils/utils';
import { getCourierCompany } from '../core/utils/4pxCourierNameMap';
import { getCourierTrackignUrl } from '../core/utils/4pxCourierTrackingMap';
import { skuMappingStore } from '../store';
import { observer } from 'mobx-react';
import { findMatchedSkuMappings } from '@ezom/library/lib/cjs/skuMapUtils';

const styles = StyleSheet.create({
  card: {
    marginHorizontal: '0.4em',
  },

  desktopCard: {
    minWidth: '400px',
  },

  mobileCard: {
    marginBottom: '1em',
    width: '90%',
  },
});

const STEPS = { SELECTING_ITEMS: 1, ADDING_TRACKING: 3 };

export default observer(
  ({
    order,
    selectedConsignment,
    hasMatchingConsignments,
    noTitle = false,
    refetchOrder = () => {},
    startAddingTracking = () => {},
    stopAddingTracking = () => {},
    actionsAllowed = true,
  }) => {
    const [selectedItems, setSelectedItems] = useState(
      new Set([...order.lineItems.filter((item) => item.fulfillmentStatus !== 'fulfilled')]),
    );
    const isMobile = useIsMobile();
    const [step, setStep] = useState(null);
    const onCardSelect = (item) => () => {
      if (selectedItems.has(item)) {
        selectedItems.delete(item);
        setSelectedItems(new Set([...selectedItems]));
      } else {
        selectedItems.add(item);
        setSelectedItems(new Set([...selectedItems]));
      }
    };

    const [selectedItemQuantity, setSelectedItemQuantity] = useState({});

    const trackingInfoMap = zipObj(
      ...transpose(
        order.fulfillments
          .filter((f) => f.trackingInfo)
          .flatMap((f) => f.fulfillmentLineItems.map((item) => [item.id, f.trackingInfo])) || [],
      ),
    );

    const skuMappings = skuMappingStore.items;

    const renderForm = (formProps) => {
      const [fulfillOrder, { loading: mutationLoading, error: mutationError }] =
        useMutation(FULFILL_ORDER);

      const renderTitleAction = (innerFormProps) => (
        <>
          <Card.Title
            title="Fulfillment"
            left={(props) => <Avatar.Icon {...props} icon="cart" />}
            right={
              !actionsAllowed
                ? null
                : () => (
                    <ActionButtons
                      step={step}
                      loading={innerFormProps.isSubmitting || innerFormProps.isSubmitting}
                      saveDisabled={innerFormProps.isSubmitting || !innerFormProps.isValid}
                      fulfillDisabled={!formProps.isValid || selectedItems.size === 0}
                      onFulfillSubmit={formProps.submitForm}
                      onCancel={() => {
                        stopAddingTracking();
                        setStep(null);
                      }}
                      onSaveSubmit={innerFormProps.submitForm}
                      onAddTracking={() => setStep(STEPS.SELECTING_ITEMS)}
                    />
                  )
            }
            subtitle={
              step === STEPS.ADDING_TRACKING && hasMatchingConsignments
                ? 'Select a consignment to prefill'
                : null
            }
          />
          {step === STEPS.ADDING_TRACKING ? (
            <Card.Actions style={{ justifyContent: 'center' }}>
              <AddTrackingForm formProps={innerFormProps} />
            </Card.Actions>
          ) : null}
        </>
      );

      return (
        <Card
          style={[
            styles.card,
            isMobile ? styles.mobileCard : styles.desktopCard,
            { marginBottom: '0.8em', overflowY: 'auto' },
          ]}>
          {noTitle ? null : (
            <Formik
              initialValues={{
                company:
                  getCourierCompany(
                    selectedConsignment?.logistics_provider ||
                      selectedConsignment?.logistics_product_code,
                    order.provider,
                  ) ||
                  selectedConsignment?.logistics_product_name_en ||
                  '',
                number: selectedConsignment?.shipping_no || '',
                url: getCourierTrackignUrl(
                  selectedConsignment?.logistics_provider ??
                    selectedConsignment?.logistics_product_code,
                  selectedConsignment?.shipping_no,
                ),
                notifyCustomer: true,
              }}
              validationSchema={Yup.object({
                company: Yup.string().max(100, 'Too long'),
                number: Yup.string(),
                notifyCustomer: Yup.boolean().required(),
                url: Yup.string()
                  .url('Must be a valid URL', 'Too long')
                  .when('number', {
                    is: (value) => !!value,
                    then: Yup.string().required(),
                  }),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);
                const {
                  data: {
                    fulfillOrder: { userErrors },
                  },
                } = await fulfillOrder({
                  variables: {
                    orderId: order.id,
                    storeId: order.storeId,
                    fulfilledLineItems: Object.entries(selectedItemQuantity).map(([key, val]) => ({
                      id: key,
                      quantity: val,
                      platformId: [...selectedItems].find((i) => i.id === key).platformId,
                    })),
                    trackingInfo: omit(['notifyCustomer'], values),
                    notifyCustomer: values.notifyCustomer,
                  },
                });
                await refetchOrder();
                setSubmitting(false);
                setStep(null);
                if (Array.isArray(userErrors) && userErrors[0]) {
                  unifiedAlert(
                    `Error: ${userErrors.map((e) => `${e.field}: ${e.message || ''}`).join('\n')}`,
                  );
                } else {
                  const msg = 'Fulfilled successfully!';
                  unifiedAlert(msg);
                }
              }}>
              {renderTitleAction}
            </Formik>
          )}

          <Card.Content>
            {order.lineItems.map((item) => (
              <LineItemCard
                key={item.id}
                selectable={[STEPS.SELECTING_ITEMS, STEPS.ADDING_TRACKING].includes(step)}
                disabled={item.fulfillmentStatus === 'fulfilled' || STEPS.ADDING_TRACKING === step}
                selected={selectedItems.has(item) && STEPS.SELECTING_ITEMS === step}
                item={item}
                tracking={trackingInfoMap[item.id]}
                quantity={formProps.values[item.id]}
                skuMappings={
                  findMatchedSkuMappings(
                    item.sku,
                    order.storeId,
                    item.variantId || item.platformId,
                    null,
                    skuMappings,
                    false,
                  ) || []
                }
                onSelectionChange={onCardSelect(item)}
                onQuantityChange={(num) => formProps.setFieldValue(item.id, num)}
                status={
                  formProps.touched[item.id] && formProps.errors[item.id] ? 'danger' : 'primary'
                }
                errorMsg={formProps.errors[item.id]}
                onAddSkuMapping={({ qty, sku }) => {
                  skuMappingStore.addItems([
                    {
                      storeId: order.storeId,
                      warehouseSku: sku,
                      storeSku: item.sku,
                      storeProductId: item.variantId || item.platformId,
                      storeProductName: `${item.name}-${item.variant}`,
                      qty: qty,
                      warehouseAccountId: item.warehouseAccountId,
                      warehouseCode: item.warehouseCode,
                    },
                  ]);
                }}
                onRemoveSkuMapping={(item) => skuMappingStore.deleteItems([item.id])}
              />
            ))}
          </Card.Content>
        </Card>
      );
    };

    return (
      <Formik
        initialValues={order.lineItems
          .filter((item) => item.fulfillmentStatus !== 'fulfilled')
          .reduce((map, item) => ({ ...map, [item.id]: item.fulfillableQuantity }), {})}
        onSubmit={(values) => {
          const selectedItemIds = Array.from(selectedItems).map((item) => item.id);
          setSelectedItemQuantity(pick(selectedItemIds, values));
          setStep(STEPS.ADDING_TRACKING);
          startAddingTracking();
        }}
        validationSchema={Yup.object(
          [...selectedItems].reduce(
            (schema, item) => ({
              ...schema,
              [item.id]: Yup.number('Must be an number')
                .integer('Must be an integer')
                .min(1, 'Too small')
                .max(item.fulfillableQuantity, 'Too large')
                .required('Required'),
            }),
            {},
          ),
        )}
        component={renderForm}
      />
    );
  },
);

const ActionButtons = ({
  step,
  loading,
  fulfillDisabled,
  saveDisabled,
  onFulfillSubmit,
  onSaveSubmit,
  onCancel,
  onAddTracking,
}) =>
  step === STEPS.SELECTING_ITEMS ? (
    <ButtonGroup appearance="ghost" size="small">
      <Button
        appearance="outline"
        status="success"
        disabled={fulfillDisabled}
        onPress={onFulfillSubmit}>
        Fulfill
      </Button>
      <Button appearance="outline" onPress={onCancel} status="warning">
        Cancel
      </Button>
    </ButtonGroup>
  ) : step === STEPS.ADDING_TRACKING ? (
    <ButtonGroup appearance="ghost" size="small">
      <Button
        appearance="outline"
        status="success"
        loading={loading}
        disabled={saveDisabled}
        onPress={onSaveSubmit}>
        Save
      </Button>
      <Button appearance="outline" onPress={onCancel} status="warning">
        Cancel
      </Button>
    </ButtonGroup>
  ) : (
    <Button
      size="small"
      appearance="ghost"
      onPress={onAddTracking}
      status="info"
      accessoryLeft={() => (
        <Icon
          name="checkmark-square-outline"
          style={{
            width: 22,
            height: 22,
            fill: paperNativeTheme.colors.primay,
          }}
        />
      )}>
      {' '}
      Add tracking
    </Button>
  );

const AddTrackingForm = ({ formProps }) => {
  return (
    <View style={{ padding: '0.6em', width: '100%' }}>
      <Input
        value={formProps.values['number']}
        touched={formProps.touched['number']}
        status={formProps.touched['number'] && formProps.errors['number'] ? 'danger' : 'primary'}
        caption={formProps.errors['number']}
        size="small"
        label="Tracking number"
        onChangeText={formProps.handleChange('number')}
      />
      <Input
        value={formProps.values['url']}
        touched={formProps.touched['url']}
        status={formProps.touched['url'] && formProps.errors['url'] ? 'danger' : 'primary'}
        caption={formProps.errors['url']}
        size="small"
        label="Tracking URL"
        onChangeText={formProps.handleChange('url')}
      />
      <Input
        value={formProps.values['company']}
        touched={formProps.touched['company']}
        status={formProps.touched['company'] && formProps.errors['company'] ? 'danger' : 'primary'}
        caption={formProps.errors['company']}
        size="small"
        label="Company"
        onChangeText={formProps.handleChange('company')}
      />
      <CheckBox
        size="small"
        style={{ marginVertical: 4 }}
        checked={formProps.values['notifyCustomer']}
        touched={formProps.touched['notifyCustomer']}
        onChange={(checked) => formProps.setFieldValue('notifyCustomer', checked)}>
        Notify customer?
      </CheckBox>
    </View>
  );
};
