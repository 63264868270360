import { Card, Avatar } from 'react-native-paper';
import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import {
  Card as KittenCard,
  CheckBox,
  Drawer,
  DrawerGroup,
  IndexPath,
  Layout,
  Text,
} from '@ui-kitten/components';
import { useIsMobile } from '../core/responsive.utils';
import { OC_STATUS_MAP } from '@ezom/library/lib/cjs/constants';
import InlineTable from 'src/components/InlineTable';
import { courierStore } from '../store';
import { volumeCmToMetre, weightGramToKg } from '@ezom/library/lib/cjs/utils';

const styles = StyleSheet.create({
  card: {
    marginHorizontal: '1em',
  },

  desktopCard: {
    marginHorizontal: '0.4em',
    minWidth: '300px',
    width: '25%',
  },

  mobileCard: {
    marginHorizontal: '1em',
    marginBottom: '1em',
    width: '90%',
  },

  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    paddingHorizontal: 15,
  },
});

export default ({
  consignments,
  setSelectedConsignment,
  selectedConsignment,
  consignmentSelectable,
  storeId,
}) => {
  const isMobile = useIsMobile();
  const [couriers, setCouriers] = useState([]);
  useEffect(() => {
    (async () => {
      const courierList = await courierStore.getAllStoreWhiteListedCouriers(storeId);
      setCouriers(courierList);
    })();
  }, [storeId, courierStore]);

  return (
    <Card
      style={[
        styles.card,
        isMobile ? styles.mobileCard : styles.desktopCard,
        { marginBottom: '0.8em' },
      ]}>
      <Card.Title
        title="Matching consignments"
        subtitle={consignmentSelectable ? 'select one to prefill tracking' : null}
        left={(props) => <Avatar.Icon {...props} icon="truck" />}
      />

      <Card.Content>
        {consignments.map((item) => (
          <KittenCard
            key={item.id}
            disabled={true}
            style={{ marginBottom: '0.5em' }}
            header={() => (
              <Layout
                level="1"
                style={{
                  alignItems: 'center',
                  margin: '0.4em',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                {consignmentSelectable ? (
                  <CheckBox
                    status="primary"
                    checked={selectedConsignment === item}
                    onChange={() => setSelectedConsignment(item)}
                  />
                ) : null}
                <Text category="label">{item.ref_no}</Text>
                <Text category="label">{OC_STATUS_MAP[item.status]}</Text>
              </Layout>
            )}>
            <Text category="p2">{`Consignment: ${
              couriers.find((c) => c.logistics_product_code === item.logistics_product_code)
                ?.logistics_product_name_en || item.logistics_product_code
            }`}</Text>
            <Text category="p2">{`Consignment No.: ${item.consignment_no}`}</Text>
            <Text category="p2">{`Reference No.: ${item.ref_no}`}</Text>
            <Text category="label">Tracking No: {item.shipping_no}</Text>
            <Text category="p2">Weight: {weightGramToKg(true)(item.total_weight)}</Text>
            <Text category="p2">Volume: {volumeCmToMetre(true)(item.total_volume)}</Text>
            <Text category="p2">Notes: {item.remark}</Text>
            <Text category="p2">Update Time: {item.update_time}</Text>
            <Text category="p2">
              Name: {`${item.first_name} ${item.last_name} ${item.company}`}
            </Text>
            <Text category="p2">
              Address:{' '}
              {`${item.house_number || ''} ${item.street || ''} ${item.disctrict || ''} ${
                item.city || ''
              } ${item.state || ''} ${item.post_code || ''}`}
            </Text>
            <Text category="p2">Notes: {`${item.remark}`}</Text>
            <Drawer selectedIndex={new IndexPath(0)} style={{ marginTop: '0.2em' }}>
              <DrawerGroup title="SKU details">
                <InlineTable
                  items={item.outboundlist_sku}
                  columnByKey={{
                    sku_code: { title: 'SKU' },
                    sku_name: { title: 'Name' },
                    qty: { title: 'QTY', size: 'small', numeric: true },
                  }}
                />
              </DrawerGroup>
            </Drawer>
          </KittenCard>
        ))}
      </Card.Content>
    </Card>
  );
};
