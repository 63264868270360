import React from 'react';
import { Card as KittenCard } from '@ui-kitten/components/ui/card/card.component';
import { Header } from './shared';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { TextInputField } from '../../components/input/TextInputField';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { StyleSheet } from 'react-native';
import { UIStatus, UIStatusWrapper } from '../../components/ui-status';
import { StripePaymentButton } from './StripePaymentButton';
// import { initStripe } from '@stripe/stripe-react-native';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';

const INITIATE_TRANSACTION = gql`
  mutation InitiateTransaction($amount: Float!) {
    initiateTransaction(amount: $amount) {
      clientSecret
      charge
    }
  }
`;

const styles = StyleSheet.create({
  field: {
    marginVertical: 8,
  },
});

const stripePromise = loadStripe(process.env.STRIPE_API_PUBLIC_KEY);

const INITIAL_AMOUNT = 1000;
export const InitiateTransaction = () => {
  const [initiateTransaction, initiateTransactionResult] = useMutation(INITIATE_TRANSACTION);
  const [status, setStatus] = React.useState(new UIStatus());
  const [clientSecret, setClientSecret] = React.useState();
  const [chargeAmount, setChargeAmount] = React.useState();

  return (
    <KittenCard
      style={styles.card}
      header={(props) => (
        <Header
          {...props}
          heading={
            clientSecret ? `You will be charged $${chargeAmount}` : 'Initiate payment with Stripe'
          }
        />
      )}>
      <UIStatusWrapper
        status={
          initiateTransactionResult.error
            ? status.setError(initiateTransactionResult.error)
            : initiateTransactionResult.data && status
            ? status.setIndeterminate(false)
            : status
        }
        loadingDataMsg="Initiating payment with Stripe ...">
        {clientSecret ? (
          <Elements
            options={{
              clientSecret,
              appearance: {
                theme: 'stripe',
              },
            }}
            stripe={stripePromise}>
            <CheckoutForm clientSecret={clientSecret} />
          </Elements>
        ) : (
          <Formik
            onSubmit={(values, resetForm) => {
              setStatus((p) => p.setIndeterminate(true));

              initiateTransaction({
                variables: {
                  amount: Number(values.amount),
                },
              }).then(({ data }) => {
                setClientSecret(data.initiateTransaction.clientSecret);
                setChargeAmount(data.initiateTransaction.charge);
              });
            }}
            validationSchema={Yup.object({
              amount: Yup.number().integer().min(process.env.MIN_PAYMENT_AMOUNT).required(),
            })}
            initialValues={{
              amount: INITIAL_AMOUNT,
            }}
            enableReinitialize>
            {(formProps) => {
              return (
                <React.Fragment>
                  <TextInputField
                    name="amount"
                    label="Amount"
                    style={styles.field}
                    {...formProps}
                  />

                  <p>
                    The transaction fee is {process.env.STRIPE_FEE_PERCENT}% of the transaction
                    value, plus ${process.env.STRIPE_FEE_FIXED} per transaction, charged by the
                    third-party payment provider.
                  </p>

                  <StripePaymentButton
                    title="Initiate"
                    onPress={() => formProps.handleSubmit()}
                    style={{ marginTop: '2em' }}
                  />
                </React.Fragment>
              );
            }}
          </Formik>
        )}
      </UIStatusWrapper>
    </KittenCard>
  );
};
