import React from 'react';
import { path } from 'ramda';
import RadioGroup from '../RadioGroup';

/**
 * A wrapper around TextInput, to be used inside a Formik form
 * @param props
 * @return {*}
 * @constructor
 */
export const RadioGroupInputField = (props) => {
  const paths = props.name.split('.');
  return (
    <RadioGroup
      style={props.style}
      size={props.size}
      label={props.label}
      options={props.options}
      disabled={props.disabled}
      value={path(paths, props.values)}
      defaultValue={props?.defaultValue || path(paths, props.values)}
      onChange={(val) => props.setFieldValue(props.name, val.key)}
    />
  );
};
