import React, { memo, useState, useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { Card } from 'react-native-paper';
import TextInput from './input/TextInput';
import debounce from 'lodash.debounce';
import { useIsMobile, useLargeScreen } from 'src/core/responsive.utils';
import { Layout, Button, Icon, Popover, Text, CheckBox, Toggle } from '@ui-kitten/components';
import { ScrollView } from 'react-native-gesture-handler';
import { OC_STATUS_CODE, OC_STATUS_MAP, PICKING_TASK_TYPE } from '@ezom/library/lib/cjs/constants';
import { observer } from 'mobx-react';
import { outboundOrderTableStore } from '../store';
import { flatten } from 'ramda';
import { useIsWmsManager } from '../core/utils/utils';
import SearchBar from './SearchBar';

const FILTER_OC_STATUS = [
  OC_STATUS_CODE.Preparing,
  OC_STATUS_CODE.Packed,
  OC_STATUS_CODE.Dispatched,
  OC_STATUS_CODE.Exception,
  OC_STATUS_CODE.Cancelled,
];

const OutboundControl = observer(
  ({
    isUserWarehouseAccountAdmin = false,
    filterParams = {},
    indicators = [],
    onSearchTermChange = () => {},
    onFilterChange = () => {},
    onSortChange = () => {},
    onPressAdvancedSearch = () => {},
    onFilterClear = () => {},
    countStatuses = {},
    setCurrentPage = () => {},
    disableSearch = false,
    requireButtonClickToSearch = false,
  }) => {
    const FilterButton = ({ status, onPress, isSelected, count }) => (
      <Toggle checked={isSelected} onChange={() => onPress(status)} style={[styles.filterButton]}>
        {`${OC_STATUS_MAP[status]} (${count || 0})`}
      </Toggle>
    );

    const isMobileDevice = useIsMobile();
    const isLargeScreen = useLargeScreen();
    const [searchTerm, setSearchTerm] = useState('');

    const [statusDropdownVisible, setStatusDropdownVisible] = useState(false);
    const [courierDropdownVisible, setCourierDropdownVisible] = useState(false);
    const [pickingTypeDropdownVisible, setPickingTypeDropdownVisible] = useState(false);
    const [organisationDropdownVisible, setOrganisationDropdownVisible] = useState(false);
    const [selectedButton, setSelectedButton] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [quickFilterStatus, setQuickFilterStatus] = useState(null);

    const handleStatusSelect = (status) => {
      setSelectedStatus(status);
      setQuickFilterStatus(status);
      if (status === selectedButton) {
        setSelectedButton(null);
        onFilterChange({ status: undefined });
      } else {
        onFilterChange({ status });
        setCurrentPage(0);
      }
    };

    const handlePopoverSelect = (status) => {
      setSelectedStatus(status);
      setQuickFilterStatus(status);
      onFilterChange({ status });
    };

    const isWmsManager = useIsWmsManager();

    return (
      <Card visible={true} style={styles.bannerContainer} actions={[]}>
        <Layout style={isMobileDevice ? styles.mobileBanner : styles.desktopBanner}>
          <SearchBar
            debounceDelayMs={500}
            disableSearch={disableSearch}
            requireButtonClickToSearch={requireButtonClickToSearch}
            inputPlaceholder="Search by outbound orders"
            inputSize="medium"
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            onSearchTermChange={onSearchTermChange}></SearchBar>
          <Layout style={isMobileDevice ? styles.mobileButtonGroup : styles.desktopButtonGroup}>
            <Popover
              visible={statusDropdownVisible}
              anchor={() => (
                <Button
                  status="primary"
                  appearance="ghost"
                  style={[styles.button]}
                  onPress={() => setStatusDropdownVisible(true)}
                  accessoryRight={statusDropdownVisible ? ArrowUpIcon : ArrowDownIcon}>
                  Status
                </Button>
              )}
              onBackdropPress={() => {
                setStatusDropdownVisible(false);
              }}
              style={styles.popover}>
              <Layout style={styles.popover}>
                <Text>Select status</Text>
                <ScrollView style={{ maxHeight: 250 }}>
                  {Object.entries(OC_STATUS_MAP).map(([key, val]) => (
                    <CheckBox
                      key={`${key}-${val}`}
                      checked={filterParams.status === key}
                      onChange={(checked) => {
                        if (checked) {
                          handlePopoverSelect(key);
                        } else {
                          handlePopoverSelect(undefined);
                        }
                      }}
                      style={styles.checkItem}>
                      {val}
                    </CheckBox>
                  ))}
                </ScrollView>
              </Layout>
            </Popover>

            <Popover
              visible={courierDropdownVisible}
              anchor={() => (
                <Button
                  status="primary"
                  appearance="ghost"
                  style={[styles.button]}
                  onPress={() => setCourierDropdownVisible(true)}
                  accessoryRight={courierDropdownVisible ? ArrowUpIcon : ArrowDownIcon}>
                  Courier
                </Button>
              )}
              onBackdropPress={() => {
                setCourierDropdownVisible(false);
              }}
              style={styles.popover}>
              <Layout style={styles.popover}>
                <Text>Select courier</Text>
                <ScrollView style={{ maxHeight: 250 }}>
                  {flatten(Object.values(outboundOrderTableStore.couriers)).map(
                    ({ logistics_product_code, logistics_product_name_en }) => (
                      <CheckBox
                        key={logistics_product_code}
                        checked={filterParams.logistics_product_code === logistics_product_code}
                        onChange={(checked) => {
                          if (checked) {
                            onFilterChange({
                              logistics_product_code,
                            });
                          } else {
                            onFilterChange({
                              logistics_product_code: undefined,
                            });
                          }
                        }}
                        style={styles.checkItem}>
                        {logistics_product_name_en}
                      </CheckBox>
                    ),
                  )}
                </ScrollView>
              </Layout>
            </Popover>

            {isWmsManager ? (
              <Popover
                visible={pickingTypeDropdownVisible}
                anchor={() => (
                  <Button
                    status="primary"
                    appearance="ghost"
                    style={[styles.button]}
                    onPress={() => setPickingTypeDropdownVisible(true)}
                    accessoryRight={pickingTypeDropdownVisible ? ArrowUpIcon : ArrowDownIcon}>
                    Picking Type
                  </Button>
                )}
                onBackdropPress={() => {
                  setPickingTypeDropdownVisible(false);
                }}
                style={styles.popover}>
                <Layout style={styles.popover}>
                  <Text>Select picking type</Text>
                  <ScrollView style={{ maxHeight: 250 }}>
                    {Object.entries(PICKING_TASK_TYPE).map(([name, code]) => (
                      <CheckBox
                        key={code}
                        checked={filterParams.pickingTaskType === code}
                        onChange={(checked) => {
                          if (checked) {
                            onFilterChange({
                              pickingTaskType: code,
                            });
                          } else {
                            onFilterChange({
                              pickingTaskType: undefined,
                            });
                          }
                        }}
                        style={styles.checkItem}>
                        {name}
                      </CheckBox>
                    ))}
                  </ScrollView>
                </Layout>
              </Popover>
            ) : null}

            {isUserWarehouseAccountAdmin ? (
              <Popover
                visible={organisationDropdownVisible}
                anchor={() => (
                  <Button
                    status="primary"
                    appearance="ghost"
                    style={[styles.button]}
                    onPress={() => setOrganisationDropdownVisible(true)}
                    accessoryRight={organisationDropdownVisible ? ArrowUpIcon : ArrowDownIcon}>
                    Organisation
                  </Button>
                )}
                onBackdropPress={() => {
                  setOrganisationDropdownVisible(false);
                }}
                style={styles.popover}>
                <Layout style={styles.popover}>
                  <Text>Select organisation</Text>
                  <ScrollView style={{ maxHeight: 250 }}>
                    {outboundOrderTableStore.organisationStore.items.map((org) => (
                      <CheckBox
                        key={org.id}
                        checked={filterParams.organisationId === +org.id}
                        onChange={(checked) => {
                          if (checked) {
                            onFilterChange({
                              organisationId: +org.id,
                            });
                          } else {
                            onFilterChange({
                              organisationId: undefined,
                            });
                          }
                        }}
                        style={styles.checkItem}>
                        {org.name}{' '}
                        <Text style={{ fontStyle: 'italic', color: 'rgba(0, 0, 0, 0.5)' }}>
                          id: {org.id}
                        </Text>
                      </CheckBox>
                    ))}
                  </ScrollView>
                </Layout>
              </Popover>
            ) : null}

            <Button
              status="primary"
              appearance="ghost"
              onPress={onPressAdvancedSearch}
              accessoryRight={ArrowUpRightIcon}
              style={styles.button}>
              Advanced
            </Button>
            <Button
              status="danger"
              appearance="ghost"
              onPress={() => {
                setSearchTerm('');
                onFilterClear();
                handleStatusSelect(null);
              }}
              style={styles.button}>
              Reset filter
            </Button>
          </Layout>
        </Layout>
        <Layout style={styles.indicator}>
          {FILTER_OC_STATUS.map((status) => (
            <FilterButton
              key={status}
              onPress={() => {
                if (selectedStatus === status) {
                  handleStatusSelect(null);
                } else {
                  handleStatusSelect(status);
                }
              }}
              isSelected={selectedStatus === status}
              status={status}
              count={countStatuses[status] || 0}
            />
          ))}
        </Layout>
      </Card>
    );
  },
);

const styles = StyleSheet.create({
  bannerContainer: {
    marginBottom: '0.5em',
    padding: '1em',
  },
  filterButton: {
    height: 45,
  },
  desktopBanner: {
    maxWidth: '1800px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  mobileBanner: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  indicator: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  chip: {
    marginTop: '0.4em',
    marginHorizontal: '0.2em',
    opacity: '0.5',
  },
  popover: {
    margin: '0.5em',
    padding: '0.5em',
  },
  rangeInput: {
    width: '100px',
    margin: '0.5em',
  },
  desktopButtonGroup: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mobileButtonGroup: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    height: '45px',
  },
  icon: {
    width: '28px',
    height: '28px',
    stroke: 'white',
    strokeWidth: '0.8',
  },
  popover: {
    margin: '0.5em',
    padding: '0.5em',
  },
  checkItem: {
    marginVertical: 4,
    marginHorizontal: 12,
  },
});

const ArrowDownIcon = (props) => (
  <Icon
    {...props}
    name="arrow-ios-downward-outline"
    style={[props.style, styles.icon, { width: '20px', height: '20px' }]}
  />
);

const ArrowUpIcon = (props) => (
  <Icon
    {...props}
    name="arrow-ios-upward-outline"
    style={[props.style, styles.icon, { width: '20px', height: '20px' }]}
  />
);

const ArrowUpRightIcon = (props) => (
  <Icon
    {...props}
    name="diagonal-arrow-right-up-outline"
    style={[props.style, styles.icon, { width: '20px', height: '20px' }]}
  />
);

export default memo(OutboundControl);
