import React, { useState, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { Banner, Card } from 'react-native-paper';
import { format, parseISO } from 'date-fns';
import { pipe, __, curry } from 'ramda';
import { observer } from 'mobx-react';
import { Button, Icon, Layout, Text } from '@ui-kitten/components';
import { Avatar } from '@ui-kitten/components';
import { UIStatusWrapper } from '../../components/ui-status';
import OrderTableControl from '../../components/OrderControl';
import Background from 'src/components/Background';
import Table from 'src/components/Table';
import { USER_SETTINGS } from 'src/core/user-settings';
import { uiKittenTheme, paperNativeTheme } from 'src/core/theme';
import exportCsv from '../../core/csv';
import { sanitize, ORDER_FULFILLMENT_STATUSES } from '../../core/utils/utils';
import { STORE_PROVIDERS } from '@ezom/library/lib/cjs/constants';
import { ORDER_FINANCIAL_STATUSES } from '@ezom/library/lib/cjs/constants';
import EBAY_LOGO from '../../assets/images/providers/ebay-logo.svg';
import SHOPIFY_LOGO from '../../assets/images/providers/shopify-logo.svg';
import WOOCOMMERCE_LOGO from '../../assets/images/providers/woocommerce-logo.svg';
import MAGENTO_LOGO from '../../assets/images/providers/magento-logo.svg';
import ETSY_LOGO from '../../assets/images/providers/etsy-logo.svg';
import { useIsFocused } from '@react-navigation/core';
import {
  orderTableStore,
  salesStatisticsStore,
  storeStore,
  warehouseAccountStore,
} from '../../store';
import { SyncOrdersModal } from './SyncOrdersModal';
import { OrderSearchModal } from './OrderSearchModal';
import { LineItemWithoutSkuWarningModal } from '../../components/LineItemWithoutSkuWarningModal';
import { WarehouseAccountSelectionModal } from '../../components/WarehouseAccountSelectionModal';

const styles = StyleSheet.create({
  cellContainer: { backgroundColor: 'inherit' },
  cellText: { marginBottom: '0.1em' },
  lineItem: { flexDirection: 'row', backgroundColor: 'inherit' },
  logo: {
    marginBottom: 5,
  },
});

const MAX_NO_OF_ITEMS_AUTO_SEARCH = 200;

const displayKeys = [
  'storeName',
  'provider',
  'number',
  'stockAvailability',
  'shippingAddress',
  'financialStatus',
  'lineItems',
];

const csvFormatter = {
  shippingAddress: ({ address1, address2, city, name, province, provinceCode, zip }) =>
    `${name}, ${address1}, ${address2}, ${city}, ${province}, ${provinceCode}, ${zip}`,
  lineItems: (items) =>
    items.map((item) => `${item.name}, ${item.variant}, ${item.sku}: ${item.quantity}`).join('; '),
};

const csvHeader = [
  'id',
  'number',
  'createdAt',
  'provider',
  'fulfillmentStatus',
  'shippingAddress',
  'lineItems',
  'price',
  'currency',
];

const titleByKey = {
  storeName: 'Store',
  provider: 'Provider',
  number: 'Order No.',
  stockAvailability: 'Stock',
  lineItems: 'Items',
  shippingAddress: 'Address',
  financialStatus: 'Payment',
};

const styleByKey = {
  storeName: { flex: 0.25 },
  provider: { flex: 0.1 },
  number: { flex: 0.25 },
  shippingAddress: { flex: 0.6 },
  financialStatus: { flex: 0.3 },
  stockAvailability: { flex: 0.14 },
};

export const lineItemsFormatter = (lineItems) => (
  <Layout style={styles.cellContainer}>
    {lineItems?.map((item, index) => (
      <Layout style={styles.lineItem} key={`lineitem-${index}`}>
        <Text category="p1" style={[styles.cellText, { marginRight: '0.4em', color: 'inherit' }]}>
          {item.fulfillableQuantity} of
        </Text>
        <Layout style={styles.cellContainer}>
          <Text category="p2" style={[styles.cellText, { color: 'inherit' }]}>
            {item.name} {sanitize(item.variant)}
          </Text>
          {item.sku ? (
            <Text category="p2" appearance="hint" style={[styles.cellText, { color: 'inherit' }]}>
              {sanitize(item.sku)}
            </Text>
          ) : null}
        </Layout>
      </Layout>
    ))}
  </Layout>
);

const formatterByKey = {
  number: (number, order) => (
    <Layout style={styles.cellContainer}>
      <Text category="p1" style={[styles.cellText, { color: 'inherit' }]}>
        {number}
      </Text>
      <Text category="p2" style={[styles.cellText, { color: 'inherit' }]}>
        {pipe(parseISO, curry(format)(__, 'd MMM y', {}))(order.createdAt)}
      </Text>
      <Text
        category="p2"
        style={styles.cellText}
        status={
          order.fulfillmentStatus === ORDER_FULFILLMENT_STATUSES.unfulfilled
            ? 'danger'
            : order.fulfillmentStatus === ORDER_FULFILLMENT_STATUSES.partial
            ? 'warning'
            : 'success'
        }>
        {order.fulfillmentStatus}
      </Text>
    </Layout>
  ),
  lineItems: lineItemsFormatter,
  shippingAddress: (address, order) => (
    <Layout style={styles.cellContainer}>
      <Text category="p2" style={[styles.cellText, { color: 'inherit' }]}>
        {sanitize(address.name)} {sanitize(address.company)}
      </Text>
      <Text category="p2" style={[styles.cellText, { color: 'inherit' }]}>
        {sanitize(address.address2)} {sanitize(address.address1)}
      </Text>
      <Text category="p2" style={[styles.cellText, { color: 'inherit' }]}>
        {sanitize(address.provinceCode)} {sanitize(address.zip)}
      </Text>
      <Text category="p2" style={[styles.cellText, { color: 'inherit' }]}>
        {sanitize(order.email)} {sanitize(address.phone)}
      </Text>
    </Layout>
  ),
  financialStatus: (financialStatus, order) => (
    <Layout style={styles.cellContainer}>
      <Text
        category="p2"
        style={styles.cellText}
        status={
          financialStatus === ORDER_FINANCIAL_STATUSES.paid
            ? 'success'
            : [
                ORDER_FINANCIAL_STATUSES.partially_paid,
                ORDER_FINANCIAL_STATUSES.partially_refunded,
              ].includes(financialStatus)
            ? 'warning'
            : financialStatus === ORDER_FINANCIAL_STATUSES.partially_refunded
            ? 'info'
            : 'danger'
        }>
        {sanitize(financialStatus)}
      </Text>
      <Text category="p2" style={[styles.cellText, { color: 'inherit' }]}>
        {sanitize(order.currency)} {sanitize(order.price)}
      </Text>
    </Layout>
  ),
  provider: (provider) => {
    let logo = null;
    switch (provider) {
      case STORE_PROVIDERS.SHOPIFY:
        logo = SHOPIFY_LOGO;
        break;
      case STORE_PROVIDERS.EBAY:
        logo = EBAY_LOGO;
        break;
      case STORE_PROVIDERS.WOOCOMMERCE:
        logo = WOOCOMMERCE_LOGO;
        break;
      case STORE_PROVIDERS.MAGENTO2:
        logo = MAGENTO_LOGO;
        break;
      case STORE_PROVIDERS.ETSY:
        logo = ETSY_LOGO;
      default:
        break;
    }

    return (
      <Layout>
        {<Avatar resizeMode="contain" size="small" source={logo} styles={styles.logo}></Avatar>}
      </Layout>
    );
  },
  storeName: (storeName) => {
    return (
      <Layout style={styles.cellContainer}>
        <Text category="p2" style={[styles.cellText, { color: 'inherit' }]}>
          {sanitize(storeName)}
        </Text>
      </Layout>
    );
  },
  stockAvailability: (_, order) => {
    let icon = 'checkmark-circle-2-outline';
    let fill = paperNativeTheme.colors.success;
    if (order.dontHaveAvailableAndOnwayStock) {
      icon = 'alert-circle-outline';
      fill = paperNativeTheme.colors.danger;
    } else if (order.dontHaveAvailableStock) {
      icon = 'clock-outline';
      fill = paperNativeTheme.colors.warning;
    }
    return <Icon fill={fill} name={icon} style={{ width: 20, height: 20 }} />;
  },
};

const getId = (i) => i.id;

export const OrderListScreen = observer(({ navigation, route }) => {
  const [visibleSearch, setVisibleSearch] = useState(false);
  const [visibleSync, setVisibleSync] = useState(false);

  const isFocused = useIsFocused();
  // Refetch data when screen is focused
  useEffect(() => {
    if (isFocused) {
      orderTableStore.fetchItems();
    }
  }, [isFocused]);

  return (
    <Background fullWidth={true}>
      <Banner
        style={{ backgroundColor: uiKittenTheme['color-primary-100'] }}
        visible={salesStatisticsStore.lineItemsWithoutSkuBannerVisible}
        actions={
          salesStatisticsStore.lineItemsWithoutSkuModalOpen
            ? []
            : [
                {
                  label: 'Learn more',
                  onPress: salesStatisticsStore.openLineItemsWithoutSkuModal,
                },
              ]
        }
        icon={() => (
          <Icon
            fill={paperNativeTheme.colors.danger}
            name="alert-triangle-outline"
            status="danger"
            style={{ width: 20, height: 20 }}
          />
        )}>
        There are products without SKU, please fix them otherwise there might be errors in analysis.
      </Banner>
      <LineItemWithoutSkuWarningModal
        visible={salesStatisticsStore.lineItemsWithoutSkuModalOpen}
        items={salesStatisticsStore.lineItemsWithoutSku}
        onBackdropPress={salesStatisticsStore.closeLineItemsWithoutSkuModal}
      />
      <OrderSearchModal
        stores={orderTableStore.storeStore.enabledStores}
        visibleSearch={visibleSearch}
        setVisibleSearch={setVisibleSearch}
      />
      <SyncOrdersModal
        stores={orderTableStore.storeStore.enabledStores}
        visibleSync={visibleSync}
        setVisibleSync={setVisibleSync}
      />
      <OrderTableControl
        stores={orderTableStore.storeStore.enabledStores}
        filterParams={orderTableStore.filterParams}
        indicators={getIndicators(orderTableStore.filterParams)}
        onSearchTermChange={(term) => {
          orderTableStore.setFilterParams({
            ...orderTableStore.filterParams,
            searchTerm: term?.trim(),
          });
        }}
        onPressAdvancedSearch={() => setVisibleSearch(true)}
        onFilterClear={() => {
          orderTableStore.resetFilterParams();
        }}
        onFilterChange={(params) => {
          const mergedParams = { ...orderTableStore.filterParams, ...params };
          orderTableStore.setFilterParams(mergedParams);
        }}
        onSortChange={({ key, direction }) => {
          orderTableStore.setFilterParams({
            ...orderTableStore.filterParams,
            sortDirection: direction,
            sortByKey: key,
          });
        }}
        disableSearch={orderTableStore.loading}
        requireButtonClickToSearch={orderTableStore.total > MAX_NO_OF_ITEMS_AUTO_SEARCH}
      />
      <UIStatusWrapper
        status={{ indeterminate: orderTableStore.loading, error: orderTableStore.error }}>
        <Card style={styles.card}>
          <Table
            items={orderTableStore.items}
            getId={getId}
            displayKeys={displayKeys}
            titleByKey={titleByKey}
            formatterByKey={formatterByKey}
            styleByKey={styleByKey}
            heightOffset={salesStatisticsStore.lineItemsWithoutSkuBannerVisible ? 440 : 340}
            totalItemNum={orderTableStore.total}
            itemsPerPage={orderTableStore.limit}
            currentPage={orderTableStore.currentPage}
            onPageChange={(page) => {
              orderTableStore.setCurrentPage(page);
            }}
            setPageSize={orderTableStore.setPageSize}
            emptyDataMsg={
              <Layout>
                <Text category="p1" style={{ marginBottom: 20 }}>
                  No unfulfilled orders found
                </Text>
                <Button appearance="outline" onPress={() => setVisibleSync(true)}>
                  Sync orders
                </Button>
              </Layout>
            }
            rowOnClick={({ id, storeId }) => {
              navigation.navigate('OrderDetailScreen', {
                id,
                storeId,
                consignmentNumbers: undefined,
              });
            }}
            sortAt="backend"
            isFabVisible={useIsFocused()}
            fabActions={(selectedIds) => {
              const actions = [];

              if (selectedIds.size === 0) {
                actions.push({
                  icon: 'magnify',
                  label: 'Search orders',
                  onPress: () => setVisibleSearch(true),
                });
                actions.push({
                  icon: 'download-outline',
                  label: 'Export all to CSV',
                  onPress: () =>
                    exportCsv(orderTableStore.items, csvHeader, 'orders.csv', csvFormatter),
                });
                actions.push({
                  icon: 'sync',
                  label: 'Sync orders',
                  onPress: () => setVisibleSync(true),
                });
              }

              const ids = Array.from(selectedIds)
                .flatMap((orderId) => orderTableStore.items.filter((o) => getId(o) === orderId))
                .map((o) => ({
                  orderId: o.id,
                  storeId: o.storeId,
                }));

              if (selectedIds.size > 0) {
                actions.push({
                  icon: 'download-outline',
                  label: `Export selected ${selectedIds.size > 1 ? 'orders' : 'order'} to CSV`,
                  onPress: () => {
                    const selectedRecords = orderTableStore.items.filter((record) =>
                      selectedIds.has(record.id),
                    );
                    exportCsv(selectedRecords, csvHeader, 'orders.csv', csvFormatter);
                  },
                });

                actions.push({
                  icon: selectedIds.size > 1 ? 'check-all' : 'check',
                  label: `Fulfill selected ${selectedIds.size > 1 ? 'orders' : 'order'}`,
                  onPress: () => {
                    navigation.navigate('OrderDispatchScreen', {
                      ids: JSON.stringify(ids),
                      localPickup: false,
                    });
                  },
                });
              }

              if (USER_SETTINGS.localPickupEnabled && selectedIds.size > 0) {
                actions.push({
                  icon: 'call-merge',
                  label: `Merge consignment for ${
                    selectedIds.size > 1 ? `${selectedIds.size} orders` : 'order'
                  }`,
                  onPress: () => {
                    const selectedOrders = orderTableStore.items.filter((record) =>
                      selectedIds.has(record.id),
                    );
                    const storeIds = new Set(selectedOrders.map((o) => o.storeId));
                    const warehouseAccountIds = new Set();
                    for (const storeId of storeIds) {
                      const ids = storeStore.getStoreWarehouseAccountsById(storeId);
                      ids.forEach((id) => warehouseAccountIds.add(id));
                    }
                    if (warehouseAccountIds.size > 1) {
                      orderTableStore.setOnWarehouseSelectionSubmit((warehouseAccount) => {
                        orderTableStore.setSelectedWarehouseAccount(warehouseAccount);
                        navigation.navigate('OrderDispatchScreen', {
                          ids: JSON.stringify(ids),
                          localPickup: true,
                          warehouseAccountId: orderTableStore.selectedWarehouseAccount.id,
                        });
                      });
                      orderTableStore.setWarehouseSelectionVisible(true);
                    } else {
                      navigation.navigate('OrderDispatchScreen', {
                        ids: JSON.stringify(ids),
                        localPickup: true,
                        warehouseAccountId: [...warehouseAccountIds][0],
                      });
                    }
                  },
                });
              }
              return actions;
            }}
          />
        </Card>
      </UIStatusWrapper>

      <WarehouseAccountSelectionModal
        visible={orderTableStore.warehouseSelectionVisible}
        warehouseAccounts={warehouseAccountStore.warehouseAccounts}
        loading={warehouseAccountStore.loading}
        error={warehouseAccountStore.error}
        onSubmit={(warehouseAccount) => {
          orderTableStore.onWarehouseSelectionSubmit(warehouseAccount);
          orderTableStore.setWarehouseSelectionVisible(false);
        }}
        onCancel={() => orderTableStore.setWarehouseSelectionVisible(false)}
      />
    </Background>
  );
});

const getIndicators = (params) => {
  const dateFormatter = pipe(parseISO, curry(format)(__, 'd MMM y', {}));
  let indicators = [];
  if (params.orderNumber) {
    indicators.push(`order number: ${params.orderNumber}`);
  }

  if (params.statuses && params.statuses.length > 0) {
    indicators.push(`${params.statuses.join(' or ')} orders`);
  }

  if (params.providers && params.providers.length > 0) {
    indicators.push(`From ${params.providers.join(' or ')}`);
  }

  if (params.financialStatuses && params.financialStatuses?.length > 0) {
    indicators.push(
      `${params.financialStatuses
        .map((s) => s.toLowerCase().replace(/_/g, ' '))
        .join(' or ')} payment`,
    );
  }
  if (params.fulfillmentStatuses && params.fulfillmentStatuses?.length > 0) {
    indicators.push(
      `${params.fulfillmentStatuses
        .map((s) => s.toLowerCase().replace(/_/g, ' '))
        .join(' or ')} orders`,
    );
  }
  if (params.minCreated && params.maxCreated) {
    indicators.push(
      `orders from ${dateFormatter(params.minCreated)} to ${dateFormatter(params.maxCreated)}`,
    );
  } else if (params.minCreated) {
    indicators.push(`orders from ${dateFormatter(params.minCreated)}`);
  } else if (params.maxCreated) {
    indicators.push(`orders by ${dateFormatter(params.maxCreated)}`);
  }

  if (params.customerName) {
    indicators.push(`customer name: ${params.customerName}`);
  }

  if (params.customerEmail) {
    indicators.push(`email: ${params.customerEmail}`);
  }

  return indicators;
};
