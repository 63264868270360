import { Icon } from '@ui-kitten/components';
import React from 'react';

export const ArrowUpIcon = (props) => (
  <Icon
    {...props}
    name="diagonal-arrow-right-up-outline"
    style={[props.style, { width: '20px', height: '20px' }]}
  />
);
