import '@expo/match-media';
import React, { memo } from 'react';
import { StyleSheet } from 'react-native';
import { Layout, Input, Text, Datepicker } from '@ui-kitten/components';
import { useIsMobile } from '../core/responsive.utils';

const DateRangeInput = ({
  label,
  minVal,
  maxVal,
  minTouched,
  maxTouched,
  minError,
  maxError,
  minPlaceholder,
  maxPlaceholder,
  size = 'medium',
  style = {},
  onMinValueChange,
  onMaxValueChange,
  onMinValueBlur = () => {},
  onMaxValueBlur = () => {},
}) => {
  const isMobileDevice = useIsMobile();
  return (
    <Layout
      style={[
        styles.rowContainer,
        isMobileDevice ? styles.mobileRowFlex : styles.desktopRowFlex,
        style,
      ]}
      level="1">
      {label ? (
        <Text
          category={size === 'large' ? 's1' : size === 'medium' ? 's2' : 'label'}
          style={isMobileDevice ? styles.mobileLabel : styles.desktopLabel}>
          {label}
        </Text>
      ) : null}
      <Layout style={[isMobileDevice ? styles.mobileInput : styles.desktopInput]}>
        <Datepicker
          size={size}
          style={styles.input}
          date={minVal}
          max={maxVal}
          label="From"
          status={minTouched && minError ? 'danger' : 'primary'}
          placeholder={minPlaceholder}
          caption={minError}
          onSelect={onMinValueChange}
          onBlur={onMinValueBlur}
        />
        <Datepicker
          size={size}
          style={styles.input}
          date={maxVal}
          min={minVal}
          label="To"
          status={maxTouched && maxError ? 'danger' : 'primary'}
          placeholder={maxPlaceholder}
          caption={maxError}
          onSelect={onMaxValueChange}
          onBlur={onMaxValueBlur}
        />
      </Layout>
    </Layout>
  );
};

const styles = StyleSheet.create({
  input: {
    flex: 1,
    marginHorizontal: '5px',
  },
  mobileLabel: {
    flex: 1,
    flexWrap: 'wrap',
    maxWidth: '100px',
  },
  desktopLabel: {
    flex: 1,
    width: '130px',
    maxWidth: '130px',
  },
  rowContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  mobileRowFlex: {
    marginHorizontal: 'auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  desktopRowFlex: {
    flexDirection: 'row',
    width: 'auto',
  },
  desktopInput: {
    flexDirection: 'row',
    width: '260px',
    minWidth: '260px',
  },
  mobileInput: {
    flexDirection: 'column',
    width: '200px',
    minWidth: '200px',
  },
});

export default memo(DateRangeInput);
