import React, { memo, useCallback, useState } from 'react';
import { USER_SETTINGS } from '../../core/user-settings';
import LocalPickUpStaging from '../../components/LocalPickUpStaging';
import { useFocusEffect } from '@react-navigation/native';
import { useMemo } from 'react';
import OrderDispatchStaging from '../../components/OrderDispatchStaging';
import { observer } from 'mobx-react';
import { StyleSheet, View } from 'react-native';
import { FAB } from 'react-native-paper';
import { paperNativeTheme } from '../../core/theme';
import { reject } from 'ramda';

const OrderDispatchScreen = observer(({ navigation, route }) => {
  const { ids, warehouseAccountId } = route.params || {};
  const [fabOpen, setFabOpen] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [orderIdsToDispatch, setOrderIdsToDispatch] = useState(null);

  const fabActions = [
    {
      icon: 'trash-can-outline',
      label: 'Delete',
      onPress: () => {
        setOrderIdsToDispatch(
          reject(({ orderId }) => checkedItems.includes(orderId), orderIdsToDispatch),
        );

        if (orderIdsToDispatch.length === 1) {
          goBack();
        }
      },
    },
  ];

  if (!ids || ids.length === 0) {
    navigation.navigate('OrderListScreen');
    return false;
  }

  const goBack = useCallback(() => {
    navigation.dangerouslyGetState().history.length > 1
      ? navigation.goBack()
      : navigation.navigate('Outbound');
  });

  useFocusEffect(
    useCallback(() => {
      try {
        const orderIds = JSON.parse(ids);
        setOrderIdsToDispatch(orderIds);
      } catch (e) {
        goBack();
      }
    }, [route.params]),
  );

  const localPickup = useMemo(
    () => JSON.parse(route.params.localPickup || false) && USER_SETTINGS.localPickupEnabled,
    [route.params.localPickup],
  );

  if (!orderIdsToDispatch) {
    return null;
  }

  return (
    <View style={{ flex: 1, position: 'relative' }}>
      {!!checkedItems.length && (
        <FAB.Group
          visible={true}
          fabStyle={styles.fab}
          open={fabOpen}
          icon={'chevron-up'}
          actions={fabActions}
          onStateChange={({ open }) => setFabOpen(open)}
        />
      )}
      <View style={{ zIndex: -1 }}>
        {localPickup ? (
          <LocalPickUpStaging
            orderIds={orderIdsToDispatch}
            warehouseAccountId={warehouseAccountId}
            onSubmit={goBack}
            onDismiss={goBack}
            goBack={goBack}
            setOrderIdsToDispatch={setOrderIdsToDispatch}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
          />
        ) : (
          <OrderDispatchStaging
            orderIdsToDispatch={orderIdsToDispatch}
            goBack={goBack}
            setOrderIdsToDispatch={setOrderIdsToDispatch}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
          />
        )}
      </View>
    </View>
  );
});

export default memo(OrderDispatchScreen);

const styles = StyleSheet.create({
  fab: {
    backgroundColor: paperNativeTheme.colors.primary,
  },
});
