import React from 'react';
import { Modal, Portal } from 'react-native-paper';
import { StyleSheet } from 'react-native';
import { addDays } from 'date-fns';
import OrderSearchForm from 'src/components/OrderSearchForm';
import { orderTableStore } from '../../store';
import { observer } from 'mobx-react';
import { not, isEmpty, omit } from 'ramda';

const styles = StyleSheet.create({
  modalStyle: {
    minWidth: 400,
    backgroundColor: 'white',
    padding: 20,
    alignSelf: 'center',
  },
});

export const OrderSearchModal = observer(({ stores, visibleSearch, setVisibleSearch }) => {
  return (
    <Portal>
      <Modal
        visible={visibleSearch}
        onDismiss={() => setVisibleSearch(false)}
        contentContainerStyle={styles.modalStyle}>
        <OrderSearchForm
          stores={stores}
          searchParams={{
            ...orderTableStore.filterParams,
            storeNames: orderTableStore.filterParams.storeNames.join('\n'),
            providers: orderTableStore.filterParams.providers.join('\n'),
            financialStatuses: orderTableStore.filterParams.financialStatuses.join('\n'),
            fulfillmentStatuses: orderTableStore.filterParams.fulfillmentStatuses.join('\n'),
            countryCodes: orderTableStore.filterParams.countryCodes.join('\n'),
            minCreated:
              orderTableStore.filterParams.minCreated &&
              new Date(orderTableStore.filterParams.minCreated),
            maxCreated:
              orderTableStore.filterParams.maxCreated &&
              new Date(orderTableStore.filterParams.maxCreated),
          }}
          onSubmit={(formParams) => {
            setVisibleSearch(false);
            const params = {
              ...orderTableStore.filterParams,
              ...formParams,
              storeNames: formParams.storeNames.split('\n').filter((s) => not(isEmpty(s))),
              providers: formParams.providers.split('\n').filter((s) => not(isEmpty(s))),
              fulfillmentStatuses: formParams.fulfillmentStatuses
                ? formParams.fulfillmentStatuses.split('\n').filter((s) => not(isEmpty(s)))
                : [],
              financialStatuses: formParams.financialStatuses
                ? formParams.financialStatuses.split('\n').filter((s) => not(isEmpty(s)))
                : [],
              countryCodes: formParams.countryCodes
                ? formParams.countryCodes.split('\n').filter((c) => not(isEmpty(c)))
                : [],
              minCreated: formParams.minCreated ? formParams.minCreated.toISOString() : '',
              maxCreated: formParams.maxCreated
                ? addDays(formParams.maxCreated, 1).toISOString()
                : '',
              customerEmail: formParams.customerEmail,
            };
            orderTableStore.setFilterParams(params);
          }}
          onDismiss={() => setVisibleSearch(false)}
        />
      </Modal>
    </Portal>
  );
});
