import React from 'react';
import { StyleSheet } from 'react-native';
import { Modal, Portal } from 'react-native-paper';
import { ezTheme } from '../../core/theme';
import AddressBookList from '../../screens/shipments/AddressBookList';

export const SelectAddressBookModal = ({ onSelect, onClose, visible }) => {
  return (
    <Portal>
      <Modal
        style={styles.container}
        visible={visible}
        onDismiss={onClose}
        dismissable={true}
        contentContainerStyle={styles.modalContent}>
        <AddressBookList type={'selectAddressBook'} onSelect={onSelect} />
      </Modal>
    </Portal>
  );
};
const styles = StyleSheet.create({
  container: {},
  backdropStyle: {
    backgroundColor: ezTheme.backdropModalColor,
  },
  modalContent: {
    backgroundColor: 'white',
    padding: 20,
    width: '80%',
    borderRadius: 5,
    marginHorizontal: 'auto',
  },
});
