import React from 'react';
import { Button, Card, Icon } from '@ui-kitten/components';
import { TextInputField } from './input/TextInputField';
import Autocomplete from './Autocomplete';
import { countryStateCityCurrencyStore } from '../store';
import { useSearchHsCode } from '../core/utils/utils';
import { observer } from 'mobx-react';
import { StyleSheet } from 'react-native';

const HS_CODE_LOOKUP = 'https://www.findhs.codes/';

export default observer(({ key, onRemove, values, touched, errors, handleChange, handleBlur }) => {
  const searchHsCode = useSearchHsCode();

  return (
    <Card
      key={key}
      footer={() =>
        onRemove ? (
          <Button
            size="medium"
            appearance="ghost"
            status="danger"
            accessoryLeft={(props) => <Icon {...props} name="minus-circle-outline" />}
            onPress={onRemove}>
            Remove import country
          </Button>
        ) : null
      }>
      <Autocomplete
        label="Export to country"
        defaultValue={values.country}
        options={countryStateCityCurrencyStore.countryCodes}
        touched={touched?.country}
        error={errors?.country}
        style={styles.field}
        onSelect={handleChange('country')}
        onBlur={handleBlur('country')}
      />
      <TextInputField
        label="Import port"
        name="import_port"
        style={styles.field}
        handleChange={handleChange}
        handleBlur={handleBlur}
        values={values}
        errors={errors}
      />
      <TextInputField
        label="Import declare value"
        name="declare_value"
        style={styles.field}
        handleChange={handleChange}
        handleBlur={handleBlur}
        values={values}
        errors={errors}
      />
      <Autocomplete
        label="Import declare currency"
        defaultValue={values.currency}
        options={countryStateCityCurrencyStore.currencies}
        touched={touched?.currency}
        error={errors?.currency}
        style={styles.field}
        onSelect={handleChange('currency')}
        onBlur={handleBlur('currency')}
      />
      <TextInputField
        label="Import declaration"
        name="import_declare"
        style={styles.field}
        handleChange={handleChange}
        handleBlur={handleBlur}
        values={values}
        errors={errors}
      />
      <Autocomplete
        label="Import HS-CODE"
        defaultValue={values.hs_code}
        touched={touched?.hs_code}
        error={errors?.hs_code}
        style={styles.field}
        onSelect={handleChange('hs_code')}
        onBlur={handleBlur('hs_code')}
        getValue={(hsCode) => hsCode.HS}
        getTitle={(hsCode) => `${hsCode.HS} - ${hsCode.description}`}
        onSearch={searchHsCode}
        allowFreetext={true}
      />
      <Button
        appearance="ghost"
        status="info"
        onPress={() => {
          window.open(HS_CODE_LOOKUP, '_blank');
        }}>
        Look up HS-CODE
      </Button>
      <Autocomplete
        label="Export from country"
        defaultValue={values.export_country}
        options={countryStateCityCurrencyStore.countryCodes}
        touched={touched?.export_country}
        error={errors?.export_country}
        style={styles.field}
        onSelect={handleChange('export_country')}
        onBlur={handleBlur('export_country')}
      />
      <TextInputField
        label="Export port"
        name="export_port"
        style={styles.field}
        handleChange={handleChange}
        handleBlur={handleBlur}
        values={values}
        errors={errors}
      />

      <TextInputField
        label="Export declare value"
        name="export_declare_value"
        style={styles.field}
        handleChange={handleChange}
        handleBlur={handleBlur}
        values={values}
        errors={errors}
      />
      <Autocomplete
        label="Export declare currency"
        defaultValue={values.export_currency}
        options={countryStateCityCurrencyStore.currencies}
        touched={touched?.export_currency}
        error={errors?.export_currency}
        style={styles.field}
        onSelect={handleChange('export_currency')}
        onBlur={handleBlur('export_currency')}
      />
      <TextInputField
        label="Export declaration"
        name="export_declare"
        style={styles.field}
        handleChange={handleChange}
        handleBlur={handleBlur}
        values={values}
        errors={errors}
      />
      <Autocomplete
        label="Export HS-CODE"
        defaultValue={values.export_hscode}
        touched={touched?.export_hscode}
        error={errors?.export_hscode}
        style={styles.field}
        onSelect={handleChange('export_hscode')}
        onBlur={handleBlur('export_hscode')}
        getValue={(hsCode) => hsCode.HS}
        getTitle={(hsCode) => `${hsCode.HS} - ${hsCode.description}`}
        onSearch={searchHsCode}
        allowFreetext={true}
      />
    </Card>
  );
});

const styles = StyleSheet.create({
  field: {
    marginVertical: 8,
  },
});
