import React, { useMemo } from 'react';
import { StyleSheet, Dimensions, ScrollView, Linking } from 'react-native';
import { Layout, Button, Text, Divider } from '@ui-kitten/components';
import * as Yup from 'yup';
import { useIsMobile } from '../core/responsive.utils';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import Select from './Select';
import { omit, remove, slice } from 'ramda';
import InlineTable from './InlineTable';
import { TextInputField } from './input/TextInputField';
import { TRANSPORTATION_TYPE, unifiedAlert } from '../core/utils/utils';
import { inboundOrderStore, warehousesStore } from '../store/index';
import { UIStatusWrapper } from './ui-status';
import BoxSkuQtyInput from './BoxSkuQtyInput';
import * as DocumentPicker from 'expo-document-picker';
import { inventoryStore } from '../store/index';
import * as ExpoLinking from 'expo-linking';
import Autocomplete from './Autocomplete';

const windowHeight = Dimensions.get('window').height;

const findDuplicateBoxSkuIndex = (iconsignment_sku) => {
  const signatures = iconsignment_sku.map((s) => `${s.box_no}-${s.sku_code}`);
  const duplciateIdx = signatures.findIndex((s, index) => index !== signatures.indexOf(s));
  return duplciateIdx;
};

const isBoxNosValid = (iconsignment_sku) => {
  const boxNos = [...new Set(iconsignment_sku.map((s) => Number(s.box_no)))].sort((a, b) => a - b);
  const diff = slice(0, -1, boxNos).map((n, i) => boxNos[i + 1] - n);
  return !(boxNos[0] !== 1 || (diff.length > 0 && diff.find((d) => d !== 1)));
};

const onDocumentSelected = async (onDataLoaded) => {
  let result = await DocumentPicker.getDocumentAsync({});

  const reader = new FileReader();
  const XLSX = await import('xlsx/xlsx.mjs');

  reader.onload = (e) => {
    const data = e.target.result;
    const workbook = XLSX.read(data, { type: 'array' });

    const csv = workbook.SheetNames.reduce((a, sheetName) => {
      const worksheet = workbook.Sheets[sheetName];
      return XLSX.utils.sheet_to_json(worksheet);
    }, {});
    onDataLoaded(csv);
  };

  reader.readAsArrayBuffer(result.file);
};

export default observer(({ onDismiss, onSubmit, warehouseAccount }) => {
  const isMobile = useIsMobile();
  const warehouses = warehousesStore.warehousesByAccountId[warehouseAccount.id] || [];
  const warehouseNames = useMemo(() => {
    return warehouses.map((w) => w.warehouse_name_en);
  }, [warehouses]);

  return (
    <UIStatusWrapper
      status={{
        error: warehousesStore.error,
        indeterminate: warehousesStore.loading,
      }}>
      <ScrollView keyboardShouldPersistTaps={'handled'} style={styles.formContainer}>
        <Layout style={styles.form}>
          <Text category="h5" style={styles.title}>
            Create Inbound Consignment
          </Text>
          <Divider />
          <Formik
            isInitialValid={false}
            initialValues={{
              ref_no: '',
              to_warehouse_code: '',
              transport_type: 'S',
              logistic_service_info: {},
              print_box_no: 'N',
              remark: '',
              iconsignment_sku: [],
            }}
            validationSchema={Yup.object({
              ref_no: Yup.string()
                .matches(
                  /^[A-Za-z0-9\-\_\#\*\/\&\+\?\.\(\)]+$/,
                  'Only allow uppercase letters, numbers, -, _, #, *, /, &, +, ?, ., ( and ).',
                )
                .required(),
              to_warehouse_code: Yup.string().required(),
              transport_type: Yup.string().required().oneOf(['A', 'S', 'E', 'G', 'R']),
              logistic_service_info: Yup.object().required(),
              print_box_no: Yup.string().required().oneOf(['Y', 'N']),
              remark: Yup.string().max(50),
              iconsignment_sku: Yup.array()
                .min(1)
                .of(
                  Yup.object({
                    box_no: Yup.number().integer().min(1),
                    sku_code: Yup.string().required(),
                    plan_qty: Yup.number().integer().required().min(1),
                  }),
                ),
            })}
            onSubmit={async (values, formikActions) => {
              const duplciateIdx = findDuplicateBoxSkuIndex(values.iconsignment_sku);
              if (duplciateIdx > -1) {
                unifiedAlert(
                  `The SKU ${values.iconsignment_sku[duplciateIdx].sku_code} appeared more than once in box ${values.iconsignment_sku[duplciateIdx].box_no}`,
                );
                return;
              }

              if (!isBoxNosValid(values.iconsignment_sku)) {
                unifiedAlert(`Box numbers have to be incremental starting from 1`);
                return;
              }

              const { consignment_no, createTransaction } = await inboundOrderStore.addItem(
                warehouseAccount.id,
                {
                  ref_no: values.ref_no,
                  to_warehouse_code: values.to_warehouse_code,
                  transport_type: values.transport_type,
                  remark: values.remark,
                  iconsignment_sku: values.iconsignment_sku.map((s) => ({
                    ...omit(['_productName'], s),
                    box_no: Number(s.box_no),
                  })),
                },
              );
              unifiedAlert(
                `${consignment_no} successfully created.` +
                  (createTransaction
                    ? ` A charge of $${createTransaction.amount} has been placed on hold. The final charge might be adjusted more or less depending on the received items.`
                    : ''),
              );
              onSubmit();
              formikActions.setSubmitting(false);
            }}>
            {(props) => {
              return (
                <>
                  <TextInputField
                    autoFocus
                    name="ref_no"
                    style={styles.field}
                    label="Ref No."
                    {...props}
                  />
                  <Autocomplete
                    label="Warehouse"
                    defaultValue={
                      warehouses.find((w) => w.warehouse_code === props.values.to_warehouse_code)
                        ?.warehouse_name_en || props.values.to_warehouse_code
                    }
                    error={props.errors.to_warehouse_code}
                    touched={props.touched.to_warehouse_code}
                    placeholder="Select a warehosue"
                    style={styles.field}
                    options={warehouseNames}
                    onSelect={(val) => {
                      const value =
                        warehouses.find((w) => w.warehouse_name_en === val)?.warehouse_code || val;
                      props.setFieldValue('to_warehouse_code', value);
                    }}
                    onBlur={props.handleBlur('to_warehouse_code')}
                  />
                  <Select
                    label="Transportation"
                    value={TRANSPORTATION_TYPE[props.values.transport_type]}
                    error={props.errors.transport_type}
                    touched={props.touched.transport_type}
                    style={styles.field}
                    options={Object.entries(TRANSPORTATION_TYPE).map(([key, val]) => ({
                      key,
                      val,
                    }))}
                    onChange={({ val, key }) => props.setFieldValue('transport_type', key)}
                    onBlur={() => props.setFieldTouched('transport_type', true)}
                  />
                  <TextInputField
                    name="remark"
                    style={styles.field}
                    label="Note"
                    textStyle={{ minHeight: 64 }}
                    multiline={true}
                    {...props}
                  />
                  <Divider />
                  <Layout
                    style={isMobile ? styles.mobileButtonContainer : styles.desktopButtonContainer}>
                    <Button
                      appearance={'ghost'}
                      style={styles.button}
                      onPress={() => {
                        ExpoLinking.openURL(require('../spreadsheetTemplates/IcSkuTemplate.xlsx'));
                      }}>
                      Download SKU Template
                    </Button>
                    <Button
                      appearance="outline"
                      style={styles.button}
                      onPress={() =>
                        onDocumentSelected((xlsData) =>
                          props.setFieldValue(
                            'iconsignment_sku',
                            xlsData.map((row) => ({
                              box_no: row['Box No.'],
                              plan_qty: row['Quantity'],
                              sku_code: row['SKU'],
                              _productName: inventoryStore.items.find(
                                (s) => s.sku_code === row['SKU'],
                              )?.sku_name,
                            })),
                          ),
                        )
                      }>
                      Upload SKUs in Batch
                    </Button>
                  </Layout>
                  <BoxSkuQtyInput
                    label="Box and SKU"
                    size="medium"
                    itemLabel="SKU"
                    qtyLabel="Quantity"
                    validateStock={false}
                    warehouseAccountId={warehouseAccount.id}
                    onSubmit={(entry) =>
                      props.setFieldValue('iconsignment_sku', [
                        ...props.values.iconsignment_sku,
                        {
                          box_no: entry.boxNo,
                          sku_code: entry.sku,
                          plan_qty: entry.qty,
                          _productName: entry.productName,
                        },
                      ])
                    }
                  />
                  <InlineTable
                    items={props.values.iconsignment_sku}
                    columnByKey={{
                      box_no: { title: 'Box', size: 'small' },
                      sku_code: { title: 'SKU' },
                      _productName: { title: 'Name' },
                      plan_qty: { title: 'QTY', size: 'small', numeric: true },
                    }}
                    onRemoveItem={(index) =>
                      props.setFieldValue(
                        'iconsignment_sku',
                        remove(index, index + 1, props.values.iconsignment_sku),
                      )
                    }
                  />
                  <Divider />
                  <Layout
                    style={isMobile ? styles.mobileButtonContainer : styles.desktopButtonContainer}>
                    <Button
                      status="primary"
                      loading={props.isSubmitting}
                      onPress={props.handleSubmit}
                      disabled={props.isSubmitting || !props.isValid}
                      style={styles.button}>
                      Submit
                    </Button>
                    <Button
                      status="basic"
                      loading={props.isSubmitting}
                      onPress={onDismiss}
                      disabled={props.isSubmitting}
                      style={styles.button}>
                      Cancel
                    </Button>
                    <Button
                      status="danger"
                      appearance="ghost"
                      disabled={props.isSubmitting}
                      onPress={props.handleReset}
                      style={styles.button}>
                      Reset
                    </Button>
                  </Layout>
                </>
              );
            }}
          </Formik>
        </Layout>
      </ScrollView>
    </UIStatusWrapper>
  );
});

const styles = StyleSheet.create({
  field: {
    marginVertical: 8,
  },
  title: {
    marginVertical: 10,
  },
  form: {
    alignSelf: 'center',
  },
  button: {
    marginVertical: 10,
    marginHorizontal: 2,
  },
  desktopButtonContainer: {
    flexDirection: 'row-reverse ',
  },
  mobileButtonContainer: {
    flexDirection: 'column',
  },
  formContainer: {
    height: windowHeight - 100,
    overflowY: 'auto',
  },
});
