import { flow, makeAutoObservable } from 'mobx';
import gql from 'graphql-tag';
import { unifiedAlert } from '../core/utils/utils';

const GET_ORGANISATIONS = gql`
  query GetOrganisations {
    organisations {
      id
      name
      abn
      acn
      tfn
      email
      address
      phone

      users {
        id
      }

      stores {
        id
        platformID
        name
        provider
        apiUrl
        apiToken
        isDisabled
      }

      rates

      shippingRates {
        originCountry
        originPostcodeFrom
        originPostcodeTo
        carrierName
        zone
        weightFromKg
        weightToKg
        baseCost
        ratePerKg
        minimumCharge
      }
      deliveryZones {
        carrierName
        suburb
        postcodeFrom
        postcodeTo
        zone
        minBusinessDays
        maxBusinessDays
        country
      }
      lastMileSurcharges {
        name
        carrierName
        country
        unit
        condition
        min
        max
        fee
        additionalCharge
      }
      rasCharges {
        name
        carrierName
        suburb
        country
        state
        postcode
        charge
      }
    }
  }
`;

const GET_ORG_NAMES = gql`
  query GetOrganisations {
    organisations {
      id
      name
    }
  }
`;

export class OrganisationStore {
  cachedItems = null;
  cachedOrgNameItems = null;
  orgId = null;
  loading = true;
  error = null;

  constructor(client) {
    this.client = client;
    makeAutoObservable(
      this,
      {
        fetchItems: flow,
        client: false,
      },
      { autoBind: true },
    );
  }

  setOrgId = (orgId) => {
    this.orgId = orgId;
  };

  getOrgId = () => {
    return this.orgId;
  };

  *fetchItems() {
    this.loading = true;
    try {
      const { data } = yield this.client.query({
        query: GET_ORGANISATIONS,
        fetchPolicy: 'no-cache',
      });
      this.cachedItems = data.organisations;
    } catch (error) {
      const msg = 'Error! ' + JSON.stringify(error.message || error);
      unifiedAlert(msg);
      throw error;
    } finally {
      this.loading = false;
    }
  }

  *fetchOrgNameItems() {
    this.loading = true;
    try {
      const { data } = yield this.client.query({
        query: GET_ORG_NAMES,
      });
      this.cachedOrgNameItems = data.organisations;
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }

  get items() {
    if (!this.cachedItems) {
      this.fetchItems();
    }
    return this.cachedItems || [];
  }

  get orgNameItems() {
    if (!this.cachedOrgNameItems) {
      this.fetchOrgNameItems();
    }
    return this.cachedOrgNameItems || [];
  }
}
