import { gql } from '@apollo/client';
import { flow, makeAutoObservable } from 'mobx';

const SAVE_ADDRESS = gql`
  mutation saveAddress($address: AddressInput!) {
    saveAddress(address: $address) {
      id
    }
  }
`;

const DELETE_ADDRESS = gql`
  mutation deleteAddress($ids: [ID!]!) {
    deleteAddress(ids: $ids)
  }
`;
export class AddressBookStore {
  cachedItems = null;
  loading = true;
  error = null;

  constructor(client) {
    this.client = client;
    makeAutoObservable(this, { fetchItems: flow, addItems: flow }, { autoBind: true });
  }

  *saveData(address) {
    this.loading = true;
    try {
      const { data } = yield this.client.mutate({
        mutation: SAVE_ADDRESS,
        variables: {
          address,
        },
      });
      return data.saveAddress;
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }

  *deleteData(listId) {
    this.loading = true;
    try {
      const { data } = yield this.client.mutate({
        mutation: DELETE_ADDRESS,
        variables: {
          ids: listId,
        },
      });
      return data.deleteAddress;
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }
}
