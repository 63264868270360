import { makeAutoObservable } from 'mobx';
import { saveAs } from 'file-saver';
import Fuse from 'fuse.js';
import { path } from 'ramda';

export class SkuMappingTableStore {
  skuMappingFormOpen = false;
  unmappedSkuModalOpen = false;
  csvModalOpen = false;
  deleteSkuMappingsModalOpen = false;
  idsToDelete = new Set();
  searchTerm = '';
  DISPLAY_KEYS = [
    'storeId',
    'storeSku',
    'storeProductId',
    'storeProductName',
    'warehouseSku',
    'warehouseCode',
    'qty',
    'createdTime',
  ];
  warehouseSelectionVisible = false;
  onWarehouseSelectionSubmit = () => {};
  selectedWarehouseAccount = null;

  constructor(dataStore) {
    this.dataStore = dataStore;
    makeAutoObservable(this, {
      DISPLAY_KEYS: false,
      dataStore: false,
    });
  }

  get searchFuse() {
    const fuse = new Fuse([], {
      keys: this.DISPLAY_KEYS,
      threshold: 0.15,
      shouldSort: true,
      ignoreLocation: true,
      minMatchCharLength: 2,
    });
    fuse.setCollection(this.dataStore.items);
    return fuse;
  }

  get matchingItems() {
    if (this.searchTerm) {
      return this.searchFuse.search(this.searchTerm).map(({ item }) => item);
    }
    return this.dataStore.items;
  }

  get bannerVisible() {
    return this.dataStore.unmappedSkus.length > 0 && !this.unmappedSkuModalOpen;
  }

  setItemIdsToDelete = (ids) => {
    this.idsToDelete = ids;
  };

  setSearchTerm = (term) => {
    this.searchTerm = term;
  };

  openSkuMappingForm = () => {
    this.skuMappingFormOpen = true;
  };

  closeSkuMappingForm = () => {
    this.skuMappingFormOpen = false;
  };

  openUnmappedSkuModal = () => {
    this.unmappedSkuModalOpen = true;
  };

  closeUnmappedSkuModal = () => {
    this.unmappedSkuModalOpen = false;
  };

  openCsvModal = () => {
    this.csvModalOpen = true;
  };

  closeCsvModal = () => {
    this.csvModalOpen = false;
  };

  openDeleteSkuMappingsModal = () => {
    this.deleteSkuMappingsModalOpen = true;
  };

  closeDeleteSkusModal = () => {
    this.deleteSkuMappingsModalOpen = false;
  };

  exportCsv = async (skuMappings, headers) => {
    var items = skuMappings.map((d) =>
      headers.reduce(
        (map, k) => ({
          ...map,
          [k]: path(k.split('.'), d),
        }),
        {},
      ),
    );
    const XLSX = await import('xlsx/xlsx.mjs');

    // use blob and file-saver/saveAs to save plain text in a new file
    let blobFile = new Blob([XLSX.utils.sheet_to_csv(XLSX.utils.json_to_sheet(items))], {
      type: 'text/plain;charset=utf-8',
    });
    saveAs(blobFile, 'SKU mappings.csv');
  };

  setOnWarehouseSelectionSubmit = (onSubmit) => {
    this.onWarehouseSelectionSubmit = onSubmit;
  };

  setSelectedWarehouseAccount = (warehouseAccount) => {
    this.selectedWarehouseAccount = warehouseAccount;
  };

  setWarehouseSelectionVisible = (visible) => {
    this.warehouseSelectionVisible = visible;
  };
}
