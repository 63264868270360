import * as React from 'react';
import { StyleSheet } from 'react-native';
import { Card, Icon, Layout, Text } from '@ui-kitten/components';
import StackedHistogramCard from '../../components/StackedHistogramCard';
import PieChartCard from '../../components/PieChartCard';
import { useIsMobile } from '../../core/responsive.utils';
import Background from '../../components/Background';
import { observer } from 'mobx-react';
import { inventoryStatisticsStore } from '../../store';
import { UIStatusWrapper } from '../../components/ui-status';
import { Banner, Divider } from 'react-native-paper';
import { SkuMappingWarningModal } from '../../components/SkuMappingWarningModal';
import { skuMappingTableStore } from '../../store/index';
import { paperNativeTheme, uiKittenTheme } from '../../core/theme';
import GroupBarChartCard from '../../components/GroupBarChartCard';
import { useIsEzomUser } from '../../core/utils/utils';

export default observer(({ navigation }) => {
  const isMobile = useIsMobile();
  const isEzomUser = useIsEzomUser();
  return (
    <Background fullWidth={true}>
      <UIStatusWrapper
        status={{
          error: inventoryStatisticsStore.error,
          emtpy: inventoryStatisticsStore.inventories.lenght === 0,
          indeterminate: inventoryStatisticsStore.loading,
        }}>
        {isEzomUser ? (
          <>
            <Banner
              style={{ backgroundColor: uiKittenTheme['color-primary-100'] }}
              visible={skuMappingTableStore.bannerVisible}
              actions={
                skuMappingTableStore.unmappedSkuModalOpen
                  ? []
                  : [
                      {
                        label: 'Learn more',
                        onPress: skuMappingTableStore.openUnmappedSkuModal,
                      },
                    ]
              }
              icon={() => (
                <Icon
                  fill={paperNativeTheme.colors.danger}
                  name="alert-triangle-outline"
                  status="danger"
                  style={{ width: 20, height: 20 }}
                />
              )}>
              There are unmapped SKUs, please fix them otherwise there might be errors in forecast
              and fulfillment.
            </Banner>
            <SkuMappingWarningModal
              visible={skuMappingTableStore.unmappedSkuModalOpen}
              skus={skuMappingTableStore.dataStore.unmappedSkus}
              onBackdropPress={skuMappingTableStore.closeUnmappedSkuModal}
            />
          </>
        ) : null}
        <Layout style={styles.container} level="1">
          <Card
            style={styles.card}
            status="danger"
            onPress={() => navigation.navigate('Inventory')}>
            <Layout style={styles.textContainer} level="1">
              <Text category="h4" status="danger">
                {inventoryStatisticsStore.stockByAvailability.onwayStock}
              </Text>
            </Layout>
            <Text>Incoming</Text>
          </Card>

          <Card
            style={styles.card}
            status="warning"
            onPress={() => navigation.navigate('Inventory')}>
            <Layout style={styles.textContainer} level="1">
              <Text category="h4" status="warning">
                {inventoryStatisticsStore.stockByAvailability.pendingStock}
              </Text>
            </Layout>
            <Text>Dispatcing</Text>
          </Card>

          <Card
            style={styles.card}
            status="success"
            onPress={() => navigation.navigate('Inventory')}>
            <Layout style={styles.textContainer} level="1">
              <Text category="h4" status="success">
                {inventoryStatisticsStore.stockByAvailability.availableStock}
              </Text>
            </Layout>
            <Text>Available</Text>
          </Card>
        </Layout>
        <Divider style={{ marginVertical: 10 }} />
        <Layout style={isMobile ? styles.mobileContainer : styles.container} level="1">
          <GroupBarChartCard
            header={() => (
              <Text
                category="h6"
                style={styles.header}
                onPress={() => navigation.navigate('Inventory')}>
                Outbound orders
              </Text>
            )}
            data={inventoryStatisticsStore.outboundStream}
            x="day"
            y="qty"
            scale={{ x: 'time' }}
            colorScale={[uiKittenTheme['color-info-400'], uiKittenTheme['color-success-400']]}
          />
          <PieChartCard
            header={() => (
              <Text
                category="h6"
                style={styles.header}
                onPress={() => navigation.navigate('Inventory')}>
                Stock age (by quantity)
              </Text>
            )}
            data={inventoryStatisticsStore.stockAgeSummary.stockAgeByQty}
            x="l"
            y="qty"
            label={({ datum }) => `Days: ${datum.l}\nQty:: ${datum.qty}`}
          />
        </Layout>
        <Layout style={isMobile ? styles.mobileContainer : styles.container} level="1">
          <StackedHistogramCard
            header={() => (
              <Text
                category="h6"
                style={styles.header}
                onPress={() => navigation.navigate('Inventory')}>
                Stock level
              </Text>
            )}
            data={[
              inventoryStatisticsStore.stockLevel.onwayStocks,
              inventoryStatisticsStore.stockLevel.availableStocks,
              inventoryStatisticsStore.stockLevel.pendingStocks,
            ]}
            x="stock"
            bins={10}
            scale={{ y: 'sqrt' }}
            label={({ datum }) =>
              datum.binnedData?.[0]?.type
                ? `${datum.y} SKUs have ${datum.x0} - ${datum.x1} stock ${datum.binnedData[0].type}`
                : null
            }
          />
          <PieChartCard
            header={() => (
              <Text
                category="h6"
                style={styles.header}
                onPress={() => navigation.navigate('Inventory')}>
                Stock age (by volume)
              </Text>
            )}
            data={inventoryStatisticsStore.stockAgeSummary.stockAgeByVol}
            x="l"
            y="vol"
            label={({ datum }) => `Days: ${datum.l}\nVol(㎥):: ${datum.vol.toFixed(1)}`}
          />
        </Layout>
      </UIStatusWrapper>
    </Background>
  );
});

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  mobileContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  card: {
    flex: 1,
    margin: 2,
  },
  footerContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  footerControl: {
    marginHorizontal: 2,
  },
  header: {
    textAlign: 'center',
    paddingVertical: 4,
  },
});
