import React from 'react';
import { StyleSheet } from 'react-native';
import { Text } from 'react-native-paper';
import { Layout } from '@ui-kitten/components';

export default ({ label, value, format, rowValue }) => (
  <Layout style={styles.desktopRow}>
    <Text
      style={[
        styles.cell,
        {
          width: '33%',
        },
      ]}>
      <strong>
        <i>{label}</i>
      </strong>
    </Text>
    <Text
      style={[
        styles.cell,
        {
          width: '66%',
          color: !value && '#DDD',
        },
      ]}>
      {(format && format(value, rowValue)) || value || 'N/A'}
    </Text>
  </Layout>
);

const styles = StyleSheet.create({
  desktopRow: {
    flex: 1,
    flexDirection: 'row',
    marginVertical: '1em',
    justifyContent: 'space-between',
    minHeight: '20px',
  },

  cell: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: '0.2em',
  },
});
