import React, { useCallback, useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Modal, Portal } from 'react-native-paper';
import Dialogue from './Dialogue';
import { Button, Icon, Input } from '@ui-kitten/components';
import Autocomplete from './Autocomplete';
import { inventoryStore } from '../store';
import { useIsMobile } from '../core/responsive.utils';
import { unifiedAlert } from '../core/utils/utils';
import * as DocumentPicker from 'expo-document-picker';
import * as ExpoLinking from 'expo-linking';

export default ({ visible, onDismiss, onSubmit, setSelectedSku, selectedSkus }) => {
  const skus = inventoryStore.items;
  const isMobileDevice = useIsMobile();

  const canSubmit = useMemo(() => {
    return selectedSkus?.length && selectedSkus.every((sku) => sku.sku && sku.quantity);
  }, [selectedSkus]);

  const pickDocument = useCallback(async () => {
    let result = await DocumentPicker.getDocumentAsync({});

    const reader = new FileReader();
    const XLSX = await import('xlsx/xlsx.mjs');
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'array' });
      let selectedSheets = workbook.SheetNames;

      const csv = selectedSheets.reduce((a, sheetName) => {
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        return json;
      }, {});

      let skuUploadedObject = csv
        .filter((item) => skus.some((sku) => sku.sku_code === item.SKU))
        .reduce((acc, cur) => {
          let sku = cur.SKU;
          let quantity = cur.QUANTITY || 0;
          if (sku) {
            acc[sku] = (acc[sku] || 0) + quantity;
          }
          return acc;
        }, {});

      let skuUploadedArray = Object.keys(skuUploadedObject).map((item, index) => ({
        sku: item,
        quantity: skuUploadedObject[item],
        id: item + index + 1 + selectedSkus?.length,
      }));

      setSelectedSku((prev) => prev.concat(skuUploadedArray));
    };
    reader.readAsArrayBuffer(result.file);
  }, [skus?.length]);

  return (
    <Portal>
      <Modal visible={visible} onDismiss={onDismiss} contentContainerStyle={styles.modalStyle}>
        <Dialogue
          onSubmit={() => {
            if (canSubmit) {
              onSubmit();
            } else {
              unifiedAlert('Please fill in all the blank input fields.');
            }
          }}
          onDismiss={onDismiss}>
          <View style={{ minWidth: 400 }}>
            <Text style={{ marginBottom: '12px' }}>Select SKU(s)</Text>
            <View style={{ marginBottom: 20 }}>
              {selectedSkus.map((item, index) => {
                return (
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <Autocomplete
                      value={item.sku}
                      style={styles.field}
                      onSelect={(newVal) => {
                        setSelectedSku((prev) =>
                          prev.map((sku, skuIndex) => {
                            if (skuIndex === index) {
                              return { sku: newVal, quantity: 1, id: newVal + skuIndex };
                            }
                            return sku;
                          }),
                        );
                      }}
                      options={[...new Set(skus.map((s) => s.sku_code))]}
                    />
                    <Input
                      onChange={(e) => {
                        const value = parseInt(e.target.value, 10);
                        if (!isNaN(value)) {
                          setSelectedSku((prev) =>
                            prev.map((sku, skuIndex) => {
                              if (skuIndex === index) {
                                return { ...sku, quantity: value };
                              }
                              return sku;
                            }),
                          );
                        }
                      }}
                      size="small"
                      style={[styles.qtyInput, isMobileDevice ? styles.mobileInput : '']}
                      value={item.quantity}
                      keyboardType="numeric"
                    />
                    <Button
                      size="small"
                      appearance="ghost"
                      onPress={() => {
                        setSelectedSku((prevSkus) => {
                          const filteredSkus = prevSkus.filter(
                            (sku) => sku.id !== item.id || item.quantity !== sku.quantity,
                          );

                          return filteredSkus;
                        });
                      }}
                      accessoryLeft={<Icon name="trash-2-outline" />}
                    />
                  </View>
                );
              })}
            </View>

            <Button
              style={styles.btn}
              size="medium"
              appearance="ghost"
              onPress={() => {
                setSelectedSku((prev) => [
                  ...prev,
                  {
                    sku: '',
                    quantity: 1,
                    id: skus[0].sku_code + (selectedSkus.length + 1),
                  },
                ]);
              }}
              accessoryLeft={<Icon name="plus" />}>
              Add
            </Button>

            <View style={{ flexDirection: 'row' }}>
              <Button
                style={styles.btn}
                appearance="outline"
                onPress={pickDocument}
                accessoryLeft={(props) => <Icon {...props} name="upload" />}>
                Upload
              </Button>
              <Button
                style={styles.btn}
                appearance="outline"
                accessoryLeft={(props) => <Icon {...props} name="download-outline" />}
                onPress={() => {
                  ExpoLinking.openURL(
                    require('../spreadsheetTemplates/BatchPrintSkuLabelTemplate.xlsx'),
                  );
                }}>
                Download Template
              </Button>
            </View>
          </View>
        </Dialogue>
      </Modal>
    </Portal>
  );
};

const styles = StyleSheet.create({
  modalStyle: { backgroundColor: 'white', padding: 20, maxWidth: 800, marginHorizontal: 'auto' },
  field: {
    marginVertical: 8,
  },
  qtyInput: {
    marginHorizontal: 12,
    flex: 1,
    maxWidth: '40px',
  },
  mobileInput: {
    maxWidth: '200px',
    minWidth: '200px',
  },
  btn: {
    minWidth: 200,
    marginVertical: 10,
    marginHorizontal: 6,
  },
});
