import React from 'react';
import { StyleSheet } from 'react-native';
import { Card } from '@ui-kitten/components';
import { VictoryPie, VictoryTooltip } from 'victory-native';

export default ({ header, label, ...props }) => {
  return (
    <Card style={styles.card} header={header}>
      <VictoryPie
        width={700}
        height={360}
        colorScale="cool"
        style={{ labels: { fill: 'white' } }}
        innerRadius={80}
        labels={label}
        labelComponent={<CustomLabel label={label} />}
        {...props}
      />
    </Card>
  );
  n;
};

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  card: {
    flex: 1,
    margin: 2,
    maxWidth: 700,
  },
  footerContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  footerControl: {
    marginHorizontal: 2,
  },
});

const CustomLabel = ({ label, ...props }) => (
  <VictoryTooltip
    {...props}
    text={label}
    x={350}
    y={255}
    orientation="top"
    pointerLength={0}
    cornerRadius={75}
    flyoutWidth={150}
    flyoutHeight={150}
    style={{ fill: 'black', fontSize: 16 }}
    flyoutStyle={{ fill: 'white' }}
  />
);

CustomLabel.defaultEvents = VictoryTooltip.defaultEvents;
