import React, { memo, useState, useCallback, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { Chip, Card } from 'react-native-paper';
import { useIsMobile } from 'src/core/responsive.utils';
import { Layout, Button, Icon, Popover, Text, CheckBox } from '@ui-kitten/components';
import { ScrollView } from 'react-native-gesture-handler';
import { PACKAGING_MATERIAL_MAP, PACKAGING_TYPE_MAP } from '@ezom/library/lib/cjs/constants';
import SearchBar from './SearchBar';

const PackagingMaterialTableControl = ({
  filterParams = {},
  initialSearchTerm = '',
  indicators = [],
  onFilterChange = () => {},
  onFilterClear = () => {},
  disableSearch = false,
  requireButtonClickToSearch = false,
}) => {
  const onSearchTermChange = (text) => {
    onFilterChange({ searchTerm: text });
  };
  const isMobileDevice = useIsMobile();
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);

  const [materialDropdownVisible, setMaterialDropdownVisible] = useState(false);
  const [typeDropdownVisible, setTypeDropdownVisible] = useState(false);

  useEffect(() => {
    onFilterChange(initialSearchTerm);
  }, [initialSearchTerm]);

  return (
    <Card visible={true} style={styles.bannerContainer} actions={[]}>
      <Layout style={isMobileDevice ? styles.mobileBanner : styles.desktopBanner}>
        <SearchBar
          disableSearch={disableSearch}
          onSearchTermChange={onSearchTermChange}
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          requireButtonClickToSearch={requireButtonClickToSearch}
          inputPlaceholder="Search packaging material"
          inputSize="medium"></SearchBar>
        <Layout style={isMobileDevice ? styles.mobileButtonGroup : styles.desktopButtonGroup}>
          <Popover
            visible={materialDropdownVisible}
            anchor={() => (
              <Button
                status="primary"
                appearance="ghost"
                style={[styles.button]}
                onPress={() => setMaterialDropdownVisible(true)}
                accessoryRight={materialDropdownVisible ? ArrowUpIcon : ArrowDownIcon}>
                Material
              </Button>
            )}
            onBackdropPress={() => {
              setMaterialDropdownVisible(false);
            }}
            style={styles.popover}>
            <Layout style={styles.popover}>
              <Text>Select material</Text>
              <ScrollView style={{ maxHeight: 250 }}>
                {Object.entries(PACKAGING_MATERIAL_MAP).map(([key, val]) => (
                  <CheckBox
                    key={`${key}-${val}`}
                    checked={filterParams.material === key}
                    onChange={(checked) => {
                      if (checked) {
                        onFilterChange({
                          material: key,
                        });
                      } else {
                        onFilterChange({
                          material: undefined,
                        });
                      }
                    }}
                    style={styles.checkItem}>
                    {val}
                  </CheckBox>
                ))}
              </ScrollView>
            </Layout>
          </Popover>

          <Popover
            visible={typeDropdownVisible}
            anchor={() => (
              <Button
                status="primary"
                appearance="ghost"
                style={[styles.button]}
                onPress={() => setTypeDropdownVisible(true)}
                accessoryRight={typeDropdownVisible ? ArrowUpIcon : ArrowDownIcon}>
                Type
              </Button>
            )}
            onBackdropPress={() => {
              setTypeDropdownVisible(false);
            }}
            style={styles.popover}>
            <Layout style={styles.popover}>
              <Text>Select type</Text>
              <ScrollView style={{ maxHeight: 250 }}>
                {Object.entries(PACKAGING_TYPE_MAP).map(([key, val]) => (
                  <CheckBox
                    key={`${key}-${val}`}
                    checked={filterParams.type === key}
                    onChange={(checked) => {
                      if (checked) {
                        onFilterChange({
                          type: key,
                        });
                      } else {
                        onFilterChange({
                          type: undefined,
                        });
                      }
                    }}
                    style={styles.checkItem}>
                    {val}
                  </CheckBox>
                ))}
              </ScrollView>
            </Layout>
          </Popover>
          <Button
            status="danger"
            appearance="ghost"
            onPress={() => {
              onFilterClear();
            }}
            style={styles.button}>
            Reset filter
          </Button>
        </Layout>
      </Layout>
      <Layout style={styles.indicator}>
        {indicators.map((indicator, index) => (
          <Chip style={styles.chip} key={`${indicator}-${index}`}>
            {indicator}
          </Chip>
        ))}
      </Layout>
    </Card>
  );
};

const styles = StyleSheet.create({
  bannerContainer: {
    marginBottom: '0.5em',
    padding: '1em',
  },
  desktopBanner: {
    maxWidth: '1800px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  mobileBanner: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  indicator: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  chip: {
    marginTop: '0.4em',
    marginHorizontal: '0.2em',
    opacity: '0.5',
  },
  popover: {
    margin: '0.5em',
    padding: '0.5em',
  },
  desktopButtonGroup: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mobileButtonGroup: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    height: '45px',
  },
  icon: {
    width: '28px',
    height: '28px',
    stroke: 'white',
    strokeWidth: '0.8',
  },
  checkItem: {
    marginVertical: 4,
    marginHorizontal: 12,
  },
});

const ArrowDownIcon = (props) => (
  <Icon
    {...props}
    name="arrow-ios-downward-outline"
    style={[props.style, styles.icon, { width: '20px', height: '20px' }]}
  />
);

const ArrowUpIcon = (props) => (
  <Icon
    {...props}
    name="arrow-ios-upward-outline"
    style={[props.style, styles.icon, { width: '20px', height: '20px' }]}
  />
);

const ArrowUpRightIcon = (props) => (
  <Icon
    {...props}
    name="diagonal-arrow-right-up-outline"
    style={[props.style, styles.icon, { width: '20px', height: '20px' }]}
  />
);

export default memo(PackagingMaterialTableControl);
