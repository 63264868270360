import { SHIPPING_CHANNEL_CODE } from '@ezom/library/lib/cjs/constants';

const urlDict = {
  // AU
  F030: (trackingNumber) => `http://auspost.com.au/track/track.html?id=${trackingNumber}`,
  F031: (trackingNumber) => `http://auspost.com.au/track/track.html?id=${trackingNumber}`,
  F032: (trackingNumber) =>
    `https://online.toll.com.au/trackandtrace/showConnotes.do?connote=${trackingNumber}`,
  F033: (trackingNumber) =>
    `https://online.toll.com.au/trackandtrace/showConnotes.do?connote=${trackingNumber}`,
  F034: (trackingNumber) =>
    `https://www.directfreight.com.au/ConsignmentStatus.aspx?consignment_no=${trackingNumber}`,
  F035: (trackingNumber) => `http://auspost.com.au/track/track.html?id=${trackingNumber}`,
  F036: (trackingNumber) => `http://auspost.com.au/track/track.html?id=${trackingNumber}`,
  F036: (trackingNumber) => `https://www.nzpost.co.nz/tools/tracking?trackid=${trackingNumber}`,
  F171: (trackingNumber) => `http://auspost.com.au/track/track.html?id=${trackingNumber}`,
  F219: (trackingNumber) => `https://www.fastway.com.au/tools/track/?l=${trackingNumber}`,
  F231: (trackingNumber) => `https://auspost.com.au/mypost/track/#/details/${trackingNumber}`,
  F232: (trackingNumber) => `https://auspost.com.au/mypost/track/#/details/${trackingNumber}`,
  F1507: (trackingNumber) => `https://auspost.com.au/mypost/track/#/details/${trackingNumber}`,
  F400: (trackingNumber) => ``,
  F522: (trackingNumber) =>
    `https://fastway.ie/courier-services/track-your-parcel/?l=${trackingNumber}`,
  F686: (trackingNumber) => `http://auspost.com.au/track/track.html?id=${trackingNumber}`,
  F687: (trackingNumber) => `http://auspost.com.au/track/track.html?id=${trackingNumber}`,
  F688: (trackingNumber) =>
    `https://online.toll.com.au/trackandtrace/showConnotes.do?connote=${trackingNumber}`,
  F689: (trackingNumber) =>
    `https://online.toll.com.au/trackandtrace/showConnotes.do?connote=${trackingNumber}`,
  F690: (trackingNumber) =>
    `https://www.directfreight.com.au/ConsignmentStatus.aspx?consignment_no=${trackingNumber}`,
  F790: (trackingNumber) => `http://auspost.com.au/track/track.html?id=${trackingNumber}`,
  F791: (trackingNumber) => `http://auspost.com.au/track/track.html?id=${trackingNumber}`,
  F835: (trackingNumber) => `https://www.borderexpress.com.au/tracking/?cn=${trackingNumber}`,
  F836: (trackingNumber) => `https://www.borderexpress.com.au/tracking/?cn=${trackingNumber}`,
  F837: (trackingNumber) => `https://www.borderexpress.com.au/tracking/?cn=${trackingNumber}`,
  F843: (trackingNumber) => `https://www.borderexpress.com.au/tracking/?cn=${trackingNumber}`,
  F844: (trackingNumber) => `https://www.borderexpress.com.au/tracking/?cn=${trackingNumber}`,
  F995: (trackingNumber) => `https://www.hunterexpress.com.au/tracking/`,
  F996: (trackingNumber) => `https://www.hunterexpress.com.au/tracking/`,
  [SHIPPING_CHANNEL_CODE.AUSTRALIA_POST_EPARCEL]: (trackingNumber) =>
    `http://auspost.com.au/track/track.html?id=${trackingNumber}`,
  [SHIPPING_CHANNEL_CODE.AUSTRALIA_POST_EPARCEL_EXPRESS]: (trackingNumber) =>
    `http://auspost.com.au/track/track.html?id=${trackingNumber}`,
  [SHIPPING_CHANNEL_CODE.AUSPOST_INTERNATIONAL]: (trackingNumber) =>
    `http://auspost.com.au/track/track.html?id=${trackingNumber}`,
  [SHIPPING_CHANNEL_CODE.ETOWER]: (trackingNumber) =>
    `http://auspost.com.au/track/track.html?id=${trackingNumber}`,
  [SHIPPING_CHANNEL_CODE.FREIGHSTER]: (trackingNumber) =>
    `https://freightster.com.au/tracking-result?tracking=${trackingNumber}`,
  // The follow four are for backward compatibility
  // ToDo: remove them after all the clients are updated
  AUSPOST_EPARCEL: (trackingNumber) =>
    `http://auspost.com.au/track/track.html?id=${trackingNumber}`,
  AUSPOST_EXPRESS: (trackingNumber) =>
    `http://auspost.com.au/track/track.html?id=${trackingNumber}`,
  POST_EPARCEL_RP: (trackingNumber) =>
    `http://auspost.com.au/track/track.html?id=${trackingNumber}`,
  POST_EPARCEL_CN: (trackingNumber) =>
    `http://auspost.com.au/track/track.html?id=${trackingNumber}`,

  // USA
  F120: (trackingNumber) =>
    `https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1=${trackingNumber}`,
  F121: (trackingNumber) =>
    `https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1=${trackingNumber}`,
  F123: (trackingNumber) =>
    `https://www.ups.com/track?loc=en_US&requester=QUIC&tracknum=${trackingNumber}`,
  F124: (trackingNumber) =>
    `https://www.ups.com/track?loc=en_US&requester=QUIC&tracknum=${trackingNumber}`,
  F125: (trackingNumber) =>
    `https://www.ups.com/track?loc=en_US&requester=QUIC&tracknum=${trackingNumber}`,
  F127: (trackingNumber) =>
    `https://www.ups.com/track?loc=en_US&requester=QUIC&tracknum=${trackingNumber}`,
  F128: (trackingNumber) =>
    `https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${trackingNumber}`,
  F129: (trackingNumber) =>
    `https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${trackingNumber}`,
  F130: (trackingNumber) =>
    `https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${trackingNumber}`,
  F131: (trackingNumber) =>
    `https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${trackingNumber}`,
  F132: (trackingNumber) => `https://www.fedex.com/fedextrack/?trknbr=${trackingNumber}`,
  F133: (trackingNumber) => `https://www.fedex.com/fedextrack/?trknbr=${trackingNumber}`,
  F134: (trackingNumber) => `https://www.fedex.com/fedextrack/?trknbr=${trackingNumber}`,
  F139: (trackingNumber) =>
    `https://www.ups.com/track?loc=en_US&requester=QUIC&tracknum=${trackingNumber}`,
  F140: (trackingNumber) =>
    `https://www.ups.com/track?loc=en_US&requester=QUIC&tracknum=${trackingNumber}`,
};

export const getCourierTrackignUrl = (courierCode, trackingNumer) => {
  if (!trackingNumer && trackingNumer !== 0) {
    return '';
  }

  const uppercaseCourierCode = courierCode.toUpperCase();

  if (urlDict[uppercaseCourierCode]) {
    return urlDict[uppercaseCourierCode](trackingNumer);
  }

  return '';
};
