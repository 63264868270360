import { computed, makeObservable } from 'mobx';
import { flatten, sum, uniq } from 'ramda';
import { OrderDispatchStore } from './OrderDispatchStore';
import { getCourierCodeFromMapping } from '../core/utils/utils';

export class MergeOrderDispatchStore extends OrderDispatchStore {
  get mergedTotalWeight() {
    return sum(flatten(this.totalWeight));
  }

  get mergedTotalVolume() {
    return sum(flatten(this.totalVolume));
  }

  get mergedUnmappedStoreSkus() {
    return [...new Set(flatten(this.unmappedStoreSkus))];
  }

  get mergedOutOfStockWarehouseSkus() {
    return [...new Set(flatten(this.outOfStockWarehouseSkus))];
  }

  get mergedSkuQuantity() {
    const mergedSkuQuantity = flatten(this.skuQuantity).reduce((acc, cur) => {
      if (acc[cur.warehouseSku]) {
        acc[cur.warehouseSku] = {
          ...acc[cur.warehouseSku],
          qty: acc[cur.warehouseSku].qty + cur.qty,
        };
      } else {
        acc[cur.warehouseSku] = cur;
      }
      return acc;
    }, {});

    return Object.values(mergedSkuQuantity);
  }

  get mergedOutboundConsignment() {
    const stores = this.orders.map((o) => this.storeStore.getStoreById(o.storeId));
    const warehouses = uniq(this.orders.map((o, i) => this.getDefaultWarehouse(stores[i], o)));
    const warehouseAccountIds = uniq(
      this.orders.map((o, i) => this.getWarehouseAccountId(stores[i], o)),
    );
    const couriers = uniq(this.orders.map((o, i) => getCourierCodeFromMapping(stores[i], o)));
    const countries = uniq(this.orders.map((o) => o.shippingAddress.countryCode));
    const states = uniq(this.orders.map((o) => o.shippingAddress.provinceCode));
    const cities = uniq(this.orders.map((o) => o.shippingAddress.city));
    const postCodes = uniq(this.orders.map((o) => o.shippingAddress.zip));
    const streets = uniq(
      this.orders.map(
        (o) => `${o.shippingAddress.address2 || ''} ${o.shippingAddress.address1 || ''}`,
      ),
    );
    const companies = uniq(this.orders.map((o) => o.shippingAddress.company || ''));
    const names = uniq(this.orders.map((o) => o.shippingAddress.name));
    const phones = uniq(this.orders.map((o) => o.shippingAddress.phone || ''));
    const emails = uniq(this.orders.map((o) => o.email || ''));
    return {
      refNo: this.orders.map((o) => o.number).join('/'),
      sales_no: this.orders.map((o) => o.number).join('/'),
      note: 'Please call receiver before delivery.',
      courier: couriers.length === 1 ? couriers[0] : '',
      warehouseAccountId: warehouseAccountIds.length === 1 ? warehouseAccountIds[0] : '',
      warehouse: warehouses.length === 1 ? warehouses[0] : '',
      address: {
        country: countries.length === 1 ? countries[0] : '',
        state: states.length === 1 ? states[0] : '',
        city: cities.length === 1 ? cities[0] : '',
        post_code: postCodes.length === 1 ? postCodes[0] : '',
        street: streets.lenght === 1 ? streets[0] : '',
        company: companies.length === 1 ? companies[0] : '',
        name: names.length === 1 ? names[0] : '',
        phone: phones.length === 1 ? phones[0] : '',
        email: emails.length === 1 ? emails[0] : '',
      },
      skus: this.mergedSkuQuantity,
    };
  }

  constructor(
    client,
    outboundOrderStore,
    storeStore,
    orderIds,
    storeIds,
    warehouseAccountStore,
    countryStateCityCurrencyStore,
  ) {
    super(
      client,
      outboundOrderStore,
      storeStore,
      orderIds,
      storeIds,
      warehouseAccountStore,
      countryStateCityCurrencyStore,
    );
    // MobX doesn't allow markAutoObservable in subclasses
    makeObservable(this, {
      mergedTotalWeight: computed,
      mergedTotalVolume: computed,
      mergedUnmappedStoreSkus: computed,
      mergedOutOfStockWarehouseSkus: computed,
      mergedSkuQuantity: computed,
    });
  }
}
