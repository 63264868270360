import React from 'react';
import { Button, Card, CheckBox, Icon, Divider } from '@ui-kitten/components';
import { Linking, Platform, StyleSheet } from 'react-native';
import { Layout, Text } from '@ui-kitten/components';
import NumericInput from 'react-numeric-input';
import { isNil } from 'ramda';
import InlineTable from './InlineTable';
import SkuQtyInput from './SkuQtyInput';
import { useState } from 'react';
import { unifiedConfirm } from '../core/utils/utils';

export default ({
  selectable,
  disabled,
  selected,
  item,
  tracking,
  quantity,
  onSelectionChange,
  onQuantityChange,
  status,
  errorMsg,
  skuMappings,
  onAddSkuMapping,
  onRemoveSkuMapping,
}) => {
  const [editingWarehouseSku, setEditingWarehouseSku] = useState(false);

  return (
    <Card
      key={item.id}
      disabled={true}
      style={{ marginBottom: '0.5em', minWidth: '300px' }}
      header={() => (
        <Layout
          level="1"
          style={{
            alignItems: 'center',
            margin: '0.4em',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          {selectable ? (
            <CheckBox
              status="primary"
              disabled={disabled}
              checked={selected}
              onChange={() => onSelectionChange(!selected)}
            />
          ) : null}
          <Text
            category="label"
            style={{ width: selected ? '190px' : '220px', paddingHorizontal: '0.3em' }}>
            {item.name}
          </Text>
          <Text category="p1">
            {selected ? (
              <>
                <NumericInput
                  style={{ wrap: { marginLeft: '0.4em' }, input: { maxWidth: '90px' } }}
                  key={`${item.id}-input`}
                  disabled={disabled}
                  mobile={true}
                  status={status}
                  caption={errorMsg}
                  value={isNil(quantity) ? item.fulfillableQuantity : quantity}
                  onChange={onQuantityChange}
                  min={0}
                />{' '}
                /{' '}
                <Text category="p1" key={`${item.id}-quantity`}>
                  {item.quantity}
                </Text>
              </>
            ) : (
              <Text category="label" key={`${item.id}-text`}>
                ${item.unitPrice} x {item.quantity}
              </Text>
            )}
          </Text>
        </Layout>
      )}>
      {item.variant ? <Text category="p2">Variant: {item.variant}</Text> : null}
      <Text category="p2">Fulfillable: {item.fulfillableQuantity}</Text>
      <Text category="p2">Status: {item.fulfillmentStatus}</Text>
      {item.fulfillmentStatus !== 'unfulfilled' ? (
        <Text category="p2">
          Tracking:{' '}
          {tracking ? (
            <Text
              status="info"
              category="p2"
              style={styles.trackingNumber}
              onPress={() => {
                if (Platform.OS === 'web') {
                  window.open(tracking.url, '_blank');
                } else {
                  Linking.openURL(tracking.url);
                }
              }}>
              {tracking.number}
            </Text>
          ) : null}
        </Text>
      ) : null}
      <Text category="p2">Store SKU: {item.sku || ''}</Text>

      <Divider style={{ marginVertical: 10 }} />
      <Text category="s1">SKU Mapping</Text>
      <InlineTable
        items={skuMappings.map((m) => ({ ...m, fulfillableQty: item.fulfillableQuantity }))}
        columnByKey={{
          warehouseSku: { title: 'Warehouse SKU', size: 'large' },
          warehouseCode: { title: 'Location', size: 'medium' },
          qty: { title: 'QTY', numeric: true },
        }}
        onRemoveItem={(index) => {
          unifiedConfirm(
            'Did you know?',
            `\nThis change will be saved in your SKU mapping and will affect all future fulfillments.
            \nIf you want to make a one-off change, like changing items for a single order, you can use manual outbound via "Fulfill order".
            \nDo you want to continue?`,
            () => onRemoveSkuMapping(skuMappings[index]),
          );
        }}
      />
      {editingWarehouseSku ? null : (
        <Button
          size="small"
          appearance="ghost"
          accessoryLeft={(props) => <Icon {...props} name="plus-circle-outline" />}
          onPress={() => {
            unifiedConfirm(
              'Did you know?',
              `\nThis change will be saved in your SKU mapping and will affect all future fulfillments.
            \nIf you want to make a one-off change, like changing items for a single order, you can use manual outbound via "Fulfill order".
            \nDo you want to continue?`,
              () => setEditingWarehouseSku(true),
            );
          }}
        />
      )}
      {editingWarehouseSku ? (
        <SkuQtyInput
          style={{ marginVertical: '0.4em' }}
          itemLabel="SKU"
          qtyLabel="QTY"
          validateStock={false}
          onSubmit={async (skuQty) => {
            await onAddSkuMapping(skuQty);
            setEditingWarehouseSku(false);
          }}
          onCancel={() => setEditingWarehouseSku(false)}
        />
      ) : null}
    </Card>
  );
};

const styles = StyleSheet.create({
  trackingNumber: {
    marginRight: '0.3em',
  },

  quantityInput: {
    width: '15px',
    padding: 0,
    marginHorizontal: 0,
  },
});
