import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import { Card } from '@ui-kitten/components';
import {
  createContainer,
  VictoryChart,
  VictoryStack,
  VictoryTooltip,
  VictoryAxis,
  VictoryHistogram,
} from 'victory-native';

const VictoryZoomVoronoiContainer = createContainer('zoom', 'voronoi');

export default ({ data, scale, header, x, y, label, bins, ...props }) => {
  const [zoomDomain, setZoomDomain] = useState();
  return (
    <Card style={styles.card} header={header}>
      <VictoryChart
        width="700"
        height="310"
        scale={scale}
        containerComponent={
          <VictoryZoomVoronoiContainer
            zoomDimension="x"
            voronoiDimension="x"
            labels={label}
            zoomDomain={zoomDomain}
            onZoomDomainChange={setZoomDomain}
            labelComponent={<VictoryTooltip cornerRadius={0} flyoutStyle={{ fill: 'white' }} />}
          />
        }>
        <VictoryAxis dependentAxis />
        <VictoryAxis tickCount={12} />
        <VictoryStack colorScale="cool" style={{ labels: { fontSize: 16 } }}>
          {data.map((d, i) => (
            <VictoryHistogram
              data={d}
              animate={{
                duration: 2000,
                onLoad: { duration: 1000 },
              }}
              x={x}
              y={y}
              bins={bins}
              scale={scale}
              binSpacing={8}
              style={{
                data: {
                  strokeWidth: 0,
                },
              }}
            />
          ))}
        </VictoryStack>
      </VictoryChart>
    </Card>
  );
};

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  card: {
    flex: 1,
    margin: 2,
    maxWidth: 700,
  },
  footerContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  footerControl: {
    marginHorizontal: 2,
  },
});
