import React from 'react';
import { StyleSheet } from 'react-native';
import { Layout, Button, Text, Divider } from '@ui-kitten/components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useIsMobile } from '../core/responsive.utils';
import DateRangeInput from './DateRangeInput';
import { TextInputField } from './input/TextInputField';
import { PICKING_TASK_STATUS_MAP } from '@ezom/library/lib/cjs/constants';
import { observer } from 'mobx-react';
import Select from './Select';
import { warehousesStore } from '../store';
import Autocomplete from './Autocomplete';

const DEFAULT_VALUES = {
  create_time_start: undefined,
  create_time_end: undefined,
  warehouse_code: undefined,
  status: undefined,
  type: undefined,
  id: undefined,
};

export default observer(({ onSubmit, searchParams, onDismiss }) => {
  const isMobile = useIsMobile();
  const warehouses = warehousesStore.warehouses;
  const warehouseNames = warehousesStore.warehouseNames;
  return (
    <Layout style={styles.form}>
      <Text category="h5" style={styles.title}>
        Search Picking Tasks
      </Text>
      <Divider />
      <Formik
        initialValues={searchParams || DEFAULT_VALUES}
        validationSchema={Yup.object({
          id: Yup.string(),
          type: Yup.string(),
          status: Yup.string(),
          warehouse_code: Yup.string().nullable(true),
          create_time_start: Yup.date(),
          create_time_end: Yup.date(),
        })}
        onSubmit={(values, formikActions) => {
          onSubmit(values);
          // Important: Make sure to setSubmitting to false so our loading indicator
          // goes away.
          formikActions.setSubmitting(false);
          onDismiss();
        }}>
        {(props) => {
          return (
            <>
              <TextInputField label="ID" name="id" style={styles.field} {...props} />
              <TextInputField label="Type" name="type" style={styles.field} {...props} />
              <Select
                name="status"
                style={styles.field}
                options={Object.entries(PICKING_TASK_STATUS_MAP).map((s) => ({
                  key: s[0],
                  val: s[1],
                }))}
                label="Status"
                value={PICKING_TASK_STATUS_MAP[props.values.status]}
                error={props.errors.status}
                touched={props.touched.status}
                onChange={(val) => {
                  props.setFieldValue('status', val.key);
                }}
                onBlur={() => props.setFieldTouched('status', true)}
                {...props}
              />
              <Autocomplete
                label="Warehouse"
                value={
                  warehouses.find((w) => w.warehouse_code === props.values.warehouse_code)
                    ?.warehouse_name_en || props.values.warehouse_code
                }
                error={props.errors.warehouse_code}
                touched={props.touched.warehouse_code}
                placeholder="Select a warehosue"
                style={styles.field}
                options={warehouseNames}
                onSelect={(val) => {
                  const value =
                    warehouses.find((w) => w.warehouse_name_en === val)?.warehouse_code || val;
                  props.setFieldValue('warehouse_code', value);
                }}
                onBlur={props.handleBlur('warehouse_code')}
              />
              <DateRangeInput
                style={styles.field}
                label="Created"
                minVal={props.values.create_time_start}
                maxVal={props.values.create_time_end}
                minTouched={props.touched.create_time_start}
                maxTouched={props.touched.create_time_end}
                onMinValueChange={(date) => props.setFieldValue('create_time_start', date)}
                onMaxValueChange={(date) => props.setFieldValue('create_time_end', date)}
                onMinValueBlur={() => props.setFieldTouched('create_time_start', true)}
                onMaxValueBlur={() => props.setFieldTouched('create_time_end', true)}
                minError={props.errors.create_time_start}
                maxError={props.errors.create_time_end}
                minPlaceholder="DD-MM-YYYY"
                maxPlaceholder="DD-MM-YYYY"
              />

              <Divider />
              <Layout
                style={isMobile ? styles.mobileButtonContainer : styles.desktopButtonContainer}>
                <Button
                  status="primary"
                  loading={props.isSubmitting}
                  disabled={props.isSubmitting || !props.isValid}
                  onPress={props.handleSubmit}
                  style={styles.button}>
                  Submit
                </Button>
                <Button
                  status="basic"
                  loading={props.isSubmitting}
                  onPress={onDismiss}
                  disabled={props.isSubmitting}
                  style={styles.button}>
                  Cancel
                </Button>
                <Button
                  status="danger"
                  appearance="ghost"
                  disabled={props.isSubmitting}
                  onPress={() => props.setValues(DEFAULT_VALUES)}
                  style={styles.button}>
                  Reset
                </Button>
              </Layout>
            </>
          );
        }}
      </Formik>
    </Layout>
  );
});

const styles = StyleSheet.create({
  field: {
    marginVertical: 8,
  },
  title: {
    marginVertical: 10,
  },
  form: {
    alignSelf: 'center',
  },
  button: {
    marginVertical: 10,
    marginHorizontal: 2,
  },
  desktopButtonContainer: {
    flexDirection: 'row-reverse ',
  },
  mobileButtonContainer: {
    flexDirection: 'column',
  },
});
