import { Card, Button, Avatar } from 'react-native-paper';
import React, { useEffect, useMemo } from 'react';
import { Comments } from 'src/components/Comments';
import { Formik } from 'formik';
import { gql, useMutation, useQuery } from '@apollo/client';
import { TextInputField } from 'src/components/input/TextInputField';
import { UIStatusWrapper } from '../../components/ui-status';
import { sortBy, prop, reverse } from 'ramda';
import { unifiedAlert } from '../../core/utils/utils';
import { STORE_PROVIDERS } from '@ezom/library/lib/cjs/constants';

const GET_COMMENTS = gql`
  query comments($orderId: ID!, $storeId: ID!) {
    comments(orderId: $orderId, storeId: $storeId) {
      id
      authorId
      orderId
      createdTime
      updatedTime
      authorName
      content
      editable
    }
  }
`;

const SYNC_ORDER_COMMENTS = gql`
  mutation syncComments($id: ID!, $storeId: ID!) {
    syncComments(orderId: $id, storeId: $storeId)
  }
`;

const CREATE_COMMENT = gql`
  mutation createComment($orderId: ID!, $content: String, $storeId: ID!) {
    createComment(orderId: $orderId, content: $content, storeId: $storeId) {
      id
    }
  }
`;

export default ({ order, noTitle = false }) => {
  const {
    data: commentsData,
    loading: loadingComments,
    error: commentsLoadingError,
    refetch: refetchComments,
  } = useQuery(GET_COMMENTS, {
    variables: {
      orderId: order.id,
      storeId: order.storeId,
    },
  });

  const [syncOrderComments, { loading: loadingSyncShopifyComments }] = useMutation(
    SYNC_ORDER_COMMENTS,
    {
      variables: {
        id: order.id,
        storeId: order.storeId,
      },
    },
  );

  const [addComment, { loading: mutationLoading, error: mutationError }] =
    useMutation(CREATE_COMMENT);

  const comments = useMemo(
    () =>
      reverse(
        sortBy(
          prop('createdTime'),
          (commentsData?.comments || []).map((c) => ({
            ...c,
            storeId: order.storeId,
            createdTime: new Date(Number(c.createdTime)).toISOString(),
            updatedTime: new Date(Number(c.updatedTime)).toISOString(),
          })),
        ),
      ),
    [commentsData],
  );

  const onSyncCommentsButtonPressed = async () => {
    await syncOrderComments({ id: order.id, storeId: order.storeId });
    await refetchComments();
  };

  return (
    <UIStatusWrapper
      status={{
        error: commentsLoadingError,
        emtpy: !!commentsData,
        indeterminate: loadingComments,
      }}>
      <Card>
        {noTitle ? null : (
          <Card.Title
            title="Comments"
            left={(props) => <Avatar.Icon {...props} icon="comment" />}
          />
        )}
        <Formik
          initialValues={{ comment: '' }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            try {
              await addComment({
                variables: {
                  orderId: order.id,
                  content: values.comment,
                  storeId: order.storeId,
                },
              });
              await refetchComments();
              resetForm();
            } catch (e) {
              const msg = 'Error! ' + JSON.stringify(e);
              unifiedAlert(msg);
            }
            setSubmitting(false);
          }}>
          {(formProps) => (
            <>
              <Card.Content>
                <TextInputField
                  size="medium"
                  placeholder="Type something ..."
                  multiline={true}
                  name="comment"
                  textStyle={{ minHeight: 64 }}
                  style={{ width: '100%' }}
                  {...formProps}
                />
              </Card.Content>

              <Card.Actions>
                <Button
                  onPress={formProps.submitForm}
                  primary
                  loading={formProps.isSubmitting}
                  disabled={formProps.isSubmitting || !formProps.dirty}>
                  Add comment
                </Button>
                {order.provider == STORE_PROVIDERS.SHOPIFY && (
                  <Button
                    onPress={onSyncCommentsButtonPressed}
                    loading={loadingSyncShopifyComments}
                    disabled={loadingSyncShopifyComments}>
                    Sync comments
                  </Button>
                )}
              </Card.Actions>
            </>
          )}
        </Formik>
        <UIStatusWrapper
          status={{
            error: commentsLoadingError,
            emtpy: !!comments || comments.length === 0,
            indeterminate: loadingComments || loadingSyncShopifyComments,
          }}>
          {order.note ? (
            <Comments
              comments={[
                {
                  content: order.note,
                  createdTime: order.createdAt,
                  updatedTime: order.createdAt,
                  authorName: 'Customer',
                },
              ]}
            />
          ) : null}
          <Comments comments={comments} onCommentsChanged={refetchComments} />
        </UIStatusWrapper>
      </Card>
    </UIStatusWrapper>
  );
};
