import { Formik } from 'formik';
import { StyleSheet, View } from 'react-native';
import { Button, Card, Text, Modal } from '@ui-kitten/components';
import React from 'react';
import { TextInputField } from './input/TextInputField';
import { UIStatusWrapper, UIStatus } from './ui-status';
import { warehouseAccountStore } from '../store';
import { unifiedAlert } from '../core/utils/utils';
import { ezTheme } from '../core/theme';

const CONFIRM_MSG = 'delete';
export const DeleteConfirmationModal = ({ visible, title, warningMessage, onSubmit, onCancel }) => {
  return (
    <Modal visible={visible} backdropStyle={styles.backdrop} onBackdropPress={onCancel}>
      <Formik
        initialValues={{
          confirmMsg: '',
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          if (values.confirmMsg.toLowerCase() === CONFIRM_MSG) {
            setSubmitting(true);
            try {
              await onSubmit();
            } catch (e) {
              unifiedAlert(JSON.stringify(e));
              throw e;
            }
            setSubmitting(false);
          }
        }}>
        {(props) => {
          const Footer = (footerProps) => (
            <View {...footerProps}>
              <Button
                loading={props.isSubmitting}
                disabled={
                  warehouseAccountStore.loading ||
                  props.values.confirmMsg.toLowerCase() !== CONFIRM_MSG
                }
                style={styles.footerControl}
                status="danger"
                size="small"
                onPress={() => props.handleSubmit()}>
                Delete
              </Button>
            </View>
          );

          return (
            <UIStatusWrapper
              status={new UIStatus(warehouseAccountStore.loading, warehouseAccountStore.error)}>
              <Card
                style={styles.card}
                header={(props) => (
                  <View {...props}>
                    <Text category="h6">{title}</Text>
                  </View>
                )}
                footer={Footer}>
                <Text status="primary">
                  Warning: {warningMessage}. Type{' '}
                  <strong>
                    <i>delete</i>
                  </strong>{' '}
                  to confirm
                </Text>
                <TextInputField
                  name="confirmMsg"
                  style={styles.field}
                  placeholder="delete"
                  {...props}
                />
              </Card>
            </UIStatusWrapper>
          );
        }}
      </Formik>
    </Modal>
  );
};

const styles = StyleSheet.create({
  field: {
    marginVertical: 8,
  },
  footerControl: {
    marginTop: '0.5em',
  },
  card: {
    flex: 1,
    margin: 2,
  },
  backdrop: {
    backgroundColor: ezTheme.backdropModalColor,
  },
});
