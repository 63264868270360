import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { courierStore, warehouseAccountStore, warehousesStore } from '../store';
import CheckTreePicker from 'rsuite/CheckTreePicker';
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import TagGroup from 'rsuite/TagGroup';
import Tag from 'rsuite/Tag';
import 'rsuite/dist/rsuite.css';
import { Layout } from '@ui-kitten/components';
import { prop, sortBy } from 'ramda';
import { Dimensions } from 'react-native';

const WAREHOUSE_TYPE = 'WAREHOUSE';
const WAREHOUSE_ACCOUNT_TYPE = 'WAREHOUSE_ACCOUNT';
const COLOURS = ['red', 'orange', 'yellow', 'green', 'cyan', 'blue', 'violet'];

export const WarehouseCourierTreeSelect = observer(({ value, setValue }) => {
  const [data, setData] = useState([]);
  const [tags, setTags] = React.useState([]);
  const windowHeight = Dimensions.get('window').height;

  useEffect(() => {
    const d = sortBy(
      prop('label'),
      warehouseAccountStore.warehouseAccounts.map((warehouseAccount) => {
        return {
          label: warehouseAccount.name,
          value: `${warehouseAccount.id}`,
          key: warehouseAccount.id,
          id: warehouseAccount.id,
          type: WAREHOUSE_ACCOUNT_TYPE,
          children: sortBy(
            prop('label'),
            (warehouseAccount &&
              warehousesStore.warehousesByAccountId?.[warehouseAccount.id]?.map((warehouse) => ({
                label: warehouse.warehouse_name_en,
                value: `${warehouseAccount.id}:${warehouse.warehouse_code}`,
                key: warehouse.warehouse_code,
                id: warehouse.warehouse_code,
                type: WAREHOUSE_TYPE,
                warehouseAccountId: warehouseAccount.id,
                children: [],
              }))) ||
              [],
          ),
        };
      }),
    );
    setData(d);
  }, [warehouseAccountStore.warehouseAccounts, warehousesStore.warehousesByAccountId]);

  useEffect(() => {
    (async () => {
      const tags = await Promise.all(
        value.map(async (v) => {
          let tagText;
          const wAccountNode = data.find((d) => d.value === v.split(':')[0]);
          if (!wAccountNode) {
            return null;
          }
          if (v.split(':').length > 1) {
            const warehouseNode = wAccountNode.children?.find((c) => c.id === v.split(':')[1]);
            if (!warehouseNode) {
              return null;
            }
            if (v.split(':').length > 2) {
              const couriers = await courierStore.getCouriers(wAccountNode.id, [warehouseNode.id]);
              const courier = couriers.find((c) => c.logistics_product_code === v.split(':')[2]);
              tagText = `${wAccountNode.label}:${warehouseNode.label}:${courier.logistics_product_name_en}`;
            } else {
              tagText = `${wAccountNode.label}:${warehouseNode.label}`;
            }
          } else {
            tagText = `${wAccountNode.label}`;
          }
          const group =
            COLOURS[(v.split(':')[1] || v.split(':')[0]).charCodeAt(0) % COLOURS.length];
          return [tagText, group];
        }),
      );
      setTags(tags.filter((t) => !!t));
    })();
  }, [value, data]);

  return (
    <Layout>
      <TagGroup style={{ marginTop: 10, marginBottom: 5 }}>
        {tags.map((tag) => (
          <Tag size="lg" color={tag[1]}>
            {tag[0]}
          </Tag>
        ))}
      </TagGroup>

      <CheckTreePicker
        menuStyle={{
          maxHeight: windowHeight > 800 ? 400 : windowHeight > 600 ? 200 : 100,
          overflow: 'auto',
        }}
        countable={false}
        uncheckableItemValues={warehouseAccountStore.warehouseAccounts.map((wa) => `${wa.id}`)}
        data={data}
        value={value}
        getChildren={async (node) => {
          if (!node.expanded && node.type === WAREHOUSE_TYPE) {
            const couriers = await courierStore.getCouriers(node.parent.id, [node.id]);
            return sortBy(
              prop('label'),
              couriers.map((c) => ({
                label: c.logistics_product_name_en,
                value: `${node.parent.id}:${node.id}:${c.logistics_product_code}`,
                id: c.logistics_product_code,
                key: c.logistics_product_code,
              })),
            );
          }
        }}
        onChange={(values) => {
          setValue(values);
        }}
        block
        renderMenu={(menu) => {
          if (data.length === 0) {
            return (
              <div style={{ padding: 10, color: '#999', textAlign: 'center' }}>
                <SpinnerIcon spin /> Loading...
              </div>
            );
          }
          return menu;
        }}
        size="md"
        renderValue={(values, checkedItems, selectedElement) => {
          return `${values.length} item(s) selected`;
        }}
      />
    </Layout>
  );
});
