import { SHIPPING_CHANNEL_CODE, STORE_PROVIDERS } from '@ezom/library/lib/cjs/constants';

// https://developer.ebay.com/devzone/xml/docs/reference/ebay/types/ShippingCarrierCodeType.html
const ebayCourierDict = {
  // AU
  F030: 'AustraliaPost',
  F031: 'AustraliaPost',
  F032: 'Toll',
  F033: 'Toll',
  F034: '',
  F035: 'AustraliaPost',
  F036: 'AustraliaPost',
  F171: 'AustraliaPost',
  F219: 'FASTWAYCOURIERS',
  F231: 'AustraliaPost',
  F232: 'AustraliaPost',
  F1507: 'AustraliaPost',
  F400: 'FourPX',
  F522: 'FASTWAYCOURIERS',
  F686: 'AustraliaPost',
  F687: 'AustraliaPost',
  F688: 'Toll',
  F689: 'Toll',
  F690: '',
  F790: 'AustraliaPost',
  F791: 'AustraliaPost',
  F835: 'BorderExpress',
  F836: 'BorderExpress',
  F837: 'BorderExpress',
  F843: 'BorderExpress',
  F844: 'BorderExpress',
  F995: 'HUNTEREXPRESS',
  F996: 'HUNTEREXPRESS',
  // TODO: kept for backward compatibility, remvoe the below two in next release
  POST_EPARCEL_RP: 'AustraliaPost',
  POST_EPARCEL_CN: 'AustraliaPost',
  [SHIPPING_CHANNEL_CODE.AUSTRALIA_POST_EPARCEL]: 'Australia Post',
  [SHIPPING_CHANNEL_CODE.AUSTRALIA_POST_EPARCEL_EXPRESS]: 'Australia Post',
  [SHIPPING_CHANNEL_CODE.AUSPOST_INTERNATIONAL]: 'Australia Post',
  [SHIPPING_CHANNEL_CODE.ETOWER]: 'Australia Post',
  // USA
  F120: 'USPS',
  F121: 'USPS',
  F123: 'UPS',
  F124: 'UPS',
  F125: 'UPS',
  F127: 'UPS',
  F128: 'USPS',
  F129: 'USPS',
  F130: 'USPS',
  F131: 'USPS',
  F132: 'FedEx',
  F133: 'FedEx',
  F134: 'FedEx',
  F139: 'UPS',
  F140: 'UPS',
};

// https://developer.etsy.com/documentation/tutorials/fulfillment/#tracking-updates-for-shipping-carriers
const etsyCourierDict = {
  // AU
  F030: 'australia-post',
  F031: 'australia-post',
  F032: 'toll-global-express',
  F033: 'toll-global-express',
  F034: '',
  F035: 'australia-post',
  F036: 'australia-post',
  F171: 'australia-post',
  F219: 'fastway-au',
  F231: 'australia-post',
  F232: 'australia-post',
  F1507: 'australia-post',
  F400: '4px',
  F522: 'fastway-au',
  F686: 'australia-post',
  F687: 'australia-post',
  F688: 'toll-global-express',
  F689: 'toll-global-express',
  F690: '',
  F790: 'australia-post',
  F791: 'australia-post',
  F835: '',
  F836: '',
  F837: '',
  F843: '',
  F844: '',
  F995: '',
  F996: '',
  // TODO: kept for backward compatibility, remvoe the below two in next release
  POST_EPARCEL_RP: 'australia-post',
  POST_EPARCEL_CN: 'australia-post',
  [SHIPPING_CHANNEL_CODE.AUSTRALIA_POST_EPARCEL]: 'australia-post',
  [SHIPPING_CHANNEL_CODE.AUSTRALIA_POST_EPARCEL_EXPRESS]: 'australia-post',
  [SHIPPING_CHANNEL_CODE.AUSPOST_INTERNATIONAL]: 'australia-post',
  [SHIPPING_CHANNEL_CODE.ETOWER]: 'australia-post',
  // USA
  F120: 'usps',
  F121: 'usps',
  F123: 'ups',
  F124: 'ups',
  F125: 'ups',
  F127: 'ups',
  F128: 'usps',
  F129: 'usps',
  F130: 'usps',
  F131: 'usps',
  F132: 'fedex',
  F133: 'fedex',
  F134: 'fedex',
  F139: 'ups',
  F140: 'ups',
};

// https://shopify.dev/api/admin-rest/2022-07/resources/fulfillment#resource-object
const shopifyCourierDict = {
  // AU
  F030: 'Australia Post',
  F031: 'Australia Post',
  F032: 'Toll IPEC',
  F033: 'Toll IPEC',
  F034: '',
  F035: 'Australia Post',
  F036: 'Australia Post',
  F171: 'Australia Post',
  F219: 'FASTWAYCOURIERS',
  F231: 'Australia Post',
  F232: 'Australia Post',
  F1507: 'Australia Post',
  F400: '4PX',
  F522: 'Aramex Australia',
  F686: 'Australia Post',
  F687: 'Australia Post',
  F688: 'Toll IPEC',
  F689: 'Toll IPEC',
  F690: '',
  F790: 'Australia Post',
  F791: 'Australia Post',
  F835: '',
  F836: '',
  F837: '',
  F843: '',
  F844: '',
  F995: 'Hunter Express',
  F996: 'Hunter Express',
  // TODO: kept for backward compatibility, remvoe the below two in next release
  POST_EPARCEL_RP: 'Australia Post',
  POST_EPARCEL_CN: 'Australia Post',
  [SHIPPING_CHANNEL_CODE.AUSTRALIA_POST_EPARCEL]: 'Australia Post',
  [SHIPPING_CHANNEL_CODE.AUSTRALIA_POST_EPARCEL_EXPRESS]: 'Australia Post',
  [SHIPPING_CHANNEL_CODE.AUSPOST_INTERNATIONAL]: 'Australia Post',
  [SHIPPING_CHANNEL_CODE.ETOWER]: 'Australia Post',
  // USA
  F120: 'USPS',
  F121: 'USPS',
  F123: 'UPS',
  F124: 'UPS',
  F125: 'UPS',
  F127: 'UPS',
  F128: 'USPS',
  F129: 'USPS',
  F130: 'USPS',
  F131: 'USPS',
  F132: 'FedEx',
  F133: 'FedEx',
  F134: 'FedEx',
  F139: 'UPS',
  F140: 'UPS',
};

export const getCourierCompany = (courierCode, storeProvider) => {
  if (storeProvider === STORE_PROVIDERS.EBAY) {
    return ebayCourierDict[courierCode] || '';
  } else if (storeProvider === STORE_PROVIDERS.ETSY) {
    return etsyCourierDict[courierCode] || '';
  } else if (storeProvider === STORE_PROVIDERS.SHOPIFY) {
    return shopifyCourierDict[courierCode] || '';
  }
  return '';
};
