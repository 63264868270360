import React, { useMemo } from 'react';
import { StyleSheet, Dimensions, ScrollView } from 'react-native';
import { Layout, Button, Text, Divider, Modal, Card, Icon } from '@ui-kitten/components';
import * as Yup from 'yup';
import { useIsMobile } from '../core/responsive.utils';
import { Formik } from 'formik';
import { TextInputField } from './input/TextInputField';
import Autocomplete from './Autocomplete';
import { observer } from 'mobx-react';
import { countryStateCityCurrencyStore, inventoryStore, warehousesStore } from '../store';
import DeclareCountryInput from './DeclareCountryInput';
import { omit, remove } from 'ramda';
import { ezTheme } from '../core/theme';

const windowHeight = Dimensions.get('window').height;

export default observer(({ visible, setVisible, sku, onSubmit, isWmsManager }) => {
  const isMobile = useIsMobile();
  const uoms = inventoryStore.getUoms(sku.warehouseAccountId);
  const COUNTRIES = countryStateCityCurrencyStore.countryCodes;
  const CURRENCIES = countryStateCityCurrencyStore.currencies;

  const skuInfoSchema = useMemo(
    () =>
      Yup.object({
        length: Yup.number().min(1, 'Must be at least 1').required('Required'),
        width: Yup.number().min(1, 'Must be at least 1').required('Required'),
        height: Yup.number().min(1, 'Must be at least 1').required('Required'),
        weight: Yup.number().min(0.01, 'Must be at least 0.001').required('Required'),
        product_code: Yup.string()
          .matches(/^[a-zA-Z0-9]+$/, 'only allow letters and number')
          .min(3, 'Minimum 3 characters'),
        uom: Yup.string().oneOf(
          uoms.map((u) => u.code),
          'Not a valid UOM',
        ),
        declare_country_list: Yup.array()
          .of(
            Yup.object().shape({
              export_country: Yup.string()
                .nullable()
                .oneOf(COUNTRIES, 'Invalid country code')
                .required('Export country is Required')
                .default('CN'),
              export_port: Yup.string().nullable().required('Export port is required'),
              export_declare: Yup.string().nullable().required('Export declaration is required'),
              export_hscode: Yup.string().nullable().required('Export HS-CODE is required'),
              export_declare_value: Yup.number()
                .nullable()
                .required('Export declare value is required'),
              export_currency: Yup.string()
                .nullable()
                .oneOf(CURRENCIES, 'export declare currency not valid')
                .required('Export declare currency is required')
                .default('USD'),
              country: Yup.string()
                .nullable()
                .oneOf(COUNTRIES, 'Invalid country code')
                .required('Import country is required')
                .default('AU'),
              import_port: Yup.string().nullable().required('Import port is required'),
              import_declare: Yup.string().nullable().required('Import declaration is required.'),
              hs_code: Yup.string()
                .nullable()
                .required('Import HS-CODE is required. If you are not sure, please look it up.'),
              declare_value: Yup.number()
                .nullable()
                .min(0, 'Too small')
                .max(Number.MAX_SAFE_INTEGER, 'Too large')
                .required('Import declare value'),
              currency: Yup.string()
                .nullable()
                .oneOf(CURRENCIES, 'Not valid')
                .required('Import declare currency')
                .default('USD'),
            }),
          )
          .min(1),
      }),

    [warehousesStore.uomsByAccountId, sku, uoms, COUNTRIES, CURRENCIES],
  );

  return (
    <Modal
      visible={visible}
      backdropStyle={{
        backgroundColor: ezTheme.backdropModalColor,
      }}
      onBackdropPress={() => setVisible(false)}>
      <Card
        style={isMobile ? styles.mobileModalStyle : styles.modalStyle}
        header={() => (
          <Text category="h6" style={styles.title}>
            {`Update ${sku.sku_id}`}
          </Text>
        )}
        disabled>
        <ScrollView
          keyboardShouldPersistTaps={'handled'}
          contentContainerStyle={styles.formContainer}>
          <Formik
            isInitialValid={true}
            initialValues={{
              length: sku.length,
              width: sku.width,
              height: sku.height,
              weight: `${(sku.weight / 1000).toFixed(2)}`,
              product_code: sku.product_code || '',
              uom: sku.uom || '',
              declare_country_list: (sku.declare_country_list &&
                sku.declare_country_list.map(omit(['__typename']))) || [
                {
                  export_country: 'AU',
                  export_declare: '',
                  export_hscode: '',
                  export_declare_value: '',
                  export_currency: '',
                  country: 'AU',
                  import_declare: '',
                  hs_code: '',
                  declare_value: '',
                  currency: '',
                },
              ],
            }}
            validationSchema={skuInfoSchema}
            onSubmit={async (values) => {
              await onSubmit({
                length: parseInt(values.length),
                width: parseInt(values.width),
                height: parseInt(values.height),
                weight: parseFloat(values.weight) * 1000,
                product_code: values.product_code,
                uom: values.uom,
                declare_country_list: values.declare_country_list,
              });
              setVisible(false);
            }}>
            {(props) => {
              return (
                <>
                  <TextInputField
                    name="weight"
                    style={styles.field}
                    label="Weight (kg)"
                    disabled={!isWmsManager}
                    {...props}
                  />
                  <TextInputField
                    name="length"
                    style={styles.field}
                    label="Length (cm)"
                    disabled={!isWmsManager}
                    {...props}
                  />
                  <TextInputField
                    name="width"
                    style={styles.field}
                    label="Width (cm)"
                    disabled={!isWmsManager}
                    {...props}
                  />
                  <TextInputField
                    name="height"
                    style={styles.field}
                    label="Height (cm)"
                    disabled={!isWmsManager}
                    {...props}
                  />
                  <TextInputField
                    autoFocus
                    placeholder="4987188506042"
                    name="product_code"
                    style={styles.field}
                    label="Product code"
                    disabled={!isWmsManager}
                    {...props}
                  />
                  <Autocomplete
                    name="uom"
                    label="Unit of Measure"
                    defaultValue={props.values.uom}
                    error={props.errors.uom}
                    touched={props.touched.uom}
                    style={styles.field}
                    placeholder="Select a UOM"
                    options={uoms}
                    getValue={(uom) => uom.code}
                    getTitle={(uom) => uom.name_en}
                    onSelect={props.handleChange('uom')}
                    disabled={!isWmsManager}
                    onBlur={props.handleBlur('uom')}
                  />

                  {props.values.declare_country_list.map((c, i) => (
                    <DeclareCountryInput
                      key={'declare-country-' + i}
                      onRemove={() =>
                        props.setFieldValue(
                          'declare_country_list',
                          remove(i, i + 1, props.values.declare_country_list),
                        )
                      }
                      values={props.values.declare_country_list[i]}
                      touched={props.touched.declare_country_list?.[i]}
                      errors={props.errors.declare_country_list?.[i]}
                      handleChange={(valKey) =>
                        props.handleChange(`declare_country_list[${i}].${valKey}`)
                      }
                      handleBlur={(valKey) =>
                        props.handleBlur(`declare_country_list[${i}].${valKey}`)
                      }
                    />
                  ))}
                  <Divider />
                  <Button
                    size="medium"
                    appearance="ghost"
                    accessoryLeft={(props) => <Icon {...props} name="plus-circle-outline" />}
                    onPress={() =>
                      props.setFieldValue('declare_country_list', [
                        ...props.values.declare_country_list,
                        {},
                      ])
                    }>
                    Add import country
                  </Button>

                  <Divider />

                  <Divider />
                  <Layout
                    style={isMobile ? styles.mobileButtonContainer : styles.desktopButtonContainer}>
                    <Button
                      status="primary"
                      loading={props.isSubmitting}
                      onPress={props.handleSubmit}
                      disabled={props.isSubmitting || !props.isValid}
                      style={styles.button}>
                      Submit
                    </Button>
                    <Button
                      status="basic"
                      loading={props.isSubmitting}
                      onPress={() => setVisible(false)}
                      disabled={props.isSubmitting}
                      style={styles.button}>
                      Cancel
                    </Button>
                  </Layout>
                </>
              );
            }}
          </Formik>
        </ScrollView>
      </Card>
    </Modal>
  );
});

const styles = StyleSheet.create({
  field: {
    marginVertical: 8,
  },
  title: {
    marginBottom: 10,
  },
  button: {
    marginVertical: 10,
    marginHorizontal: 2,
  },
  desktopButtonContainer: {
    marginTop: 20,
    flexDirection: 'row-reverse ',
  },
  mobileButtonContainer: {
    marginTop: 10,
    flexDirection: 'column',
  },
  formContainer: {
    maxHeight: 0.75 * windowHeight,
    overflowY: 'auto',
    alignItems: 'center',
  },
  mobileRowFlex: {
    marginHorizontal: 'auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  desktopRowFlex: {
    flexDirection: 'row',
    width: 'auto',
  },
  mobileModalStyle: { backgroundColor: 'white', marginHorizontal: 20, padding: 10 },
  modalStyle: { backgroundColor: 'white', minWidth: 900, padding: 10 },
});
