import React, { memo, useState } from 'react';
import { paperNativeTheme } from '../core/theme';
import { StyleSheet } from 'react-native';
import { Card } from 'react-native-paper';
import Slider from '@react-native-community/slider';
import { useIsMobile } from 'src/core/responsive.utils';
import { Button, Layout, Text } from '@ui-kitten/components';
import { restockListStore } from '../store';
import { observer } from 'mobx-react';
import Select from './Select';
import SearchBar from './SearchBar';

const RestockListControl = observer(
  ({
    onSearchTermChange = () => {},
    disableSearch = false,
    requireButtonClickToSearch = false,
  }) => {
    const isMobileDevice = useIsMobile();
    const [forecastPeriod, setForecastPeriod] = useState(restockListStore.forecastPeriod);
    const [searchTerm, setSearchTerm] = useState('');
    return (
      <Card visible={true} style={styles.bannerContainer} actions={[]}>
        <Layout style={isMobileDevice ? styles.mobileBanner : styles.desktopBanner}>
          <SearchBar
            desktopSearchBar={{
              minWidth: 180,
              flex: 1,
            }}
            debounceDelayMs={500}
            disableSearch={disableSearch}
            requireButtonClickToSearch={requireButtonClickToSearch}
            inputPlaceholder="Search for products"
            inputSize="medium"
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            onSearchTermChange={onSearchTermChange}></SearchBar>
          <Button
            status="danger"
            appearance="ghost"
            onPress={() => {
              setSearchTerm('');
              restockListStore.resetSearch();
            }}
            style={styles.button}>
            Reset
          </Button>

          <Select
            size="small"
            value={restockListStore.selectedStore}
            options={restockListStore.storeOptions}
            onChange={(option) => {
              restockListStore.setSelectedStore(option.key);
            }}
            style={{ width: '15%', minWidth: 180 }}
          />
          <Layout style={{ marginHorizontal: 5, flexDirection: 'row' }}>
            <Text>Forecast period: {forecastPeriod} week(s): </Text>
            <Slider
              style={{ minWidth: 120, width: '15%' }}
              minimumValue={1}
              maximumValue={28}
              step={1}
              value={restockListStore.forecastPeriod}
              onValueChange={setForecastPeriod}
              onSlidingComplete={(val) => {
                restockListStore.setForecastPeriod(val);
              }}
              minimumTrackTintColor={paperNativeTheme.colors.primary}
            />
          </Layout>
        </Layout>
      </Card>
    );
  },
);

const styles = StyleSheet.create({
  bannerContainer: {
    marginBottom: '0.5em',
    padding: '1em',
  },
  desktopBanner: {
    maxWidth: '1800px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  mobileBanner: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  button: {
    height: '45px',
  },
});

export default memo(RestockListControl);
