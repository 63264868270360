import TextInput from './TextInput';
import React from 'react';
import { path } from 'ramda';

/**
 * A wrapper around TextInput, to be used inside a Formik form
 * @param props
 * @return {*}
 * @constructor
 * HandleMultilineInput behavior, automatically expanding the field when Enter or Space is pressed.
 * The number of lines to display for the multiline TextInput.
 * Used to automatically expand the input field.
 */
export const TextInputField = (props) => {
  const paths = props.name.split('.');
  const [numberOfLines, setNumberOfLines] = React.useState(1);
  const handleMultilineInput = props.multiline
    ? (e) => {
        if (e.nativeEvent.key === 'Enter') {
          e.preventDefault();
          props.handleChange && props.handleChange(props.name)(props.values[props.name] + '\n');
        }
      }
    : undefined;

  React.useEffect(() => {
    if (props.multiline) {
      const lines = (props.values[props.name] || '').split('\n').length;
      setNumberOfLines(Math.max(2, lines));
    }
  }, [props.values?.[props.name], props.multiline, props.name]);
  return (
    <TextInput
      style={props.style}
      size={props.size}
      label={props.label}
      placeholder={props.placeholder}
      disabled={props.disabled}
      autoFocus={props.autoFocus}
      error={path(paths, props.errors)}
      touched={path(paths, props.touched)}
      value={path(paths, props.values)}
      onChange={props.handleChange && props.handleChange(props.name)}
      onBlur={props.handleBlur && props.handleBlur(props.name)}
      textStyle={props.textStyle}
      multiline={props.multiline}
      onKeyPress={handleMultilineInput}
      numberOfLines={numberOfLines}
      textContentType={props.textContentType}
      keyboardType={props.keyboardType}
      accessoryRight={props.accessoryRight}
      {...props}
    />
  );
};
