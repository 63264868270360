import React, { memo, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Card } from 'react-native-paper';
import SearchBar from './SearchBar';

const SearchBanner = ({
  onSearchTermChange = () => {},
  requireButtonClickToSearch = false,
  disableSearch = true,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  return (
    <Card visible={true} style={styles.bannerContainer} actions={[]}>
      <SearchBar
        desktopSearchBar={{
          maxWidth: '1000px',
          flex: 1,
        }}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        onSearchTermChange={onSearchTermChange}
        inputPlaceholder="Search SKU mappings"
        inputSize="medium"
        requireButtonClickToSearch={requireButtonClickToSearch}
        disableSearch={disableSearch}></SearchBar>
    </Card>
  );
};

const styles = StyleSheet.create({
  bannerContainer: {
    marginBottom: '0.5em',
    padding: '1em',
  },
});

export default memo(SearchBanner);
