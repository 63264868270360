import { gql } from '@apollo/client';
import { flow, makeAutoObservable } from 'mobx';

const STATE_CODES_OF_COUNTRY = gql`
  query stateCodesOfCountry($countryCode: String!) {
    stateCodesOfCountry(countryCode: $countryCode)
  }
`;

const CITIES_OF_STATE = gql`
  query citiesOfState($countryCode: String!, $stateCode: String!) {
    citiesOfState(countryCode: $countryCode, stateCode: $stateCode)
  }
`;

const COUNTRIES = gql`
  query countries {
    countries {
      isoCode
      name
      currency
    }
  }
`;

export class CountryStateCityCurrencyStore {
  loading = false;
  error = null;
  cachedCurrencies = null;
  cachedCountries = null;

  constructor(client) {
    this.client = client;
    makeAutoObservable(
      this,
      {
        fetchItems: flow,
        client: false,
        warehouseAccountStore: false,
      },
      { autoBind: true },
    );
  }

  get currencies() {
    // only allow USD
    return ['USD'];
    // return [...new Set(this.countries.map((c) => c.currency))];
  }

  get countryCodes() {
    return this.countries.map((c) => c.isoCode);
  }

  async getStateCodesOfCountry(countryCode) {
    const data = await this.client.query({
      query: STATE_CODES_OF_COUNTRY,
      variables: {
        countryCode,
      },
    });
    return data.data.stateCodesOfCountry;
  }

  async getCitiesOfState(countryCode, stateCode) {
    const data = await this.client.query({
      query: CITIES_OF_STATE,
      variables: {
        countryCode,
        stateCode,
      },
    });
    return data.data.citiesOfState;
  }

  get countries() {
    if (!this.cachedCountries) {
      this.fetchCountries();
      return [];
    } else {
      return this.cachedCountries;
    }
  }

  *getCountryByCode(countryCode) {
    if (!this.cachedCountries) {
      yield this.fetchCountries();
    }
    return this.countries.find((c) => c.isoCode.toLowerCase() === countryCode.toLowerCase());
  }

  *getCountryByName(country) {
    if (!this.cachedCountries) {
      yield this.fetchCountries();
    }
    return this.countries.find((c) => c.name.toLowerCase() === country.toLowerCase());
  }

  *fetchCountries() {
    const { data } = yield this.client.query({
      query: COUNTRIES,
    });
    this.cachedCountries = data?.countries;
  }
}
