import { Button, Layout } from '@ui-kitten/components';
import { memo, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Card, Chip } from 'react-native-paper';
import { useIsMobile } from '../core/responsive.utils';
import SearchBar from './SearchBar';
import React from 'react';

export interface Props {
  filterParams?: any;
  indicators?: any[];
  onSearchTermChange?: (value: string) => void;
  onFilterChange?: (data: any) => void;
  onFilterClear?: () => void;
  disableSearch?: boolean;
  requireButtonClickToSearch?: boolean;
  listFilterComponent?: any[];
  placeholder?: string;
}
const HeaderSearchFilter = ({
  disableSearch = false,
  filterParams = {},
  indicators = [],
  onFilterChange = () => {},
  onFilterClear = () => {},
  onSearchTermChange = () => {},
  requireButtonClickToSearch = false,
  listFilterComponent = [],
  placeholder,
}) => {
  const isMobileDevice = useIsMobile();
  const [searchTerm, setSearchTerm] = useState('');
  return (
    <Card visible={true} style={styles.bannerContainer} actions={[]}>
      <Layout style={isMobileDevice ? styles.mobileBanner : styles.desktopBanner}>
        <SearchBar
          debounceDelayMs={500}
          disableSearch={disableSearch}
          onSearchTermChange={onSearchTermChange}
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          requireButtonClickToSearch={requireButtonClickToSearch}
          inputPlaceholder={placeholder || 'Search'}
          inputSize="medium"></SearchBar>
        <Layout style={isMobileDevice ? styles.mobileButtonGroup : styles.desktopButtonGroup}>
          {listFilterComponent?.map((item) => item)}
          <Button
            status="danger"
            appearance="ghost"
            onPress={() => {
              setSearchTerm('');
              onFilterClear();
            }}
            style={styles.button}>
            Reset filter
          </Button>
        </Layout>
      </Layout>
      <Layout style={styles.indicator}>
        {indicators.map((indicator) => (
          <Chip style={styles.chip} key={indicator}>
            {indicator}
          </Chip>
        ))}
      </Layout>
    </Card>
  );
};

const styles = StyleSheet.create({
  bannerContainer: {
    marginBottom: '0.5em',
    padding: '1em',
  },
  desktopBanner: {
    maxWidth: '1800px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  mobileBanner: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  indicator: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  chip: {
    marginTop: '0.4em',
    marginHorizontal: '0.2em',
    opacity: '0.5',
  },

  rangeInput: {
    width: '100px',
    margin: '0.5em',
  },
  desktopButtonGroup: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mobileButtonGroup: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    height: '45px',
  },
  popover: {
    margin: '0.5em',
    padding: '0.5em',
  },
  checkItem: {
    marginVertical: 4,
    marginHorizontal: 12,
  },
});

export default memo(HeaderSearchFilter);
