import React, { memo } from 'react';
import { StyleSheet } from 'react-native';
import { Select, Text, Layout, SelectItem, IndexPath } from '@ui-kitten/components';
import { paperNativeTheme } from 'src/core/theme';
import { useIsMobile } from '../core/responsive.utils';
import { reject, isNil } from 'ramda';
import { observer } from 'mobx-react';

const Component = observer(
  ({
    error,
    touched,
    style = {},
    size = 'medium',
    label,
    options,
    onChange,
    value,
    multiSelect,
    ...props
  }) => {
    const isMobileDevice = useIsMobile();
    const [selectedIndex, setSelectedIndex] = React.useState(
      multiSelect
        ? reject(
            isNil,
            (value || []).map((v) =>
              options.map((o) => o.key).indexOf(v) > -1
                ? new IndexPath(options.map((o) => o.key).indexOf(v))
                : null,
            ),
          )
        : options.map((o) => o.key).indexOf(value) > -1
        ? new IndexPath(options.indexOf(value))
        : null,
    );

    return (
      <Layout
        style={[
          styles.rowContainer,
          isMobileDevice ? styles.mobileRowFlex : styles.desktopRowFlex,
          style,
        ]}
        level="1">
        {label ? (
          <Text
            category={size === 'large' ? 's1' : size === 'medium' ? 's2' : 'label'}
            style={isMobileDevice ? styles.mobileLabel : styles.desktopLabel}>
            {label}
          </Text>
        ) : null}
        <Select
          size={size}
          style={[
            styles.input,
            label ? (isMobileDevice ? styles.mobileInput : styles.desktopInput) : { width: '100%' },
          ]}
          selectionColor={paperNativeTheme.colors.primary}
          status={touched && error ? 'danger' : 'primary'}
          caption={error}
          selectedIndex={selectedIndex}
          value={
            multiSelect
              ? (value || []).map((v) => options.find((o) => o.key === v)?.val || v).join(', ')
              : options.find((o) => o.key === value)?.val || value
          }
          multiSelect={multiSelect}
          onSelect={(index) => {
            if (Array.isArray(index)) {
              setSelectedIndex(index);
              onChange(index.map((i) => options[i.row]));
            } else {
              setSelectedIndex(index);
              onChange(options[index.row]);
            }
          }}
          {...props}>
          {options.map((val) => {
            if (val instanceof Object) {
              return <SelectItem title={val.val} key={val.key} accessoryLeft={val.accessoryLeft} />;
            } else {
              return <SelectItem title={val} key={val} />;
            }
          })}
        </Select>
      </Layout>
    );
  },
);

const styles = StyleSheet.create({
  input: {
    flex: 1,
    marginHorizontal: 5,
  },
  mobileLabel: {
    flex: 1,
    maxWidth: '100px',
    minWidth: '100px',
  },
  desktopLabel: {
    minWidth: '130px',
  },
  rowContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mobileRowFlex: {
    marginHorizontal: 'auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  desktopRowFlex: {
    flexDirection: 'row',
    width: 'auto',
  },
  desktopInput: {
    width: '260px',
    minWidth: '260px',
  },
  mobileInput: {
    width: '200px',
    minWidth: '200px',
  },
});

export default memo(Component);
