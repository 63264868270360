import { gql } from '@apollo/client';
import { flow, makeAutoObservable } from 'mobx';
import { flatten, groupBy, prop } from 'ramda';

const GET_COURIERS = gql`
  query courierList($warehouseAccountId: ID!, $warehouses: [ID!]!) {
    courierList(warehouseAccountId: $warehouseAccountId, warehouses: $warehouses) {
      logistics_provider
      logistics_product_code
      logistics_product_name_en
      warehouse_code
      requireExportInfo
      singlePackageOnly
      supportPacking
      autoPack
      allowUserShipmentOrder
      deliveryWindow
      dangerousGoods
      handUnload
      tailgate
    }
  }
`;

// Query for getting couriers specifically for shipment orders
const GET_SHIPMENT_ORDER_COURIERS = gql`
  query shipmentOrderCourierList($warehouseAccountId: ID!) {
    shipmentOrderCourierList(warehouseAccountId: $warehouseAccountId) {
      logistics_provider
      logistics_product_code
      logistics_product_name_en
      warehouse_code
      requireExportInfo
      singlePackageOnly
      supportPacking
      autoPack
      allowUserShipmentOrder
      deliveryWindow
      dangerousGoods
      handUnload
      tailgate
    }
  }
`;

export class CourierStore {
  loading = false;
  error = null;
  client = null;

  constructor(client, storeStore) {
    this.client = client;
    this.storeStore = storeStore;

    makeAutoObservable(
      this,
      {
        fetchItems: flow,
        client: false,
        storeStore: false,
      },
      { autoBind: true },
    );
  }

  *fetchShipmentOrderCourierList(warehouseAccountId) {
    try {
      const { data } = yield this.client.query({
        query: GET_SHIPMENT_ORDER_COURIERS,
        variables: { warehouseAccountId },
      });
      return data?.shipmentOrderCourierList;
    } catch (error) {
      console.error(error);
    }
  }

  async getCouriers(warehouseAccountId, warehouses) {
    const data = await this.client.query({
      query: GET_COURIERS,
      variables: {
        warehouseAccountId,
        warehouses,
      },
    });

    return data.data?.courierList || [];
  }

  async getStoreWhitelistedCouriersByWarehouse(storeId, warehouse) {
    const warehouseCourierWhiteList = await this.storeStore.getStoreWarehouseCourierById(storeId);
    const warehouseCourierEntries = (warehouseCourierWhiteList || []).filter(
      (w) => w.warehouseCode === warehouse,
    );
    if (warehouseCourierEntries.length > 0) {
      const courierList = await this.getCouriers(warehouseCourierEntries[0].warehouseAccountId, [
        warehouse,
      ]);
      // Whitelist couriers if courierCode is not empty for any entry
      if (warehouseCourierEntries.find((w) => !!w.courierCode)) {
        const whiltelistedCouriers = warehouseCourierEntries.map((w) => w.courierCode);
        return courierList.filter((c) => whiltelistedCouriers.includes(c.logistics_product_code));
      } else {
        return courierList;
      }
    }
    return [];
  }

  async getAllStoreWhiteListedCouriers(storeId) {
    const warehouseCourierWhiteList = await this.storeStore.getStoreWarehouseCourierById(storeId);
    const warehouseCodesGroupedByAccount = map(
      (warehouseCouriers) => warehouseCouriers.map((wc) => wc.warehouseCode),
      groupBy(prop('warehouseAccountId'), warehouseCourierWhiteList),
    );
    const whiteListedCouriers = await Promise.all(
      Object.values(warehouseCodesGroupedByAccount).map(
        async ([warehouseAccountId, warehouseCodes]) => {
          const couriers = await this.getCouriers(warehouseAccountId, warehouseCodes);
          return couriers.filter((courier) => {
            if (
              warehouseCourierWhiteList.find(
                (w) => !!w.courierCode && w.warehouseCode === courier.warehouse_code,
              )
            ) {
              const whiltelistedCouriers = warehouseCourierWhiteList.map((w) => w.courierCode);
              return whiltelistedCouriers.includes(courier.logistics_product_code);
            } else {
              couriers;
            }
          });
        },
      ),
    );
    return flatten(whiteListedCouriers);
  }
}
