import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import { Card } from '@ui-kitten/components';
import {
  createContainer,
  VictoryChart,
  VictoryTooltip,
  VictoryAxis,
  VictoryGroup,
  VictoryBar,
} from 'victory-native';

const VictoryZoomVoronoiContainer = createContainer('zoom', 'voronoi');

export default ({ data, scale, header, x, y, colorScale, style, ...props }) => {
  const [zoomDomain, setZoomDomain] = useState();
  return (
    <Card style={[styles.card, style]} header={header}>
      <VictoryChart
        width="700"
        height="310"
        scale={scale}
        containerComponent={
          <VictoryZoomVoronoiContainer
            zoomDimension="x"
            voronoiDimension="x"
            labels={({ datum }) => (datum[y] ? `${datum.l}: ${datum[y]}` : null)}
            zoomDomain={zoomDomain}
            onZoomDomainChange={setZoomDomain}
            labelComponent={<VictoryTooltip cornerRadius={0} flyoutStyle={{ fill: 'white' }} />}
          />
        }>
        <VictoryAxis dependentAxis />
        <VictoryAxis tickCount={6} />
        <VictoryGroup offset={10} style={{ data: { width: 6 } }} colorScale={colorScale}>
          {data.map((d, index) => (
            <VictoryBar data={d} x={x} y={y} key={index} />
          ))}
        </VictoryGroup>
      </VictoryChart>
    </Card>
  );
};

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  card: {
    flex: 1,
    margin: 2,
    maxWidth: 700,
  },
  footerContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  footerControl: {
    marginHorizontal: 2,
  },
});
