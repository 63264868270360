import React, { useMemo } from 'react';
import { StyleSheet, Dimensions, ScrollView } from 'react-native';
import { Layout, Button, Text, Divider, Modal, Card, Toggle } from '@ui-kitten/components';
import * as Yup from 'yup';
import { useIsMobile } from '../core/responsive.utils';
import { Formik } from 'formik';
import Autocomplete from './Autocomplete';
import { observer } from 'mobx-react';
import { RadioGroupInputField } from './input/RadioGroupInputField';
import { printerSettingStore } from '../store';
import { unifiedAlert } from '../core/utils/utils';
import { PAPER_SIZE_LIST, ORIENTATION_LIST } from '../core/utils/utils';
import { TextInputField } from './input/TextInputField';
import { ezTheme } from '../core/theme';

const windowHeight = Dimensions.get('window').height;

export default observer(({ visible, setVisible, onSubmit, labelList, printerDevices }) => {
  const isMobile = useIsMobile();

  const printerSettingInfoSchema = useMemo(
    () =>
      Yup.object({
        courierLabel: Yup.string().required('Courier label is required'),
        printerName: Yup.string().required('Printer name is required'),
        paperSize: Yup.string()
          .oneOf(PAPER_SIZE_LIST, 'Not a valid paper size')
          .required('Paper size is required'),
        orientation: Yup.string()
          .oneOf(
            ORIENTATION_LIST.map((o) => o.key),
            'Not a valid orientation',
          )
          .required('Orientation is required'),
        enable: Yup.boolean()
          .oneOf([true, false], 'Not a valid option')
          .required('Must choose enable option'),
      }),
    [],
  );

  if (!printerSettingStore.selectedSetting) return null;

  return (
    <Modal
      visible={visible}
      backdropStyle={{
        backgroundColor: ezTheme.backdropModalColor,
      }}
      onBackdropPress={() => setVisible(false)}>
      <Card
        style={isMobile ? styles.mobileModalStyle : styles.modalStyle}
        header={() => (
          <Text category="h6" style={styles.title}>
            Update
          </Text>
        )}
        disabled>
        <ScrollView
          keyboardShouldPersistTaps={'handled'}
          contentContainerStyle={styles.formContainer}>
          <Formik
            isInitialValid={true}
            initialValues={{
              id: printerSettingStore.selectedSetting.id,
              courierLabel: printerSettingStore.selectedSetting.courierLabel,
              printerName: printerSettingStore.selectedSetting.printerName,
              paperSize: printerSettingStore.selectedSetting.paperSize,
              orientation: printerSettingStore.selectedSetting.orientation,
              warehouseCode: printerSettingStore.selectedSetting.warehouseCode,
              enable: printerSettingStore.selectedSetting.enable,
            }}
            validationSchema={printerSettingInfoSchema}
            onSubmit={async (values) => {
              try {
                setVisible(false);
                await printerSettingStore.updateItem(values);
                unifiedAlert('Update successfully.');
                onSubmit();
              } catch (err) {
                unifiedAlert(err);
              }
            }}>
            {(props) => {
              return (
                <>
                  <TextInputField
                    disabled={true}
                    label="Warehouse Code"
                    name="warehouseCode"
                    style={styles.field}
                    placeholder="Warehouse Code"
                    {...props}
                  />

                  <TextInputField
                    disabled={true}
                    label="Courier label"
                    name="courierLabel"
                    style={styles.field}
                    placeholder="Courier label"
                    {...props}
                  />

                  <Autocomplete
                    name="printerName"
                    label="Select printer"
                    error={props.errors.printerName}
                    touched={props.touched.printerName}
                    style={styles.field}
                    placeholder="Select a printer"
                    options={printerDevices.map((prt) => ({ key: prt, val: prt }))}
                    onSelect={props.handleChange('printerName')}
                    onBlur={props.handleBlur('printerName')}
                    defaultValue={props.values.printerName}
                    getTitle={(val) => val.val}
                    getValue={(val) => val.key}
                  />
                  <Autocomplete
                    name="paperSize"
                    label="Paper size"
                    defaultValue={props.values.paperSize}
                    error={props.errors.paperSize}
                    touched={props.touched.paperSize}
                    style={styles.field}
                    placeholder="Select paper size"
                    options={PAPER_SIZE_LIST}
                    onSelect={props.handleChange('paperSize')}
                    onBlur={props.handleBlur('paperSize')}
                  />
                  <RadioGroupInputField
                    label="Orientation"
                    name="orientation"
                    style={styles.field}
                    options={ORIENTATION_LIST}
                    defaultValue={props.values.orientation}
                    setFieldValue={props.setFieldValue}
                  />
                  <Layout
                    style={[
                      styles.rowContainer,
                      isMobile ? styles.mobileRowFlex : styles.desktopRowFlex,
                    ]}>
                    <Text category="s2" style={isMobile ? styles.mobileLabel : styles.desktopLabel}>
                      Enable
                    </Text>

                    <Toggle
                      checked={props.values.enable}
                      onChange={(isEnabled) => props.setFieldValue('enable', isEnabled)}
                    />
                  </Layout>
                  <Text status="info" style={{ marginBottom: '12px' }}>
                    The QzTray Desktop App allows you to send labels and documentation directly to
                    your printer in just one click. Configure unique print settings for each
                    courier, including: label dimensions, orientation, and printer preference.{' '}
                    <br />
                    <br />
                    To install and configure QzTray locally, follow this link{' '}
                    <Text
                      status="info"
                      style={{ textDecorationLine: 'underline' }}
                      onPress={() => Linking.openURL('https://qz.io/')}>
                      https://qz.io/
                    </Text>
                  </Text>
                  <Divider />
                  <Layout
                    style={isMobile ? styles.mobileButtonContainer : styles.desktopButtonContainer}>
                    <Button
                      status="primary"
                      loading={props.isSubmitting}
                      onPress={props.handleSubmit}
                      disabled={props.isSubmitting || !props.isValid}
                      style={styles.button}>
                      Submit
                    </Button>
                    <Button
                      status="basic"
                      loading={props.isSubmitting}
                      onPress={() => setVisible(false)}
                      disabled={props.isSubmitting}
                      style={styles.button}>
                      Cancel
                    </Button>
                  </Layout>
                </>
              );
            }}
          </Formik>
        </ScrollView>
      </Card>
    </Modal>
  );
});

const styles = StyleSheet.create({
  field: {
    marginVertical: 8,
  },
  title: {
    marginBottom: 10,
  },
  button: {
    marginVertical: 10,
    marginHorizontal: 2,
  },
  desktopButtonContainer: {
    marginTop: 20,
    flexDirection: 'row-reverse',
  },
  mobileButtonContainer: {
    marginTop: 10,
    flexDirection: 'column',
  },
  formContainer: {
    maxHeight: 0.75 * windowHeight,
    overflowY: 'auto',
  },
  mobileRowFlex: {
    marginHorizontal: 'auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  desktopRowFlex: {
    flexDirection: 'row',
    width: 'auto',
  },
  mobileModalStyle: { backgroundColor: 'white', marginHorizontal: 20, padding: 10 },
  modalStyle: { backgroundColor: 'white', padding: 10, maxWidth: 700 },

  mobileLabel: {
    flex: 1,
    flexWrap: 'wrap',
    maxWidth: '100px',
  },
  desktopLabel: {
    minWidth: '130px',
  },
  rowContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: '12px',
  },
});
