import { makeAutoObservable } from 'mobx';
import { flatten, groupBy, map, sum, omit } from 'ramda';
import { unixMillisecondToDate } from '../core/utils/utils';

export class InventoryStatisticsStore {
  constructor(client, outboundOrderDataStore, inventoryDataStore) {
    this.client = client;
    this.outboundOrderDataStore = outboundOrderDataStore;
    this.inventoryDataStore = inventoryDataStore;
    makeAutoObservable(
      this,
      {
        outboundOrderDataStore: false,
        inventoryDataStore: false,
        client: false,
      },
      { autoBind: true },
    );
  }

  get loading() {
    return this.inventoryDataStore.loading || this.outboundOrderDataStore.loading;
  }

  get error() {
    return this.inventoryDataStore.error || this.outboundOrderDataStore.error;
  }

  get inventories() {
    return this.inventoryDataStore.items;
  }

  get stockByAvailability() {
    let pendingStock = 0;
    let onwayStock = 0;
    let availableStock = 0;
    this.inventories.forEach((item) => {
      pendingStock += item.pending_stock;
      onwayStock += item.onway_stock;
      availableStock += item.available_stock;
    });
    return {
      onwayStock,
      availableStock,
      pendingStock,
    };
  }

  get stockLevel() {
    const pendingStocks = this.inventories.map((item) => ({
      stock: item.pending_stock,
      type: 'dispatching',
    }));
    const onwayStocks = this.inventories.map((item) => ({
      stock: item.onway_stock,
      type: 'incoming',
    }));
    const availableStocks = this.inventories.map((item) => ({
      stock: item.available_stock,
      type: 'available',
    }));

    return {
      pendingStocks,
      onwayStocks,
      availableStocks,
    };
  }

  get stockAgeSummary() {
    const ageList = flatten(
      this.inventories
        .filter((inv) => !!inv.batches?.[0]?.stockAge)
        .map((sku) =>
          sku.batches.map((batch) =>
            batch.stockAge.map((age) => ({
              ...age,
              length: sku.length,
              width: sku.width,
              height: sku.height,
              weight: sku.weight,
            })),
          ),
        ),
    );
    const groupByAge = groupBy((s) => {
      const age = s?.inventory_age;
      if (!age) return null;
      return age <= 30
        ? '< 30'
        : age <= 60
        ? '30 - 60'
        : age <= 90
        ? '60 - 90'
        : age <= 120
        ? '90 - 120'
        : age <= 180
        ? '120 - 180'
        : age <= 360
        ? '180 - 360'
        : '> 360';
    });
    const groupedList = omit([null], groupByAge(ageList));
    const summedQtyList = map((list) => sum(list.map((s) => s.warehouse_stock)), groupedList);
    const summedVolList = map(
      (list) => sum(list.map((s) => (s.length * s.width * s.height * s.warehouse_stock) / 1000000)),
      groupedList,
    );
    return {
      stockAgeByQty: Object.entries(summedQtyList).map(([key, val]) => ({
        l: key,
        qty: val,
      })),
      stockAgeByVol: Object.entries(summedVolList).map(([key, val]) => ({
        l: key,
        vol: val,
      })),
    };
  }

  // Group outbound orders by day
  get outboundStream() {
    const activeOcs = this.outboundOrderDataStore.items.filter(
      (oc) => !['N', 'D', 'X'].includes(oc.status),
    );
    const groupByCreateTime = groupBy((s) => unixMillisecondToDate(s.create_time));
    const groupByCompleteTime = groupBy((s) => unixMillisecondToDate(s.complete_time));
    const listGroupedByCreateTime = groupByCreateTime(activeOcs);
    const listGroupedByCompleteTime = groupByCompleteTime(activeOcs);
    const days = [
      ...new Set(
        Object.keys(listGroupedByCompleteTime).concat(Object.keys(listGroupedByCreateTime)),
      ),
    ].sort((a, b) => {
      let dateA = new Date(a);
      let dateB = new Date(b);
      return dateA - dateB;
    });
    return [
      days.map((day) => ({
        day,
        qty: listGroupedByCreateTime[day]?.length || 0,
        l: 'created orders',
      })),
      days.map((day) => ({
        day,
        qty: listGroupedByCompleteTime[day]?.length || 0,
        l: 'completed orders',
      })),
    ];
  }
}
