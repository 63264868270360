import React, { memo, useMemo } from 'react';
import { Linking, StyleSheet } from 'react-native';
import Background from 'src/components/Background';
import { Formik } from 'formik';
import { Header } from './shared';
import { TextInputField } from 'src/components/input/TextInputField';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';
import { UIStatus, UIStatusWrapper } from '../../components/ui-status';
import Button from '../../components/Button';
import * as Yup from 'yup';
import { RadioGroupInputField } from '../../components/input/RadioGroupInputField';
import { Card } from 'react-native-paper';
import { observer } from 'mobx-react';
import { Divider, Layout, Text, Toggle, Button as KittenButton, Icon } from '@ui-kitten/components';
import { jsonToExcel, shippingRatesToExcel } from '../../core/utils/utils';
import { isEmpty, remove } from 'ramda';

const styles = StyleSheet.create({
  card: {
    marginBottom: '1em',
    border: '1px solid #F2F2F2',
  },

  btnGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
  },

  field: {
    marginVertical: 8,
  },
});

const GET_USER = gql`
  query GetUser {
    user {
      id
      organisation {
        id
        name
        abn
        acn
        email
        address
        phone
        hexId
        rates
        enableStockSubscription
        stockSubscriptionWebhookUrl
        stockSubscriptionWebhookHeaders {
          key
          value
        }
        shippingRates {
          originCountry
          originPostcodeFrom
          originPostcodeTo
          carrierName
          zone
          weightFromKg
          weightToKg
          baseCost
          ratePerKg
          minimumCharge
        }
        deliveryZones {
          carrierName
          postcodeFrom
          postcodeTo
          zone
          minBusinessDays
          maxBusinessDays
          country
        }
        lastMileSurcharges {
          name
          carrierName
          country
          unit
          condition
          min
          max
          fee
          additionalCharge
        }
        rasCharges {
          name
          carrierName
          country
          state
          postcode
          charge
        }
      }
    }
  }
`;

const IS_ORG_ADMIN = gql`
  query isOrgAdmin {
    isOrgAdmin
  }
`;

const ADD_USER = gql`
  mutation AddUserToOrg($email: String!, $isAdmin: Boolean!) {
    addUserToOrg(email: $email, isAdmin: $isAdmin) {
      id
    }
  }
`;

const UPDATE_ORG = gql`
  mutation UpdateOrg(
    $name: String!
    $email: String!
    $abn: String
    $acn: String
    $tfn: String
    $address: String
    $phone: String!
    $enableStockSubscription: Boolean!
    $stockSubscriptionWebhookUrl: String
    $stockSubscriptionWebhookHeaders: [WebhookHeaderInput!]
  ) {
    updateOrganisation(
      input: {
        name: $name
        email: $email
        abn: $abn
        acn: $acn
        tfn: $tfn
        address: $address
        phone: $phone
        enableStockSubscription: $enableStockSubscription
        stockSubscriptionWebhookUrl: $stockSubscriptionWebhookUrl
        stockSubscriptionWebhookHeaders: $stockSubscriptionWebhookHeaders
      }
    ) {
      id
    }
  }
`;

const ADMIN_ACCESS_TYPE = 'admin';
const ACCESS_TYPE_OPTIONS = [
  { key: ADMIN_ACCESS_TYPE, val: 'Admin' },
  { key: 'user', val: 'Standard user' },
];

const OrgProfileScreen = observer(({ navigation }) => {
  const [addUser, addUserResult] = useMutation(ADD_USER);
  const [updateOrg, updateOrgResult] = useMutation(UPDATE_ORG);
  const { data, loading, error, refetch: refetchUser } = useQuery(GET_USER);

  const {
    loading: checkingIsOrgAdmin,
    error: isOrgAdminError,
    data: isOrgAdminRes,
  } = useQuery(IS_ORG_ADMIN);

  const disabled = !isOrgAdminRes || !isOrgAdminRes.isOrgAdmin;
  const org = useMemo(() => data?.user?.organisation || {}, [data]);
  return (
    <Background fullWidth={true} style={{ overflowY: 'auto', height: '100%' }}>
      <UIStatusWrapper
        status={
          new UIStatus(
            loading || updateOrgResult.loading,
            error || updateOrgResult.error,
            data && data.user && !data.user.id,
          )
        }>
        <Formik
          key={loading}
          onSubmit={(values, resetForm) => {
            updateOrg({
              variables: {
                name: values.name,
                email: values.email,
                phone: values.phone,
                abn: values.abn,
                acn: values.acn,
                tfn: values.tfn,
                address: values.address,
                stockSubscriptionWebhookUrl: values.stockSubscriptionWebhookUrl,
                stockSubscriptionWebhookHeaders: values.stockSubscriptionWebhookHeaders.map(
                  (h) => ({ key: h.key, value: h.value }),
                ),
                enableStockSubscription: values.enableStockSubscription,
              },
            }).then(refetchUser);
          }}
          validationSchema={Yup.object({
            name: Yup.string().required(),
            email: Yup.string().email().required(),
            phone: Yup.number().required(),
            abn: Yup.number().nullable(),
            acn: Yup.number().nullable(),
            tfn: Yup.number().nullable(),
            enableStockSubscription: Yup.boolean().nullable(),
            stockSubscriptionWebhookUrl: Yup.string()
              .url()
              .when('enableStockSubscription', {
                is: true,
                then: Yup.string().required('Stock subscription URL is required'),
              }),
            stockSubscriptionWebhookHeaders: Yup.array().of(
              Yup.object({
                key: Yup.string().required('Header key is required'),
                value: Yup.string().required('Header value is required'),
              }),
            ),
          })}
          initialValues={data && data.user && data.user.organisation}
          enableReinitialize>
          {(formProps) => (
            <Card style={styles.card}>
              <Card.Title
                title={
                  <Header
                    heading={`${data?.user?.organisation.name || ''} (Account number: ${
                      data?.user.organisation?.hexId || ''
                    })`}
                  />
                }
              />
              <Card.Content>
                <TextInputField
                  name="name"
                  label="Name"
                  style={styles.field}
                  {...formProps}
                  disabled={disabled}
                />

                <TextInputField
                  name="email"
                  label="Email"
                  style={styles.field}
                  {...formProps}
                  disabled={disabled}
                />

                <TextInputField
                  name="phone"
                  label="Phone"
                  style={styles.field}
                  {...formProps}
                  disabled={disabled}
                />

                <TextInputField
                  name="address"
                  label="Address"
                  style={styles.field}
                  {...formProps}
                  disabled={disabled}
                />

                <TextInputField
                  name="abn"
                  label="ABN"
                  style={styles.field}
                  {...formProps}
                  disabled={disabled}
                />

                <TextInputField
                  name="acn"
                  label="ACN"
                  style={styles.field}
                  {...formProps}
                  disabled={disabled}
                />

                <TextInputField
                  name="tfn"
                  label="TFN"
                  style={styles.field}
                  {...formProps}
                  disabled={disabled}
                />

                <Toggle
                  disabled={disabled}
                  checked={formProps.values.enableStockSubscription}
                  onChange={(val) => formProps.setFieldValue('enableStockSubscription', val)}>
                  Stock update subscription
                </Toggle>

                {(formProps.values.enableStockSubscription && (
                  <>
                    <TextInputField
                      name="stockSubscriptionWebhookUrl"
                      label="Stock subscription URL"
                      style={styles.field}
                      {...formProps}
                      disabled={disabled}
                    />
                    <Layout
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      <Text category="s2">Stock request headers</Text>
                      <Layout
                        style={{
                          paddingHorizontal: 8,
                          paddingVertical: 7,
                          width: 'calc(100% - 250px)',
                        }}>
                        {formProps.values.stockSubscriptionWebhookHeaders?.map(
                          (header, stockRequestHeaderIdx) => (
                            <>
                              <Layout
                                style={{
                                  flexDirection: 'row',
                                  justifyContent: 'flex-start',
                                }}>
                                <Text category="p2" style={{ width: '50%', marginLeft: 7 }}>
                                  Header key
                                </Text>
                                <Text category="p2" style={{ width: '50%', marginLeft: 7 }}>
                                  Header value
                                </Text>
                              </Layout>
                              <Layout
                                key={`request-header-${stockRequestHeaderIdx}`}
                                style={{
                                  flexDirection: 'row',
                                  justifyContent: 'flex-start',
                                  alignItems: 'baseline',
                                }}>
                                <TextInputField
                                  disabled={disabled}
                                  name={`stockSubscriptionWebhookHeaders.${stockRequestHeaderIdx}.key`}
                                  size="tiny"
                                  style={{ width: '50%' }}
                                  {...formProps}
                                />
                                <TextInputField
                                  disabled={disabled}
                                  name={`stockSubscriptionWebhookHeaders.${stockRequestHeaderIdx}.value`}
                                  size="tiny"
                                  style={{ width: '50%' }}
                                  {...formProps}
                                />

                                <KittenButton
                                  disabled={disabled}
                                  size="medium"
                                  appearance="ghost"
                                  accessoryLeft={(props) => (
                                    <Icon {...props} name="trash-2-outline" />
                                  )}
                                  onPress={() =>
                                    formProps.setFieldValue(
                                      `stockSubscriptionWebhookHeaders`,
                                      remove(
                                        stockRequestHeaderIdx,
                                        1,
                                        formProps.values.stockSubscriptionWebhookHeaders,
                                      ),
                                    )
                                  }
                                />
                              </Layout>
                            </>
                          ),
                        )}
                        {(formProps.values.stockSubscriptionWebhookHeaders || []).length > 0 ? (
                          <Divider style={{ marginTop: 5 }} />
                        ) : null}
                        <KittenButton
                          disabled={disabled}
                          accessoryLeft={(props) => <Icon {...props} name="plus" />}
                          size="small"
                          appearance="ghost"
                          onPress={() => {
                            formProps.setFieldValue(`stockSubscriptionWebhookHeaders`, [
                              ...(formProps.values.stockSubscriptionWebhookHeaders || []),
                              { key: '', value: '' },
                            ]);
                          }}>
                          Add header
                        </KittenButton>
                      </Layout>
                    </Layout>
                  </>
                )) ||
                  null}
              </Card.Content>

              <Card.Actions style={styles.btnGroup}>
                <Button
                  disabled={
                    disabled ||
                    updateOrgResult.loading ||
                    formProps.isSubmitting ||
                    !formProps.isValid
                  }
                  mode="outlined"
                  onPress={() => formProps.handleSubmit()}>
                  Save
                </Button>
              </Card.Actions>
            </Card>
          )}
        </Formik>
      </UIStatusWrapper>
      {!disabled && (
        <>
          <UIStatusWrapper status={new UIStatus(checkingIsOrgAdmin, isOrgAdminError, false)}>
            <Card style={styles.card}>
              <Card.Title title="Rates" />
              <Card.Content>
                <Card style={styles.card}>
                  <Card.Title title="Warehouse rates" />
                  <Card.Content>
                    {org.rates ? (
                      <Button
                        icon="download"
                        mode="outlined"
                        onPress={() => jsonToExcel(org.rates, 'warehouse_rates.xlsx')}>
                        Download warehouse rates
                      </Button>
                    ) : (
                      <Text status="info">No warehouse rates found.</Text>
                    )}
                  </Card.Content>
                </Card>

                <Card style={styles.card}>
                  <Card.Title title="Shipping rates" />
                  <Card.Content>
                    {!isEmpty(org.shippingRates) ||
                    !isEmpty(org.deliveryZones) ||
                    !isEmpty(org.lastMileSurcharges) ||
                    !isEmpty(org.rasCharges) ? (
                      <Button
                        icon="download"
                        mode="outlined"
                        onPress={async () => {
                          const wbout = await shippingRatesToExcel(
                            org.shippingRates,
                            org.deliveryZones,
                            org.lastMileSurcharges,
                            org.rasCharges,
                          );
                          const blobFile = new Blob([new Uint8Array(wbout)], {
                            type: 'application/octet-stream',
                          });
                          saveAs(blobFile, `OrgShippingRates_${org.name}.xlsx`);
                        }}>
                        Download shipping rates
                      </Button>
                    ) : (
                      <Text status="info">No shipping rates found.</Text>
                    )}
                  </Card.Content>
                </Card>
              </Card.Content>
            </Card>
          </UIStatusWrapper>

          <UIStatusWrapper status={new UIStatus(checkingIsOrgAdmin, isOrgAdminError, false)}>
            <Formik
              initialValues={{
                email: '',
                accessType: 'user',
              }}
              validationSchema={Yup.object({
                email: Yup.string().email().required(),
                accessType: Yup.string()
                  .oneOf(ACCESS_TYPE_OPTIONS.map((o) => o.key))
                  .required(),
              })}
              onSubmit={(values) => {
                addUser({
                  variables: {
                    email: values.email,
                    isAdmin: values.accessType === ADMIN_ACCESS_TYPE,
                  },
                });
              }}>
              {(formProps) => (
                <>
                  <Card style={styles.card}>
                    <Card.Title title={<Header heading="Add user" />} />
                    <UIStatusWrapper
                      status={new UIStatus(addUserResult.loading, addUserResult.error, false)}>
                      <Card.Content>
                        <TextInputField
                          name="email"
                          label="Email"
                          placeholder="e.g user@company.com"
                          {...formProps}
                          disabled={disabled}
                        />

                        <RadioGroupInputField
                          label="Access type"
                          name="accessType"
                          style={styles.field}
                          options={ACCESS_TYPE_OPTIONS}
                          disabled={disabled}
                          {...formProps}
                        />
                      </Card.Content>

                      <Card.Actions style={styles.btnGroup}>
                        <Button
                          disabled={disabled}
                          mode="outlined"
                          onPress={() => formProps.handleSubmit()}>
                          Add
                        </Button>
                      </Card.Actions>
                    </UIStatusWrapper>
                  </Card>

                  <Button
                    disabled={disabled}
                    mode="outlined"
                    onPress={() => {
                      const url = `${process.env.KEYCLOAK_AUTH_URL}/admin/${process.env.KEYCLOAK_REALM}/console`;
                      Linking.canOpenURL(url).then((supported) => {
                        if (supported) {
                          Linking.openURL(url);
                        } else {
                          console.log("Don't know how to open URI: " + url);
                        }
                      });
                    }}>
                    Manage members access
                  </Button>
                </>
              )}
            </Formik>
          </UIStatusWrapper>
        </>
      )}
    </Background>
  );
});

export default memo(OrgProfileScreen);
