import React from 'react';
import { getIn } from 'formik';
import Select from './Select';

export class TableCellSelect extends React.Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(props) {
    if (
      props.name !== this.props.name ||
      getIn(props.values, this.props.name) !== getIn(this.props.values, this.props.name) ||
      getIn(props.errors, this.props.name) !== getIn(this.props.errors, this.props.name) ||
      getIn(props.touched, this.props.name) !== getIn(this.props.touched, this.props.name) ||
      Object.keys(this.props).length !== Object.keys(props).length ||
      props.isSubmitting !== this.props.isSubmitting
    ) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const { name, ...props } = this.props;
    return (
      <Select
        name={name}
        size="small"
        {...props}
        style={{ width: '100%', input: { margin: 0, paddingRight: 3 }, ...props.style }}
      />
    );
  }
}
