import * as React from 'react';
import { Card, List, Portal } from 'react-native-paper';
import { View, StyleSheet } from 'react-native';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { Button, Icon, MenuItem, OverflowMenu, Text } from '@ui-kitten/components';
import { Button as RNPButton } from 'react-native-paper';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';
import AwesomeAlert from 'react-native-awesome-alerts';
import { TooltipWrapper } from 'src/components/TooltipWrapper';
import { TextInputField } from './input/TextInputField';
import { Formik } from 'formik';
import * as Yup from 'yup';
import SHOPIFY_LOGO from '../assets/images/providers/shopify-logo.svg';
import EZOM_LOGO from '../assets/logo.png';
import { Avatar } from '@ui-kitten/components';
import { useUser } from '../core/utils/utils';

const styles = StyleSheet.create({
  card: {
    maxWidth: '380px',
  },
  icon: {
    width: 15,
    height: 15,
  },
  btnGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
  },
});

const UPDATE_COMMENT = gql`
  mutation updateComment($id: ID!, $storeId: ID!, $content: String!) {
    updateComment(id: $id, storeId: $storeId, content: $content) {
      id
    }
  }
`;

const DELETE_COMMENT = gql`
  mutation deleteComment($id: ID!, $storeId: ID!) {
    deleteComment(id: $id, storeId: $storeId)
  }
`;

export const Comments = ({ comments, onCommentsChanged }) => {
  const [visibleIdx, setVisibleIdx] = React.useState(null);

  const [commentToDelete, setCommentToDelete] = React.useState(null);
  const [commentToUpdate, setCommentToUpdate] = React.useState(null);
  const [showDeleteAlert, setShowDeleteAlert] = React.useState(false);

  const user = useUser();

  const [deleteComment] = useMutation(DELETE_COMMENT);
  const [updateComment] = useMutation(UPDATE_COMMENT);

  return (
    <View style={styles.card}>
      {comments.map((c, index) => {
        const isLogoDisplayed = !c.editable;
        return commentToUpdate === c.id ? (
          <Formik
            initialValues={{ comment: c.content }}
            validationSchema={Yup.object({
              comment: Yup.string().required(),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              await updateComment({
                variables: {
                  id: c.id,
                  storeId: c.storeId,
                  content: values.comment,
                },
              });
              await onCommentsChanged();
              setCommentToUpdate(null);
              setSubmitting(false);
            }}>
            {(formProps) => (
              <>
                <Card.Content>
                  <TextInputField
                    size="medium"
                    placeholder="Type something ..."
                    multiline={true}
                    name="comment"
                    error={formProps.errors.comment}
                    touched={formProps.touched.comment}
                    textStyle={{ minHeight: 64 }}
                    style={{ width: '100%' }}
                    {...formProps}
                  />
                </Card.Content>

                <Card.Actions style={styles.btnGroup}>
                  <Button
                    appearance="ghost"
                    status="primary"
                    onPress={formProps.submitForm}
                    primary
                    loading={formProps.isSubmitting}
                    disabled={formProps.isSubmitting || !formProps.dirty || !formProps.isValid}>
                    Update
                  </Button>
                  <Button
                    appearance="ghost"
                    status="warning"
                    onPress={() => {
                      formProps.resetForm();
                      setCommentToUpdate(null);
                    }}
                    basic
                    loading={formProps.isSubmitting}
                    disabled={formProps.isSubmitting}>
                    Cancel
                  </Button>
                </Card.Actions>
              </>
            )}
          </Formik>
        ) : (
          <List.Item
            key={c.id || c.createdTime}
            title={
              <TooltipWrapper>
                <Text category="p1">{c.content}</Text>
              </TooltipWrapper>
            }
            description={
              <Text category="c2">
                {formatDistanceToNow(parseISO(c.updatedTime || c.createdTime), {
                  addSuffix: true,
                })}
              </Text>
            }
            left={() => (
              <Avatar
                resizeMode="contain"
                size="small"
                source={isLogoDisplayed ? SHOPIFY_LOGO : EZOM_LOGO}
                styles={styles.logo}></Avatar>
            )}
            right={() =>
              user?.id === c.authorId ? (
                <OverflowMenu
                  anchor={() => (
                    <Button
                      size="tiny"
                      style={styles.btn}
                      appearance="ghost"
                      status="danger"
                      onPress={(e) => {
                        e.stopPropagation();
                        setVisibleIdx(index);
                      }}
                      accessoryLeft={() => (
                        <Icon name="more-vertical-outline" style={styles.icon} />
                      )}
                    />
                  )}
                  visible={visibleIdx === index}
                  onSelect={(idx) => {
                    setVisibleIdx(null);
                  }}
                  onBackdropPress={() => setVisibleIdx(null)}>
                  <MenuItem
                    title="Edit"
                    disabled={!c.editable}
                    onPress={() => {
                      setVisibleIdx(null);
                      setCommentToUpdate(c.id);
                    }}
                  />
                  <MenuItem
                    title="Delete"
                    disabled={!c.editable}
                    onPress={() => {
                      setVisibleIdx(null);
                      setShowDeleteAlert(true);
                      setCommentToDelete(c);
                    }}
                  />
                </OverflowMenu>
              ) : null
            }
          />
        );
      })}
      <AwesomeAlert
        show={showDeleteAlert}
        showProgress={false}
        title="Are you sure to delete?"
        message="Deleted comments cannot be recovered"
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText="No, cancel"
        confirmText="Yes, delete it"
        confirmButtonColor="#DD6B55"
        onCancelPressed={() => {
          setCommentToDelete(null);
          setShowDeleteAlert(false);
        }}
        onConfirmPressed={async () => {
          if (commentToDelete) {
            await deleteComment({
              variables: { id: commentToDelete.id, storeId: commentToDelete.storeId },
            });

            setCommentToDelete(null);
            onCommentsChanged && (await onCommentsChanged());
          }
          setShowDeleteAlert(false);
        }}
      />
    </View>
  );
};
