import React from 'react';
import { StyleSheet } from 'react-native';
import { Layout, Button, Text, Modal, Card } from '@ui-kitten/components';
import { useIsMobile } from '../core/responsive.utils';
import InlineTable from './InlineTable';
import { volumeCmToMetre, weightGramToKg } from '@ezom/library/lib/cjs/utils';
import { ezTheme } from '../core/theme';

export default ({ visible, draftPickingTask, onSubmit, onCancel }) => {
  const isMobile = useIsMobile();

  return (
    <Modal
      visible={visible}
      backdropStyle={{
        backgroundColor: ezTheme.backdropModalColor,
      }}
      onBackdropPress={onCancel}>
      <Card
        style={isMobile ? styles.mobileModalStyle : styles.modalStyle}
        header={() => (
          <Text category="h6" style={styles.title}>
            Create picking order
          </Text>
        )}
        footer={(props) => (
          <Layout
            {...props}
            style={[
              props.style,
              styles.footerContainer,
              isMobile ? styles.mobileButtonContainer : styles.desktopButtonContainer,
            ]}>
            <Button
              style={styles.footerControl}
              status="basic"
              onPress={() => {
                onCancel();
              }}>
              Cancel
            </Button>
            <Button
              style={styles.footerControl}
              onPress={() => {
                onSubmit();
              }}>
              Submit
            </Button>
          </Layout>
        )}
        disabled>
        <InlineTable
          items={[draftPickingTask]}
          columnByKey={{
            weight: {
              title: 'Weight (kg)',
              formatter: (key, item) => weightGramToKg(false)(item[key]),
            },
            volume: {
              title: 'Volume (㎥)',
              formatter: (key, item) => volumeCmToMetre(false)(item[key]),
            },
            skuTypes: { title: 'SKU Types' },
            qtyToPick: { title: 'QTY' },
            type: { title: 'Type' },
            pickLocations: { title: 'Locations' },
            dispatchChannel: { title: 'Channel' },
          }}
        />
      </Card>
    </Modal>
  );
};

const styles = StyleSheet.create({
  title: {
    marginBottom: 10,
  },
  desktopButtonContainer: {
    marginTop: 20,
    flexDirection: 'row',
  },
  mobileButtonContainer: {
    marginTop: 10,
    flexDirection: 'column',
  },
  mobileModalStyle: { backgroundColor: 'white', marginHorizontal: 20, padding: 10 },
  modalStyle: { backgroundColor: 'white', minWidth: 800, padding: 20 },
  footerControl: {
    marginHorizontal: 2,
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footerContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});
