import React, { useEffect } from 'react';
import { Alert, StyleSheet } from 'react-native';
import { Layout, Button, Text, Divider, Icon } from '@ui-kitten/components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Select from './Select';
import { useIsMobile } from '../core/responsive.utils';
import { UIStatusWrapper } from './ui-status';
import { orderCancelStore } from '../store';
import { observer } from 'mobx-react';
import { ORDER_CANCEL_REASONS } from '../core/utils/utils';

const DEFAULT_VALUES = {
  cancelReason: '',
};

export default observer(({ onDismiss, order, navigation }) => {
  const isMobile = useIsMobile();

  useEffect(() => {
    orderCancelStore.setOrder(order);
  }, [order]);
  return (
    <Layout style={styles.form}>
      <Text category="h6" style={styles.title}>
        Cancel Order
      </Text>
      <Divider />
      <UIStatusWrapper
        status={{
          indeterminate: orderCancelStore.loading,
          error: orderCancelStore.error,
          empty: !orderCancelStore.cancelReasons,
        }}>
        {!orderCancelStore.isEligible && (
          <Text category="p1">
            This order can not be cancelled.{' '}
            <strong>{orderCancelStore.failureReasons?.join(', ')}</strong>
          </Text>
        )}

        {orderCancelStore.isEligible && (
          <Text category="p1">This order is eligible to be cancelled.</Text>
        )}

        <Formik
          initialValues={{ ...DEFAULT_VALUES }}
          validationSchema={Yup.object({
            cancelReason: Yup.string().required(),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            const { data } = await orderCancelStore.cancel(values.cancelReason);
            const outcome = data?.cancelOrder?.outcome;
            setSubmitting(false);
            if (outcome) {
              onDismiss();
              alert(`Successfully cancelled order ${orderCancelStore.order.id}`);
              navigation.navigate('OrderListScreen');
            } else {
              const userErrors = data?.cancelOrder?.userErrors;
              alert(
                `Failed to cancel order ${orderCancelStore.order.id}\n${userErrors
                  .map((e) => `${e.field}: ${e.message}`)
                  .join('\n')}`,
              );
            }
          }}>
          {(props) => {
            return (
              <>
                <Select
                  label="Reason to cancel"
                  value={
                    props.values.cancelReason && ORDER_CANCEL_REASONS[props.values.cancelReason]
                  }
                  error={props.errors.cancelReason}
                  touched={props.touched.cancelReason}
                  style={styles.field}
                  options={orderCancelStore.cancelReasons}
                  onChange={({ key }) => {
                    props.setFieldValue('cancelReason', key);
                  }}
                  onBlur={() => props.setFieldTouched('cancelReason', true)}
                  disabled={!orderCancelStore.isEligible}
                />

                <Divider />
                <Layout
                  style={isMobile ? styles.mobileButtonContainer : styles.desktopButtonContainer}>
                  <Button
                    status="danger"
                    loading={props.isSubmitting}
                    disabled={props.isSubmitting || !props.isValid || !orderCancelStore.isEligible}
                    onPress={props.handleSubmit}
                    style={styles.button}>
                    Cancel Order
                  </Button>
                  <Button
                    status="basic"
                    loading={props.isSubmitting}
                    onPress={onDismiss}
                    disabled={props.isSubmitting}
                    style={styles.button}>
                    Cancel
                  </Button>
                </Layout>
              </>
            );
          }}
        </Formik>
      </UIStatusWrapper>
    </Layout>
  );
});

const styles = StyleSheet.create({
  field: {
    marginVertical: 8,
  },
  title: {
    marginVertical: 10,
  },
  form: {
    alignSelf: 'center',
    padding: '1em',
  },
  button: {
    marginVertical: 10,
    marginHorizontal: 2,
  },
  desktopButtonContainer: {
    flexDirection: 'row-reverse',
  },
  mobileButtonContainer: {
    flexDirection: 'column',
  },
});
