import React, { memo, useState, useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { Chip, Card } from 'react-native-paper';
import { useIsMobile, useLargeScreen } from 'src/core/responsive.utils';
import {
  Layout,
  Button,
  Icon,
  Popover,
  Text,
  RadioGroup,
  Radio,
  CheckBox,
} from '@ui-kitten/components';
import SearchBar from './SearchBar';
import { ScrollView } from 'react-native-gesture-handler';
import { OC_TYPE_MAP, OC_STATUS_MAP, SHIPMENT_ORDER_STATES } from '@ezom/library/lib/cjs/constants';

const OC_TYPE_LIST = Object.values(OC_TYPE_MAP);

const CourierShipmentTableControl = ({
  filterParams = {},
  indicators = [],
  onSearchTermChange = () => {},
  onFilterChange = () => {},
  onFilterClear = () => {},
  disableSearch = false,
  requireButtonClickToSearch = false,
}) => {
  const isMobileDevice = useIsMobile();
  const [searchTerm, setSearchTerm] = useState('');
  const [statusDropdownVisible, setStatusDropdownVisible] = useState(false);
  const [typeDropdownVisible, setTypeDropdownVisible] = useState(false);

  return (
    <Card visible={true} style={styles.bannerContainer} actions={[]}>
      <Layout style={isMobileDevice ? styles.mobileBanner : styles.desktopBanner}>
        <SearchBar
          debounceDelayMs={500}
          disableSearch={disableSearch}
          onSearchTermChange={onSearchTermChange}
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          requireButtonClickToSearch={requireButtonClickToSearch}
          inputPlaceholder="Search shipments"
          inputSize="medium"></SearchBar>
        <Layout style={isMobileDevice ? styles.mobileButtonGroup : styles.desktopButtonGroup}>
          <Popover
            visible={statusDropdownVisible}
            anchor={() => (
              <Button
                status="primary"
                appearance="ghost"
                style={[styles.button]}
                onPress={() => setStatusDropdownVisible(true)}
                accessoryRight={ArrowDownIcon}>
                Status
              </Button>
            )}
            onBackdropPress={() => {
              setStatusDropdownVisible(false);
            }}
            style={styles.popover}>
            <Layout style={styles.popover}>
              <Text>Select status</Text>
              <ScrollView style={{ maxHeight: 250 }}>
                {Object.entries(SHIPMENT_ORDER_STATES).map(([key, val]) => (
                  <CheckBox
                    key={`${key}-${val}`}
                    checked={filterParams.status === val}
                    onChange={(checked) => {
                      if (checked) {
                        onFilterChange({
                          status: val,
                        });
                      } else {
                        onFilterChange({
                          status: undefined,
                        });
                      }
                    }}
                    style={styles.checkItem}>
                    {key}
                  </CheckBox>
                ))}
              </ScrollView>
            </Layout>
          </Popover>

          <Button
            status="danger"
            appearance="ghost"
            onPress={() => {
              setSearchTerm('');
              onFilterClear();
            }}
            style={styles.button}>
            Reset filter
          </Button>
        </Layout>
      </Layout>
      <Layout style={styles.indicator}>
        {indicators.map((indicator) => (
          <Chip style={styles.chip} key={indicator}>
            {indicator}
          </Chip>
        ))}
      </Layout>
    </Card>
  );
};

const styles = StyleSheet.create({
  bannerContainer: {
    marginBottom: '0.5em',
    padding: '1em',
  },
  desktopBanner: {
    maxWidth: '1800px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  mobileBanner: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  indicator: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  chip: {
    marginTop: '0.4em',
    marginHorizontal: '0.2em',
    opacity: '0.5',
  },
  popover: {
    margin: '0.5em',
    padding: '0.5em',
  },
  rangeInput: {
    width: '100px',
    margin: '0.5em',
  },
  desktopButtonGroup: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mobileButtonGroup: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    height: '45px',
  },
  icon: {
    width: '28px',
    height: '28px',
    stroke: 'white',
    strokeWidth: '0.8',
  },
  popover: {
    margin: '0.5em',
    padding: '0.5em',
  },
  checkItem: {
    marginVertical: 4,
    marginHorizontal: 12,
  },
});

const ArrowDownIcon = (props) => (
  <Icon
    {...props}
    name="arrow-ios-downward-outline"
    style={[props.style, styles.icon, { width: '20px', height: '20px' }]}
  />
);

const ArrowUpRightIcon = (props) => (
  <Icon
    {...props}
    name="diagonal-arrow-right-up-outline"
    style={[props.style, styles.icon, { width: '20px', height: '20px' }]}
  />
);

export default memo(CourierShipmentTableControl);
