import { Formik } from 'formik';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { countryStateCityCurrencyStore } from '../../store';
import { Button, ButtonGroup, Divider, Icon, Layout, Text } from '@ui-kitten/components';
import { StyleSheet } from 'react-native';
import Autocomplete from '../Autocomplete';

export const LocationForm = observer(({ onSubmit = () => {}, onCancel = () => {}, value }) => {
  const COUNTRIES = countryStateCityCurrencyStore.countryCodes;
  return (
    <Formik
      initialValues={value}
      onSubmit={(value) => {
        onSubmit(value);
        formikActions.setSubmitting(false);
      }}>
      {(props) => {
        const [states, setStates] = useState([]);
        useEffect(() => {
          (async () => {
            if (props.values.country) {
              const states = await countryStateCityCurrencyStore.getStateCodesOfCountry(
                props.values.country,
              );
              setStates(states);
            }
          })();
        });

        const [cities, setCities] = useState([]);
        useEffect(() => {
          (async () => {
            if (props.values.country && props.values.state) {
              const cities = await countryStateCityCurrencyStore.getCitiesOfState(
                props.values.country,
                props.values.state,
              );
              setCities(cities);
            }
          })();
        });

        return (
          <Layout style={styles.formContainer}>
            <Text category="h5" style={styles.title}>
              {'Location'}
            </Text>
            <Divider />
            <Autocomplete
              label="Country"
              name="country"
              defaultValue={props.values.country}
              error={props.errors.country}
              touched={props.touched.country}
              style={styles.field}
              placeholder="Select a country"
              options={COUNTRIES}
              onSelect={props.handleChange('country')}
              onBlur={props.handleBlur('country')}
            />
            <Autocomplete
              label="State"
              name="state"
              defaultValue={props.values.state}
              error={props.errors.state}
              touched={props.touched.state}
              style={styles.field}
              placeholder="Select a state"
              disabled={!props.values.country}
              options={states}
              onSelect={props.handleChange('state')}
              onBlur={props.handleBlur('state')}
            />
            <Autocomplete
              label="City"
              name="city"
              defaultValue={props.values.city}
              error={props.errors.city}
              touched={props.touched.city}
              placeholder="Select a city"
              style={styles.field}
              disabled={!props.values.state}
              options={cities}
              onSelect={props.handleChange('city')}
              onBlur={props.handleBlur('city')}
            />
            <Divider />
            <ButtonGroup appearance="ghost" style={styles.btnContainer}>
              <Button
                appearance="outline"
                status="primary"
                accessoryLeft={(props) => <Icon {...props} name="funnel-outline" />}
                loading={props.isSubmitting}
                disabled={props.isSubmitting || !props.isValid}
                onPress={props.handleSubmit}>
                Filter
              </Button>
              <Button
                appearance="outline"
                status="danger"
                accessoryLeft={(innerProps) => <Icon {...innerProps} name="close-circle-outline" />}
                loading={props.isSubmitting}
                disabled={props.isSubmitting}
                onPress={onCancel}>
                Cancel
              </Button>
            </ButtonGroup>
          </Layout>
        );
      }}
    </Formik>
  );
});

const styles = StyleSheet.create({
  field: {
    marginVertical: 8,
  },
  btnContainer: {
    marginHorizontal: '5px',
    justifyContent: 'space-evenly',
    marginVertical: 8,
  },
  formContainer: {
    alignSelf: 'center',
    overflowY: 'auto',
  },
  title: {
    marginVertical: 10,
  },
});
