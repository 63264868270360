import { makeObservable } from 'mobx';
import { mergeAll } from 'ramda';
import { OrderDispatchStore, SKU_MAPPINGS } from './OrderDispatchStore';

export class ImportedOrderDispatchStore extends OrderDispatchStore {
  get skuQuantity() {
    const skuMappingMap = mergeAll(Object.values(this.skuMappingMap));
    const skuQtyByOrder = this.orders.map((o) => {
      const skuQuantity = {};
      (o.lineItems || []).forEach((item) => {
        const key = item.sku || item.variantId || item.platformId;
        if (skuMappingMap[key]) {
          skuMappingMap[key].forEach((mapping) => {
            if (skuQuantity[mapping.warehouseSku]) {
              skuQuantity[mapping.warehouseSku].qty += item.quantity * mapping.qty;
            } else {
              skuQuantity[mapping.warehouseSku] = {
                qty: item.quantity * mapping.qty,
                warehouseSku: mapping.warehouseSku,
              };
            }
          });
        }
      });
      return Object.values(skuQuantity);
    });
    return skuQtyByOrder;
  }

  get orders() {
    return this.cachedOrders;
  }

  get unmappedStoreSkus() {
    return this.orders.map((o) => {
      const unmappedStoreSkus = new Set();
      o.lineItems.forEach((item) => {
        if (!this.skuMappings.find((s) => s.storeSku === item.sku)) {
          unmappedStoreSkus.add(item.variant ? `${item.name} / ${item.variant}` : item.name);
        }
      });
      return [...unmappedStoreSkus];
    });
  }

  *fetchSkuMappings() {
    this.loadingSkuMappings = true;
    this.cachedSkuMappings = null;

    const storeIds = yield this.storeStore.getEnabledStoreIds();

    this.client
      .watchQuery({
        query: SKU_MAPPINGS,
        variables: {
          storeIds: storeIds,
          storeSkus: this.storeSkus,
          storeProductIds: this.storeProductIds,
          warehouseAccountIds: this.warehouseAccountStore.warehouseAccountIds,
        },
        fetchPolicy: 'cache-and-network',
      })
      .subscribe({
        next: ({ data }) => {
          this.cachedSkuMappings = data.skuMappings;
          this.loadingSkuMappings = false;
          this.fetchInventories();
        },
        error: (e) => {
          this.error = e;
          console.error(e);
          this.loadingSkuMappings = false;
        },
      });
  }

  constructor(
    client,
    outboundOrderStore,
    storeStore,
    orders,
    warehouseAccountStore,
    countryStateCityCurrencyStore,
  ) {
    super(
      client,
      outboundOrderStore,
      storeStore,
      [],
      [],
      warehouseAccountStore,
      countryStateCityCurrencyStore,
    );
    this.cachedOrders = orders;
    this.outboundConsignmentSettings = orders.map(() => ({}));
    this.orderIds = orders.map((o) => o.id);

    // MobX doesn't allow markAutoObservable in subclasses
    makeObservable(this, {});
  }
}
