import React, { useMemo } from 'react';
import { StyleSheet, Dimensions, ScrollView } from 'react-native';
import { Layout, Button, Text, Divider, Modal, Card } from '@ui-kitten/components';
import * as Yup from 'yup';
import { useIsMobile } from '../core/responsive.utils';
import { Formik } from 'formik';
import InlineTable from './InlineTable';
import { sum, groupBy, update, remove, map, reject } from 'ramda';
import { unifiedAlert } from '../core/utils/utils';
import { warehousesStore } from '../store';
import { STOCK_QUALITY_CODE } from '@ezom/library/lib/cjs/constants';
import { TableCellInput } from './TableCellInput';
import { ezTheme } from '../core/theme';

const windowHeight = Dimensions.get('window').height;

export default ({ visible, setVisible, consignment, onSubmit }) => {
  const isMobile = useIsMobile();

  const warehouse = useMemo(
    () =>
      consignment?.to_warehouse_code
        ? warehousesStore.getWarehouse(consignment.to_warehouse_code)
        : null,
    [consignment?.to_warehouse_code, warehousesStore.warehouses],
  );

  const putawayRowSchema = useMemo(
    () =>
      Yup.object({
        sku_code: Yup.string().required(),
        received_qty: Yup.number().integer().required().min(0),
        remaining_qty: Yup.number().integer().required().min(0),
        putaway_qty: Yup.number('invalid')
          .integer('invalid')
          .required('required')
          .min(1, 'invalid')
          .when('received_qty', (receivedQty, schema) => {
            return schema.max(receivedQty);
          }),
        aisle: Yup.number('invalid')
          .integer('invalid')
          .required('required')
          .min(1, 'invalid')
          .max(warehouse?.aisles || Number.MAX_SAFE_INTEGER),
        unit: Yup.number('invalid')
          .integer('invalid')
          .required('required')
          .min(1, 'invalid')
          .max(warehouse?.units_per_aisle || Number.MAX_SAFE_INTEGER),
        shelf: Yup.number('invalid')
          .integer('invalid')
          .required('required')
          .min(1, 'invalid')
          .max(warehouse?.shelves_per_unit || Number.MAX_SAFE_INTEGER),
        section: Yup.number('invalid')
          .integer('invalid')
          .required('required')
          .min(1, 'invalid')
          .max(warehouse?.sections_per_shelf || Number.MAX_SAFE_INTEGER),
      }),
    [warehouse],
  );

  const qtyGroupedBySku = useMemo(
    () =>
      reject(
        (s) => s.received_qty === 0,
        Object.values(groupBy((s) => s.sku_code, consignment?.lstsku)).map((items) => ({
          sku_code: items[0].sku_code,
          received_qty: sum(items.map((item) => item.received_qty)),
        })) || [],
      ),
    [consignment?.lstsku],
  );

  return (
    <Modal
      visible={visible}
      backdropStyle={{
        backgroundColor: ezTheme.backdropModalColor,
      }}
      onBackdropPress={() => setVisible(false)}>
      <Card
        style={isMobile ? styles.mobileModalStyle : styles.modalStyle}
        header={() => (
          <Text category="h6" style={styles.title}>
            {`Putaway: ${consignment.ref_no}`}
          </Text>
        )}
        disabled>
        <ScrollView
          keyboardShouldPersistTaps={'handled'}
          contentContainerStyle={styles.formContainer}>
          <Formik
            isInitialValid={true}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={
              qtyGroupedBySku.map((item) => ({
                sku_code: item.sku_code,
                received_qty: item.received_qty,
                remaining_qty: item.received_qty,
                putaway_qty: null,
                aisle: null,
                unit: null,
                shelf: null,
                section: null,
                editable: true,
              })) || []
            }
            validationSchema={Yup.array().min(1).of(putawayRowSchema)}
            onSubmit={async (values) => {
              const putawayQtySum = map(
                (group) => sum(group.map((g) => parseInt(g.putaway_qty))),
                groupBy((s) => s.sku_code, values),
              );
              const misMatchRecord = qtyGroupedBySku.find(
                (s) => s.received_qty !== putawayQtySum[s.sku_code],
              );
              if (misMatchRecord) {
                unifiedAlert(
                  'Putaway quantity does not match received: ' + misMatchRecord.sku_code,
                );
                return;
              }
              await onSubmit({
                lstsku: values.map((s) => ({
                  sku_code: s.sku_code,
                  putaway_qty: parseInt(s.putaway_qty),
                  stock_quality: STOCK_QUALITY_CODE.Good,
                  location_aisle: parseInt(s.aisle),
                  location_unit: parseInt(s.unit),
                  location_shelf: parseInt(s.shelf),
                  location_section: parseInt(s.section),
                })),
              });
              setVisible(false);
            }}>
            {(props) => {
              return (
                <>
                  <InlineTable
                    onRemoveItem={(index) => {
                      const item = props.values[index];
                      const putawayQty = sum(
                        props.values
                          .filter(({ sku_code: sc }, i) => sc === item.sku_code && index !== i)
                          .map((v) => parseInt(v.putaway_qty)),
                      );
                      const remainingQty = item.received_qty - putawayQty;
                      const hasOtherPutawayEntries = props.values.find(
                        (s, i) => s.sku_code === item.sku_code && i !== index,
                      );
                      if (hasOtherPutawayEntries) {
                        props.setValues(
                          remove(index, 1, props.values).map((s) =>
                            s.sku_code === item.sku_code
                              ? { ...s, editable: true, remaining_qty: remainingQty }
                              : s,
                          ),
                        );
                      } else {
                        props.setValues(
                          update(
                            index,
                            {
                              ...item,
                              putaway_qty: null,
                              remaining_qty: remainingQty,
                              editable: true,
                            },
                            props.values,
                          ),
                        );
                      }
                    }}
                    onSaveItem={(index) => {
                      const item = props.values[index];
                      try {
                        putawayRowSchema.validateSync(item);
                      } catch (e) {
                        unifiedAlert(e);
                        return;
                      }

                      const putawayQty = sum(
                        props.values
                          .filter(({ sku_code: sc }) => sc === item.sku_code)
                          .map((v) => parseInt(v.putaway_qty)),
                      );
                      const remainingQty = item.received_qty - putawayQty;
                      if (remainingQty > 0) {
                        props.setValues([
                          ...props.values.slice(0, index),
                          { ...item, remaining_qty: remainingQty, editable: false },
                          { ...item, putaway_qty: null, remaining_qty: remainingQty },
                          ...props.values.slice(index + 1),
                        ]);
                      } else {
                        props.setValues(
                          props.values.map((s) =>
                            s.sku_code === item.sku_code
                              ? { ...s, remaining_qty: 0, editable: false }
                              : s,
                          ),
                        );
                      }
                    }}
                    items={props.values}
                    columnByKey={{
                      sku_code: { title: 'SKU', size: 'large' },
                      received_qty: { title: 'Received QTY', numeric: true },
                      remaining_qty: { title: 'Remaining QTY', size: 'large', numeric: true },
                      putaway_qty: {
                        title: 'Putaway QTY',
                        size: 'medium',
                        numeric: true,
                        formatter: (key, item, index) =>
                          item.editable ? (
                            <TableCellInput name={`${index}.${key}`} {...props} />
                          ) : (
                            item[key]
                          ),
                      },
                      aisle: {
                        title: 'Asile No.',
                        size: 'medium',
                        numeric: true,
                        formatter: (key, item, index) =>
                          item.editable ? (
                            <TableCellInput
                              name={`${index}.${key}`}
                              {...props}
                              style={{ maxWidth: 70 }}
                            />
                          ) : (
                            item[key]
                          ),
                      },
                      unit: {
                        title: 'Unit No.',
                        size: 'medium',
                        numeric: true,
                        formatter: (key, item, index) =>
                          item.editable ? (
                            <TableCellInput
                              name={`${index}.${key}`}
                              {...props}
                              style={{ maxWidth: 70 }}
                            />
                          ) : (
                            item[key]
                          ),
                      },
                      shelf: {
                        title: 'Shelf No.',
                        size: 'medium',
                        numeric: true,
                        formatter: (key, item, index) =>
                          item.editable ? (
                            <TableCellInput
                              name={`${index}.${key}`}
                              {...props}
                              style={{ maxWidth: 70 }}
                            />
                          ) : (
                            item[key]
                          ),
                      },
                      section: {
                        title: 'Section No.',
                        size: 'medium',
                        numeric: true,
                        formatter: (key, item, index) =>
                          item.editable ? (
                            <TableCellInput
                              name={`${index}.${key}`}
                              {...props}
                              style={{ maxWidth: 70 }}
                            />
                          ) : (
                            item[key]
                          ),
                      },
                    }}
                  />
                  <Divider />
                  <Layout
                    style={isMobile ? styles.mobileButtonContainer : styles.desktopButtonContainer}>
                    <Button
                      status="primary"
                      loading={props.isSubmitting}
                      onPress={props.handleSubmit}
                      disabled={props.isSubmitting}
                      style={styles.button}>
                      Submit
                    </Button>
                    <Button
                      status="basic"
                      loading={props.isSubmitting}
                      onPress={() => setVisible(false)}
                      disabled={props.isSubmitting}
                      style={styles.button}>
                      Cancel
                    </Button>
                  </Layout>
                </>
              );
            }}
          </Formik>
        </ScrollView>
      </Card>
    </Modal>
  );
};

const styles = StyleSheet.create({
  field: {
    marginVertical: 8,
  },
  title: {
    marginBottom: 10,
  },
  button: {
    marginVertical: 10,
    marginHorizontal: 2,
  },
  desktopButtonContainer: {
    marginTop: 20,
    flexDirection: 'row-reverse ',
  },
  mobileButtonContainer: {
    marginTop: 10,
    flexDirection: 'column',
  },
  formContainer: {
    maxHeight: 0.75 * windowHeight,
    overflowY: 'auto',
    alignItems: 'center',
  },
  mobileLabel: {
    flex: 1,
    maxWidth: '100px',
    minWidth: '100px',
  },
  desktopLabel: {
    minWidth: '130px',
  },
  rowContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mobileRowFlex: {
    marginHorizontal: 'auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  desktopRowFlex: {
    flexDirection: 'row',
    width: 'auto',
  },
  mobileModalStyle: { backgroundColor: 'white', marginHorizontal: 20, padding: 10 },
  modalStyle: { backgroundColor: 'white', minWidth: 900, padding: 10 },
});
