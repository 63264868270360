import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import { Layout, Button, Text, Divider, Icon } from '@ui-kitten/components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Autocomplete from './Autocomplete';
import { useIsMobile } from '../core/responsive.utils';
import { TextInputField } from './input/TextInputField';
import DateRangeInput from './DateRangeInput';
import { ORDER_FULFILLMENT_STATUSES } from '../core/utils/utils';
import { ORDER_FINANCIAL_STATUSES } from '@ezom/library/lib/cjs/constants';
import { observer } from 'mobx-react';
import { storeStore } from '../store';
import { STORE_PROVIDERS } from '@ezom/library/lib/cjs/constants';

const DEFAULT_VALUES = {
  orderNumber: '',
  fulfillmentStatuses: '',
  financialStatuses: '',
  customerEmail: '',
  customerName: '',
  productTitle: '',
  providers: '',
  minCreated: undefined,
  maxCreated: undefined,
};

export default observer(({ stores, onSubmit, searchParams, onDismiss }) => {
  const isMobile = useIsMobile();
  const [showAdvanced, setShowAdvanced] = useState(false);
  return (
    <Layout style={styles.form}>
      <Text category="h5" style={styles.title}>
        Search orders
      </Text>
      <Divider />
      <Formik
        initialValues={{
          ...DEFAULT_VALUES,
          ...searchParams,
          storeNames: stores.map((s) => s.name).join('\n'),
        }}
        validationSchema={Yup.object({
          orderNumber: Yup.string().nullable(),
          fulfillmentStatuses: Yup.string(),
          customerName: Yup.string().nullable(),
          customerEmail: Yup.string().nullable(),
          productTitle: Yup.string().nullable(),
          financialStatuses: Yup.string(),
          providers: Yup.string(),
          storeNames: Yup.string(),
          minCreated: Yup.date().nullable(),
          maxCreated: Yup.date().nullable(),
          countryCodes: Yup.string(),
        })}
        onSubmit={(values, formikActions) => {
          onSubmit(values);
          // Important: Make sure to setSubmitting to false so our loading indicator
          // goes away.
          formikActions.setSubmitting(false);
        }}>
        {(props) => {
          return (
            <>
              <TextInputField
                label="Order number"
                name="orderNumber"
                style={styles.field}
                {...props}
              />
              <Autocomplete
                label="Fulfillment status(es)"
                multiline={true}
                defaultValue={props.values.fulfillmentStatuses}
                touched={props.touched.fulfillmentStatuses}
                error={props.errors.fulfillmentStatuses}
                style={styles.field}
                onSelect={props.handleChange('fulfillmentStatuses')}
                onBlur={props.handleBlur('fulfillmentStatuses')}
                options={Object.values(ORDER_FULFILLMENT_STATUSES)}
              />
              <Autocomplete
                label="Country"
                multiline={true}
                defaultValue={props.values.countryCodes}
                touched={props.touched.countryCodes}
                error={props.errors.countryCodes}
                style={styles.field}
                onSelect={props.handleChange('countryCodes')}
                onBlur={props.handleBlur('countryCodes')}
                options={storeStore.orderCountryCodes}
              />
              <Divider />
              <TextInputField
                label="Customer Name"
                name="customerName"
                style={styles.field}
                {...props}
              />
              <TextInputField
                label="Customer Email"
                name="customerEmail"
                style={styles.field}
                {...props}
              />
              <Divider />
              <Button
                appearance="ghost"
                status="primary"
                onPress={() => setShowAdvanced(!showAdvanced)}
                accessoryLeft={(props) => (
                  <Icon
                    {...props}
                    name={showAdvanced ? 'chevron-up-outline' : 'chevron-down-outline'}
                  />
                )}>
                Advanced Search
              </Button>

              {showAdvanced && (
                <>
                  <Autocomplete
                    label="Payment status(es)"
                    defaultValue={props.values.financialStatuses}
                    multiline={true}
                    touched={props.touched.financialStatuses}
                    error={props.errors.financialStatuses}
                    style={styles.field}
                    onSelect={props.handleChange('financialStatuses')}
                    onBlur={props.handleBlur('financialStatuses')}
                    options={Object.values(ORDER_FINANCIAL_STATUSES)}
                  />

                  <Autocomplete
                    label="Store"
                    defaultValue={props.values.storeNames}
                    multiline={true}
                    touched={props.touched.storeNames}
                    error={props.errors.storeNames}
                    style={styles.field}
                    onSelect={props.handleChange('storeNames')}
                    onBlur={props.handleBlur('storeNames')}
                    options={stores.map((s) => s.name)}
                  />

                  <Autocomplete
                    label="Provider"
                    defaultValue={props.values.providers}
                    multiline={true}
                    touched={props.touched.providers}
                    error={props.errors.providers}
                    style={styles.field}
                    onSelect={props.handleChange('providers')}
                    onBlur={props.handleBlur('providers')}
                    options={Object.values(STORE_PROVIDERS)}
                  />

                  <DateRangeInput
                    style={styles.field}
                    label="Created"
                    minVal={props.values.minCreated}
                    maxVal={props.values.maxCreated}
                    minTouched={props.touched.minCreated}
                    maxTouched={props.touched.maxCreated}
                    onMinValueChange={(date) => props.setFieldValue('minCreated', date)}
                    onMaxValueChange={(date) => props.setFieldValue('maxCreated', date)}
                    onMinValueBlur={() => props.setFieldTouched('minCreated', true)}
                    onMaxValueBlur={() => props.setFieldTouched('maxCreated', true)}
                    minError={props.errors.minCreated}
                    maxError={props.errors.maxCreated}
                    minPlaceholder="DD/MM/YYYY"
                    maxPlaceholder="DD/MM/YYYY"
                  />
                </>
              )}
              <Divider />
              <Layout
                style={isMobile ? styles.mobileButtonContainer : styles.desktopButtonContainer}>
                <Button
                  status="primary"
                  loading={props.isSubmitting}
                  disabled={props.isSubmitting || !props.isValid}
                  onPress={props.handleSubmit}
                  style={styles.button}>
                  Submit
                </Button>
                <Button
                  status="basic"
                  loading={props.isSubmitting}
                  onPress={onDismiss}
                  disabled={props.isSubmitting}
                  style={styles.button}>
                  Cancel
                </Button>
                <Button
                  status="danger"
                  appearance="ghost"
                  disabled={props.isSubmitting}
                  onPress={() => props.setValues(DEFAULT_VALUES)}
                  style={styles.button}>
                  Reset
                </Button>
              </Layout>
            </>
          );
        }}
      </Formik>
    </Layout>
  );
});

const styles = StyleSheet.create({
  field: {
    marginVertical: 8,
  },
  title: {
    marginVertical: 10,
  },
  form: {
    alignSelf: 'center',
  },
  button: {
    marginVertical: 10,
    marginHorizontal: 2,
  },
  desktopButtonContainer: {
    flexDirection: 'row-reverse',
  },
  mobileButtonContainer: {
    flexDirection: 'column',
  },
  controlContainer: {
    borderRadius: 4,
    margin: 2,
    padding: 6,
    backgroundColor: '#3366FF',
  },
});
