import { path } from 'ramda';
import { saveAs } from 'file-saver';

const exportCsv = async (tableData, headers, filename, formatter = {}) => {
  const XLSX = await import('xlsx/xlsx.mjs');
  var items = tableData.map((d) =>
    headers.reduce((map, k) => {
      const rawValue = path(k.split('.'), d);
      const displayValue = formatter.hasOwnProperty(k) ? formatter[k](rawValue) : rawValue;
      return {
        ...map,
        [k]: Array.isArray(displayValue) ? JSON.stringify(displayValue) : displayValue,
      };
    }, {}),
  );
  // use blob and file-saver/saveAs to save plain text in a new file
  let blobFile = new Blob([XLSX.utils.sheet_to_csv(XLSX.utils.json_to_sheet(items))], {
    type: 'text/plain;charset=utf-8',
  });
  saveAs(blobFile, filename);
};

export default exportCsv;
