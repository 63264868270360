import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import { Layout, Button, Text, Divider, Icon } from '@ui-kitten/components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import RangeInput from './input/RangeInput';
import { useIsMobile } from '../core/responsive.utils';
import { TextInputField } from './input/TextInputField';
import Autocomplete from './Autocomplete';
import { observer } from 'mobx-react';
import { inventoryStore } from '../store';

const DEFAULT_VALUES = {
  productName: '',
  skus: '',
  warehouses: '',
  minSaleableStock: 0,
  maxSaleableStock: 2000,
  minOnwayStock: 0,
  maxOnwayStock: 2000,
  minWeight: 0,
  maxWeight: 1000,
};

export default observer(({ onSubmit, searchParams }) => {
  const isMobile = useIsMobile();
  const [warehouseTerm, setWarehouseTerm] = useState();
  const [showAdvanced, setShowAdvanced] = useState(false);
  const skus = inventoryStore.items;
  return (
    <Layout style={styles.form}>
      <Text category="h5" style={styles.title}>
        Search products
      </Text>
      <Divider />
      <Formik
        initialValues={searchParams || DEFAULT_VALUES}
        validationSchema={Yup.object({
          productName: Yup.string(),
          skus: Yup.string(),
          warehouses: Yup.string(),
          minSaleableStock: Yup.number().min(0),
          maxSaleableStock: Yup.number().when('minSaleableStock', (min, schema) => {
            return min
              ? schema.moreThan(Yup.ref('minSaleableStock'), 'Max must be greater than min')
              : schema.nullable();
          }),
          minOnwayStock: Yup.number().min(0),
          maxOnwayStock: Yup.number().when('minOnwayStock', (min, schema) => {
            return min
              ? schema.moreThan(Yup.ref('minOnwayStock'), 'Max must be greater than min')
              : schema.nullable();
          }),
          minWeight: Yup.number().min(0),
          maxWeight: Yup.number().when('minWeight', (min, schema) => {
            return min
              ? schema.moreThan(Yup.ref('minWeight'), 'Max must be greater than min')
              : schema.nullable();
          }),
          minLength: Yup.number().min(0),
          maxLength: Yup.number().when('minLength', (min, schema) => {
            return min
              ? schema.moreThan(Yup.ref('minLength'), 'Max must be greater than min')
              : schema.nullable();
          }),
          minWidth: Yup.number().min(0),
          maxWidth: Yup.number().when('minWidth', (min, schema) => {
            return min
              ? schema.moreThan(Yup.ref('minWidth'), 'Max must be greater than min')
              : schema.nullable();
          }),
          minHeight: Yup.number().min(0),
          maxHeight: Yup.number().when('minHeight', (min, schema) => {
            return min
              ? schema.moreThan(Yup.ref('minHeight'), 'Max must be greater than min')
              : schema.nullable();
          }),
        })}
        onSubmit={(values, formikActions) => {
          const nonEmptyValues = Object.fromEntries(
            Object.entries(values).filter(([_, value]) => value !== ''),
          );

          onSubmit(nonEmptyValues);
          // Important: Make sure to setSubmitting to false so our loading indicator
          // goes away.
          formikActions.setSubmitting(false);
        }}>
        {(props) => {
          return (
            <>
              <TextInputField
                size="small"
                label="Product Name"
                name="productName"
                style={styles.field}
                {...props}
              />
              <Autocomplete
                label="SKU(s)"
                defaultValue={props.values.skus}
                multiline={true}
                touched={props.touched.skus}
                error={props.errors.skus}
                style={styles.field}
                onSelect={props.handleChange('skus')}
                onBlur={props.handleBlur('skus')}
                options={[...new Set(skus.map((s) => s.sku_code))]}
              />
              <Autocomplete
                label="Warehouse(s)"
                defaultValue={props.values.warehouses}
                multiline={true}
                touched={props.touched.warehouses}
                error={props.errors.warehouses}
                style={styles.field}
                onSelect={props.handleChange('warehouses')}
                onBlur={props.handleBlur('warehouses')}
                options={[...new Set(skus.map((s) => s.warehouse_code).map((w) => w || ''))]}
              />
              <RangeInput
                style={styles.field}
                label="Saleable stock"
                minVal={props.values.minSaleableStock}
                maxVal={props.values.maxSaleableStock}
                minTouched={props.touched.minSaleableStock}
                maxTouched={props.touched.maxSaleableStock}
                onMinValueChange={props.handleChange('minSaleableStock')}
                onMaxValueChange={props.handleChange('maxSaleableStock')}
                onMinValueBlur={props.handleBlur('minSaleableStock')}
                onMaxValueBlur={props.handleBlur('maxSaleableStock')}
                minError={props.errors.minSaleableStock}
                maxError={props.errors.maxSaleableStock}
                minPlaceholder="Min Quantity"
                maxPlaceholder="Max Quantity"
              />
              <RangeInput
                style={styles.field}
                label="Product Weight (kg)"
                minVal={props.values.minWeight}
                maxVal={props.values.maxWeight}
                minTouched={props.touched.minWeight}
                maxTouched={props.touched.maxWeight}
                onMinValueChange={props.handleChange('minWeight')}
                onMaxValueChange={props.handleChange('maxWeight')}
                onMinValueBlur={props.handleBlur('minWeight')}
                onMaxValueBlur={props.handleBlur('maxWeight')}
                minError={props.errors.minWeight}
                maxError={props.errors.maxWeight}
                minPlaceholder="Min Weight"
                maxPlaceholder="Max Weight"
              />
              <Button
                appearance="ghost"
                status="primary"
                onPress={() => setShowAdvanced(!showAdvanced)}
                accessoryLeft={(props) => (
                  <Icon
                    {...props}
                    name={showAdvanced ? 'chevron-up-outline' : 'chevron-down-outline'}
                  />
                )}>
                Advanced Search
              </Button>

              {showAdvanced && (
                <>
                  <RangeInput
                    style={styles.field}
                    label="Incoming stock"
                    minVal={props.values.minOnwayStock}
                    maxVal={props.values.maxOnwayStock}
                    minTouched={props.touched.minOnwayStock}
                    maxTouched={props.touched.maxOnwayStock}
                    onMinValueChange={props.handleChange('minOnwayStock')}
                    onMaxValueChange={props.handleChange('maxOnwayStock')}
                    onMinValueBlur={props.handleBlur('minOnwayStock')}
                    onMaxValueBlur={props.handleBlur('maxOnwayStock')}
                    minError={props.errors.minOnwayStock}
                    maxError={props.errors.maxOnwayStock}
                    minPlaceholder="Min Quantity"
                    maxPlaceholder="Max Quantity"
                  />
                  <RangeInput
                    style={styles.field}
                    label="Product Length (cm)"
                    minVal={props.values.minLength}
                    maxVal={props.values.maxLength}
                    minTouched={props.touched.minLength}
                    maxTouched={props.touched.maxLength}
                    onMinValueChange={props.handleChange('minLength')}
                    onMaxValueChange={props.handleChange('maxLength')}
                    onMinValueBlur={props.handleBlur('minLength')}
                    onMaxValueBlur={props.handleBlur('maxLength')}
                    minError={props.errors.minLength}
                    maxError={props.errors.maxLength}
                    minPlaceholder="Min Length"
                    maxPlaceholder="Max Length"
                  />

                  <RangeInput
                    style={styles.field}
                    label="Product Width (cm)"
                    minVal={props.values.minWidth}
                    maxVal={props.values.maxWidth}
                    minTouched={props.touched.minWidth}
                    maxTouched={props.touched.maxWidth}
                    onMinValueChange={props.handleChange('minWidth')}
                    onMaxValueChange={props.handleChange('maxWidth')}
                    onMinValueBlur={props.handleBlur('minWidth')}
                    onMaxValueBlur={props.handleBlur('maxWidth')}
                    minError={props.errors.minWidth}
                    maxError={props.errors.maxWidth}
                    minPlaceholder="Min Width"
                    maxPlaceholder="Max Width"
                  />

                  <RangeInput
                    style={styles.field}
                    label="Product Height (cm)"
                    minVal={props.values.minHeight}
                    maxVal={props.values.maxHeight}
                    minTouched={props.touched.minHeight}
                    maxTouched={props.touched.maxHeight}
                    onMinValueChange={props.handleChange('minHeight')}
                    onMaxValueChange={props.handleChange('maxHeight')}
                    onMinValueBlur={props.handleBlur('minHeight')}
                    onMaxValueBlur={props.handleBlur('maxHeight')}
                    minError={props.errors.minHeight}
                    maxError={props.errors.maxHeight}
                    minPlaceholder="Min Height"
                    maxPlaceholder="Max Height"
                  />
                </>
              )}
              <Divider />
              <Layout
                style={isMobile ? styles.mobileButtonContainer : styles.desktopButtonContainer}>
                <Button
                  status="primary"
                  loading={props.isSubmitting}
                  disabled={props.isSubmitting || !props.isValid}
                  onPress={props.handleSubmit}
                  style={styles.button}>
                  Submit
                </Button>
                <Button
                  status="danger"
                  appearance="ghost"
                  disabled={props.isSubmitting}
                  onPress={() => props.setValues(DEFAULT_VALUES)}
                  style={styles.button}>
                  Reset
                </Button>
              </Layout>
            </>
          );
        }}
      </Formik>
    </Layout>
  );
});

const styles = StyleSheet.create({
  field: {
    marginVertical: 8,
  },
  title: {
    marginVertical: 10,
  },
  form: {
    alignSelf: 'center',
  },
  button: {
    marginVertical: 10,
    marginHorizontal: 2,
  },
  desktopButtonContainer: {
    flexDirection: 'row-reverse ',
  },
  mobileButtonContainer: {
    flexDirection: 'column',
  },
  controlContainer: {
    borderRadius: 4,
    margin: 2,
    padding: 6,
    backgroundColor: '#3366FF',
  },
});
