import { format, formatDistanceStrict, parseISO } from 'date-fns';

export const DATE_TIME_FORMAT = 'd MMM yyyy, H:mm'; // https://date-fns.org/

export const formatDate = (d) => {
  if (d) {
    const obj = typeof d === 'string' ? parseISO(d) : d;
    if (!isNaN(obj.getTime())) {
      return format(obj, DATE_TIME_FORMAT);
    }
  }

  return '';
};

export const getDataAgeString = (earlierDate, laterDate) => {
  let ageString = '';

  if (!isNaN(earlierDate.getTime()) && !isNaN(laterDate.getTime())) {
    ageString = formatDistanceStrict(earlierDate, laterDate, {
      addSuffix: true,
    });
  }
  return ageString;
};
