import gql from 'graphql-tag';
import { flow, makeAutoObservable } from 'mobx';
import { mergeWith, forEachObjIndexed, flatten, uniq, clone, unnest } from 'ramda';
import exportCsv from '../core/csv';
import { format } from 'date-fns';
import { PRODUCT_SALES_FORCAST } from '../core/utils/utils';

const LINE_ITEM_WITHOUT_SKU = gql`
  query lineItemsWithoutSku($storeIds: [ID!]!) {
    lineItemsWithoutSku(storeIds: $storeIds) {
      orderNumber
      name
      variant
    }
  }
`;

const STORE_SKU_STOCK = gql`
  query storeSkuStock($storeIds: [ID!]!, $warehouseAccountIds: [ID!]!) {
    storeSkuStock(storeIds: $storeIds, warehouseAccountIds: $warehouseAccountIds) {
      ordersDontHaveAvailableAndOnwayStock
      ordersDontHaveAvailableStock
      storeSkuCodeStock
      storeSkuCodeStockKept
    }
  }
`;

const SALES_FORCAST = gql`
  query salesForecast(
    $storeIds: [ID!]!
    $timeZone: String!
    $forecastPeriod: Int!
    $interval: TimeInterval!
    $smoothPeriod: Int
  ) {
    salesForecast(
      storeIds: $storeIds
      timeZone: $timeZone
      smoothPeriod: $smoothPeriod
      forecastPeriod: $forecastPeriod
      interval: $interval
    )
  }
`;

const ALL_STORE_OPTION = 'ALL';

export const TIME_INTERVAL = ['WEEK', 'MONTH', 'QUARTER'];

export class SalesStatisticsStore {
  cachedLineItemsWithoutSku = null;
  cachedStoreSkuStock = null;
  cachedSalesForecast = null;
  cachedProductSalesForecast = null;

  loadingLineItemWithoutSku = true;
  loadingStoreSkuStock = true;
  loadingSalesForecast = true;
  loadingProductSalesForecast = true;

  loadingLineItemWithoutSkuError = null;
  loadingStoreSkuStockError = null;
  loadingSalesForecastError = null;
  loadingProductSalesForecastError = null;

  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  smoothPeriod = 6;

  salesForecastPeriod = 12;
  salesInterval = TIME_INTERVAL[0];
  setSalesInterval = (interval) => {
    this.salesInterval = interval;
    this.cachedSalesForecast = null;
  };
  setSalesForecastPeriod = (period) => {
    this.salesForecastPeriod = period;
    this.cachedSalesForecast = null;
  };

  productSalesForecastPeriod = 12;
  productSalesInterval = TIME_INTERVAL[0];
  selectedProduct = null;
  selectedStoreForSales = ALL_STORE_OPTION;
  selectedStoreForProduct = ALL_STORE_OPTION;

  setProductSalesInterval = (interval) => {
    this.productSalesInterval = interval;
    this.cachedProductSalesForecast = null;
  };
  setProductSalesForecastPeriod = (period) => {
    this.productSalesForecastPeriod = period;
    this.cachedProductSalesForecast = null;
  };
  setSelectedProduct = (product) => {
    this.selectedProduct = product;
  };
  setSelectedStoreForSales = (storeId) => {
    this.selectedStoreForSales = storeId;
  };

  setSelectedStoreForProduct = (storeId) => {
    this.selectedStoreForProduct = storeId;
    this.selectedProduct = null;
  };

  lineItemsWithoutSkuModalOpen = false;

  openLineItemsWithoutSkuModal() {
    this.lineItemsWithoutSkuModalOpen = true;
  }

  closeLineItemsWithoutSkuModal() {
    this.lineItemsWithoutSkuModalOpen = false;
  }

  get storeOptions() {
    const options = [...this.storeStore.enabledStores, { id: ALL_STORE_OPTION, name: 'All' }].map(
      (s) => ({
        key: s.id,
        val: s.name,
      }),
    );
    return options;
  }

  constructor(client, storeStore, warehouseAccountStore) {
    this.client = client;
    this.storeStore = storeStore;
    this.warehouseAccountStore = warehouseAccountStore;
    makeAutoObservable(
      this,
      {
        storeStore: false,
        client: false,
        fetchLineItemWithoutSku: flow,
        fetchProductSalesForecast: flow,
        fetchStoreSkuStock: flow,
        fetchSalesForecast: flow,
      },
      { autoBind: true },
    );
  }

  get error() {
    return (
      this.storeStore.error ||
      this.loadingLineItemWithoutSkuError ||
      this.loadingStoreSkuStockError ||
      this.loadingSalesForecastError ||
      this.loadingProductSalesForecastError
    );
  }

  get loading() {
    return (
      this.storeStore.loading ||
      this.loadingLineItemWithoutSku ||
      this.loadingStoreSkuStock ||
      this.loadingSalesForecast ||
      this.loadingProductSalesForecast
    );
  }

  get lineItemsWithoutSkuBannerVisible() {
    return this.lineItemsWithoutSku?.length > 0;
  }

  get lineItemsWithoutSku() {
    if (!this.cachedLineItemsWithoutSku) {
      this.fetchLineItemWithoutSku();
    }
    return this.cachedLineItemsWithoutSku || [];
  }

  get storeSkuStock() {
    if (!this.cachedStoreSkuStock) {
      this.fetchStoreSkuStock();
    }
    return this.cachedStoreSkuStock || {};
  }

  get productsOfStockLessEqualZero() {
    const res = Object.values(this.storeSkuStock?.storeSkuCodeStockKept || {})
      .flatMap(Object.entries)
      .reduce((p, [sku, { available_stock, onway_stock }]) => {
        if (p[sku]) {
          p[sku] += available_stock + onway_stock;
        } else {
          p[sku] = available_stock + onway_stock;
        }
        return p;
      }, {});
    return Object.entries(res)
      .map(([sku, stock]) => ({ sku, stock }))
      .filter(({ stock }) => stock <= 0);
  }

  get productsOfStockLessGreater15() {
    const res = Object.values(this.storeSkuStock?.storeSkuCodeStockKept || {})
      .flatMap(Object.entries)
      .reduce((p, [sku, { available_stock, onway_stock }]) => {
        if (p[sku]) {
          p[sku] += available_stock + onway_stock;
        } else {
          p[sku] = available_stock + onway_stock;
        }
        return p;
      }, {});
    return Object.entries(res)
      .map(([sku, stock]) => ({ sku, stock }))
      .filter(({ stock }) => stock > 0);
  }

  get salesForecast() {
    if (!this.cachedSalesForecast) {
      this.fetchSalesForecast();
    }
    let storeSalesForecast;
    if (this.selectedStoreForSales === ALL_STORE_OPTION) {
      const salesForecastArray = Object.values(this.cachedSalesForecast || {});
      const mergedSalesForecast = { forecasted: [], past: [] };
      salesForecastArray.forEach((sales) => {
        Object.keys(sales).forEach((key) => {
          sales[key].forEach((salesEntry) => {
            const matchingDateIndex = mergedSalesForecast[key].findIndex(
              (s) => s.date === salesEntry.date,
            );
            if (matchingDateIndex !== -1) {
              mergedSalesForecast[key][matchingDateIndex].value += salesEntry.value;
            } else {
              mergedSalesForecast[key].push(clone(salesEntry));
            }
          });
        });
      });
      storeSalesForecast = mergedSalesForecast;
    } else {
      storeSalesForecast = this.cachedSalesForecast?.[this.selectedStoreForSales];
    }
    return [
      (storeSalesForecast?.past || []).map((d) => ({
        date: d.date,
        value: Math.round(d.value),
        l: 'past',
      })),
      (storeSalesForecast?.forecasted || []).map((d) => ({
        date: d.date,
        value: Math.round(d.value),
        l: 'forecast',
      })),
    ];
  }

  get storeProductSalesForecast() {
    if (!this.cachedProductSalesForecast) {
      this.fetchProductSalesForecast();
    }
    let storeProductSalesForecast;
    if (this.selectedStoreForProduct === ALL_STORE_OPTION) {
      const productSalesForecastArray = Object.values(this.cachedProductSalesForecast || {});
      const mergedSalesForecast = {};
      productSalesForecastArray.forEach((sales) => {
        Object.keys(sales).forEach((sku) => {
          if (!mergedSalesForecast[sku]) {
            mergedSalesForecast[sku] = {
              forecasted: [],
              past: [],
            };
          }
          Object.keys(sales[sku]).forEach((key) => {
            sales[sku][key].forEach((salesEntry) => {
              const matchingDateIndex = mergedSalesForecast[sku][key].findIndex(
                (s) => s.date === salesEntry.date,
              );
              if (matchingDateIndex !== -1) {
                mergedSalesForecast[sku][key][matchingDateIndex].value += salesEntry.value;
              } else {
                mergedSalesForecast[sku][key].push(clone(salesEntry));
              }
            });
          });
        });
      });
      storeProductSalesForecast = mergedSalesForecast;
    } else {
      storeProductSalesForecast =
        this.cachedProductSalesForecast?.[this.selectedStoreForProduct] || {};
    }
    this.selectedProduct = Object.keys(storeProductSalesForecast)?.[0] || null;
    return storeProductSalesForecast;
  }

  get productSalesForecast() {
    const ret = [
      (this.storeProductSalesForecast?.[this.selectedProduct]?.past || []).map((d) => ({
        date: d.date,
        value: Math.round(d.value),
        l: 'past',
      })),
      (this.storeProductSalesForecast?.[this.selectedProduct]?.forecasted || []).map((d) => ({
        date: d.date,
        value: Math.round(d.value),
        l: 'forecast',
      })),
    ];
    return ret;
  }

  *fetchLineItemWithoutSku() {
    try {
      this.loadingLineItemWithoutSku = true;
      const { data } = yield this.client.query({
        query: LINE_ITEM_WITHOUT_SKU,
        variables: { storeIds: yield this.storeStore.getEnabledStoreIds() },
      });
      this.cachedLineItemsWithoutSku = data.lineItemsWithoutSku;
    } catch (error) {
      this.loadingLineItemWithoutSkuError = error;
      console.error(error);
    } finally {
      this.loadingLineItemWithoutSku = false;
    }
  }

  *fetchStoreSkuStock() {
    try {
      this.loadingStoreSkuStock = true;
      const { data } = yield this.client.query({
        query: STORE_SKU_STOCK,
        variables: {
          storeIds: yield this.storeStore.getEnabledStoreIds(),
          warehouseAccountIds: yield this.warehouseAccountStore.getWarehouseAccountIds(),
        },
      });
      this.cachedStoreSkuStock = data.storeSkuStock;
    } catch (error) {
      this.loadingStoreSkuStockError = error;
      console.error(error);
    } finally {
      this.loadingStoreSkuStock = false;
    }
  }

  *fetchSalesForecast() {
    try {
      this.loadingSalesForecast = true;
      const { data } = yield this.client.query({
        query: SALES_FORCAST,
        variables: {
          storeIds: yield this.storeStore.getEnabledStoreIds(),
          timeZone: this.timeZone,
          smoothPeriod: this.smoothPeriod,
          forecastPeriod: this.salesForecastPeriod,
          interval: this.salesInterval,
        },
      });
      this.cachedSalesForecast = data.salesForecast;
    } catch (error) {
      this.loadingSalesForecastError = error;
      console.error(error);
    } finally {
      this.loadingSalesForecast = false;
    }
  }

  *fetchProductSalesForecast() {
    try {
      this.loadingProductSalesForecast = true;
      const { data } = yield this.client.query({
        query: PRODUCT_SALES_FORCAST,
        variables: {
          storeIds: yield this.storeStore.getEnabledStoreIds(),
          timeZone: this.timeZone,
          smoothPeriod: this.smoothPeriod,
          forecastPeriod: this.productSalesForecastPeriod,
          interval: this.productSalesInterval,
        },
      });
      this.cachedProductSalesForecast = data.productSalesForecast;
    } catch (error) {
      this.loadingProductSalesForecastError = error;
      console.error(error);
    } finally {
      this.loadingProductSalesForecast = false;
    }
  }

  exportSalesForecast() {
    const data = (this.salesForecast[1] || []).map((d, i) => ({
      Date: format(new Date(d.date), 'yyyy-MM-dd'),
      'Actual Sales': this.salesForecast[0][i]?.value || '',
      'Forecasted Sales': d.value,
    }));
    const csvHeaders = ['Date', 'Actual Sales', 'Forecasted Sales'];
    exportCsv(data, csvHeaders, `SALES_FORECAST_${this.salesInterval}.csv`);
  }

  exportProductSalesForecast() {
    const raw = this.cachedProductSalesForecast;
    const data = {};
    forEachObjIndexed((val, sku) => {
      val?.past?.forEach((d) => {
        if (data[d.date]) {
          data[d.date][`Actual Sales ${sku}`] = d.value;
        } else {
          data[d.date] = { [`Actual Sales ${sku}`]: d.value };
        }
      });
      val?.forecasted?.forEach((d) => {
        if (data[d.date]) {
          data[d.date][`Forecast Sales ${sku}`] = d.value;
        } else {
          data[d.date] = { [`Forecast Sales ${sku}`]: d.value };
        }
      });
    }, raw);
    const csvData = Object.entries(data)
      .map(([date, val]) => ({ Date: Number(date), ...val }))
      .sort((a, b) => a.date - b.date)
      .map((d) => ({ ...d, Date: format(new Date(d.Date), 'yyyy-MM-dd') }));
    const csvHeaders = uniq(flatten(Object.values(csvData).map((d) => Object.keys(d))));
    exportCsv(csvData, csvHeaders, `PRODUCT_SALES_FORECAST_${this.productSalesInterval}.csv`);
  }
}
