import React, { memo, useEffect } from 'react';
import Background from 'src/components/Background';
import BalanceInfo from './BalanceInfo';
import TransactionHistory from './TransactionHistory';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { UIStatus, UIStatusWrapper } from '../../components/ui-status';
import { Modal, Portal } from 'react-native-paper';
import { useIsMobile } from '../../core/responsive.utils';
import { StyleSheet, View } from 'react-native';
import { Card as KittenCard } from '@ui-kitten/components/ui/card/card.component';
import { Header } from './shared';
import { useRoute } from '@react-navigation/core';
import { Layout } from '@ui-kitten/components';
import { ListInvoice } from '../../components/ListInvoice';
import { useUser } from '../../core/utils/utils';

const GET_TRANSACTIONS = gql`
  query Transactions {
    transactions {
      id
      type
      amount
      createdTime
      updatedTime
      remark
      remarkExtra
      status
    }
  }
`;

const UPDATE_TRANSACTION_STATUS = gql`
  mutation UpdateTransactionStatus($paymentIntentId: String!) {
    updateTransactionStatus(paymentIntentId: $paymentIntentId) {
      status
    }
  }
`;

const TRANSACTION_STATUS = {
  OnHold: 'OnHold',
  Initiated: 'Initiated',
  InProcess: 'InProcess',
  Unknown: 'Unknown',
  Completed: 'Completed',
  Cancelled: 'Cancelled',
  Failed: 'Failed',
  TimedOut: 'TimedOut',
};

const styles = StyleSheet.create({
  card: {
    marginBottom: '1em',
    border: '1px solid #F2F2F2',
  },

  mobileModalStyle: { backgroundColor: 'white', marginHorizontal: 20 },
  modalStyle: { backgroundColor: 'white', marginHorizontal: '40%', minWidth: 600 },
});

const SUPPORT_EMAIL = process.env.SUPPORT_EMAIL || 'admin@ezom.app';

const UserAccountScreen = () => {
  const isMobile = useIsMobile();
  const route = useRoute();
  const user = useUser();

  const [
    loadTransactions,
    { data: transResponse, loading: loadingTrans, error: loadingTransError },
  ] = useLazyQuery(GET_TRANSACTIONS);

  const [transStatus, setTransStatus] = React.useState();

  const [updateTransactionStatus, updateTransactionStatusResult] =
    useMutation(UPDATE_TRANSACTION_STATUS);

  useEffect(() => {
    let payment_intent;
    if (route.params) {
      payment_intent = route.params.payment_intent;
    }

    if (payment_intent) {
      updateTransactionStatus({
        variables: {
          paymentIntentId: payment_intent,
        },
      }).then(({ data }) => {
        const status = data.updateTransactionStatus.status;
        if (status) {
          setTransStatus(status);
          loadTransactions();
        }
      });

      // To avoid an issue with react-navigation preserving the params between navigation change
      // https://github.com/react-navigation/react-navigation/issues/6674#issuecomment-593618335
      delete route.params.payment_intent;
    } else {
      setTransStatus(undefined);
      loadTransactions();
    }
  }, [route.params && route.params.token]);

  const displayPaymentStatus = () => {
    return (
      <React.Fragment>
        <p>
          {transStatus === TRANSACTION_STATUS.Completed && 'Thank you! '}
          Your payment was <strong>{transStatus}</strong>.
        </p>

        {transStatus !== TRANSACTION_STATUS.Completed && <p>Please try again later.</p>}
      </React.Fragment>
    );
  };

  return (
    <Background fullWidth={true}>
      <Layout style={{ overflowY: 'auto', height: 'calc(100vh - 125px)' }}>
        <BalanceInfo />

        <UIStatusWrapper
          status={
            new UIStatus(
              loadingTrans || updateTransactionStatusResult.loading,
              loadingTransError || updateTransactionStatusResult.error,
            )
          }>
          <View>
            <TransactionHistory
              transactions={(transResponse && transResponse.transactions) || []}
            />

            <ListInvoice organisationId={user.organisation.id} />
          </View>
        </UIStatusWrapper>
      </Layout>

      <Portal>
        <Modal
          visible={transStatus !== undefined}
          onDismiss={() => {
            setTransStatus(undefined);
          }}
          contentContainerStyle={isMobile ? styles.mobileModalStyle : styles.modalStyle}>
          <KittenCard
            style={styles.card}
            header={(props) => <Header {...props} heading="Payment status" />}>
            {displayPaymentStatus()}
          </KittenCard>
        </Modal>
      </Portal>
    </Background>
  );
};

export default memo(UserAccountScreen);
