import { flow, makeAutoObservable } from 'mobx';
import gql from 'graphql-tag';
import { unifiedAlert } from '../core/utils/utils';

const SKU_MAPPINGS = gql`
  query skuMappings {
    skuMappings {
      id
      qty
      storeId
      storeSku
      storeProductId
      storeProductName
      warehouseSku
      createdTime
      warehouseCode
    }
  }
`;

const DELETE_SKU_MAPPINGS = gql`
  mutation deleteSkuMappings($ids: [ID!]!) {
    deleteSkuMappings(ids: $ids)
  }
`;

const CREATE_SKU_MAPPINGS = gql`
  mutation createSkuMappings($skuMappings: [SkuMappingInput!]!) {
    createSkuMappings(skuMappings: $skuMappings) {
      id
      storeId
      storeSku
      storeProductId
      storeProductName
      warehouseSku
      warehouseCode
      qty
      createdTime
    }
  }
`;

const UNMAPPED_SKUS = gql`
  query unmappedSkus($storeIds: [ID!]!) {
    unmappedSkus(storeIds: $storeIds)
  }
`;

const getUniqueKey = (s) =>
  `${s.storeId}+${
    (s.storeSku && `storeSku:${s.storeSku}`) ||
    (s.storeProductId && `productId:${s.storeProductId}`)
  }+${s.warehouseSku}+${s.warehouseCode || ''}`;

export class SkuMappingStore {
  loading = false;
  error = null;
  cachedItems = null;
  cachedUnmappedSkus = null;

  FORMATTER_BY_KEY = {
    warehouseCode: (w) => this.warehousesStore.getWarehouseNameEn(w),
  };

  constructor(client, storeStore, warehousesStore, warehouseAccountStore) {
    this.client = client;
    this.storeStore = storeStore;
    this.warehouseAccountStore = warehouseAccountStore;
    this.warehousesStore = warehousesStore;
    makeAutoObservable(
      this,
      {
        fetchItems: flow,
        deleteItems: flow,
        addItems: flow,
        fetchUnmappedSkus: flow,
        client: false,
        storeStore: false,
        searchBarFuse: false,
      },
      { autoBind: true },
    );
  }

  get items() {
    if (!this.cachedItems) {
      this.fetchItems();
    }
    return this.cachedItems || [];
  }

  get unmappedSkus() {
    if (!this.cachedUnmappedSkus) {
      this.fetchUnmappedSkus();
    }
    return this.cachedUnmappedSkus || [];
  }

  *fetchUnmappedSkus() {
    try {
      this.loading = true;

      const { data } = yield this.client.query({
        query: UNMAPPED_SKUS,
        variables: { storeIds: yield this.storeStore.getEnabledStoreIds() },
      });
      this.cachedUnmappedSkus = data.unmappedSkus;
    } catch (error) {
      this.error = error;
    } finally {
      this.loading = false;
    }
  }

  *fetchItems() {
    try {
      this.loading = true;
      this.cachedItems = null;

      const { data } = yield this.client.query({
        query: SKU_MAPPINGS,
        fetchPolicy: 'network-only', // Doesn't check cache before making a network request
      });
      this.cachedItems = data.skuMappings;
    } catch (error) {
      this.error = error;
    } finally {
      this.loading = false;
    }
  }

  *addItems(skuMappings) {
    try {
      if (!skuMappings || !Array.isArray(skuMappings)) {
        console.error('Invalid or missing skuMappings');
        return;
      }
      const { data } = yield this.client.mutate({
        mutation: CREATE_SKU_MAPPINGS,
        variables: {
          skuMappings,
        },
        // refetch skuMappingForDispatch queries used in dispatch store
        refetchQueries: ['skuMappingForDispatch'],
      });
      yield this.fetchItems();
    } catch (error) {
      unifiedAlert(error);
      console.error(error);
      throw error;
    }
  }

  *deleteItems(ids) {
    try {
      yield this.client.mutate({
        mutation: DELETE_SKU_MAPPINGS,
        variables: {
          ids,
        },
        // refetch skuMappingForDispatch queries used in dispatch store
        refetchQueries: ['skuMappingForDispatch'],
      });
      yield this.fetchItems();
    } catch (error) {
      console.error(error);
    }
  }
}
