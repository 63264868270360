import { StyleSheet, View } from 'react-native';
import { Button, Text } from '@ui-kitten/components';
import { formatDistanceToNow } from 'date-fns';
import React from 'react';

const styles = StyleSheet.create({
  footerContainer: {
    flex: 1,
  },

  footerControl: {
    marginHorizontal: 2,
  },
});

export const Header = (props) => (
  <View {...props}>
    <Text category="h6">{props.heading}</Text>
    {/*<Text appearance="hint">*/}
    {/*  <strong>last updated:</strong>{' '}*/}
    {/*  {formatDistanceToNow(new Date(faker.date.recent()), { addSuffix: true })}*/}
    {/*</Text>*/}
  </View>
);

export const Footer = (props) => (
  <View style={[props.style, styles.footerContainer]}>
    <Button style={styles.footerControl} disabled={props.disabled} onPress={props.onPress}>
      Update
    </Button>
  </View>
);
