import React, { memo, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Chip, Card } from 'react-native-paper';
import { useIsMobile, useLargeScreen } from 'src/core/responsive.utils';
import { Layout, Button, Icon, Popover, Text, CheckBox } from '@ui-kitten/components';
import { ScrollView } from 'react-native-gesture-handler';
import { IC_STATUS_MAP } from '@ezom/library/lib/cjs/constants';
import SearchBar from './SearchBar';

const COMMON_IC_STATUS_LIST = [
  'Approved',
  'Receiving',
  'Received',
  'Putaway',
  'Cancelled',
  'Draft',
];

const SORT_OPTIONS = [
  { key: 'consignment_no', title: 'Consignment number' },
  { key: 'ref_no', title: 'Customer number' },
  { key: 'status', title: 'Status' },
  { key: 'to_warehouse_code', title: 'Warehouse' },
  { key: 'numOfItems', title: 'SKU Quantity' },
  { key: 'skuCategories', title: 'Category' },
  { key: 'total_weight', title: 'Weight' },
  { key: 'total_volume', title: 'Volume' },
  { key: 'create_time', title: 'Create time' },
].reduce(
  (prev, option) => [...prev, { ...option, direction: 'asc' }, { ...option, direction: 'desc' }],
  [],
);

const InboundControl = ({
  filterParams = {},
  indicators = [],
  onSearchTermChange = () => {},
  onFilterChange = () => {},
  onSortChange = () => {},
  onPressAdvancedSearch = () => {},
  onFilterClear = () => {},
  disableSearch = false,
  requireButtonClickToSearch = false,
}) => {
  const isMobileDevice = useIsMobile();
  const isLargeScreen = useLargeScreen();
  const [searchTerm, setSearchTerm] = useState('');

  const [visible, setVisible] = useState(false);
  const [statusDropdownVisible, setStatusDropdownVisible] = useState(false);
  const [sortIndex, setSortIndex] = useState(-1);

  return (
    <Card visible={true} style={styles.bannerContainer} actions={[]}>
      <Layout style={isMobileDevice ? styles.mobileBanner : styles.desktopBanner}>
        <SearchBar
          disableSearch={disableSearch}
          onSearchTermChange={onSearchTermChange}
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          requireButtonClickToSearch={requireButtonClickToSearch}
          inputPlaceholder="Search inbound orders"
          inputSize="medium"></SearchBar>
        <Layout style={isMobileDevice ? styles.mobileButtonGroup : styles.desktopButtonGroup}>
          <Popover
            visible={statusDropdownVisible}
            anchor={() => (
              <Button
                status="primary"
                appearance="ghost"
                style={[styles.button]}
                onPress={() => setStatusDropdownVisible(true)}
                accessoryRight={statusDropdownVisible ? ArrowUpIcon : ArrowDownIcon}>
                Status
              </Button>
            )}
            onBackdropPress={() => {
              setStatusDropdownVisible(false);
            }}
            style={styles.popover}>
            <Layout style={styles.popover}>
              <Text>Select status</Text>
              <ScrollView style={{ maxHeight: 250 }}>
                {Object.entries(IC_STATUS_MAP).map(([key, val]) => (
                  <CheckBox
                    key={`${key}-${val}`}
                    checked={filterParams.status === key}
                    onChange={(checked) => {
                      if (checked) {
                        onFilterChange({
                          status: key,
                        });
                      } else {
                        onFilterChange({
                          status: undefined,
                        });
                      }
                    }}
                    style={styles.checkItem}>
                    {val}
                  </CheckBox>
                ))}
              </ScrollView>
            </Layout>
          </Popover>
          <Button
            status="primary"
            appearance="ghost"
            onPress={onPressAdvancedSearch}
            accessoryRight={ArrowUpRightIcon}
            style={styles.button}>
            Advanced
          </Button>
          <Button
            status="danger"
            appearance="ghost"
            onPress={() => {
              setSearchTerm('');
              onFilterClear();
            }}
            style={styles.button}>
            Reset filter
          </Button>
        </Layout>
      </Layout>
      <Layout style={styles.indicator}>
        {indicators.map((indicator, index) => (
          <Chip style={styles.chip} key={`${indicator}-${index}`}>
            {indicator}
          </Chip>
        ))}
      </Layout>
    </Card>
  );
};

const styles = StyleSheet.create({
  bannerContainer: {
    marginBottom: '0.5em',
    padding: '1em',
  },
  desktopBanner: {
    maxWidth: '1800px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  mobileBanner: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  indicator: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  chip: {
    marginTop: '0.4em',
    marginHorizontal: '0.2em',
    opacity: '0.5',
  },
  popover: {
    margin: '0.5em',
    padding: '0.5em',
  },
  rangeInput: {
    width: '100px',
    margin: '0.5em',
  },
  desktopButtonGroup: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mobileButtonGroup: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    height: '45px',
  },
  icon: {
    width: '28px',
    height: '28px',
    stroke: 'white',
    strokeWidth: '0.8',
  },
  popover: {
    margin: '0.5em',
    padding: '0.5em',
  },
  checkItem: {
    marginVertical: 4,
    marginHorizontal: 12,
  },
});

const ArrowDownIcon = (props) => (
  <Icon
    {...props}
    name="arrow-ios-downward-outline"
    style={[props.style, styles.icon, { width: '20px', height: '20px' }]}
  />
);

const ArrowUpIcon = (props) => (
  <Icon
    {...props}
    name="arrow-ios-upward-outline"
    style={[props.style, styles.icon, { width: '20px', height: '20px' }]}
  />
);

const ArrowUpRightIcon = (props) => (
  <Icon
    {...props}
    name="diagonal-arrow-right-up-outline"
    style={[props.style, styles.icon, { width: '20px', height: '20px' }]}
  />
);

export default memo(InboundControl);
