import { Formik } from 'formik';
import { URL } from 'react-native-url-polyfill';
import { Linking, StyleSheet, View } from 'react-native';
import { Avatar, Button, Card, Text } from '@ui-kitten/components';
import Select from './Select';
import React, { useMemo } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { TextInputField } from './input/TextInputField';
import * as Yup from 'yup';
import { WAREHOUSE_PROVIDERS } from '@ezom/library/lib/cjs/constants';
import { get4pxLink } from '../utils/oAuth-util';
import { warehouseAccountStore } from '../store';
import { useIsWmsManager } from '../core/utils/utils';

const EZWMS_PROVIDER = {
  id: WAREHOUSE_PROVIDERS.EZWMS,
  name: 'EzWMS',
  logoSrc: require('../assets/logo.png'),
  supported: true,
};

const FOURPX_PROVIDER = {
  id: WAREHOUSE_PROVIDERS.FOURPX,
  name: '4PX',
  logoSrc: require('../assets/images/providers/4px-logo.png'),
  supported: true,
};

const FOURPX_APP_KEY = gql`
  query fourpxConnector {
    fourpxConnector {
      appKey
      host
    }
  }
`;

export const AddWmsForm = ({
  provider = '',
  providerAuthorised = false,
  onSubmit,
  navigation,
  consumerKey = '',
  consumerSecret = '',
  accessToken = '',
  accessTokenSecret = '',
}) => {
  const isWmsManager = useIsWmsManager();

  const providers = useMemo(() => {
    if (isWmsManager) {
      return [EZWMS_PROVIDER, FOURPX_PROVIDER];
    } else {
      return [FOURPX_PROVIDER];
    }
  });

  const [fourpxConnectionDetail, fourpxConnectionDetailResult] = useLazyQuery(FOURPX_APP_KEY, {
    onCompleted: (d) => {
      const { host, appKey } = d.fourpxConnector;
      const url = new URL(
        get4pxLink(host, appKey, encodeURIComponent(window.location.href + '?addWarehouse=true')),
      );

      Linking.canOpenURL(url).then((supported) => {
        if (supported) {
          Linking.openURL(url);
        } else {
          console.log("Don't know how to open URI: " + url);
        }
      });
    },
  });

  return (
    <Formik
      isInitialValid={false}
      initialValues={{
        provider,
        name: '',
        consumerKey,
        consumerSecret,
        accessToken,
        accessTokenSecret,
      }}
      validationSchema={Yup.object({
        name: providerAuthorised ? Yup.string().required('Required') : Yup.string(),
        provider: Yup.string().required('Required'),
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        if (providerAuthorised) {
          onSubmit && onSubmit(values);
        } else {
          if (values.provider === WAREHOUSE_PROVIDERS.FOURPX) {
            fourpxConnectionDetail();
          } else if (values.provider === WAREHOUSE_PROVIDERS.EZWMS) {
            await warehouseAccountStore.addWmsAccount('EzWMS', WAREHOUSE_PROVIDERS.EZWMS);
            onSubmit && onSubmit(values);
          }
        }
        setSubmitting(false);
      }}>
      {(props) => {
        const selectedProvider = providers.find(({ id }) => id === props.values.provider) || {};

        const Footer = (footerProps) => (
          <View {...footerProps}>
            <Button
              loading={props.isSubmitting}
              style={styles.footerControl}
              size="small"
              onPress={() => props.handleSubmit()}>
              {selectedProvider.supported === false ? 'Please contact us' : 'Add'}
            </Button>
          </View>
        );

        return (
          <Card
            style={styles.card}
            header={(props) => (
              <View {...props}>
                <Text category="h6">{providerAuthorised ? 'Name your warehouse' : 'Add WMS'}</Text>
              </View>
            )}
            footer={Footer}>
            {providerAuthorised ? (
              <TextInputField
                label="Name"
                name="name"
                style={styles.field}
                placeholder="Warehouse name"
                {...props}
              />
            ) : null}

            <Select
              disabled={providerAuthorised}
              label="Provider"
              name="provider"
              placeholder="Select provider"
              value={selectedProvider.name}
              error={props.errors.provider}
              touched={props.touched.provider}
              style={styles.field}
              options={providers.map((p) => ({
                key: p.id,
                val: p.name,
                accessoryLeft: () => {
                  return (
                    <Avatar
                      resizeMode="contain"
                      size="large"
                      source={p.logoSrc}
                      style={{ width: 50, height: 30 }}
                    />
                  );
                },
              }))}
              onChange={(val) => {
                props.setFieldValue('provider', val.key);
              }}
              onBlur={() => props.setFieldTouched('provider', true)}
            />
          </Card>
        );
      }}
    </Formik>
  );
};

const styles = StyleSheet.create({
  field: {
    marginVertical: 8,
  },
  footerControl: {
    marginTop: '0.5em',
  },
  card: {
    flex: 1,
    margin: 2,
  },
});
