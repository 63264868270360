import React from 'react';
import { Tooltip } from '@ui-kitten/components';

export const TooltipWrapper = ({ children, anchor }) => {
  const [visible, setVisible] = React.useState(false);
  const onPress = React.useMemo(() => () => setVisible(true), [setVisible]);

  return (
    <Tooltip
      anchor={() => React.cloneElement(anchor || children, { onPress })}
      visible={visible}
      onBackdropPress={() => setVisible(false)}>
      {React.cloneElement(children, { style: { color: 'white' } })}
    </Tooltip>
  );
};
