import React, { useCallback, useMemo, useState } from 'react';
import { StyleSheet, Dimensions } from 'react-native';
import { Layout, Button, Text, Card, Input, Toggle } from '@ui-kitten/components';
import { useIsMobile } from '../core/responsive.utils';
import { Modal, Portal } from 'react-native-paper';
import {
  mergeAll,
  range,
  splitEvery,
  takeLast,
  uniq,
  zipObj,
  groupWith,
  flatten,
  map,
} from 'ramda';
import { useAssets } from 'expo-asset';
import seedrandom from 'seedrandom';
import { UIStatusWrapper } from './ui-status';
import { warehouseAccountStore } from '../store';
import { sanitize } from '../core/utils/utils';
import { weightGramToKg } from '@ezom/library/lib/cjs/utils';
import PrintSkuLabel from './PrintSkuLabel';

const windowHeight = Dimensions.get('window').height;

const generatePdf = (pdf) => {
  const base64str = pdf.file.data;

  // decode base64 string, remove space for IE compatibility
  const binary = atob(base64str.replace(/\s/g, ''));
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);
  for (var i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }
  const file = new Blob([view], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL, '_blank');
};

export default ({ visible, setVisible, ic, warehouseAccountId }) => {
  const isMobile = useIsMobile();
  const warehouseAccount = warehouseAccountStore.warehouseAccounts.find(
    (w) => w.id === warehouseAccountId,
  );
  const accountNumber = warehouseAccount?.accountNumber;

  const [countryOfOrigin, setCountryOfOrigin] = useState('Made in China');
  const [printCountryOfOrigin, setPrintCountryOfOrigin] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [fonts] = useAssets([
    require('../pdfTemplates/fonts/cn.ttf'),
    require('../pdfTemplates/fonts/Helvetica.ttf'),
  ]);

  const generatePdfFromTempalte = useCallback(
    async (template, inputs = []) => {
      const url = fonts[0].localUri;
      const fontBytes = await fetch(url).then((res) => res.arrayBuffer());
      const url2 = fonts[1].localUri;
      const fontBytes2 = await fetch(url2).then((res) => res.arrayBuffer());
      const font = { CN: fontBytes, Helvetica: fontBytes2 };
      const labelmake = (await import('labelmake')).default;
      const pdf = await labelmake({
        template: { ...template, fontName: 'Helvetica' },
        inputs,
        font,
      });
      const blob = new Blob([pdf.buffer], { type: 'application/pdf' });
      const page = await window.open(URL.createObjectURL(blob), '_blank');
      return page;
    },
    [fonts],
  );

  return (
    <Portal>
      <Modal
        visible={visible}
        onDismiss={() => setVisible(false)}
        contentContainerStyle={isMobile ? styles.mobileModalStyle : styles.modalStyle}>
        <UIStatusWrapper
          status={{
            error: warehouseAccountStore.error,
            indeterminate: warehouseAccountStore.loading,
          }}>
          <Card
            header={(props) => (
              <Text category="h6" style={styles.header}>
                Print
              </Text>
            )}
            disabled>
            <PrintSkuLabel skuToGeneratePdf={ic.lstsku} />

            <Card
              header={(props) => (
                <Text category="h6" style={styles.header}>
                  Print box labels
                </Text>
              )}
              style={{ marginBottom: 5 }}
              disabled>
              <Layout style={styles.container}>
                <Button
                  style={styles.button}
                  appearance="outline"
                  size="small"
                  disabled={btnDisabled}
                  onPress={async () => {
                    setBtnDisabled(true);
                    await generateBoxLabel(
                      accountNumber,
                      {
                        ...ic,
                        consignment_no:
                          ic.consignment_no.length > 23
                            ? ic.consignment_no.substring(0, 23) + '...'
                            : ic.consignment_no,
                        ref_no:
                          ic.ref_no.length > 23 ? ic.ref_no.substring(0, 23) + '...' : ic.ref_no,
                        lstsku: ic.lstsku.map((sku) => ({
                          ...sku,
                          sku_code:
                            sku.sku_code.length > 20
                              ? sku.sku_code.substring(0, 20) + '...'
                              : sku.sku_code,
                        })),
                      },
                      printCountryOfOrigin,
                      countryOfOrigin,
                      generatePdfFromTempalte,
                      '8x9',
                      7,
                    );
                    setBtnDisabled(false);
                  }}>
                  8cmX9cm
                </Button>
                <Button
                  style={styles.button}
                  appearance="outline"
                  size="small"
                  disabled={btnDisabled}
                  onPress={async () => {
                    setBtnDisabled(true);
                    await generateBoxLabel(
                      accountNumber,
                      {
                        ...ic,
                        consignment_no:
                          ic.consignment_no.length > 24
                            ? ic.consignment_no.substring(0, 24) + '...'
                            : ic.consignment_no,
                        ref_no:
                          ic.ref_no.length > 20 ? ic.ref_no.substring(0, 20) + '...' : ic.ref_no,
                        lstsku: ic.lstsku.map((sku) => ({
                          ...sku,
                          sku_code:
                            sku.sku_code.length > 24
                              ? sku.sku_code.substring(0, 24) + '...'
                              : sku.sku_code,
                        })),
                      },
                      printCountryOfOrigin,
                      countryOfOrigin,
                      generatePdfFromTempalte,
                      '10x6',
                      4,
                    );
                    setBtnDisabled(false);
                  }}>
                  10cmX6cm
                </Button>
              </Layout>
              <Layout style={styles.container}>
                <Button
                  style={styles.button}
                  appearance="outline"
                  size="small"
                  disabled={btnDisabled}
                  onPress={async () => {
                    setBtnDisabled(true);
                    await generateBoxLabel(
                      accountNumber,
                      {
                        ...ic,
                        consignment_no:
                          ic.consignment_no.length > 24
                            ? ic.consignment_no.substring(0, 24) + '...'
                            : ic.consignment_no,
                        ref_no:
                          ic.ref_no.length > 20 ? ic.ref_no.substring(0, 20) + '...' : ic.ref_no,
                        lstsku: ic.lstsku.map((sku) => ({
                          ...sku,
                          sku_code:
                            sku.sku_code.length > 20
                              ? sku.sku_code.substring(0, 20) + '...'
                              : sku.sku_code,
                        })),
                      },
                      printCountryOfOrigin,
                      countryOfOrigin,
                      generatePdfFromTempalte,
                      '10x15',
                      7,
                    );
                    setBtnDisabled(false);
                  }}>
                  10cmX15cm
                </Button>
                <Button
                  style={styles.button}
                  appearance="outline"
                  size="small"
                  disabled={btnDisabled}
                  onPress={async () => {
                    setBtnDisabled(true);
                    await generateBoxLabel(
                      accountNumber,
                      {
                        ...ic,
                        consignment_no:
                          ic.consignment_no.length > 22
                            ? ic.consignment_no.substring(0, 22) + '...'
                            : ic.consignment_no,
                        ref_no:
                          ic.ref_no.length > 20 ? ic.ref_no.substring(0, 20) + '...' : ic.ref_no,
                        lstsku: ic.lstsku.map((sku) => ({
                          ...sku,
                          sku_code:
                            sku.sku_code.length > 20
                              ? sku.sku_code.substring(0, 20) + '...'
                              : sku.sku_code,
                        })),
                      },
                      printCountryOfOrigin,
                      countryOfOrigin,
                      generatePdfFromTempalte,
                      '10x20',
                      9,
                    );
                    setBtnDisabled(false);
                  }}>
                  10cmX20cm
                </Button>
                <Button
                  style={styles.button}
                  appearance="outline"
                  size="small"
                  disabled={btnDisabled}
                  onPress={async () => {
                    setBtnDisabled(true);
                    await generateBoxLabel(
                      accountNumber,
                      {
                        ...ic,
                        consignment_no:
                          ic.consignment_no.length > 30
                            ? ic.consignment_no.substring(0, 30) + '...'
                            : ic.consignment_no,
                        ref_no:
                          ic.ref_no.length > 24 ? ic.ref_no.substring(0, 24) + '...' : ic.ref_no,
                        lstsku: ic.lstsku.map((sku) => ({
                          ...sku,
                          sku_code:
                            sku.sku_code.length > 30
                              ? sku.sku_code.substring(0, 30) + '...'
                              : sku.sku_code,
                        })),
                      },
                      printCountryOfOrigin,
                      countryOfOrigin,
                      generatePdfFromTempalte,
                      'A4',
                      15,
                    );
                    setBtnDisabled(false);
                  }}>
                  A4
                </Button>
              </Layout>
            </Card>
            <Card
              header={(props) => (
                <Text category="h6" style={styles.header}>
                  Print package list
                </Text>
              )}
              style={{ marginBottom: 5 }}
              disabled>
              <Layout style={styles.container}>
                <Button
                  style={styles.button}
                  appearance="outline"
                  size="small"
                  disabled={btnDisabled}
                  onPress={() => {
                    printPackageList(ic);
                  }}>
                  A4
                </Button>
              </Layout>
            </Card>
            <Card
              header={(props) => (
                <Text category="h6" style={styles.header}>
                  Print battery labels
                </Text>
              )}
              disabled>
              <Layout style={styles.container}>
                <Button
                  style={styles.button}
                  appearance="outline"
                  size="small"
                  disabled={btnDisabled}
                  onPress={async () => {
                    setBtnDisabled(true);
                    const pdf = await import(
                      '../pdfTemplates/batteryLabels/pure_battery_12x11.json'
                    );
                    generatePdf(pdf);
                    setBtnDisabled(false);
                  }}>
                  Pure battery(12cmX11cm)
                </Button>
                <Button
                  style={styles.button}
                  appearance="outline"
                  size="small"
                  disabled={btnDisabled}
                  onPress={async () => {
                    setBtnDisabled(true);
                    const pdf = await import(
                      '../pdfTemplates/batteryLabels/int_ext_battery_12x11.json'
                    );
                    generatePdf(pdf);
                    setBtnDisabled(false);
                  }}>
                  Internal/external battery(12cmX11cm)
                </Button>
              </Layout>
              <Layout style={styles.container}>
                <Button
                  style={styles.button}
                  appearance="outline"
                  size="small"
                  disabled={btnDisabled}
                  onPress={async () => {
                    setBtnDisabled(true);
                    const pdf = await import(
                      '../pdfTemplates/batteryLabels/pure_battery_10.5x7.4.json'
                    );
                    generatePdf(pdf);
                    setBtnDisabled(false);
                  }}>
                  Pure battery(10.5cmX7.4cm)
                </Button>
                <Button
                  style={styles.button}
                  appearance="outline"
                  size="small"
                  disabled={btnDisabled}
                  onPress={async () => {
                    setBtnDisabled(true);
                    const pdf = await import(
                      '../pdfTemplates/batteryLabels/int_ext_battery_10.5x7.4.json'
                    );
                    generatePdf(pdf);
                    setBtnDisabled(false);
                  }}>
                  Internal/external battery(10.5cmX7.4cm)
                </Button>
              </Layout>
            </Card>
          </Card>
        </UIStatusWrapper>
      </Modal>
    </Portal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  layout: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  field: {
    marginVertical: 8,
  },
  title: {
    marginVertical: 10,
  },
  form: {
    alignSelf: 'center',
    minWidth: '20%',
  },
  button: {
    marginVertical: 10,
    marginHorizontal: 2,
  },
  desktopButtonContainer: {
    flexDirection: 'row-reverse ',
  },
  mobileButtonContainer: {
    flexDirection: 'column',
  },
  controlContainer: {
    borderRadius: 4,
    margin: 2,
    padding: 6,
    backgroundColor: '#3366FF',
  },
  formContainer: {
    height: 0.75 * windowHeight,
    overflowY: 'auto',
  },
  mobileModalStyle: { backgroundColor: 'white', marginHorizontal: 20 },
  modalStyle: { backgroundColor: 'white', marginHorizontal: '40%', minWidth: 600 },
  header: { alignSelf: 'center', paddingVertical: 10 },
});

const BOX_LETTER_CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

const generateBoxLabel = async (
  accountNumber,
  ic,
  printCountryOfOrigin,
  countryOfOrigin,
  generatePdfFromTempalte,
  size,
  gapSize = 7,
) => {
  const template = await import(`../pdfTemplates/boxLabels/${size}/box_${size}_template.json`);
  const {
    schemas: [schema],
    basePdf,
  } = template;
  const myrng = new seedrandom(ic.consignment_no);
  // Generate initial two-char box letters
  const icInitChars =
    BOX_LETTER_CHARS[Math.floor(myrng.quick() * 25)] +
    BOX_LETTER_CHARS[Math.floor(myrng.quick() * 25)];
  const boxIdList = [];

  const groupedSkus = groupWith((a, b) => a.box_no === b.box_no, ic.lstsku);
  const letterBoxImg = await import(`../pdfTemplates/boxLabels/${size}/letterBoxImgb64.json`);

  const inputs = groupedSkus.map((groupedSku) => {
    const isMixedBox = groupedSku.length > 1;
    const input = {
      customerId: accountNumber,
      boxBarCode: groupedSku[0].box_barcode,
      packId: groupedSku[0].box_barcode,
      icNo: ic.consignment_no,
      boxQty: `(${Math.max(...ic.lstsku.map((s) => s.box_no))})`,
      icRefNo: ic.ref_no,
      warehouseCode: ic.to_warehouse_code,
      warehouseCodeBottom: ic.to_warehouse_code,
      boxNoBottom: groupedSku[0].box_no,
      Note: printCountryOfOrigin ? countryOfOrigin : '',
    };
    // Add box latter identifers when a box is not of mixed SKUs
    if (!isMixedBox) {
      const sku = groupedSku[0];
      // Box string consists of sku and sku qty, used to generate unique box letters
      const boxIdentifier = generateBoxIdentifier(sku, boxIdList, icInitChars);
      const skuQty = sku.plan_qty;

      if (skuQty > 1) {
        return {
          letterBox: letterBoxImg.value,
          skuCode: sku.sku_code,
          boxNo: '1',
          batchNo: sku.batch_no || ' ',
          skuQty: sku.plan_qty,
          skuPacks: `${ic.lstsku.filter((s) => s.sku_code === sku.sku_code).length || ''}`,
          skuPcs: sku.plan_qty,
          ic4Digits: takeLast(4, ic.consignment_no.split('...')[0]),
          boxIdentifier1: boxIdentifier[0],
          boxIdentifier2: boxIdentifier[1],
          ...input,
        };
      } else {
        return {
          skuIdTitle: 'SKU ID:',
          letterBox: letterBoxImg.value,
          skuCode: sku.sku_code,
          boxNo: '1',
          batchNo: sku.batch_no || ' ',
          skuQty: sku.plan_qty,
          skuBarCode: sku.sku_id,
          skuPacks: `${ic.lstsku.filter((s) => s.sku_code === sku.sku_code).length || ''}`,
          skuPcs: sku.plan_qty,
          ic4Digits: takeLast(4, ic.consignment_no.split('...')[0]),
          boxIdentifier1: boxIdentifier[0],
          boxIdentifier2: boxIdentifier[1],
          ...input,
        };
      }
    } else {
      const maxItemsPerPage = 6;
      range(0, maxItemsPerPage).forEach((i) => {
        schema[`boxNo_${i}`] = {
          ...schema.boxNo,
          position: { x: schema.boxNo.position.x, y: schema.boxNo.position.y + gapSize * i },
        };
        schema[`skuCode_${i}`] = {
          ...schema.skuCode,
          position: { x: schema.skuCode.position.x, y: schema.skuCode.position.y + gapSize * i },
        };
        schema[`batchNo_${i}`] = {
          ...schema.batchNo,
          position: { x: schema.batchNo.position.x, y: schema.batchNo.position.y + gapSize * i },
        };
        schema[`skuQty_${i}`] = {
          ...schema.skuQty,
          position: { x: schema.skuQty.position.x, y: schema.skuQty.position.y + gapSize * i },
        };
      });

      const splitedSkus = splitEvery(maxItemsPerPage, groupedSku);
      return splitedSkus.map((skus, splitIdx) => ({
        ...zipObj(
          flatten(
            skus.map((s, i) => [`boxNo_${i}`, `skuCode_${i}`, `batchNo_${i}`, `skuQty_${i}`]),
          ),
          flatten(
            skus.map((s, i) => [
              `${i + splitIdx * maxItemsPerPage + 1}`,
              s.sku_code,
              s.batch_no,
              s.plan_qty,
            ]),
          ),
        ),
        ...input,
        page: `${splitIdx + 1}/${splitedSkus.length}`,
      }));
    }
  });
  await generatePdfFromTempalte({ schemas: [schema], basePdf }, flatten(inputs));
};

const printPackageList = async (ic) => {
  const bwipjs = await import('bwip-js');
  const printJs = (await import('print-js')).default;
  const data = ic.lstsku.map((s) => ({
    ...map(sanitize, s),
    receipt: '',
    package: '',
  }));
  const canvas = document.createElement('canvas');
  bwipjs.toCanvas(canvas, {
    bcid: 'code128',
    text: ic.consignment_no,
    scale: 2,
    height: 10,
    includetext: false, // Show human-readable text
  });
  const barCodeDataUrl = canvas.toDataURL('image/png');
  printJs({
    printable: data.map((d) => ({ ...d, weight: weightGramToKg(false)(d.weight) })),
    type: 'json',
    documentTitle: 'Package List',
    properties: [
      { field: 'box_no', displayName: 'Box' },
      { field: 'sku_code', displayName: 'SKU Code' },
      { field: 'product_code', displayName: 'Product Code' },
      { field: 'weight', displayName: 'Weight' },
      { field: 'plan_qty', displayName: 'QTY' },
      { field: 'receipt', displayName: 'Receipt' },
      { field: 'package', displayName: 'package' },
    ],
    header: `
      <h2>Package List</h2>
      <div class="container">
        <div class="sub-container">
          <img src=${barCodeDataUrl} class="image">
          <h4>IC NO: ${ic.consignment_no}</h4>
          <h4>REF#: ${ic.ref_no}</h4>
        </div>
        <div class="sub-container">
            <h4>Order date: ${new Date(parseInt(ic.create_time)).toLocaleDateString()}</h4>
            <h4>Dest. warehouse: ${ic.to_warehouse_code}</h4>
            <h4>Box QTY: ${data.length || 0}</h4>
            <h4>SKU (Item/QTY): ${uniq(data.map((s) => s.sku_code)).length || 0}/${
      data.length || 0
    }</h4>
                    </div>
                      </div>
                      `,
    style:
      '.container { width: 100%; display: flex; flex-direction: row; justify-content: space-between; }; .sub-container { width : 50%; }',
    gridStyle: 'padding-right: 5px; padding-left: 5px; border: 0.5px solid lightgray;',
    font_size: '8pt',
  });
};

const generateBoxIdentifier = (sku, boxIdList, icInitChars) => {
  const boxStr = `${sku.sku_code}_${sku.plan_qty}`;
  if (!boxIdList.includes(boxStr)) {
    boxIdList.push(boxStr);
  }
  const idCharIdx = boxIdList.indexOf(boxStr);
  let boxIdentifier = '';
  if (idCharIdx < 26) {
    // Use a initial two-char box letter + single letter
    boxIdentifier = [icInitChars, BOX_LETTER_CHARS[idCharIdx]];
  } else if (idCharIdx < 26 * 26) {
    // Use a initial two-char box letter + two letters where unqiue boxes > 26
    boxIdentifier = [
      icInitChars,
      BOX_LETTER_CHARS[Math.floor(idCharIdx / 26)] + BOX_LETTER_CHARS[idCharIdx % 26],
    ];
  } else if (idCharIdx < 26 * 26 * 25) {
    // Increment second char of initial box letter where unqiue boxes > 26*26
    boxIdentifier = [
      icInitChars[0] +
        BOX_LETTER_CHARS[
          (BOX_LETTER_CHARS.indexOf(icInitChars[1]) + Math.floor(idCharIdx / (26 * 26))) % 26
        ],
      BOX_LETTER_CHARS[Math.floor(idCharIdx / 26)] + BOX_LETTER_CHARS[idCharIdx % 26],
    ];
  } else {
    // Increment first char of initial box letter where unqiue boxes > 26*26*25
    boxIdentifier = [
      BOX_LETTER_CHARS[
        (BOX_LETTER_CHARS.indexOf(icInitChars[0]) + Math.floor(idCharIdx / (26 * 26 * 25))) % 26
      ] +
        BOX_LETTER_CHARS[
          (BOX_LETTER_CHARS.indexOf(icInitChars[1]) + Math.floor(idCharIdx / (26 * 26))) % 26
        ],
      BOX_LETTER_CHARS[Math.floor(idCharIdx / 26)] + BOX_LETTER_CHARS[idCharIdx % 26],
    ];
  }
  return boxIdentifier;
};
