import React, { useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import Background from 'src/components/Background';
import { gql, useQuery } from '@apollo/client';
import { useIsMobile } from '../../core/responsive.utils';
import { Card, Divider, Avatar, Text, FAB } from 'react-native-paper';
import { Layout } from '@ui-kitten/components';
import { UIStatusWrapper } from '../../components/ui-status';
import InlineTable from '../../components/InlineTable';
import { inventoryStore, warehouseAccountStore } from '../../store';
import { flatten, isNil, merge } from 'ramda';
import { formatInventoryLocation, unifiedAlert, useIsWmsManager } from '../../core/utils/utils';
import SkuInfoEditModal from '../../components/SkuInfoEditModal';
import { paperNativeTheme } from '../../core/theme';
import { weightGramToKg } from '@ezom/library/lib/cjs/utils';
import { observer } from 'mobx-react';

const keyInfos = [
  {
    label: 'SKU ID',
    key: 'sku_id',
  },
  {
    label: 'SKU code',
    key: 'sku_code',
  },
  {
    label: 'SKU name',
    key: 'sku_name',
  },
  {
    label: 'Product code',
    key: 'product_code',
  },
  {
    label: 'Length (cm)',
    key: 'length',
  },
  {
    label: 'Width (cm)',
    key: 'width',
  },
  {
    label: 'Height (cm)',
    key: 'height',
  },
  {
    label: 'Unit of Measure',
    key: 'uom',
  },
  {
    label: 'Weight',
    key: 'weight',
    format: weightGramToKg(true),
  },
  {
    label: 'Warehouse Code',
    key: 'warehouse_code',
  },
  {
    label: 'Include Battery',
    key: 'include_battery',
  },
  {
    label: 'Note',
    key: 'remark',
  },
  {
    label: 'Saleable Stock',
    key: 'saleable_stock',
  },
  {
    label: 'Pending Stock',
    key: 'pending_stock',
  },
  {
    label: 'Available Stock',
    key: 'available_stock',
  },
  {
    label: 'On-way Stock',
    key: 'onway_stock',
  },
];

const otherInfos = [
  {
    label: 'Appearance',
    key: 'appearance',
  },
  {
    label: 'Wrapping',
    key: 'wrapping',
  },
  {
    label: 'Characteristic',
    key: 'characteristic',
  },
  {
    label: 'Logistics package',
    key: 'logistics_package',
    format: (v) => (v ? 'Yes' : 'No'),
  },
];

const SKU_QUERY = gql`
  query inventories($warehouseAccountIds: [ID!]!, $skuCodes: [String!]) {
    inventories(warehouseAccountIds: $warehouseAccountIds, skuCodes: $skuCodes) {
      inventories {
        warehouseAccountId
        product_code
        uom
        sku_id
        sku_name
        sku_code
        warehouse_code
        saleable_stock
        pending_stock
        available_stock
        onway_stock
        customer_code
        logistics_package
        width
        length
        height
        weight
        remark
        uom
        declare_country_list {
          export_country
          export_port
          export_declare
          export_declare_value
          export_currency
          country
          import_port
          import_declare
          hs_code
          export_hscode
          declare_value
          currency
          risk
        }
        batches {
          batch_no
          available_stock
          pending_stock
          onway_stock
          stockAge {
            warehouse_code
            stock_quality
            consignment_no
            warehouse_stock
            pending_stock
            batch_no
            inventory_age
            putaway_time
          }
        }
      }
    }
  }
`;

const WMS_MANAGER_SKU_QUERY = gql`
  query inventories($warehouseAccountIds: [ID!]!, $skuCodes: [String!]) {
    inventories(warehouseAccountIds: $warehouseAccountIds, skuCodes: $skuCodes) {
      inventories {
        warehouseAccountId
        product_code
        uom
        sku_id
        sku_name
        sku_code
        warehouse_code
        saleable_stock
        pending_stock
        available_stock
        onway_stock
        customer_code
        logistics_package
        width
        length
        height
        weight
        remark
        uom
        declare_country_list {
          export_country
          export_port
          export_declare
          export_declare_value
          export_currency
          country
          import_port
          import_declare
          hs_code
          export_hscode
          declare_value
          currency
          risk
        }
        batches {
          batch_no
          available_stock
          pending_stock
          onway_stock
          stockAge {
            warehouse_code
            stock_quality
            consignment_no
            warehouse_stock
            pending_stock
            batch_no
            inventory_age
            putaway_time
            location_aisle
            location_unit
            location_shelf
            location_section
            container_id
          }
        }
      }
    }
  }
`;

const SkuDetailScreen = observer(({ navigation, route }) => {
  const decodedId = (route?.params?.id && decodeURIComponent(route?.params?.id)) || null;
  const [detail, setDetail] = useState(null);
  if (route.params.id !== decodedId) {
    navigation.setParams({ id: decodedId });
    return null;
  }

  const { id, warehouseAccountId } = route.params || {};
  const isWmsManager = useIsWmsManager();
  const { loading, error, refetch } = useQuery(isWmsManager ? WMS_MANAGER_SKU_QUERY : SKU_QUERY, {
    variables: {
      warehouseAccountIds: [warehouseAccountId],
      skuCodes: [id],
    },
    // The next two lines are required to make sure onComplted is triggered on refetch
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setDetail(data.inventories?.inventories?.[0]);
    },
  });
  const stockAgeColumnByKey = merge(
    {
      batch_no: { title: 'Batch number' },
      putaway_time: {
        title: 'Putaway time',
        formatter: (key, item) => new Date(Number(item[key])).toLocaleString(),
      },
      inventory_age: { title: 'Age (days)' },
      stock_quality: { title: 'Quality' },
      warehouse_stock: { title: 'Available stock' },
      pending_stock: { title: 'Pending stock' },
      warehouse_code: { title: 'Warehouse' },
    },
    isWmsManager
      ? {
          location_aisle: {
            title: 'Location',
            formatter: (_, item) => formatInventoryLocation(item),
          },
          container_id: {
            title: 'Container',
          },
        }
      : {},
  );

  const isMobile = useIsMobile();

  const [skuInfoEditModalVisible, setSkuInfoEditModalVisible] = React.useState(false);

  const [fabOpen, setFabOpen] = React.useState(false);

  const fabActions = React.useMemo(() => {
    const actions = [
      {
        icon: 'file-edit-outline',
        label: 'Update SKU Info',
        onPress: () => setSkuInfoEditModalVisible(true),
      },
    ];
    return actions;
  }, []);

  return (
    <Background fullWidth={true}>
      <UIStatusWrapper
        status={{
          error,
          empty: !detail,
          indeterminate: loading,
        }}>
        <ScrollView style={styles.container}>
          <Card style={[styles.card, isMobile ? styles.mobileCard : styles.desktopCard]}>
            <Card.Title
              title="Common Info"
              left={(props) => <Avatar.Icon {...props} icon="information-outline" />}
            />
            <Divider />
            <Card.Content style={styles.content}>
              <Layout style={isMobile ? styles.mobileColumn : styles.desktopColumn}>
                {keyInfos.slice(0, Math.ceil(keyInfos.length / 2)).map(({ key, label, format }) => (
                  <RowDetail label={label} value={detail && detail[key]} format={format} />
                ))}
              </Layout>
              <Layout style={isMobile ? styles.mobileColumn : styles.desktopColumn}>
                {keyInfos.slice(Math.ceil(keyInfos.length / 2)).map(({ key, label, format }) => (
                  <RowDetail label={label} value={detail && detail[key]} format={format} />
                ))}
              </Layout>
            </Card.Content>
          </Card>

          <Card style={[styles.card, isMobile ? styles.mobileCard : styles.desktopCard]}>
            <Card.Title
              title="Other Info"
              left={(props) => <Avatar.Icon {...props} icon="information-outline" />}
            />
            <Divider />
            <Card.Content style={styles.content}>
              <Layout style={isMobile ? styles.mobileColumn : styles.desktopColumn}>
                {otherInfos
                  .slice(0, Math.ceil(otherInfos.length / 2))
                  .map(({ key, label, format }) => (
                    <RowDetail label={label} value={detail && detail[key]} format={format} />
                  ))}
              </Layout>
              <Layout style={isMobile ? styles.mobileColumn : styles.desktopColumn}>
                {otherInfos
                  .slice(Math.ceil(otherInfos.length / 2))
                  .map(({ key, label, format }) => (
                    <RowDetail label={label} value={detail && detail[key]} format={format} />
                  ))}
              </Layout>
            </Card.Content>
          </Card>
          {detail?.declare_country_list ? (
            <Card style={[styles.card, isMobile ? styles.mobileCard : styles.desktopCard]}>
              <Card.Title
                title="Importation info"
                left={(props) => <Avatar.Icon {...props} icon="information-outline" />}
              />
              <Divider />
              <Card.Content style={styles.content}>
                <InlineTable
                  items={detail.declare_country_list}
                  columnByKey={{
                    country: { title: 'Export to' },
                    currency: { title: 'Import declare currency' },
                    declare_value: { title: 'Import declare value' },
                    import_declare: { title: 'Import declaration' },
                    hs_code: { title: 'Import HS-CODE' },
                    export_country: { title: 'Export from' },
                    export_currency: { title: 'Export declare currency' },
                    export_declare_value: { title: 'Export declare value' },
                    export_declare: { title: 'Export declaration' },
                    export_hscode: { title: 'Export HS-CODE' },
                  }}
                />
              </Card.Content>
            </Card>
          ) : null}
          {detail?.batches ? (
            <Card style={[styles.card, isMobile ? styles.mobileCard : styles.desktopCard]}>
              <Card.Title
                title="Batch info"
                left={(props) => <Avatar.Icon {...props} icon="information-outline" />}
              />
              <Divider />
              <Card.Content style={styles.content}>
                <InlineTable
                  items={detail.batches}
                  columnByKey={{
                    batch_no: { title: 'Batch number' },
                    available_stock: { title: 'Available stock' },
                    pending_stock: { title: 'Pending stock' },
                    onway_stock: { title: 'On-way stock' },
                  }}
                />
              </Card.Content>
            </Card>
          ) : null}
          {detail?.batches[0]?.stockAge ? (
            <Card style={[styles.card, isMobile ? styles.mobileCard : styles.desktopCard]}>
              <Card.Title
                title="Stock Age"
                left={(props) => <Avatar.Icon {...props} icon="information-outline" />}
              />
              <Divider />
              <Card.Content style={styles.content}>
                <InlineTable
                  items={flatten(detail.batches.map((b) => b.stockAge))}
                  columnByKey={stockAgeColumnByKey}
                />
              </Card.Content>
            </Card>
          ) : null}
        </ScrollView>
        <FAB.Group
          visible={true}
          isFabVisible={true}
          fabStyle={{ backgroundColor: paperNativeTheme.colors.primary }}
          open={fabOpen}
          icon={fabOpen ? 'chevron-down' : 'chevron-up'}
          actions={fabActions}
          onStateChange={({ open }) => setFabOpen(open)}
        />
        <SkuInfoEditModal
          visible={skuInfoEditModalVisible}
          setVisible={setSkuInfoEditModalVisible}
          isWmsManager={isWmsManager}
          sku={detail}
          onSubmit={async (values) => {
            try {
              await inventoryStore.modifySkuInfo({
                warehouseAccountId: detail.warehouseAccountId,
                skuId: detail.sku_id,
                ...(isWmsManager && {
                  length: values.length,
                  width: values.width,
                  height: values.height,
                  weight: values.weight,
                  product_code: values.product_code,
                  uom: values.uom,
                }),
                declare_country_list: values.declare_country_list,
              });
              setSkuInfoEditModalVisible(false);
              refetch();
            } catch (e) {
              unifiedAlert('Error: ' + e.message);
            }
          }}
        />
      </UIStatusWrapper>
    </Background>
  );
});

const RowDetail = ({ label, value, format }) => (
  <Layout style={styles.desktopRow}>
    <Text
      style={[
        styles.cell,
        {
          width: '33%',
        },
      ]}>
      <strong>
        <i>{label}</i>
      </strong>
    </Text>
    <Text
      style={[
        styles.cell,
        {
          width: '66%',
          color: isNil(value) && '#DDD',
        },
      ]}>
      {isNil(value) ? 'N/A' : (format && format(value)) || value}
    </Text>
  </Layout>
);

const styles = StyleSheet.create({
  card: {
    marginHorizontal: '1em',
    marginVertical: '1em',
  },

  desktopCard: {
    marginHorizontal: '0.8em',
  },

  mobileCard: {
    marginHorizontal: '1em',
    marginBottom: '1em',
  },

  menuListIcons: {
    margin: 0,
    alignItems: 'start',
  },

  desktopRow: {
    flex: 1,
    flexDirection: 'row',
    marginVertical: '1em',
    justifyContent: 'space-between',
    minHeight: '20px',
  },

  mobileRow: {
    flex: 1,
    flexDirection: 'col',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginVertical: '1em',
  },

  cell: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: '0.2em',
  },

  desktopColumn: {
    width: '50%',
    flex: 1,
    flexDirection: 'column',
  },

  mobileColumn: {
    width: '100%',
  },

  content: {
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },

  container: {
    maxHeight: 'calc(100vh - 120px)',
    overflowY: 'auto',
  },
});

export default SkuDetailScreen;
