import { Button, Layout, Text } from '@ui-kitten/components';
import { Formik } from 'formik';
import * as React from 'react';
import { Card, Modal, Portal } from 'react-native-paper';
import { TextInputField } from './input/TextInputField';
import * as Yup from 'yup';

export default ({ onSubmit, onDismiss, visible }) => {
  return (
    <Portal>
      <Modal visible={visible} onDismiss={onDismiss}>
        <Formik
          initialValues={{
            exceptionDescription: '',
          }}
          isInitialValid={false}
          validationSchema={Yup.object({
            exceptionDescription: Yup.string().required('Required').min(3),
          })}
          onSubmit={async (values, formikActions) => {
            formikActions.setSubmitting(true);
            await onSubmit(values.exceptionDescription);
            // Important: Make sure to setSubmitting to false so our loading indicator
            // goes away.
            formikActions.setSubmitting(false);
            onDismiss();
            formikActions.resetForm();
          }}>
          {(props) => (
            <Card disabled={true} style={{ alignSelf: 'center', padding: 10 }}>
              <Text category="p1" style={{ marginBottom: 10 }}>
                Please specify reasons for this exception, like incorrect addresss, insufficient
                stock, etc.
              </Text>
              <Text category="s1" style={{ marginBottom: 10 }}>
                Items from this OC will be removed from this picking task, make sure you return the
                picked items.
              </Text>
              <TextInputField multiline autoFocus name="exceptionDescription" {...props} />
              <Layout style={{ flexDirection: 'row' }}>
                <Button
                  status="danger"
                  onPress={props.handleSubmit}
                  disabled={!props.isValid || props.isSubmitting}
                  style={{
                    marginVertical: 10,
                    marginHorizontal: 2,
                  }}>
                  Submit
                </Button>
                <Button
                  status="basic"
                  onPress={onDismiss}
                  style={{
                    marginVertical: 10,
                    marginHorizontal: 2,
                  }}>
                  Cancel
                </Button>
              </Layout>
            </Card>
          )}
        </Formik>
      </Modal>
    </Portal>
  );
};
