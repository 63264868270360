// number-util.js
const DIGITS_AFTER_DECIMAL = 3;

export const toFixedNumber = (value) => {
  if (value == null) {
    return NaN;
  }

  const numericValue = Number(value);

  return isNaN(numericValue) ? NaN : numericValue.toFixed(DIGITS_AFTER_DECIMAL);
};
