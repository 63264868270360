import React, { memo, useEffect, useMemo, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import Table from './Table';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';
import { zipObj, adjust, flatten } from 'ramda';
import { sub } from 'date-fns';
import { UIStatusWrapper } from './ui-status';
import { Button, CheckBox, Layout, Modal, Radio, RadioGroup, Text } from '@ui-kitten/components';
import { Card } from 'react-native-paper';
import Background from './Background';
import {
  FULFILL_ORDER,
  NETWORK_CALL_STATUS,
  ORDER_FULFILLMENT_STATUSES,
  unixMillisecondToDate,
} from '../core/utils/utils';
import { OC_STATUS_MAP } from '@ezom/library/lib/cjs/constants';
import { getCourierCompany } from '../core/utils/4pxCourierNameMap';
import { getCourierTrackignUrl } from '../core/utils/4pxCourierTrackingMap';
import { StyleSheet } from 'react-native';
import { AddTrackingProgressListModal } from './AddTrackingProgressListModal';
import { useIsFocused } from '@react-navigation/core';
import { courierStore, skuMappingStore, storeStore, warehouseAccountStore } from '../store';
import { observer } from 'mobx-react';
import { lineItemsFormatter } from '../screens';
import { findMatchedSkuMappings } from '@ezom/library/lib/cjs/skuMapUtils';
import { ezTheme } from '../core/theme';

const FULFILLABLE_ORDER_STATUS = 'open';

const ORDERS = gql`
  query ordersToFulfill(
    $storeIds: [ID!]!
    $fulfillmentStatuses: [FulfillmentStatus!]!
    $statuses: [OrderStatus!]!
  ) {
    orders(storeIds: $storeIds, fulfillmentStatuses: $fulfillmentStatuses, statuses: $statuses) {
      id
      number
      provider
      storeId
      fulfillmentStatus
      lineItems {
        id
        platformId
        storeId
        sku
        name
        variant
        variantId
        quantity
        fulfillableQuantity
      }
      shippingChannel
    }
  }
`;

const OUTBOUNDS = gql`
  query outbounds(
    $warehouseAccountIds: [ID!]!
    $create_time_start: Float
    $create_time_end: Float
  ) {
    outbounds(
      warehouseAccountIds: $warehouseAccountIds
      create_time_start: $create_time_start
      create_time_end: $create_time_end
    ) {
      consignments {
        consignment_type
        consignment_no
        ref_no
        sales_no
        remark
        shipping_no
        status
        logistics_product_code
        logistics_provider
        create_time
        complete_time
        outboundlist_sku {
          sku_code
          qty
          stock_quality
          sku_id
          sku_name
          product_code
        }
      }
    }
  }
`;

const INTERVAL = 14;

const INVALID_OC_STATUS = ['N', 'E', 'X', 'D'];

const FulfillmentTable = observer(() => {
  const navigation = useNavigation();
  const [enabledStoreIds, setEnabledStoreIds] = useState([]);
  const [enabledStores, setEnabledStores] = useState([]);
  useEffect(() => {
    (async () => {
      const stores = await storeStore.getEnabledStores();
      setEnabledStoreIds(stores.map((s) => s.id));
    })();
  }, [storeStore]);
  const {
    data: ordersData,
    loading: ordersLoading,
    error: ordersError,
    refetch: refetchOrders,
  } = useQuery(ORDERS, {
    variables: {
      storeIds: enabledStoreIds,
      fulfillmentStatuses: [
        ORDER_FULFILLMENT_STATUSES.unfulfilled,
        ORDER_FULFILLMENT_STATUSES.in_progress,
        ORDER_FULFILLMENT_STATUSES.partial,
      ],
      statuses: [FULFILLABLE_ORDER_STATUS],
    },
    fetchPolicy: 'no-cache',
    skip: !enabledStoreIds || enabledStoreIds?.length < 1,
  });
  const orders = useMemo(() => ordersData?.orders || [], [ordersData]);
  const [endDate, setEndDate] = useState(new Date());
  const createTimeStart = useMemo(() => sub(endDate, { days: INTERVAL }).getTime(), [endDate]);
  const createTimeEnd = useMemo(() => endDate.getTime(), [endDate]);
  const {
    data: outboundsData,
    loading: outboundsLoading,
    error: outboundsError,
  } = useQuery(OUTBOUNDS, {
    variables: {
      warehouseAccountIds: warehouseAccountStore.warehouseAccountIds,
      create_time_start: createTimeStart,
      create_time_end: createTimeEnd,
    },
    fetchPolicy: 'no-cache',
  });
  const outbounds = useMemo(
    () =>
      (outboundsData?.outbounds?.consignments || []).filter(
        (oc) => oc.shipping_no && !INVALID_OC_STATUS.includes(oc.status),
      ),
    [outboundsData],
  );
  const outboundRefNoMap = useMemo(() => {
    const filteredOutbounds = outbounds.filter((o) => !!o.ref_no);
    if (filteredOutbounds.length) {
      return zipObj(
        filteredOutbounds.map((o) => o.ref_no),
        filteredOutbounds,
      );
    }
    return {};
  }, [outbounds]);
  const outboundSalesNoMap = useMemo(() => {
    const filteredOutbounds = outbounds.filter((o) => !!o.sales_no);
    return zipObj(
      filteredOutbounds.map((o) => o.sales_no),
      filteredOutbounds,
    );
  }, [outbounds]);

  const isFocused = useIsFocused();
  // Refetch data when screen is focused
  useEffect(() => {
    if (isFocused) {
      skuMappingStore.fetchItems();
    }
  }, [isFocused]);

  const orderConsigmentList = useMemo(() => {
    const list = [];

    const getFulfilledLineItems = (outbound, lineItems) => {
      const outboundSkuQtyBySku = outbound.outboundlist_sku.reduce((a, { sku_code, qty }) => {
        if (a.has(sku_code)) {
          a.set(sku_code, a.get(sku_code) + qty);
        } else {
          a.set(sku_code, qty);
        }
        return a;
      }, new Map());

      return lineItems
        .map((lineItem) => {
          const requiredSkus = findMatchedSkuMappings(
            lineItem.sku,
            lineItem.storeId,
            lineItem.variantId || lineItem.platformId,
            outbound.from_warehouse_code,
            skuMappingStore.items,
            true,
          );

          let fulfilledQuantity = undefined;
          for (let i = 0; i < requiredSkus?.length; i++) {
            const rs = requiredSkus[i];
            if (outboundSkuQtyBySku.has(rs.warehouseSku)) {
              const canFulfillQty = Math.floor(outboundSkuQtyBySku.get(rs.warehouseSku) / rs.qty);

              if (canFulfillQty === 0) {
                return undefined;
              } else {
                if (canFulfillQty < lineItem.fulfillableQuantity)
                  console.warn(
                    `There are not enough SKUs (warehouse code: ${rs.warehouseSku}), required; ${
                      lineItem.fulfillableQuantity
                    }, actual: ${canFulfillQty}, in outbound ${outbound.id} for order ${
                      outbound.sales_no || outbound.ref_no
                    } to fully fulfill line item ${lineItem.name}`,
                  );

                fulfilledQuantity = Math.min(
                  fulfilledQuantity || Number.MAX_SAFE_INTEGER,
                  canFulfillQty,
                );
              }
            } else {
              return undefined;
            }
          }

          return fulfilledQuantity ? { ...lineItem, fulfilledQuantity } : undefined;
        })
        .filter((l) => l !== undefined);
    };

    if (orders.length) {
      orders.forEach((o) => {
        if (outboundRefNoMap[o.number.toString()]) {
          list.push({
            order: o,
            outbound: outboundRefNoMap[o.number],
            fulfilledLineItems: getFulfilledLineItems(outboundRefNoMap[o.number], o.lineItems),
          });
        } else if (outboundSalesNoMap[o.number.toString()]) {
          list.push({
            order: o,
            outbound: outboundSalesNoMap[o.number],
            fulfilledLineItems: getFulfilledLineItems(
              outboundSalesNoMap[o.number.toString()],
              o.lineItems,
            ),
          });
        }
      });
    }
    return list;
  }, [outboundSalesNoMap, outboundRefNoMap, orders, skuMappingStore.items]);

  const [couriers, setCouriers] = useState([]);
  useEffect(() => {
    (async () => {
      const courierList = await Promise.all(
        enabledStores.map((store) => courierStore.getAllStoreWhiteListedCouriers(store.id)),
      );
      setCouriers(flatten(courierList));
    })();
  }, [enabledStores, courierStore]);

  const loading = useMemo(
    () => storeStore.loading || ordersLoading || outboundsLoading,
    [storeStore.loading, ordersLoading, outboundsLoading],
  );

  const error = useMemo(
    () => storeStore.error || ordersError || outboundsError,
    [storeStore.error, ordersError, outboundsError],
  );

  const [trackingSelectionModalVisible, setTrackingSelectionModalVisible] = useState(false);
  const [addTrackingModalVisible, setAddTrackingModalVisible] = useState(false);

  const [fulfillOrder, { loading: mutationLoading, error: mutationError }] =
    useMutation(FULFILL_ORDER);
  const [trackingRequests, setTrackingRequests] = useState([]);
  const [orderNumbersToFulfill, setOrderNumbersToFulfill] = useState(new Set());

  const startFulfilling = async (trackingOption, notifyCustomer) => {
    const orderConsignments = orderConsigmentList.filter((oc) =>
      orderNumbersToFulfill.has(oc.order.number),
    );
    setTrackingRequests(
      orderConsignments.map((oc) => ({
        status: NETWORK_CALL_STATUS.PENDING,
        orderNumber: oc.order.number,
        errMsg: '',
      })),
    );
    for (const [index, oc] of orderConsignments.entries()) {
      try {
        const {
          data: {
            fulfillOrder: { userErrors },
          },
        } = await fulfillOrder({
          variables: {
            orderId: oc.order.id,
            storeId: oc.order.storeId,
            fulfilledLineItems: oc.fulfilledLineItems.map((item) => ({
              id: item.id,
              platformId: item.platformId,
              quantity: item.fulfillableQuantity,
            })),
            trackingInfo: {
              company:
                getCourierCompany(
                  oc.outbound.logistics_provider || oc.outbound.logistics_product_code,
                  oc.order.provider,
                ) ||
                couriers.find(
                  (c) => c.logistics_product_code === oc.outbound.logistics_product_code,
                )?.logistics_provider ||
                couriers.find(
                  (c) => c.logistics_product_code === oc.outbound.logistics_product_code,
                )?.logistics_product_name_en ||
                '',
              number: oc.outbound[TRACKING_NUMBER_KEY[trackingOption]] || '',
              url: getTrackingUrl(trackingOption, oc.outbound) || '',
            },
            notifyCustomer,
          },
        });
        if (!userErrors || userErrors.length === 0) {
          setTrackingRequests((previousTrackingRequests) => {
            return adjust(
              index,
              (tracking) => ({ ...tracking, status: NETWORK_CALL_STATUS.SUCCESS }),
              previousTrackingRequests,
            );
          });
        } else {
          setTrackingRequests((previousTrackingRequests) => {
            return adjust(
              index,
              (tracking) => ({
                ...tracking,
                status: NETWORK_CALL_STATUS.FAILED,
                errMsg: JSON.stringify(userErrors),
              }),
              previousTrackingRequests,
            );
          });
        }
      } catch (e) {
        setTrackingRequests((previousTrackingRequests) => {
          return adjust(
            index,
            (tracking) => ({
              ...tracking,
              status: NETWORK_CALL_STATUS.FAILED,
              errMsg: JSON.stringify(e),
            }),
            previousTrackingRequests,
          );
        });
        throw e;
      }
    }
  };

  const uiStatus = useMemo(
    () => ({
      error: error,
      indeterminate: loading,
      empty: orderConsigmentList.length === 0,
    }),
    [error, loading, orderConsigmentList.length],
  );
  return (
    <Background fullWidth={true}>
      <Card>
        <UIStatusWrapper
          status={uiStatus}
          emptyDataMsg="You can add tracking numbers and fulfill orders in bulk when those orders have an associated outbound consignment.">
          <Table
            rowOnClick={(oc) => {
              navigation.navigate('OrderDetailScreen', {
                id: oc.order.id,
                storeId: oc.order.storeId,
                consignmentNumbers: oc.outbound.consignment_no,
              });
            }}
            items={orderConsigmentList}
            getId={(item) => item.order.number}
            displayKeys={[
              'order.number',
              'order.fulfillmentStatus',
              'fulfilledLineItems',
              'outbound.status',
              'outbound.consignment_no',
              'outbound.shipping_no',
              'outbound.logistics_product_code',
              'outbound.create_time',
              'outbound.complete_time',
            ]}
            titleByKey={{
              'order.number': 'Order',
              'order.fulfillmentStatus': 'Order status',
              'outbound.status': 'Consignment status',
              'outbound.consignment_no': 'Consignment No.',
              'outbound.shipping_no': 'Tracking No.',
              'outbound.logistics_product_code': 'Courier',
              'outbound.create_time': 'Created',
              'outbound.complete_time': 'Completed',
              fulfilledLineItems: 'Fulfilled items',
            }}
            formatterByKey={{
              'outbound.status': (v) => OC_STATUS_MAP[v] || v,
              'outbound.logistics_product_code': (v) =>
                couriers.find((c) => c.logistics_product_code === v)?.logistics_product_name_en ||
                v,
              'outbound.create_time': unixMillisecondToDate,
              'outbound.complete_time': unixMillisecondToDate,
              fulfilledLineItems: lineItemsFormatter,
            }}
            styleByKey={{
              firstColumn: { flex: '1 1 0' },
              'order.number': { flex: '0.5 1 0' },
              'order.fulfillmentStatus': { flex: '1 1 0' },
              fulfilledLineItems: { flex: '4 1 0' },
              'outbound.status': { flex: '1 1 0' },
              'outbound.consignment_no': { flex: '1.5 1 0' },
              'outbound.shipping_no': { flex: '1.5 1 0' },
              'outbound.logistics_product_code': { flex: '0.8 1 0' },
              'outbound.create_time': { flex: '0.8 1 0' },
              'outbound.complete_time': { flex: '0.8 1 0' },
            }}
            isFabVisible={useIsFocused()}
            fabActions={(selectedOrderNumbers) => {
              const actions = [];
              if (selectedOrderNumbers.size > 0) {
                actions.push({
                  icon: 'playlist-check',
                  label: 'Fulfill',
                  onPress: () => {
                    setTrackingSelectionModalVisible(true);
                    setOrderNumbersToFulfill(selectedOrderNumbers);
                  },
                });
              }

              return actions;
            }}
          />
        </UIStatusWrapper>
        {createTimeEnd !== undefined && !loading && !error ? (
          <Button
            appearance="ghost"
            status="info"
            onPress={() => setEndDate(sub(endDate, { days: INTERVAL }))}>
            {`Click to load consignments from ${sub(endDate, {
              days: INTERVAL * 2,
            }).toLocaleDateString()}  to ${sub(endDate, {
              days: INTERVAL,
            }).toLocaleDateString()}`}
          </Button>
        ) : null}
      </Card>
      <TrackingSelectionModal
        setVisible={setTrackingSelectionModalVisible}
        visible={trackingSelectionModalVisible}
        onSubmit={async (trackingOption, notifyCustomer) => {
          setTrackingSelectionModalVisible(false);
          setAddTrackingModalVisible(true);
          await startFulfilling(trackingOption, notifyCustomer);
        }}
      />
      <AddTrackingProgressListModal
        trackingRequests={trackingRequests}
        visible={addTrackingModalVisible}
        onDismiss={() => {
          setAddTrackingModalVisible(false);
          refetchOrders();
        }}
      />
    </Background>
  );
});

const TRACKING_OPTIONS = {
  COURIER: 'COURIER',
  CONSIGNMENT: 'CONSIGNMENT',
  NONE: 'NONE',
};

const TRACKING_NUMBER_KEY = {
  [TRACKING_OPTIONS.NONE]: 'none',
  [TRACKING_OPTIONS.CONSIGNMENT]: 'consignment_no',
  [TRACKING_OPTIONS.COURIER]: 'shipping_no',
};

const getTrackingUrl = (trackingOption, outbound) => {
  switch (trackingOption) {
    case TRACKING_OPTIONS.NONE:
      return ``;
    case TRACKING_OPTIONS.COURIER:
      return getCourierTrackignUrl(
        outbound.logistics_provider ?? outbound.logistics_product_code,
        outbound.shipping_no,
      );
    case TRACKING_OPTIONS.CONSIGNMENT:
      return `https://track.4px.com/#/result/0/${outbound.consignment_no}`;
  }
  return '';
};

const TrackingSelectionModal = ({ setVisible, visible, onSubmit }) => {
  const choices = [
    {
      key: TRACKING_OPTIONS.COURIER,
      text: 'Courier tracking number',
      exampleUrl: 'https://auspost.com.au/mypost/track/#/details/G1234567898',
    },
    {
      key: TRACKING_OPTIONS.CONSIGNMENT,
      text: 'Consignment number',
      exampleUrl: 'https://track.4px.com/#/result/0/OC1234567890',
    },
    {
      key: TRACKING_OPTIONS.NONE,
      text: 'No tracking code',
      exampleUrl: '',
    },
  ];
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [notifyCustomer, setNotifyCustomer] = useState(true);
  return (
    <Modal
      visible={visible}
      backdropStyle={styles.backdrop}
      onBackdropPress={() => setVisible(false)}>
      <Layout style={styles.modal}>
        <Text category="h6">Select a tracking option</Text>
        <RadioGroup selectedIndex={selectedIndex} onChange={(index) => setSelectedIndex(index)}>
          {choices.map((c) => (
            <Radio key={c.key}>
              <Layout>
                <Text category="p1">{c.text}</Text>
                <Text category="p2" appearance="hint">
                  {(c.exampleUrl && `Example URL: ${c.exampleUrl}`) || ''}
                </Text>
              </Layout>
            </Radio>
          ))}
        </RadioGroup>
        <CheckBox
          size="medium"
          style={{ marginVertical: 4 }}
          checked={notifyCustomer}
          onChange={(checked) => setNotifyCustomer(checked)}>
          Notify customer?
        </CheckBox>

        <Layout style={styles.desktopButtonContainer}>
          <Button status="danger" onPress={() => setVisible(false)} style={styles.button}>
            Back
          </Button>
          <Button
            status="primary"
            onPress={() => onSubmit(choices[selectedIndex].key, notifyCustomer)}
            style={styles.button}>
            Submit
          </Button>
        </Layout>
      </Layout>
    </Modal>
  );
};

const styles = StyleSheet.create({
  button: {
    marginVertical: 10,
    marginHorizontal: 2,
  },
  desktopButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '80%',
    minWidth: 400,
  },
  backdrop: {
    backgroundColor: ezTheme.backdropModalColor,
  },
  modal: {
    padding: 10,
    minWidth: '600px',
    alignItems: 'center',
  },
});

export default memo(FulfillmentTable);
