import React, { memo } from 'react';
import { ImageBackground, StyleSheet, KeyboardAvoidingView, Text } from 'react-native';

const VERSION = process.env.VERSION;
const SHORT_COMMIT_HASH = process.env.SHORT_COMMIT_HASH;

const Background = ({ children, fullWidth, style }) => (
  <ImageBackground
    source={require('../assets/background_dot.png')}
    resizeMode="repeat"
    style={styles.background}>
    <KeyboardAvoidingView
      style={[fullWidth ? styles.fullWidthContainer : styles.container, style]}
      behavior="padding">
      {children}

      <Text style={styles.footer}>
        <small>© {new Date().getFullYear()} EzOM, </small>
        <small>
          Ver {VERSION}
          <span style={{ display: 'none' }}>{` (${SHORT_COMMIT_HASH})`}</span>
        </small>
      </Text>
    </KeyboardAvoidingView>
  </ImageBackground>
);

const styles = StyleSheet.create({
  background: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
  fullWidthContainer: {
    margin: '0.8em',
  },
  container: {
    flex: 1,
    padding: 20,
    width: '100%',
    maxWidth: 700,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footer: {
    marginTop: '1em',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

export default memo(Background);
