import React from 'react';
import { Text, Card, List, ListItem, Layout, Modal } from '@ui-kitten/components';
import { View } from 'react-native';
import { ezTheme } from '../core/theme';

export const LineItemWithoutSkuWarningModal = ({ visible, onBackdropPress, items }) => {
  return (
    <Modal
      visible={visible}
      backdropStyle={{ backgroundColor: ezTheme.backdropModalColor }}
      onBackdropPress={onBackdropPress}>
      <Layout style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Card
          disabled={true}
          header={(props) => (
            <View {...props}>
              <Text category="h5">Products with missing SKU</Text>
            </View>
          )}>
          <List
            style={{ maxHeight: '50vh' }}
            data={items}
            renderItem={({ item }) => (
              <ListItem
                title={`Order ${item.orderNumber}`}
                description={`${item.name}${item.variant ? ` : ${item.variant}` : ''}`}
                key={item.orderNumber}
              />
            )}></List>
        </Card>
      </Layout>
    </Modal>
  );
};
