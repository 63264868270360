import React from 'react';
import { Button, Card, Divider, Icon, Layout, ListItem, Text } from '@ui-kitten/components';
import { observer } from 'mobx-react';
import { ScrollView, StyleSheet, useWindowDimensions, View } from 'react-native';
import { sanitize } from '../core/utils/utils';
import { paperNativeTheme } from 'src/core/theme';

const isSubmitShipmentOrdersEnabled = (shipmentOrders, errors) => {
  if (
    errors === undefined ||
    errors === null ||
    (typeof errors === 'object' && Object.keys(errors).length === 0)
  ) {
    return shipmentOrders.length > 0;
  }
  return false;
};

export default observer(({ shipmentOrders, errors, onSubmit, onClearOrders }) => {
  const windowHeight = useWindowDimensions().height;
  return (
    <View>
      <Header
        errors={errors}
        shipmentOrders={shipmentOrders}
        onSubmit={onSubmit}
        onPressBack={onClearOrders}
      />
      <Divider />
      <ScrollView style={{ maxHeight: windowHeight - 150 }}>
        {shipmentOrders.length > 0
          ? shipmentOrders.map((shipmentOrder, index) => (
              <ListItem key={shipmentOrder.ref_no}>
                <ShipmentOrderDetail
                  {...shipmentOrder}
                  rowNumber={index + 1}
                  error={errors[index]}
                />
              </ListItem>
            ))
          : null}
      </ScrollView>
    </View>
  );
});

const ShipmentOrderDetail = observer(
  ({ rowNumber, ref_no, pickupAddress, receiverAddress, sales_no, shipment, error }) => (
    <Card style={styles.card}>
      <Layout style={styles.row}>
        <Layout style={[styles.column, { minWidth: '40pm' }]}>
          <Text category="p1">{rowNumber}</Text>
        </Layout>
        <Layout style={[styles.column, { minWidth: '30px' }]}>
          {error ? <ErrorBadge /> : <TickBadge />}
        </Layout>
        <Layout style={[styles.column, { minWidth: '80px' }]}>
          <Text category="p1">Ref No: {ref_no}</Text>
          <Text category="p1">Sales No: {sales_no}</Text>
        </Layout>
        <Layout style={[styles.column, { minWidth: '300px' }]}>
          <Text category="p2">Pick-up Address:</Text>
          <Text category="p2">
            {sanitize(pickupAddress?.name)} {sanitize(pickupAddress?.company)}
            {sanitize(pickupAddress?.house_number)} {sanitize(pickupAddress?.street)}{' '}
            {sanitize(pickupAddress?.district)} {sanitize(pickupAddress?.city)}{' '}
            {sanitize(pickupAddress?.state)} {sanitize(pickupAddress?.countryCode)}{' '}
            {sanitize(pickupAddress?.post_code)}
          </Text>
          <Text category="p2">Email: {sanitize(pickupAddress?.email)} </Text>
          <Text category="p2">Phone: {sanitize(pickupAddress?.phone)} </Text>
        </Layout>
        <Layout style={[styles.column, { minWidth: '300px' }]}>
          <Text category="p2">Receiver Address:</Text>
          <Text category="p2">
            {sanitize(receiverAddress?.name)} {sanitize(receiverAddress?.company)}
            {sanitize(receiverAddress?.house_number)} {sanitize(receiverAddress?.street)}{' '}
            {sanitize(receiverAddress?.district)} {sanitize(receiverAddress?.city)}{' '}
            {sanitize(receiverAddress?.state)} {sanitize(receiverAddress?.countryCode)}{' '}
            {sanitize(receiverAddress?.post_code)}
          </Text>
          <Text category="p2">Email: {sanitize(receiverAddress?.email)} </Text>
          <Text category="p2">Phone: {sanitize(receiverAddress?.phone)} </Text>
        </Layout>
        <Layout style={[styles.column, { minWidth: '40px' }]}>
          <Text category="p1">Courier: {shipment.courier}</Text>
        </Layout>
        <Layout style={[styles.column, { minWidth: '200px' }]}>
          <Text>Items</Text>
          {Array.isArray(shipment.items) &&
            shipment.items.map(
              (
                {
                  description,
                  qty,
                  weight,
                  length,
                  width,
                  height,
                  declare,
                  declareValue,
                  hscode,
                  countryOfOrigin,
                },
                index,
              ) => (
                <>
                  <Text key={`sku-${index}`}>
                    {qty} x ({description}, {weight}kg, {length}cm, {width}cm, {height}cn)
                  </Text>
                  <Text key={`export-${index}`}>
                    {declare} {declareValue} {hscode} {countryOfOrigin}
                  </Text>
                </>
              ),
            )}
        </Layout>
      </Layout>
      <Layout style={{ alignItems: 'flex-end' }}>
        {error && (
          <Text category="p1" status="danger">
            {error.message}
          </Text>
        )}
        {error &&
          error.errors.map((errMsg, i) => (
            <Text key={i} category="p2" status="danger">
              {errMsg}
            </Text>
          ))}
      </Layout>
    </Card>
  ),
);

const UploadIcon = (props) => <Icon {...props} name="upload-outline" />;
const ReuploadIcon = (props) => <Icon {...props} name="flip-2-outline" />;

const Header = observer(({ errors, onSubmit, onPressBack, shipmentOrders }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  return (
    <Layout style={[styles.header]}>
      <Text style={{ marginBottom: '0.2em', width: '40%' }} category="h6">
        {shipmentOrders.length || 0} Courier Shipment Order(s)
      </Text>
      <Text status="danger" style={styles.error} category="h6">
        {(typeof errors === 'object' && Object.keys(errors).length) || 0} contains error(s)
      </Text>
      <Button
        appearance="ghost"
        status="warning"
        onPress={onPressBack}
        accessoryLeft={ReuploadIcon}>
        Re-upload
      </Button>
      <Button
        status="primary"
        disabled={!isSubmitShipmentOrdersEnabled(shipmentOrders, errors) || isSubmitting}
        appearance="ghost"
        accessoryLeft={UploadIcon}
        onPress={async () => {
          setIsSubmitting(true);
          await onSubmit();
          setIsSubmitting(false);
        }}>
        Upload
      </Button>
    </Layout>
  );
});

const TickBadge = () => (
  <Icon
    name="checkmark-circle-2-outline"
    style={{ width: 20, height: 20, fill: paperNativeTheme.colors.success }}
  />
);

const ErrorBadge = () => (
  <Icon
    name="alert-circle-outline"
    style={{ width: 20, height: 20, fill: paperNativeTheme.colors.danger }}
  />
);

const styles = StyleSheet.create({
  error: { marginBottom: '0.2em', width: '40%' },
  row: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transpa',
  },
  header: {
    marginHorizontal: '0.8em',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    width: '100%',
  },

  modalLayout: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: { marginHorizontal: '0.5em' },
});
