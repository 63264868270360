import { Portal } from 'react-native-paper';
import React from 'react';
import { Linking, Platform, StyleSheet, View } from 'react-native';
import {
  List as KittenList,
  Button as KittenButton,
  ListItem,
  Divider as KittenDivider,
  OverflowMenu,
  Icon,
  MenuItem,
} from '@ui-kitten/components';
import { Text } from '@ui-kitten/components';
import { gql, useMutation } from '@apollo/client';
import AwesomeAlert from 'react-native-awesome-alerts';
import TrackingEditor from './TrackingEditor';
import { unifiedAlert } from '../core/utils/utils';

const styles = StyleSheet.create({
  icon: {
    width: 15,
    height: 15,
  },
});

const UPDATE_TRACKING = gql`
  mutation updateTracking(
    $id: ID!
    $storeId: ID!
    $trackingNumber: String!
    $trackingUrl: String
    $notes: String
  ) {
    updateTracking(
      id: $id
      storeId: $storeId
      trackingNumber: $trackingNumber
      trackingUrl: $trackingUrl
      notes: $notes
    ) {
      id
    }
  }
`;

const DELETE_TRACKING = gql`
  mutation deleteTracking($id: ID!, $storeId: ID!) {
    deleteTracking(id: $id, storeId: $storeId)
  }
`;

export default ({ trackings, onTrackingsChanged, storeId }) => {
  const [visibleMenuId, setVisibleMenuId] = React.useState(null);

  const [trackingToDelete, setTrackingToDelete] = React.useState(null);
  const [trackingToUpdate, setTrackingToUpdate] = React.useState(null);
  const [showDeleteAlert, setShowDeleteAlert] = React.useState(false);
  const [trackingInFocus, setTrackingInFocus] = React.useState(null);

  const [deleteTracking] = useMutation(DELETE_TRACKING);
  const [updateTracking] = useMutation(UPDATE_TRACKING);

  return (
    <View>
      <KittenList
        data={trackings}
        ItemSeparatorComponent={KittenDivider}
        renderItem={({ item }) =>
          item.id === trackingToUpdate ? (
            <TrackingEditor
              initialValues={item}
              onCancel={() => setTrackingToUpdate(null)}
              onSubmit={async (values) => {
                try {
                  await updateTracking({
                    variables: {
                      id: item.id,
                      storeId: storeId,
                      notes: values.notes,
                      trackingNumber: values.trackingNumber,
                      trackingUrl: values.trackingUrl,
                    },
                  });
                  await onTrackingsChanged();
                  setTrackingToUpdate(null);
                } catch (e) {
                  const msg = 'Error! ' + JSON.stringify(e);
                  unifiedAlert(msg);
                }
              }}
            />
          ) : (
            <ListItem
              accessoryRight={(props) => (
                <Text category="c2" appearance="hint">
                  {item.authorName}
                </Text>
              )}
              accessoryLeft={(props) => (
                <OverflowMenu
                  anchor={() => (
                    <KittenButton
                      size="tiny"
                      style={styles.btn}
                      appearance="ghost"
                      status="danger"
                      onPress={(e) => {
                        e.stopPropagation();
                        setVisibleMenuId(item.id);
                      }}
                      accessoryLeft={() => (
                        <Icon name="more-vertical-outline" style={styles.icon} />
                      )}
                    />
                  )}
                  visible={visibleMenuId === item.id}
                  onSelect={(idx) => {
                    setVisibleMenuId(null);
                  }}
                  onBackdropPress={() => setVisibleMenuId(null)}>
                  <MenuItem
                    title="Edit"
                    onPress={() => {
                      setVisibleMenuId(null);
                      setTrackingToUpdate(item.id);
                    }}
                  />
                  <MenuItem
                    title="Delete"
                    onPress={() => {
                      setVisibleMenuId(null);
                      setShowDeleteAlert(true);
                      setTrackingToDelete(item);
                    }}
                  />
                </OverflowMenu>
              )}
              title={() => (
                <Text
                  status="info"
                  category="s2"
                  onPress={() => {
                    if (Platform.OS === 'web') {
                      window.open(item.trackingUrl, '_blank');
                    } else {
                      Linking.openURL(item.trackingUrl);
                    }
                  }}>
                  {item.trackingNumber}
                </Text>
              )}
              description={() => <Text category="p1">{item.notes}</Text>}></ListItem>
          )
        }
      />
      <AwesomeAlert
        show={showDeleteAlert}
        showProgress={false}
        title="Are you sure to delete?"
        message="Deleted trackings cannot be recovered"
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText="No, cancel"
        confirmText="Yes, delete it"
        confirmButtonColor="#DD6B55"
        onCancelPressed={() => {
          setTrackingToDelete(null);
          setShowDeleteAlert(false);
        }}
        onConfirmPressed={async () => {
          if (trackingToDelete) {
            await deleteTracking({
              variables: { id: trackingToDelete.id, storeId: trackingToDelete.storeId },
            });

            setTrackingToDelete(null);
            onTrackingsChanged && (await onTrackingsChanged());
          }
          setShowDeleteAlert(false);
        }}
      />
      <AwesomeAlert
        show={!!trackingInFocus}
        showProgress={false}
        title={`${trackingInFocus?.authorName} - ${
          trackingInFocus &&
          formatDistanceToNow(
            parseISO(trackingInFocus.updatedTime || trackingInFocus.createdTime),
            {
              addSuffix: true,
            },
          )
        }`}
        message={trackingInFocus?.content}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={true}
        showCancelButton={true}
        showConfirmButton={false}
        cancelText="Ok"
        onCancelPressed={() => {
          setTrackingInFocus(null);
        }}
      />
    </View>
  );
};
