import React, { memo } from 'react';
import { Layout, Spinner, Text } from '@ui-kitten/components';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  spinnerContainer: {
    minHeight: '50vh',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
});

export default memo(({ content }) => (
  <Layout style={styles.spinnerContainer}>
    <Spinner size="giant" />
    <Text>{content}</Text>
  </Layout>
));
