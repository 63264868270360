import { observer } from 'mobx-react';
import debounce from 'lodash.debounce';
import { courierStore } from '../store';
import { Avatar, Badge, Card, IconButton } from 'react-native-paper';
import { remove } from 'ramda';
import { Text } from '@ui-kitten/components';
import Autocomplete from './Autocomplete';
import InlineTable from './InlineTable';
import SkuQtyInput from './SkuQtyInput';
import { styles } from './CreateOutboundForm';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

const DEBOUNCE_DELAY_MS = 800;

export default observer(
  ({
    formProps,
    shippingCost,
    warehouses,
    warehouseNames,
    warehouse,
    warehouseAccountId,
    courier,
    skus,
    index,
    onWarehouseChanged,
  }) => {
    const [couriers, setCouriers] = useState([]);

    const updateCouriers = useCallback(
      debounce(async (w) => {
        const c = await courierStore.getCouriers(warehouseAccountId, [w]);
        setCouriers(c);
      }, DEBOUNCE_DELAY_MS),
      [],
    );

    useEffect(async () => {
      if (warehouse) updateCouriers(warehouse);
    }, [warehouse]);

    const COURIERS = useMemo(
      () => couriers.map((c) => c.logistics_product_name_en),
      [JSON.stringify(couriers)],
    );

    const warehouseFieldName = `outboundConsignments[${index}].warehouse`;
    const courierFieldName = `outboundConsignments[${index}].courier`;
    const skusFieldName = `outboundConsignments[${index}].skus`;

    return (
      <Card>
        <Card.Title
          title={`Outbound Consignment${
            formProps.values.outboundConsignments.length > 1 ? ` ${index + 1}` : ''
          }`}
          left={(props) => <Avatar.Icon {...props} icon="truck" />}
          right={() => (
            <React.Fragment>
              {formProps.values.outboundConsignments.length > 1 ? (
                <IconButton
                  icon="delete-outline"
                  onPress={() => {
                    formProps.setFieldValue(
                      'outboundConsignments',
                      remove(index, 1, formProps.values.outboundConsignments),
                    );
                  }}
                />
              ) : null}
            </React.Fragment>
          )}
        />
        <Card.Content>
          {shippingCost[index] && shippingCost[index].shippingCost < 0 ? (
            <Text status="danger">{shippingCost[index].errorMsg}</Text>
          ) : (
            shippingCost[index] && (
              <Text status="info">
                Shipping cost:{' '}
                <Badge>$ {shippingCost[index] && shippingCost[index].shippingCost}</Badge>
              </Text>
            )
          )}

          <Autocomplete
            label="Warehouse"
            value={
              warehouses.find((w) => w.warehouse_code === warehouse)?.warehouse_name_en || warehouse
            }
            error={formProps.errors[warehouseFieldName]}
            touched={formProps.touched[warehouseFieldName]}
            placeholder="Select a warehouse"
            style={styles.field}
            options={warehouseNames}
            onSelect={(val) => {
              const value =
                warehouses.find((w) => w.warehouse_name_en === val)?.warehouse_code || val;
              formProps.setFieldValue(warehouseFieldName, value);
              formProps.setFieldValue(courierFieldName, undefined);
              onWarehouseChanged && onWarehouseChanged(val);
            }}
            onBlur={formProps.handleBlur(warehouseFieldName)}
          />

          <Autocomplete
            label="Courier"
            disabled={couriers.length === 0}
            value={
              couriers.find((c) => c.logistics_product_code === courier)
                ?.logistics_product_name_en || courier
            }
            error={formProps.errors[courierFieldName]}
            touched={formProps.touched[courierFieldName]}
            placeholder="Select a courier"
            style={styles.field}
            options={COURIERS}
            onSelect={(val) => {
              const value =
                couriers.find((c) => c.logistics_product_name_en === val)?.logistics_product_code ||
                val;
              formProps.setFieldValue(courierFieldName, value);
            }}
            onBlur={formProps.handleBlur(courierFieldName)}
          />
          <React.Fragment>
            <InlineTable
              items={skus}
              columnByKey={{
                warehouseSku: { title: 'Warehouse SKU' },
                qty: { title: 'QTY', size: 'small', numeric: true },
              }}
              onRemoveItem={(index) =>
                formProps.setFieldValue(skusFieldName, remove(index, 1, skus))
              }
              emptyPlaceHolder={
                warehouse ? 'Use the form below to add SKUs' : 'Please select a warehouse'
              }
            />

            {warehouse && (
              <SkuQtyInput
                style={{ marginVertical: '0.4em', width: '100%' }}
                itemLabel="SKU"
                qtyLabel="Quantity"
                warehouse_code={warehouse}
                warehouseAccountId={warehouseAccountId}
                onSubmit={(sku) =>
                  formProps.setFieldValue(skusFieldName, [
                    ...skus,
                    { warehouseSku: sku.sku, qty: sku.qty },
                  ])
                }
              />
            )}
          </React.Fragment>
        </Card.Content>
      </Card>
    );
  },
);
