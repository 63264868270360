import { Button, Icon, Layout } from '@ui-kitten/components';
import { observer } from 'mobx-react';
import { StyleSheet } from 'react-native';
import React, { useCallback, useState } from 'react';
import Table from './Table';
import { useIsMobile } from '../core/responsive.utils';
import * as DocumentPicker from 'expo-document-picker';

export default observer(({ onSubmit, onDismiss, sheetIndices = 'all' }) => {
  const isMobile = useIsMobile();
  const [csvData, setCsvData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const pickDocument = useCallback(async () => {
    let result = await DocumentPicker.getDocumentAsync({});

    const reader = new FileReader();
    const XLSX = await import('xlsx/xlsx.mjs');
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'array' });
      let selectedSheets = workbook.SheetNames;

      if (Array.isArray(sheetIndices)) {
        selectedSheets = workbook.SheetNames.filter((_, index) => sheetIndices.includes(index));
      }

      const csv = selectedSheets.reduce((a, sheetName) => {
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        return json;
      }, {});
      setCsvData(csv);
    };
    reader.readAsArrayBuffer(result.file);
  }, [setCsvData]);

  return csvData ? (
    <Layout style={styles.tableContainer}>
      <Table items={csvData} displayKeys={Object.keys(csvData?.[0] || {})} />
      <Layout style={isMobile ? styles.mobileButtonContainer : styles.desktopButtonContainer}>
        <Button
          status="primary"
          disabled={isSubmitting}
          onPress={async () => {
            setIsSubmitting(true);
            try {
              await onSubmit(csvData);
              setCsvData(null);
            } finally {
              setIsSubmitting(false);
            }
          }}
          style={styles.button}>
          Submit
        </Button>
        <Button
          status="basic"
          disabled={isSubmitting}
          onPress={() => {
            setCsvData(null);
          }}
          style={styles.button}>
          Cancel
        </Button>
      </Layout>
    </Layout>
  ) : (
    <Layout style={styles.readerContainer}>
      <Layout style={isMobile ? styles.mobileButtonContainer : styles.desktopButtonContainer}>
        <Button
          style={styles.button}
          appearance="outline"
          accessoryLeft={(props) => <Icon {...props} name="upload" />}
          onPress={pickDocument}>
          Upload & Preview
        </Button>
        <Button status="basic" onPress={onDismiss} style={styles.button}>
          Cancel
        </Button>
      </Layout>
    </Layout>
  );
});

const styles = StyleSheet.create({
  readerContainer: {
    alignSelf: 'center',
    minHeight: 300,
  },
  tableContainer: {
    alignSelf: 'center',
    maxWidth: '90vw',
  },
  button: {
    marginVertical: 10,
    marginHorizontal: 2,
  },
  desktopButtonContainer: {
    flexDirection: 'row-reverse',
  },
  mobileButtonContainer: {
    flexDirection: 'column',
  },
});
