import React from 'react';
import { StyleSheet } from 'react-native';
import { Button, Text, Icon } from '@ui-kitten/components';
import { DataTable } from 'react-native-paper';
import { TooltipWrapper } from 'src/components/TooltipWrapper';
import { sanitize } from '../core/utils/utils';
import { path } from 'ramda';

export default ({
  items,
  columnByKey,
  onRemoveItem,
  onEditItem,
  onSaveItem,
  onDuplicateItem,
  emptyPlaceHolder,
  style = {},
}) => {
  return (
    <DataTable style={style}>
      <DataTable.Header>
        {Object.entries(columnByKey).map(([k, column]) => (
          <DataTable.Title
            key={`title-${k}`}
            style={
              column.size === 'small'
                ? styles.smallColumn
                : column.size === 'medium'
                ? styles.mediumColumn
                : column.size === 'large'
                ? styles.largeColumn
                : {}
            }
            numeric={column.numeric}>
            {column.title || k}
          </DataTable.Title>
        ))}
        {onSaveItem ? <DataTable.Title numeric style={styles.smallColumn}></DataTable.Title> : null}
        {onEditItem ? <DataTable.Title numeric style={styles.smallColumn}></DataTable.Title> : null}
        {onDuplicateItem ? (
          <DataTable.Title numeric style={styles.smallColumn}></DataTable.Title>
        ) : null}
        {onRemoveItem ? (
          <DataTable.Title numeric style={styles.smallColumn}></DataTable.Title>
        ) : null}
      </DataTable.Header>
      {items && items.length > 0 ? (
        items.map((item, index) => (
          <DataTable.Row key={`row-${index}`}>
            {Object.entries(columnByKey).map(([k, column]) => (
              <DataTable.Cell
                style={
                  column.size === 'small'
                    ? styles.smallColumn
                    : column.size == 'medium'
                    ? styles.mediumColumn
                    : column.size === 'large'
                    ? styles.largeColumn
                    : {}
                }
                numeric={column.numeric}
                key={k}>
                <TooltipWrapper>
                  <Text category="p2">
                    {(column.formatter && sanitize(column.formatter(k, item, index))) ||
                      sanitize(path(k.split('.'), item))}
                  </Text>
                </TooltipWrapper>
              </DataTable.Cell>
            ))}
            {onRemoveItem ? (
              <DataTable.Cell numeric style={styles.smallColumn}>
                <Button
                  size="small"
                  appearance="ghost"
                  accessoryLeft={(props) => <Icon {...props} name="trash-2-outline" />}
                  onPress={() => onRemoveItem(index)}
                />
              </DataTable.Cell>
            ) : null}
            {onEditItem ? (
              <DataTable.Cell numeric style={styles.smallColumn}>
                <Button
                  size="small"
                  appearance="ghost"
                  accessoryLeft={(props) => <Icon {...props} name="edit-2-outline" />}
                  onPress={() => onEditItem(index)}
                />
              </DataTable.Cell>
            ) : null}
            {onDuplicateItem ? (
              <DataTable.Cell numeric style={styles.smallColumn}>
                <Button
                  size="small"
                  appearance="ghost"
                  accessoryLeft={(props) => <Icon {...props} name="copy-outline" />}
                  onPress={() => onDuplicateItem(index)}
                />
              </DataTable.Cell>
            ) : null}
            {onSaveItem ? (
              <DataTable.Cell numeric style={styles.smallColumn}>
                <Button
                  size="small"
                  appearance="ghost"
                  accessoryLeft={(props) => <Icon {...props} name="save-outline" />}
                  onPress={() => onSaveItem(index)}
                />
              </DataTable.Cell>
            ) : null}
          </DataTable.Row>
        ))
      ) : (
        <Text appearance="hint" style={styles.hint}>
          {emptyPlaceHolder || 'No Added Items'}
        </Text>
      )}
    </DataTable>
  );
};

const styles = StyleSheet.create({
  smallColumn: {
    maxWidth: 40,
  },
  mediumColumn: {
    maxWidth: 100,
    minWidth: 45,
  },
  largeColumn: {
    maxWidth: 180,
    minWidth: 130,
  },
  hint: {
    justifyContent: 'space-around',
    display: 'flex',
    marginVertical: 1,
  },
});
