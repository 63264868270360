import React from 'react';
import { StyleSheet, Dimensions, ScrollView } from 'react-native';
import {
  Layout,
  Button,
  Text,
  Divider,
  Modal,
  Icon,
  Datepicker,
  Card,
} from '@ui-kitten/components';
import * as Yup from 'yup';
import { useIsMobile } from '../core/responsive.utils';
import { Formik } from 'formik';
import InlineTable from './InlineTable';
import { TableCellInput } from './TableCellInput';
import { ezTheme } from '../core/theme';

const windowHeight = Dimensions.get('window').height;

const CalendarIcon = (props) => <Icon {...props} name="calendar" />;

export default ({ visible, setVisible, consignment, onSubmit }) => {
  const isMobile = useIsMobile();

  return (
    <Modal
      visible={visible}
      backdropStyle={{
        backgroundColor: ezTheme.backdropModalColor,
      }}
      onBackdropPress={() => setVisible(false)}>
      <Card
        style={isMobile ? styles.mobileModalStyle : styles.modalStyle}
        header={() => (
          <Text category="h6" style={styles.title}>
            {`Received: ${consignment.ref_no}`}
          </Text>
        )}
        disabled>
        <ScrollView
          keyboardShouldPersistTaps={'handled'}
          contentContainerStyle={styles.formContainer}>
          <Formik
            isInitialValid={true}
            validateOnBlur={false}
            validateOnMount={false}
            initialValues={{
              receive_time: new Date(),
              lstsku:
                consignment?.lstsku.map(({ box_no, sku_code, received_qty, plan_qty }) => ({
                  box_no,
                  sku_code,
                  plan_qty,
                  received_qty: received_qty || plan_qty,
                  exception_qty: 0,
                })) || [],
            }}
            validationSchema={Yup.object({
              receive_time: Yup.date().required(),
              lstsku: Yup.array()
                .min(1)
                .of(
                  Yup.object({
                    box_no: Yup.number().integer().min(1),
                    sku_code: Yup.string().required(),
                    plan_qty: Yup.number().integer().required().min(1),
                    received_qty: Yup.number().integer().required().min(0),
                    exception_qty: Yup.number().integer().required().min(0),
                  }),
                ),
            })}
            onSubmit={async (values) => {
              await onSubmit({
                ...values,
                lstsku: values.lstsku.map((s) => ({
                  ...s,
                  box_no: parseInt(s.box_no),
                  plan_qty: parseInt(s.plan_qty),
                  plan_qty: parseInt(s.plan_qty),
                  received_qty: parseInt(s.received_qty),
                  exception_qty: parseInt(s.exception_qty),
                })),
              });
              setVisible(false);
            }}>
            {(props) => {
              return (
                <>
                  <Layout
                    style={[
                      styles.rowContainer,
                      isMobile ? styles.mobileRowFlex : styles.desktopRowFlex,
                      styles.field,
                    ]}>
                    <Text
                      category="medium"
                      style={isMobile ? styles.mobileLabel : styles.desktopLabel}>
                      Received Date
                    </Text>
                    <Datepicker
                      placeholder="Pick Received Date"
                      date={props.values.receive_time}
                      onSelect={(date) => props.setFieldValue('receive_time', date)}
                      accessoryRight={CalendarIcon}
                    />
                  </Layout>
                  <InlineTable
                    items={props.values.lstsku}
                    columnByKey={{
                      box_no: { title: 'Box', size: 'small' },
                      sku_code: { title: 'SKU' },
                      plan_qty: { title: 'Planned QTY', numeric: true },
                      received_qty: {
                        title: 'Received QTY',
                        numeric: true,
                        formatter: (key, item, index) => (
                          <TableCellInput
                            name={`lstsku.${index}.${key}`}
                            {...props}
                            style={{ maxWidth: 100 }}
                          />
                        ),
                      },
                      exception_qty: {
                        title: 'Exception QTY',
                        numeric: true,
                        formatter: (key, item, index) => (
                          <TableCellInput
                            name={`lstsku.${index}.${key}`}
                            {...props}
                            style={{ maxWidth: 100 }}
                          />
                        ),
                      },
                    }}
                  />
                  <Divider />
                  <Layout
                    style={isMobile ? styles.mobileButtonContainer : styles.desktopButtonContainer}>
                    <Button
                      status="primary"
                      loading={props.isSubmitting}
                      onPress={props.handleSubmit}
                      disabled={props.isSubmitting || !props.isValid}
                      style={styles.button}>
                      Submit
                    </Button>
                    <Button
                      status="basic"
                      loading={props.isSubmitting}
                      onPress={() => setVisible(false)}
                      disabled={props.isSubmitting}
                      style={styles.button}>
                      Cancel
                    </Button>
                  </Layout>
                </>
              );
            }}
          </Formik>
        </ScrollView>
      </Card>
    </Modal>
  );
};

const styles = StyleSheet.create({
  field: {
    marginVertical: 8,
  },
  title: {
    marginBottom: 10,
  },
  button: {
    marginVertical: 10,
    marginHorizontal: 2,
  },
  desktopButtonContainer: {
    marginTop: 20,
    flexDirection: 'row-reverse ',
  },
  mobileButtonContainer: {
    marginTop: 10,
    flexDirection: 'column',
  },
  formContainer: {
    maxHeight: 0.75 * windowHeight,
    overflowY: 'auto',
    alignItems: 'center',
  },
  mobileLabel: {
    flex: 1,
    maxWidth: '100px',
    minWidth: '100px',
  },
  desktopLabel: {
    minWidth: '130px',
  },
  rowContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mobileRowFlex: {
    marginHorizontal: 'auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  desktopRowFlex: {
    flexDirection: 'row',
    width: 'auto',
  },
  mobileModalStyle: { backgroundColor: 'white', marginHorizontal: 20, padding: 10 },
  modalStyle: { backgroundColor: 'white', minWidth: 600, padding: 20 },
});
