import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { courierStore, warehouseAccountStore, warehousesStore } from '../store';
import CheckTreePicker from 'rsuite/CheckTreePicker';
import 'rsuite/dist/rsuite.css';
import { Layout, Text } from '@ui-kitten/components';
import { flatten, prop, sortBy } from 'ramda';
import { useIsMobile } from '../core/responsive.utils';

const WAREHOUSE_TYPE = 'WAREHOUSE';
const WAREHOUSE_ACCOUNT_TYPE = 'WAREHOUSE_ACCOUNT';

export const CourierTreeSelect = observer(
  ({ label, size = 'medium', error, touched, style = {}, value, setValue, whiteListValues }) => {
    const isMobileDevice = useIsMobile();
    const [data, setData] = useState([]);
    const whiteListedWarehouseAccountIds = useMemo(
      () => new Set(whiteListValues.map((v) => v.split(':')[0])),
      [whiteListValues],
    );
    const whiteListedWarehouses = useMemo(
      () =>
        whiteListValues
          .filter((v) => v.split(':').length > 1)
          .map((v) => ({
            warehouse_code: v.split(':')[1],
            allCouriers: v.split(':').length === 2,
          })),
      [whiteListValues],
    );
    const whiteListedCouriers = useMemo(
      () =>
        new Set(whiteListValues.filter((v) => v.split(':').length > 2).map((v) => v.split(':')[2])),
      [whiteListValues],
    );
    useEffect(() => {
      (async () => {
        let selectedWarehouseCouriers = null;
        if (value) {
          const [selectedWAccountId, selectedWarehouseCode] = value.split(':');
          try {
            selectedWarehouseCouriers = await courierStore.getCouriers(selectedWAccountId, [
              selectedWarehouseCode,
            ]);
          } catch (e) {
            console.error(e);
          }
        }
        const d = sortBy(
          prop('label'),
          warehouseAccountStore.warehouseAccounts
            .filter((w) => whiteListedWarehouseAccountIds.has(w.id.toString()))
            .map((warehouseAccount) => {
              return {
                label: warehouseAccount.name,
                value: `${warehouseAccount.id}`,
                key: warehouseAccount.id,
                id: warehouseAccount.id,
                type: WAREHOUSE_ACCOUNT_TYPE,
                children: sortBy(
                  prop('label'),
                  (warehouseAccount &&
                    warehousesStore.warehousesByAccountId?.[warehouseAccount.id]
                      ?.filter((w) =>
                        whiteListedWarehouses.find((wh) => wh.warehouse_code === w.warehouse_code),
                      )
                      .map((warehouse) => ({
                        label: warehouse.warehouse_name_en,
                        value: `${warehouseAccount.id}:${warehouse.warehouse_code}`,
                        key: warehouse.warehouse_code,
                        id: warehouse.warehouse_code,
                        type: WAREHOUSE_TYPE,
                        warehouseAccountId: warehouseAccount.id,
                        children: selectedWarehouseCouriers
                          ? getCourierOptions(
                              selectedWarehouseCouriers,
                              warehouse.warehouse_code,
                              warehouseAccount.id,
                              whiteListedWarehouses,
                              whiteListedCouriers,
                            )
                          : [],
                      }))) ||
                    [],
                ),
              };
            }),
        );
        setData(d);
      })();
    }, [
      warehouseAccountStore.warehouseAccounts,
      warehousesStore.warehousesByAccountId,
      whiteListedCouriers,
      whiteListedWarehouseAccountIds,
      whiteListedWarehouses,
      value,
    ]);

    return (
      <Layout
        style={[
          styles.rowContainer,
          isMobileDevice ? styles.mobileRowFlex : styles.desktopRowFlex,
          style,
        ]}
        level="1">
        {label ? (
          <Text
            category={size === 'large' ? 's1' : size === 'medium' ? 's2' : 'label'}
            style={isMobileDevice ? styles.mobileLabel : styles.desktopLabel}>
            {label}
          </Text>
        ) : null}
        <CheckTreePicker
          style={{
            ...styles.input,
            ...(label
              ? isMobileDevice
                ? styles.mobileInput
                : styles.desktopInput
              : { width: '100%' }),
          }}
          uncheckableItemValues={flatten(
            whiteListValues.map((v) => {
              const splits = v.split(':');
              if (splits.length > 1) {
                return [splits[0], [splits[0], splits[1]].join(':')];
              } else {
                return splits[0];
              }
            }),
          )}
          data={data}
          value={[value]}
          getChildren={async (node) => {
            if (!node.expanded && node.type === WAREHOUSE_TYPE) {
              const couriers = await courierStore.getCouriers(node.parent.id, [node.id]);
              return getCourierOptions(
                couriers,
                node.id,
                node.parent.id,
                whiteListedWarehouses,
                whiteListedCouriers,
              );
            }
          }}
          onSelect={(node) => {
            setValue({ label: node.label, value: node.value });
          }}
          block
          renderMenu={(menu) => {
            if (data.length === 0) {
              return (
                <div style={{ padding: 10, color: '#999', textAlign: 'center' }}>
                  No data, please check if you have selected warehouses and couriers
                </div>
              );
            }
            return menu;
          }}
          size="md"
          renderValue={(values, checkedItems, selectedElement) => {
            return checkedItems[0]?.label;
          }}
          cleanable={false}
        />
      </Layout>
    );
  },
);

const styles = {
  input: {
    flex: 1,
    marginHorizontal: 5,
  },
  mobileLabel: {
    flex: 1,
    maxWidth: '100px',
    minWidth: '100px',
  },
  desktopLabel: {
    minWidth: '130px',
  },
  rowContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mobileRowFlex: {
    marginHorizontal: 'auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  desktopRowFlex: {
    flexDirection: 'row',
    width: 'auto',
  },
  desktopInput: {
    width: '260px',
    minWidth: '260px',
  },
  mobileInput: {
    width: '200px',
    minWidth: '200px',
  },
};

const getCourierOptions = (
  couriers,
  warehouseId,
  warehouseAccountId,
  whiteListedWarehouses,
  whiteListedCouriers,
) => {
  return sortBy(
    prop('label'),
    couriers
      .filter((c) =>
        whiteListedWarehouses.find((w) => w.warehouse_code === warehouseId).allCouriers
          ? true
          : whiteListedCouriers.has(c.logistics_product_code),
      )
      .map((c) => ({
        label: c.logistics_product_name_en,
        value: `${warehouseAccountId}:${warehouseId}:${c.logistics_product_code}`,
        id: c.logistics_product_code,
        key: c.logistics_product_code,
      })),
  );
};
