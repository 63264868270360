// import { SHIPMENT_ORDER_STATES } from '@ezom/library';
import { Button, Modal } from '@ui-kitten/components';
import React, { memo, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Card } from 'react-native-paper';
import HeaderSearchFilter, { Props } from './HeaderSearchFilter';
import { LocationForm } from './AddressBook/LocationForm';
import { ArrowUpIcon } from './icon/ArrowUpIcon';
import { ezTheme } from '../core/theme';

const AddressBookListControl = (props: Props) => {
  const { filterParams, onFilterChange } = props;
  const [locationVisible, setLocationVisible] = useState(false);

  const openLocation = () => {
    setLocationVisible(true);
  };
  const closeLocation = () => {
    setLocationVisible(false);
  };
  const onSubmit = (data) => {
    onFilterChange({ ...filterParams, ...data });
    closeLocation();
  };

  const renderModalLocation = () => {
    return (
      <Modal
        visible={locationVisible}
        backdropStyle={styles.backdropStyle}
        onBackdropPress={closeLocation}>
        <Card style={styles.modalContent}>
          <LocationForm
            onCancel={closeLocation}
            value={filterParams}
            onSubmit={onSubmit}></LocationForm>
        </Card>
      </Modal>
    );
  };

  const renderFilterLocation = () => {
    return (
      <Button
        status="primary"
        appearance="ghost"
        style={[styles.button]}
        onPress={openLocation}
        accessoryRight={ArrowUpIcon}>
        Location
      </Button>
    );
  };

  return (
    <>
      <HeaderSearchFilter
        {...props}
        listFilterComponent={[renderFilterLocation()]}></HeaderSearchFilter>
      {renderModalLocation()}
    </>
  );
};

const styles = StyleSheet.create({
  backdropStyle: {
    backgroundColor: ezTheme.backdropModalColor,
  },
  modalContent: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
  },
  button: {
    height: '45px',
  },
});
export default memo(AddressBookListControl);
