import React, { useEffect, useMemo, useState } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { Badge } from 'react-native-paper';
import { Layout, Button, Text, Divider, Icon, ButtonGroup } from '@ui-kitten/components';
import Autocomplete from './Autocomplete';
import * as Yup from 'yup';
import { useIsMobile } from '../core/responsive.utils';
import { Formik } from 'formik';
import { TextInputField } from './input/TextInputField';
import { useMutation } from '@apollo/client';
import { unifiedAlert } from '../core/utils/utils';
import { UIStatusWrapper } from './ui-status';
import { paperNativeTheme } from 'src/core/theme';
import gql from 'graphql-tag';
import { observer } from 'mobx-react';
import { countryStateCityCurrencyStore, outboundOrderStore, warehousesStore } from '../store';
import shortUUID from 'short-uuid';
import CreateOutboundFormSplit from './CreateOutboundFormSplit';
import { OC_STATUS_CODE } from '@ezom/library/lib/cjs/constants';

const PlusIcon = (props) => <Icon {...props} name="plus" />;
export const OptimiseConsignmentsIcon = (props) => <Icon {...props} name="layers-outline" />;

export const RECOMMEND_OUTBOUND_SPLIT = gql`
  mutation recommendOutboundSplit(
    $warehouseAccountId: ID!
    $address: OutboundAddressInput!
    $skus: [SkuQtyInput!]!
    $warehouses: [String!]!
  ) {
    recommendOutboundSplit(
      warehouseAccountId: $warehouseAccountId
      address: $address
      skus: $skus
      warehouses: $warehouses
    ) {
      skus {
        sku
        qty
      }
      courier
      warehouse
    }
  }
`;

const OUTBOUND_SHIPPING_COST = gql`
  mutation outboundShippingCost(
    $warehouseAccountId: ID!
    $address: OutboundAddressInput!
    $skus: [SkuQtyInput!]!
    $courier: String!
    $warehouse: String!
  ) {
    outboundShippingCost(
      warehouseAccountId: $warehouseAccountId
      address: $address
      skus: $skus
      courier: $courier
      warehouse: $warehouse
    ) {
      shippingCost
      breakdown
    }
  }
`;

export const createInitOutboundFormValues = (initialConsignment) => {
  return !!initialConsignment
    ? {
        outboundConsignments: [
          {
            warehouse: initialConsignment?.warehouse,
            courier: initialConsignment?.courier,
            skus: initialConsignment?.skus || [],
          },
        ],
        refNo: initialConsignment?.refNo,
        sales_no: initialConsignment?.sales_no,
        note: initialConsignment?.note || '',
        address: initialConsignment?.address,
        consignment_no: initialConsignment?.consignment_no,
      }
    : {
        outboundConsignments: [
          {
            warehouse: '',
            courier: '',
            skus: [],
          },
        ],
        sales_no: '',
        refNo: '',
        note: '',
        address: {
          country: 'AU',
          name: '',
          state: '',
          city: '',
          phone: '',
          email: '',
          post_code: '',
          company: '',
          street: '',
        },
      };
};

export const OUTBOUND_VALIDATION_SCHEMA = Yup.object({
  outboundConsignments: Yup.array()
    .min(1)
    .of(
      Yup.object({
        warehouse: Yup.string().required('warehouse is required'),
        courier: Yup.string().required(),
        skus: SKUS_VALIDATION_SCHEMA,
      }),
    ),
  refNo: Yup.string().required(),
  sales_no: Yup.string().nullable(),
  note: Yup.string().nullable(),
  address: Yup.object({
    country: Yup.string().required(),
    name: Yup.string().required(),
    state: Yup.string().required(),
    city: Yup.string().required(),
    phone: Yup.string().nullable(),
    email: Yup.string().email('Not a valid email address').nullable(),
    post_code: Yup.string().required(),
    company: Yup.string().nullable(),
    street: Yup.string().required(),
  }),
});

const SKUS_VALIDATION_SCHEMA = Yup.array()
  .min(1)
  .of(
    Yup.object({
      sku: Yup.string().required(),
      qty: Yup.number().required().min(1),
    }),
  );

export default observer(
  ({
    hideTitle,
    onDismiss,
    onSubmit,
    orderId,
    storeId,
    style,
    warehouseAccountId,
    autoSplitOn,
    updateMode,
    initialConsignment = null,
    onReinitializationRequested = () => {},
  }) => {
    const isMobile = useIsMobile();

    const [
      recommendOutboundSplit,
      {
        loading: recommendOutboundSplitLoading,
        error: recommendOutboundSplitError,
        data: recommendOutboundSplitData,
      },
    ] = useMutation(RECOMMEND_OUTBOUND_SPLIT);

    const COUNTRIES = countryStateCityCurrencyStore.countryCodes;

    React.useEffect(() => {
      warehousesStore.clearCache();
    }, []);

    const initialValues = useMemo(() => {
      const values = createInitOutboundFormValues(initialConsignment);
      return values;
    }, [initialConsignment]);

    return (
      <UIStatusWrapper
        status={{
          error: warehousesStore.error,
          indeterminate: warehousesStore.loading,
        }}>
        <ScrollView
          style={{
            borderRadius: '4px',
            borderColor: paperNativeTheme.colors.secondary,
            borderWidth: '0.5px',
            marginRight: '0.8em',
            height: '95vh',
          }}>
          <Layout style={[styles.formContainer, style]}>
            {!hideTitle && (
              <span>
                <Text category="h5" style={styles.title}>
                  Create Outbound Consignment(s)
                </Text>
                <Divider />
              </span>
            )}

            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={OUTBOUND_VALIDATION_SCHEMA}
              onSubmit={async (values, formikActions) => {
                formikActions.setSubmitting(true);

                const noSkusFoundIndex = values.outboundConsignments.findIndex(
                  (oc) => oc.skus.length === 0,
                );

                if (noSkusFoundIndex >= 0) {
                  unifiedAlert(
                    `Please add SKUs to the Outbound Consignment ${noSkusFoundIndex + 1}`,
                  );
                } else {
                  let successMsg = [];
                  let failureMsg = [];

                  for (let i = 0; i < values.outboundConsignments.length; i++) {
                    const { skus, courier, warehouse } = values.outboundConsignments[i];
                    const errorPrefix =
                      values.outboundConsignments.length > 1 ? `Consignment ${i + 1}: ` : undefined;
                    try {
                      const ocPayload = {
                        consignment_no: values.consignment_no,
                        refNo:
                          values.outboundConsignments.length > 1
                            ? // use different refNo to avoid 4px failing with "Error: repetition request"
                              `${values.refNo}_${shortUUID.generate()}`
                            : values.refNo,
                        address: values.address,
                        skus: skus.map((s) => ({
                          sku: s.warehouseSku,
                          qty: s.qty,
                        })),
                        note: values.note,
                        courier: courier,
                        warehouse,
                        sales_no: values.sales_no,
                      };
                      let consignment_no, createTransaction, status, exceptionReason;
                      if (updateMode) {
                        ({ consignment_no, createTransaction, status, exceptionReason } =
                          await outboundOrderStore.updateItem(warehouseAccountId, ocPayload));
                      } else {
                        ({ consignment_no, createTransaction, status, exceptionReason } =
                          await outboundOrderStore.addItem(warehouseAccountId, ocPayload));
                      }

                      successMsg.push(
                        `Consignment${
                          values.outboundConsignments.length > 1 ? ` ${i + 1}` : ''
                        }: ${consignment_no}.` +
                          `${
                            status === OC_STATUS_CODE.Exception
                              ? '\n' + (exceptionReason || '')
                              : ''
                          }` +
                          (createTransaction
                            ? `\nYou were charged $${createTransaction.amount}.`
                            : ''),
                      );
                    } catch (e) {
                      failureMsg.push(`${errorPrefix ? errorPrefix : ''}${e.message}`);
                    }
                  }

                  unifiedAlert(
                    successMsg.length
                      ? successMsg.reduce((a, c) => a + c, 'Consignments saved:\n')
                      : '' +
                          (failureMsg.length
                            ? '\n' + failureMsg.reduce((a, c) => a + c, 'Errors:\n')
                            : ''),
                  );

                  if (successMsg.length === values.outboundConsignments.length) {
                    onSubmit();
                  }
                }

                formikActions.setSubmitting(false);
              }}>
              {(props) => {
                const [states, setStates] = useState([]);
                useEffect(() => {
                  (async () => {
                    if (props.values.address?.country) {
                      const states = await countryStateCityCurrencyStore.getStateCodesOfCountry(
                        props.values.address.country,
                      );
                      setStates(states);
                    }
                  })();
                }, [props.values.address?.country]);

                const [cities, setCities] = useState([]);
                useEffect(() => {
                  (async () => {
                    if (props.values.address?.country && props.values.address?.state) {
                      const cities = await countryStateCityCurrencyStore.getCitiesOfState(
                        props.values.address.country,
                        props.values.address.state,
                      );
                      setCities(cities);
                    }
                  })();
                }, [props.values.address?.country, props.values.address?.state]);

                const warehouses = useMemo(
                  () => {
                    return storeId !== undefined
                      ? warehousesStore
                          .getStoreWhitelistedWarehouse(storeId)
                          .filter((w) => +w.warehouseAccountId === warehouseAccountId)
                      : // if there is no store id, the list of warehouses will be all warehouse from 4px
                        warehousesStore.warehousesByAccountId[warehouseAccountId] || [];
                  },
                  warehousesStore.warehouses.map((w) => w.warehouse_code),
                );

                const skuToQtyMap = useMemo(
                  () =>
                    props.values.outboundConsignments
                      .reduce((a, c) => a.concat(c.skus), [])
                      .reduce((a, { warehouseSku, qty }) => {
                        if (a.has(warehouseSku)) {
                          a.set(warehouseSku, a.get(warehouseSku) + qty);
                        } else {
                          a.set(warehouseSku, qty);
                        }
                        return a;
                      }, new Map()),
                  [props.values.outboundConsignments],
                );

                const autoSplit = React.useCallback(
                  async (address) => {
                    const warehouseCodes = warehouses.map((w) => w.warehouse_code);

                    if (skuToQtyMap.size <= 0 || !warehouseCodes.length) return;

                    try {
                      const { data } = await recommendOutboundSplit({
                        variables: {
                          warehouseAccountId,
                          address: address,
                          skus: Array.from(skuToQtyMap.entries()).map(([k, v]) => ({
                            sku: k,
                            qty: v,
                          })),
                          warehouses: warehouseCodes,
                        },
                      });

                      const outboundConsignments = data.recommendOutboundSplit;

                      if (outboundConsignments) {
                        const foundErrorOc = outboundConsignments.find(
                          (oc) => !oc.warehouse && !oc.courier,
                        );

                        if (foundErrorOc) {
                          unifiedAlert(
                            `Unable to find a pair of warehouse and courier that can send these SKUs: ${foundErrorOc.skus.reduce(
                              (a, { sku, qty }) => `${a}\nsku: ${sku}, quantity: ${qty}`,
                              '',
                            )}`,
                          );
                        }
                      }

                      props.setFieldValue(
                        'outboundConsignments',
                        outboundConsignments.map((oc) => ({
                          skus: oc.skus.map(({ sku, qty }) => ({
                            warehouseSku: sku,
                            qty,
                          })),
                          courier: oc.courier,
                          warehouse: oc.warehouse,
                        })),
                      );
                    } catch (error) {
                      unifiedAlert(error);
                    }
                  },
                  [JSON.stringify(warehouses), JSON.stringify(Object.fromEntries(skuToQtyMap))],
                );

                React.useEffect(() => {
                  if (autoSplitOn) {
                    autoSplit(props.values.address);
                  }
                }, [
                  autoSplitOn,
                  autoSplit,
                  JSON.stringify(Object.fromEntries(skuToQtyMap)),
                  JSON.stringify(props.values.address),
                ]);

                const [shippingCost, setShippingCost] = useState([]);
                const [outboundShippingCost] = useMutation(OUTBOUND_SHIPPING_COST);

                useEffect(async () => {
                  const tmp = [];
                  const address = props.values.address;
                  for (let i = 0; i < props.values.outboundConsignments.length; i++) {
                    const { warehouse, skus, courier } = props.values.outboundConsignments[i];
                    if (
                      !warehouse ||
                      !courier ||
                      !address.country ||
                      !address.state ||
                      !address.city ||
                      !address.post_code
                    ) {
                      tmp.push({
                        shippingCost: -1,
                        errorMsg: '',
                      });
                      continue;
                    }

                    try {
                      const { data } = await outboundShippingCost({
                        variables: {
                          warehouseAccountId,
                          address: props.values.address,
                          skus: skus.map(({ qty, warehouseSku }) => ({
                            sku: warehouseSku,
                            qty,
                          })),
                          courier,
                          warehouse,
                        },
                        context: {
                          debounceKey: `${JSON.stringify(skus)}-${address.country}-${
                            address.state
                          }-${address.city}-${address.post_code}`,
                        },
                      });

                      tmp.push(data.outboundShippingCost);
                    } catch (error) {
                      tmp.push({
                        shippingCost: -1,
                        errorMsg: error.message,
                      });
                    }
                  }

                  setShippingCost(tmp);
                }, [props.values.address, JSON.stringify(props.values.outboundConsignments)]);

                const totalShippingCost =
                  shippingCost && shippingCost.length > 0
                    ? shippingCost.reduce((a, c) => (c.shippingCost ? a + c.shippingCost : a), 0)
                    : -1;

                const AddOutboundConsignmentButton = (
                  <Button
                    style={{ flexGrow: 2 }}
                    appearance="outline"
                    accessoryLeft={PlusIcon}
                    onPress={() => {
                      props.setFieldValue('outboundConsignments', [
                        ...props.values.outboundConsignments,
                        {
                          ...(props.values.outboundConsignments &&
                            props.values.outboundConsignments[
                              props.values.outboundConsignments.length - 1
                            ]),
                          skus: [],
                        },
                      ]);
                    }}
                    disabled={recommendOutboundSplitLoading}>
                    Add outbound consignment
                  </Button>
                );

                return (
                  <>
                    <ButtonGroup appearance="outline" style={{ flexDirection: 'row' }}>
                      {AddOutboundConsignmentButton}
                      <Button disabled style={{ flexGrow: 1 }}>
                        OR
                      </Button>
                      <Button
                        style={{ flexGrow: 2 }}
                        accessoryLeft={OptimiseConsignmentsIcon}
                        disabled={props.isSubmitting || recommendOutboundSplitLoading}
                        onPress={() => {
                          autoSplit(props.values.address);
                        }}>
                        Auto-split
                      </Button>
                    </ButtonGroup>

                    {totalShippingCost >= 0 && (
                      <Text status="info">
                        Total shipping cost: <Badge>$ {totalShippingCost}</Badge>
                      </Text>
                    )}

                    <UIStatusWrapper
                      status={{
                        indeterminate: recommendOutboundSplitLoading,
                      }}
                      loadingDataMsg="Finding best pairs of warehouse and courier to send the items ...">
                      {props.values.outboundConsignments.map(
                        ({ warehouse, courier, skus }, index) => {
                          return (
                            <CreateOutboundFormSplit
                              formProps={props}
                              shippingCost={shippingCost}
                              warehouses={warehouses}
                              warehouseNames={warehouses.map((w) => w.warehouse_name_en)}
                              warehouse={warehouse}
                              warehouseAccountId={warehouseAccountId}
                              courier={courier}
                              skus={skus}
                              index={index}
                              onWarehouseChanged={
                                index === 0
                                  ? (warehouseCode) => {
                                      onReinitializationRequested([
                                        {
                                          ...props.values.outboundConsignments[0],
                                          warehouse: warehouseCode,
                                        },
                                      ]);
                                    }
                                  : null
                              }
                            />
                          );
                        },
                      )}
                    </UIStatusWrapper>

                    {props.values.outboundConsignments.length > 1
                      ? AddOutboundConsignmentButton
                      : null}

                    <Divider />

                    <TextInputField
                      name="refNo"
                      style={styles.field}
                      label="Customer Order No."
                      {...props}
                    />
                    <TextInputField
                      name="sales_no"
                      style={styles.field}
                      label="Sales No."
                      {...props}
                    />
                    <Autocomplete
                      label="Country"
                      defaultValue={props.values.address?.country}
                      error={props.errors.address?.country}
                      touched={props.touched.address?.country}
                      style={styles.field}
                      placeholder="Select a country"
                      options={COUNTRIES}
                      onSelect={props.handleChange('address.country')}
                      onBlur={props.handleBlur('address.country')}
                    />
                    <Autocomplete
                      label="State"
                      defaultValue={props.values.address?.state}
                      error={props.errors.address?.state}
                      touched={props.touched.address?.state}
                      style={styles.field}
                      placeholder="Select a state"
                      options={states}
                      onSelect={props.handleChange('address.state')}
                      onBlur={props.handleBlur('address.state')}
                    />

                    <Autocomplete
                      label="City / Suburb"
                      defaultValue={props.values.address?.city}
                      error={props.errors.address?.city}
                      touched={props.touched.address?.city}
                      placeholder="Select a city / suburb"
                      style={styles.field}
                      disabled={!props.values.address?.state}
                      options={cities}
                      onSelect={props.handleChange('address.city')}
                      onBlur={props.handleBlur('address.city')}
                      allowFreetext={true}
                    />
                    <TextInputField
                      name="address.name"
                      style={styles.field}
                      label="Name"
                      {...props}
                    />
                    <TextInputField
                      label="Phone"
                      name="address.phone"
                      style={styles.field}
                      textContentType="telephoneNumber"
                      keyboardType="phone-pad"
                      {...props}
                    />
                    <TextInputField
                      label="Email"
                      name="address.email"
                      style={styles.field}
                      textContentType="emailAddress"
                      keyboardType="email-address"
                      {...props}
                    />
                    <TextInputField
                      label="Post Code"
                      name="address.post_code"
                      style={styles.field}
                      textContentType="postalCode"
                      keyboardType="number-pad"
                      {...props}
                    />
                    <TextInputField
                      label="Company"
                      name="address.company"
                      style={styles.field}
                      {...props}
                    />
                    <TextInputField
                      label="Address"
                      name="address.street"
                      style={styles.field}
                      {...props}
                    />
                    <TextInputField
                      label="Delivery Note"
                      name="note"
                      style={styles.field}
                      textStyle={{ minHeight: 64 }}
                      multiline={true}
                      {...props}
                    />
                    <Divider />
                    <Layout
                      style={
                        isMobile ? styles.mobileButtonContainer : styles.desktopButtonContainer
                      }>
                      <Button
                        status="primary"
                        loading={props.isSubmitting}
                        onPress={props.handleSubmit}
                        disabled={props.isSubmitting || !props.isValid}
                        style={styles.button}>
                        Ship
                      </Button>
                      {onDismiss ? (
                        <Button
                          status="basic"
                          loading={props.isSubmitting}
                          onPress={onDismiss}
                          disabled={props.isSubmitting}
                          style={styles.button}>
                          Cancel
                        </Button>
                      ) : null}
                      <Button
                        status="danger"
                        appearance="ghost"
                        disabled={props.isSubmitting}
                        onPress={props.handleReset}
                        style={styles.button}>
                        Reset
                      </Button>
                    </Layout>
                  </>
                );
              }}
            </Formik>
          </Layout>
        </ScrollView>
      </UIStatusWrapper>
    );
  },
);

export const styles = StyleSheet.create({
  field: {
    marginVertical: 8,
  },
  title: {
    marginVertical: 10,
  },
  button: {
    marginVertical: 10,
    marginHorizontal: 2,
  },
  desktopButtonContainer: {
    flexDirection: 'row-reverse ',
  },
  mobileButtonContainer: {
    flexDirection: 'column',
  },
  controlContainer: {
    borderRadius: 4,
    margin: 2,
    padding: 6,
    backgroundColor: '#3366FF',
  },
  formContainer: {
    padding: '20px',
  },
});
