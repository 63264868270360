import React from 'react';
import {
  Button,
  Divider,
  Icon,
  Layout,
  List,
  ListItem,
  Modal,
  Spinner,
  Text,
} from '@ui-kitten/components';
import { observer } from 'mobx-react';
import { StyleSheet } from 'react-native';
import { paperNativeTheme } from 'src/core/theme';
import { useIsMobile } from '../core/responsive.utils';
import { TooltipWrapper } from './TooltipWrapper';
import { NETWORK_CALL_STATUS } from '../core/utils/utils';
import { ezTheme } from '../core/theme';

export const AddTrackingProgressListModal = observer(({ trackingRequests, onDismiss, visible }) => {
  const renderItem = ({ item, index }) => {
    return (
      <ListItem
        title={item.orderNumber}
        description={item.trackingUrl && `Tracking: ${item.trackingUrl}`}
        accessoryRight={() =>
          item.status === NETWORK_CALL_STATUS.PENDING ? (
            <Button
              appearance="ghost"
              size="small"
              accessoryLeft={(props) => <Spinner size="medium" />}
            />
          ) : item.status === NETWORK_CALL_STATUS.SUCCESS ? (
            <Button
              appearance="ghost"
              size="small"
              accessoryLeft={(props) => (
                <Icon
                  name="checkmark-circle-2-outline"
                  style={{ width: 22, fill: paperNativeTheme.colors.success }}
                />
              )}
            />
          ) : (
            <TooltipWrapper
              anchor={
                <Button
                  appearance="ghost"
                  size="small"
                  accessoryLeft={(props) => (
                    <Icon
                      name="alert-circle-outline"
                      style={{
                        width: 22,
                        fill:
                          item.status === NETWORK_CALL_STATUS.WARNING
                            ? paperNativeTheme.colors.warning
                            : paperNativeTheme.colors.danger,
                      }}
                    />
                  )}
                />
              }>
              <Text category="p2">{item.errMsg}</Text>
            </TooltipWrapper>
          )
        }
      />
    );
  };
  const isMobile = useIsMobile();

  return (
    <Modal
      visible={visible}
      backdropStyle={{
        backgroundColor: ezTheme.backdropModalColor,
      }}
      style={{
        backgroundColor: 'none',
        minWidth: '400px',
        alignSelf: 'center',
      }}>
      <List data={trackingRequests} renderItem={renderItem} ItemSeparatorComponent={Divider} />
      {trackingRequests.find((t) => t.status === NETWORK_CALL_STATUS.PENDING) ? null : (
        <Layout style={isMobile ? styles.mobileButtonContainer : styles.desktopButtonContainer}>
          <Button status="warning" onPress={onDismiss} style={styles.button}>
            Close
          </Button>
        </Layout>
      )}
    </Modal>
  );
});
const styles = StyleSheet.create({
  button: {
    marginHorizontal: 10,
    marginVertical: 10,
  },
  desktopButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  mobileButtonContainer: {
    flexDirection: 'column',
  },
});
