import React, { useEffect, useMemo, useState } from 'react';
import { Avatar, Card, Badge } from 'react-native-paper';
import { Layout, Button, Text, Divider, Icon, CheckBox, Toggle } from '@ui-kitten/components';
import Autocomplete from './Autocomplete';
import { clone, omit, remove } from 'ramda';
import { TextInputField } from './input/TextInputField';
import { useSearchHsCode } from '../core/utils/utils';
import gql from 'graphql-tag';
import { client, countryStateCityCurrencyStore, courierStore } from '../store';
import Select from './Select';
import { StyleSheet, View } from 'react-native';
import { observer } from 'mobx-react';
import { getTriggerSurchargeMapping, prepairAddressData } from '@ezom/library/lib/cjs/shippingCost';

const PlusIcon = (props) => <Icon {...props} name="plus" />;
export const ERROR_SHIPING_COST_FIELD = 'errorShippingCost';

const SHIPMENT_SHIPPING_COST = gql`
  query courierShipmentShippingCost(
    $warehouseAccountId: ID!
    $pickupAddress: OutboundAddressInput!
    $receiverAddress: OutboundAddressInput!
    $items: [ShipmentOrderItemInput!]!
    $courier: String!
    $triggerSurcharge: [TriggerSurchargeInput!]!
  ) {
    courierShipmentShippingCost(
      warehouseAccountId: $warehouseAccountId
      pickupAddress: $pickupAddress
      receiverAddress: $receiverAddress
      items: $items
      courier: $courier
      triggerSurcharge: $triggerSurcharge
    ) {
      shippingCost
      breakdown
    }
  }
`;

export const ShipmentInfo = observer(
  ({ touchedItems, errorsItems, fieldNamePrefix, shipment, warehouseAccountId, ...props }) => {
    const items = useMemo(() => shipment.items, [shipment.items]);
    const courier = useMemo(() => shipment.courier, [shipment.courier]);
    const [shippingCost, setShippingCost] = useState({});
    useEffect(() => {
      const fetchShippingCost = async () => {
        if (
          !courier ||
          items.length < 1 ||
          !props.values.pickupAddress.post_code ||
          !props.values.pickupAddress.country ||
          !props.values.receiverAddress.post_code ||
          !props.values.receiverAddress.city ||
          !props.values.receiverAddress.country
        ) {
          return;
        }
        try {
          const pickupAddress = prepairAddressData(props.values.pickupAddress);
          const receiverAddress = prepairAddressData(props.values.receiverAddress);
          const { data: shippingCostData } = await client.query({
            query: SHIPMENT_SHIPPING_COST,
            variables: {
              warehouseAccountId,
              pickupAddress: pickupAddress,
              receiverAddress: receiverAddress,
              items: items.map(
                ({ exportInfo, weight, length, width, height, qty, description, commodity }) => ({
                  weight: parseFloat(weight) * 1000,
                  length: parseFloat(length),
                  height: parseFloat(height),
                  width: parseFloat(width),
                  qty: parseInt(qty),
                  description,
                  commodity,
                }),
              ),
              courier,
              triggerSurcharge: getTriggerSurchargeMapping({
                deliveryWindowEnabled: props.values.shipment.deliveryWindowEnabled,
                handUnload: props.values.shipment.handUnload,
                tailgate: props.values.shipment.tailgate,
                dangerousGoods: props.values.shipment.dangerousGoods,
              }),
            },
            context: {
              debounceKey: `${JSON.stringify(items)}-${props.values.pickupAddress.post_code}-${
                props.values.pickupAddress.country
              }-${props.values.pickupAddress.post_code}-${props.values.receiverAddress.post_code}-${
                props.values.receiverAddress.city
              }-${props.values.receiverAddress.country}`,
            },
          });
          props.setFieldValue(ERROR_SHIPING_COST_FIELD, undefined);
          setShippingCost(shippingCostData?.courierShipmentShippingCost || {});
        } catch (shippingCostError) {
          props.setFieldValue(ERROR_SHIPING_COST_FIELD, shippingCostError.message);
          setShippingCost({
            shippingCost: -1,
            errorMsg: shippingCostError.message,
          });
        }
      };
      fetchShippingCost();
    }, [
      courier,
      items?.length,
      props.values.pickupAddress.post_code,
      props.values.pickupAddress.country,
      props.values.receiverAddress.post_code,
      props.values.receiverAddress.city,
      props.values.receiverAddress.country,
      props.values.shipment.deliveryWindowEnabled,
      props.values.shipment.handUnload,
      props.values.shipment.tailgate,
      props.values.shipment.dangerousGoods,
    ]);

    const [shipmentOrderCourierList, setShipmentOrderCourierList] = useState([]);
    useEffect(() => {
      (async () => {
        const courierList = await courierStore.fetchShipmentOrderCourierList(warehouseAccountId);
        setShipmentOrderCourierList(courierList);
      })();
    }, []);

    const searchHsCode = useSearchHsCode();

    return (
      <Card>
        <Card.Title
          style={{ paddingLeft: 0 }}
          title={
            shippingCost && shippingCost.shippingCost < 0 ? (
              <Text status="danger" category="h6">
                {shippingCost.errorMsg}
              </Text>
            ) : (
              shippingCost && (
                <Text status="info" category="h6">
                  Shipping cost:{' '}
                  <Badge size={40}>$ {(shippingCost && shippingCost.shippingCost) || 0}</Badge>
                </Text>
              )
            )
          }
          left={(props) => <Avatar.Icon {...props} icon="package-variant" />}
        />
        <Card.Content style={{ paddingLeft: 0 }}>
          <Layout style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <View
              style={{
                width: 780,
              }}>
              {items.map((item, index) => (
                <Card
                  mode="outlined"
                  style={{
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                  }}
                  key={`package-${index}`}>
                  <Card.Title
                    title={`Package Type ${index + 1}`}
                    style={{ minHeight: 45, paddingLeft: 8 }}
                    titleStyle={{ fontSize: 16 }}
                    right={() => (
                      <React.Fragment>
                        {items.length > 1 ? (
                          <Button
                            size="small"
                            appearance="ghost"
                            accessoryLeft={(props) => <Icon {...props} name="trash-2-outline" />}
                            onPress={() =>
                              props.setFieldValue(
                                `${fieldNamePrefix}.items`,
                                remove(index, 1, items),
                              )
                            }
                          />
                        ) : null}
                      </React.Fragment>
                    )}
                  />
                  <Card.Content style={{ paddingHorizontal: 8, paddingBottom: 8 }}>
                    <Layout style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
                      <Text category="p2" style={{ width: 85, marginLeft: 4 }}>
                        Weight (kg)
                      </Text>
                      <Text category="p2" style={{ width: 85, marginLeft: 2 }}>
                        Length (cm)
                      </Text>
                      <Text category="p2" style={{ width: 85, marginLeft: 0 }}>
                        Width (cm)
                      </Text>
                      <Text category="p2" style={{ width: 85, marginLeft: 0 }}>
                        Height (cm)
                      </Text>
                      <Text category="p2" style={{ width: 85, marginLeft: 0 }}>
                        Quantity
                      </Text>
                      <Text category="p2" style={{ width: 135, marginLeft: 0 }}>
                        Package
                      </Text>
                      <Text category="p2" style={{ width: 180 }}>
                        Description
                      </Text>
                    </Layout>
                    <Layout>
                      <Layout
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          marginBottom: 2,
                        }}>
                        <TextInputField
                          name={`${fieldNamePrefix}.items.${index}.weight`}
                          size="tiny"
                          style={{ width: 85 }}
                          textStyle={{ width: 55 }}
                          {...props}
                        />
                        <TextInputField
                          name={`${fieldNamePrefix}.items.${index}.length`}
                          size="tiny"
                          style={{ width: 85 }}
                          textStyle={{ width: 55 }}
                          {...props}
                        />
                        <TextInputField
                          name={`${fieldNamePrefix}.items.${index}.width`}
                          size="tiny"
                          style={{ width: 85 }}
                          textStyle={{ width: 55 }}
                          {...props}
                        />
                        <TextInputField
                          name={`${fieldNamePrefix}.items.${index}.height`}
                          size="tiny"
                          style={{ width: 85 }}
                          textStyle={{ width: 55 }}
                          {...props}
                        />
                        <TextInputField
                          name={`${fieldNamePrefix}.items.${index}.qty`}
                          size="tiny"
                          style={{ width: 85 }}
                          textStyle={{ width: 55 }}
                          {...props}
                        />
                        <Select
                          size="medium"
                          value={props.values[fieldNamePrefix].items[index].commodity}
                          error={props.errors[fieldNamePrefix]?.items?.[index]?.commodity}
                          touched={props.touched[fieldNamePrefix]?.items?.[index]?.commodity}
                          style={{ width: 135 }}
                          textStyle={{ width: 55 }}
                          options={[
                            { key: 'CARTON', val: 'Carton' },
                            { key: 'PALLET', val: 'Pallet' },
                          ]}
                          onChange={(val) => {
                            props.setFieldValue(
                              `${fieldNamePrefix}.items.${index}.commodity`,
                              val.key,
                            );
                          }}
                          onBlur={() =>
                            props.setFieldTouched(
                              `${fieldNamePrefix}.items.${index}.commodity`,
                              true,
                            )
                          }
                        />
                        <TextInputField
                          name={`${fieldNamePrefix}.items.${index}.description`}
                          size="tiny"
                          style={{ width: 180 }}
                          textStyle={{ width: 150 }}
                          {...props}
                        />
                      </Layout>
                      {shipmentOrderCourierList.find((c) => c.logistics_product_code === courier)
                        ?.requireExportInfo ? (
                        <Card
                          mode="outlined"
                          style={{
                            padding: 8,
                            marginTop: 5,
                            marginHorizontal: 5,
                            display: 'flex',
                            justifyContent: 'space-around',
                          }}>
                          <Card.Title
                            title="Export Declaration"
                            titleStyle={{ fontSize: 13 }}
                            style={{ minHeight: 35 }}
                          />
                          <Card.Content style={{ paddingBottom: 0 }}>
                            <Layout
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                              }}>
                              <Text category="p2" style={{ width: 150, marginLeft: 4 }}>
                                Declaration
                              </Text>
                              <Text category="p2" style={{ width: 150, marginLeft: 2 }}>
                                HS-CODE
                              </Text>
                              <Text category="p2" style={{ width: 85, marginLeft: 0 }}>
                                Quantity
                              </Text>
                              <Text category="p2" style={{ width: 85, marginLeft: 0 }}>
                                Weight (kg)
                              </Text>
                              <Text category="p2" style={{ width: 85, marginLeft: 0 }}>
                                Value (USD)
                              </Text>
                              <Text category="p2" style={{ width: 105, marginLeft: 0 }}>
                                Country of Origin
                              </Text>
                            </Layout>

                            {item.exportInfo?.map((declareItem, declareItemIndex) => (
                              <Layout
                                key={`declare-item-${declareItemIndex}`}
                                style={{
                                  flexDirection: 'row',
                                  justifyContent: 'flex-start',
                                  marginBottom: 2,
                                }}>
                                <TextInputField
                                  name={`${fieldNamePrefix}.items.${index}.exportInfo.${declareItemIndex}.declare`}
                                  size="tiny"
                                  style={{ width: 150 }}
                                  textStyle={{ width: 120 }}
                                  {...props}
                                />
                                <Autocomplete
                                  name={`${fieldNamePrefix}.items.${index}.exportInfo.${declareItemIndex}.hscode`}
                                  style={{ width: 145 }}
                                  defaultValue={item.exportInfo?.[declareItemIndex]?.hscode}
                                  touched={
                                    touchedItems?.[index]?.exportInfo?.[declareItemIndex]?.hscode
                                  }
                                  error={
                                    errorsItems?.[index]?.exportInfo?.[declareItemIndex]?.hscode
                                  }
                                  onSelect={props.handleChange(
                                    `${fieldNamePrefix}.items.${index}.exportInfo.${declareItemIndex}.hscode`,
                                  )}
                                  onBlur={props.handleBlur(
                                    `${fieldNamePrefix}.items.${index}.exportInfo.${declareItemIndex}.hscode`,
                                  )}
                                  getValue={(hsCode) => hsCode.HS}
                                  getTitle={(hsCode) => `${hsCode.HS} - ${hsCode.description}`}
                                  onSearch={searchHsCode}
                                  allowFreetext={true}
                                />
                                <TextInputField
                                  name={`${fieldNamePrefix}.items.${index}.exportInfo.${declareItemIndex}.qty`}
                                  size="tiny"
                                  style={{ width: 85 }}
                                  textStyle={{ width: 55 }}
                                  {...props}
                                />
                                <TextInputField
                                  name={`${fieldNamePrefix}.items.${index}.exportInfo.${declareItemIndex}.weight`}
                                  size="tiny"
                                  style={{ width: 85 }}
                                  textStyle={{ width: 55 }}
                                  {...props}
                                />
                                <TextInputField
                                  name={`${fieldNamePrefix}.items.${index}.exportInfo.${declareItemIndex}.declareValue`}
                                  size="tiny"
                                  style={{ width: 85 }}
                                  textStyle={{ width: 55 }}
                                  {...props}
                                />
                                <Autocomplete
                                  name={`${fieldNamePrefix}.items.${index}.exportInfo.${declareItemIndex}.countryOfOrigin`}
                                  style={{ width: 100 }}
                                  defaultValue={
                                    item.exportInfo?.[declareItemIndex]?.countryOfOrigin
                                  }
                                  touched={
                                    touchedItems?.[index]?.exportInfo?.[declareItemIndex]
                                      ?.countryOfOrigin
                                  }
                                  error={
                                    errorsItems?.[index]?.exportInfo?.[declareItemIndex]
                                      ?.countryOfOrigin
                                  }
                                  options={countryStateCityCurrencyStore.countryCodes}
                                  onSelect={props.handleChange(
                                    `${fieldNamePrefix}.items.${index}.exportInfo.${declareItemIndex}.countryOfOrigin`,
                                  )}
                                  onBlur={props.handleBlur(
                                    `${fieldNamePrefix}.items.${index}.exportInfo.${declareItemIndex}.countryOfOrigin`,
                                  )}
                                />
                                {item.exportInfo?.length > 1 ? (
                                  <Button
                                    size="small"
                                    appearance="ghost"
                                    accessoryLeft={(props) => (
                                      <Icon {...props} name="trash-2-outline" />
                                    )}
                                    onPress={() =>
                                      props.setFieldValue(
                                        `${fieldNamePrefix}.items.${index}.exportInfo`,
                                        remove(declareItemIndex, 1, item.exportInfo),
                                      )
                                    }
                                  />
                                ) : null}
                              </Layout>
                            ))}
                            <Divider style={{ marginTop: 5 }} />
                            <Button
                              accessoryLeft={PlusIcon}
                              size="small"
                              appearance="ghost"
                              onPress={() => {
                                props.setFieldValue(
                                  `${fieldNamePrefix}.items.${index}.exportInfo`,
                                  [...item.exportInfo, {}],
                                );
                              }}>
                              Add item
                            </Button>
                          </Card.Content>
                        </Card>
                      ) : null}
                    </Layout>
                  </Card.Content>
                </Card>
              ))}
              <Button
                accessoryLeft={PlusIcon}
                size="medium"
                appearance="ghost"
                onPress={() => {
                  props.setFieldValue(`${fieldNamePrefix}.items`, [...items, { exportInfo: [{}] }]);
                }}>
                Add package
              </Button>
            </View>
            <View
              style={{
                width: 'calc(100% - 800px)',
              }}>
              <Select
                label="Courier"
                value={courier}
                error={props.errors[`${fieldNamePrefix}.courier`]}
                touched={props.touched[`${fieldNamePrefix}.courier`]}
                options={shipmentOrderCourierList.map(
                  ({ logistics_product_code, logistics_product_name_en }) => ({
                    key: logistics_product_code,
                    val: logistics_product_name_en,
                  }),
                )}
                onChange={(val) => {
                  props.setFieldValue(`${fieldNamePrefix}.courier`, val.key);
                }}
                onBlur={() => props.setFieldTouched(`${fieldNamePrefix}.courier`, true)}
                style={styles.field}
              />
              <TextInputField name="ref_no" style={styles.field} label="Reference No." {...props} />
              <TextInputField
                name="sales_no"
                style={styles.field}
                label="Sales Order No."
                {...props}
              />
              <TextInputField
                label="Delivery Note"
                name="note"
                style={styles.field}
                textStyle={{ minHeight: 64 }}
                multiline={true}
                {...props}
              />

              {shipmentOrderCourierList.find((c) => c.logistics_product_code === courier)
                ?.deliveryWindow ? (
                <Layout
                  style={{
                    alignItems: 'center',
                    flexDirection: 'row',
                    width: '100%',
                    height: 40,
                    marginVertical: 8,
                    fontSize: '15px',
                  }}>
                  <Text
                    category={'s2'}
                    style={{
                      minWidth: '130px',
                    }}>
                    Delivery window
                  </Text>
                  <Layout
                    style={{
                      alignItems: 'center',
                      flexDirection: 'row',
                      width: 'calc(100% - 130px)',
                      minWidth: '260px',
                      gap: '0px 12px',
                      marginLeft: '5px',
                    }}>
                    <Toggle
                      checked={props.values[fieldNamePrefix].deliveryWindowEnabled}
                      onChange={(isEnabled) =>
                        props.setFieldValue(`${fieldNamePrefix}.deliveryWindowEnabled`, isEnabled)
                      }
                    />
                    {props.values[fieldNamePrefix].deliveryWindowEnabled ? (
                      <>
                        <Text>from </Text>
                        <input
                          style={{ minWidth: '11rem' }}
                          type="datetime-local"
                          name="deliveryWindowStart"
                          value={props.values[fieldNamePrefix].deliveryWindowStart}
                          min={new Date().toISOString().slice(0, -8)}
                          max={props.values[fieldNamePrefix].deliveryWindowEnd || undefined}
                          onChange={props.handleChange(`${fieldNamePrefix}.deliveryWindowStart`)}
                          {...props}
                        />
                        <Text>to </Text>
                        <input
                          style={{ minWidth: '11rem' }}
                          name="deliveryWindowEnd"
                          type="datetime-local"
                          value={props.values[fieldNamePrefix].deliveryWindowEnd}
                          min={
                            props.values.deliveryWindowStart ||
                            new Date().toISOString().slice(0, -8)
                          }
                          onChange={props.handleChange(`${fieldNamePrefix}.deliveryWindowEnd`)}
                          {...props}
                        />
                      </>
                    ) : null}
                  </Layout>
                </Layout>
              ) : null}
              <Layout
                style={{
                  alignItems: 'center',
                  flexDirection: 'row',
                  width: '100%',
                  height: 40,
                  marginVertical: 8,
                  fontSize: '15px',
                }}>
                {shipmentOrderCourierList.find((c) => c.logistics_product_code === courier)
                  ?.dangerousGoods ? (
                  <CheckBox
                    style={{ marginHorizontal: 10 }}
                    checked={props.values[fieldNamePrefix].dangerousGoods}
                    onChange={(checked) =>
                      props.setFieldValue(`${fieldNamePrefix}.dangerousGoods`, checked)
                    }>
                    Dangerous goods
                  </CheckBox>
                ) : null}
                {shipmentOrderCourierList.find((c) => c.logistics_product_code === courier)
                  ?.handUnload ? (
                  <CheckBox
                    style={{ marginHorizontal: 10 }}
                    checked={props.values[fieldNamePrefix].handUnload}
                    onChange={(checked) =>
                      props.setFieldValue(`${fieldNamePrefix}.handUnload`, checked)
                    }>
                    Hand unload
                  </CheckBox>
                ) : null}
                {shipmentOrderCourierList.find((c) => c.logistics_product_code === courier)
                  ?.tailgate ? (
                  <CheckBox
                    style={{ marginHorizontal: 10 }}
                    checked={props.values[fieldNamePrefix].tailgate}
                    onChange={(checked) =>
                      props.setFieldValue(`${fieldNamePrefix}.tailgate`, checked)
                    }>
                    Tailgate
                  </CheckBox>
                ) : null}
              </Layout>
            </View>
          </Layout>
        </Card.Content>
      </Card>
    );
  },
);

const styles = StyleSheet.create({
  field: {
    marginVertical: 8,
  },
});
