import '@expo/match-media';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Dimensions, ScrollView, StyleSheet, View } from 'react-native';
import { Layout, Text, Button, List, Card, ListItem, Icon } from '@ui-kitten/components';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { equals, flatten, remove, sum, update } from 'ramda';
import { observer } from 'mobx-react';
import {
  playErrorSoundAsync,
  playSuccessSoundAsync,
  printBase64Pdf,
  unifiedAlert,
  useOnBarcodeScanned,
} from '../../core/utils/utils';
import {
  courierShipmentStore,
  courierStore,
  inventoryStore,
  organisationStore,
  outboundOrderStore,
  packagingMaterialTableStore,
  pickingTaskTableStore,
} from '../../store';
import InlineTable from '../../components/InlineTable';
import SkuQtyInput from '../../components/SkuQtyInput';
import { TextInputField } from '../../components/input/TextInputField';
import { UIStatusWrapper } from '../../components/ui-status';
import Autocomplete from '../../components/Autocomplete';
import { weightGramToKg } from '@ezom/library/lib/cjs/utils';
import { gql, useApolloClient } from '@apollo/client';

const PACK_SKU_ITEMS = gql`
  query packSkuItems(
    $warehouseAccountId: ID!
    $skuQty: [SkuQtyInput!]!
    $packagingMaterialId: ID!
  ) {
    packSkuItems(
      warehouseAccountId: $warehouseAccountId
      skuQty: $skuQty
      packagingMaterialId: $packagingMaterialId
    ) {
      xmlResult
      error
      weight
      width
      height
      length
    }
  }
`;

const MIN_CANVAS_SIZE = 500;
const MAX_CANVAS_SIZE = 800;

const OcPackingScreen = observer(({ navigation, route }) => {
  const { ocNo, warehouseAccountId, warehouse, pickingTaskId } = route.params;
  const [oc, setOc] = useState(null);
  const [packagingMaterials, setPackagingMaterials] = useState(null);

  const fetchOc = useCallback(async () => {
    const oc = await outboundOrderStore.getOutboundOrder(warehouseAccountId, ocNo);
    setOc(oc);
  });
  const fetchPackagingMaterials = useCallback(async () => {
    const data = await packagingMaterialTableStore.fetchPackagingMaterials(
      warehouseAccountId,
      warehouse,
      0,
      // ToDo: assume there are less than 500 packaging materials, this is a temporary solution
      500,
    );
    setPackagingMaterials(data.packagingMaterials);
  });

  useEffect(() => {
    (async () => {
      await fetchOc();
      await fetchPackagingMaterials();
    })();
  }, [warehouseAccountId, warehouse, ocNo]);

  const [focusedPackIndex, setFocusedPackIndex] = useState(0);

  const packagingMaterialOptions = useMemo(
    () =>
      (packagingMaterials || [])
        .sort((p1, p2) => (p1.organisationId ? -1 : 1))
        .map((p) => {
          const orgName = organisationStore.orgNameItems.find(
            (org) => org.id === p.organisationId,
          )?.name;
          return {
            ...p,
            title:
              (orgName ? `${p.id} -${orgName}` : p.id) + ` (${p.length}x${p.width}x${p.height})`,
          };
        }),
    [packagingMaterials, organisationStore.orgNameItems],
  );

  const canvasWidth = Math.min(
    Math.max((Dimensions.get('window').width - 200) * 0.4, MIN_CANVAS_SIZE),
    MAX_CANVAS_SIZE,
  );
  const canvasHeight = Math.min(
    Math.max((Dimensions.get('window').width - 200) * 0.4, MIN_CANVAS_SIZE),
    MAX_CANVAS_SIZE,
  );
  const scrollViewHeight = Dimensions.get('window').height - 100;

  const client = useApolloClient();

  const initialFormValues = useMemo(() => {
    return (
      oc?.packingDetails?.packages.map((p) => ({
        weight: weightGramToKg(false)(p.weight),
        height: p.height,
        width: p.width,
        length: p.length,
        items: p.skuQty.map((i) => ({ sku_code: i.sku, qty: i.qty, packedQty: 0 })),
        packagingMaterialId: p.packagingMaterialId,
      })) || [
        {
          weight: '',
          height: '',
          length: '',
          width: '',
          items: [],
          packagingMaterialId: '',
        },
      ]
    );
  }, [oc?.packingDetails?.packages]);

  const [scannedBarcode, setScannedBarcode] = useState(null);
  const [courierList, setCourierList] = useState([]);

  const shippingChannelSupportPacking = useMemo(
    () =>
      oc?.logistics_product_code
        ? courierList?.find((c) => c.logistics_product_code === oc.logistics_product_code)
            ?.supportPacking
        : true,
    [oc?.logistics_product_code, courierList],
  );

  // checks if weight and dimension info are required for the chose courier
  const weightDemensionInfoTester = useCallback(
    (value) => {
      if (shippingChannelSupportPacking) {
        return !!value;
      }
      return true;
    },
    [shippingChannelSupportPacking, oc?.shipmentOrderId],
  );

  useEffect(() => {
    (async () => {
      const couriers = await courierStore.getCouriers(warehouseAccountId, [warehouse]);
      setCourierList(couriers);
    })();
  }, []);

  return (
    <ScrollView style={{ maxHeight: scrollViewHeight }}>
      <UIStatusWrapper
        status={{
          indeterminate: !oc || !packagingMaterials || organisationStore.orgNameItems.length === 0,
        }}>
        <Layout level="1">
          <Formik
            initialValues={initialFormValues}
            isInitialValid={false}
            validationSchema={Yup.array().of(
              Yup.object({
                weight: Yup.number().min(0).test('weight', 'Required', weightDemensionInfoTester),
                height: Yup.number().min(0).test('height', 'Required', weightDemensionInfoTester),
                width: Yup.number().min(0).test('width', 'Required', weightDemensionInfoTester),
                length: Yup.number().min(0).test('length', 'Required', weightDemensionInfoTester),
                items: Yup.array()
                  .of(
                    Yup.object({
                      sku_code: Yup.string().required('Required'),
                      qty: Yup.number().min(0).required('Required'),
                      packedQty: Yup.number().min(0).required('Required'),
                    }),
                  )
                  .min(1),
                packagingMaterialId: Yup.string().oneOf(
                  (packagingMaterials || []).map((p) => p.id),
                ),
              }),
            )}
            onSubmit={async (values, formikActions) => {
              const ocSkuQty = oc.outboundlist_sku.reduce((acc, cur) => {
                if (acc[cur.sku_code]) {
                  acc[cur.sku_code] += cur.qty;
                } else {
                  acc[cur.sku_code] = cur.qty;
                }
                return acc;
              }, {});
              const packageSkuQty = values.reduce((acc, cur) => {
                cur.items.forEach((item) => {
                  if (acc[item.sku_code]) {
                    acc[item.sku_code] += item.packedQty;
                  } else {
                    acc[item.sku_code] = item.packedQty;
                  }
                });
                return acc;
              }, {});
              if (!equals(ocSkuQty, packageSkuQty)) {
                unifiedAlert(
                  'Erroor: The total quantity of each SKU must be equal to the quantity in the OC',
                );
                return;
              }
              formikActions.setSubmitting(true);

              let packedItems = undefined;
              if (shippingChannelSupportPacking) {
                packedItems = values.map((item) => ({
                  weight: Number(item.weight) * 1000,
                  length: Number(item.length),
                  width: Number(item.width),
                  height: Number(item.height),
                  description: item.items.map((i) => `${i.qty} X ${i.sku_code}`).join(', '),
                  skuQty: item.items.map((i) => ({ sku: i.sku_code, qty: i.packedQty })),
                }));
              }
              try {
                await pickingTaskTableStore.finishPackingOc(
                  warehouseAccountId,
                  pickingTaskId,
                  oc.consignment_no,
                  packedItems,
                );

                const updatedOc = await outboundOrderStore.getOutboundOrder(
                  warehouseAccountId,
                  ocNo,
                );
                if (updatedOc.shipmentOrderId) {
                  const labels = await courierShipmentStore.printLabels(warehouseAccountId, [
                    updatedOc.shipmentOrderId,
                  ]);
                  for (let label of labels) {
                    printBase64Pdf(label);
                  }
                }
                navigation.goBack();
              } catch (e) {
                unifiedAlert(e.message);
                throw e;
              }
              // Important: Make sure to setSubmitting to false so our loading indicator
              // goes away.
              formikActions.setSubmitting(false);
              formikActions.resetForm();
            }}>
            {(props) => {
              useOnBarcodeScanned(
                async (barcode) => {
                  setScannedBarcode(barcode);
                  const matchingPM = packagingMaterials.find(
                    (p) => p.id === barcode || p.barcode === barcode,
                  );
                  if (matchingPM) {
                    setFieldValue(`${focusedPackIndex}.packagingMaterialId`, matchingPM.id);
                    return;
                  }
                  const sku = await inventoryStore.searchSku(oc.warehouseAccountId, barcode);
                  if (!sku) {
                    await playErrorSoundAsync();
                    unifiedAlert('SKU not found');
                    return;
                  }
                  const sku_code = oc.outboundlist_sku.find(
                    (s) => s.sku_id === sku.sku_id,
                  )?.sku_code;
                  if (!sku_code) {
                    await playErrorSoundAsync();
                    unifiedAlert('SKU not found in OC');
                    return;
                  }

                  const packedQty = flatten(props.values.map((v) => v.items))
                    .filter((item) => item.sku_code === sku_code)
                    .reduce((acc, cur) => acc + (cur.packedQty || 0), 0);

                  const plannedQty = oc.outboundlist_sku
                    .filter((item) => item.sku_code === sku_code)
                    .reduce((acc, cur) => acc + (cur.qty || 0), 0);

                  if (packedQty >= plannedQty) {
                    await playErrorSoundAsync();
                    unifiedAlert(plannedQty + ' ' + sku_code + ' already packed');
                    return;
                  }

                  let packIdx = props.values.findIndex((pack) => {
                    return pack.items.find(
                      (item) => item.sku_code === sku_code && item.qty > (item.packedQty || 0),
                    );
                  });
                  if (packIdx === -1) {
                    packIdx = props.values.length - 1;
                  }
                  const existingItemIndex = props.values[packIdx].items.findLastIndex(
                    (item) => item.sku_code === sku_code,
                  );
                  if (existingItemIndex > -1) {
                    const updatedItems = update(
                      existingItemIndex,
                      {
                        ...props.values[packIdx].items[existingItemIndex],
                        packedQty: props.values[packIdx].items[existingItemIndex].packedQty + 1,
                        qty:
                          props.values[packIdx].items[existingItemIndex].packedQty ===
                          props.values[packIdx].items[existingItemIndex].qty
                            ? props.values[packIdx].items[existingItemIndex].qty + 1
                            : props.values[packIdx].items[existingItemIndex].qty,
                      },
                      props.values[packIdx].items,
                    );
                    props.setFieldValue(`${packIdx}.items`, updatedItems);
                  } else {
                    props.setFieldValue(`${packIdx}.items`, [
                      ...props.values[packIdx].items,
                      { sku_code, packedQty: 1, qty: 1 },
                    ]);
                  }
                  setFocusedPackIndex(packIdx);
                  await playSuccessSoundAsync();
                },
                [oc, props.values],
              );

              useEffect(() => {
                const drawWrapper = async () => {
                  let xmlResult = oc.packingDetails?.xml;
                  let packIdxToDraw = focusedPackIndex;
                  if (props.values[focusedPackIndex].packagingMaterialId) {
                    const packageMaterial = packagingMaterials.find(
                      (p) => p.id === props.values[focusedPackIndex].packagingMaterialId,
                    );
                    if (packageMaterial) {
                      props.setFieldValue(`${focusedPackIndex}.width`, packageMaterial.width);
                      props.setFieldValue(`${focusedPackIndex}.height`, packageMaterial.height);
                      props.setFieldValue(`${focusedPackIndex}.length`, packageMaterial.length);

                      const hasItemQtyChanged = !equals(
                        oc.packingDetails?.packages?.[focusedPackIndex]?.skuQty.map((i) => ({
                          sku_code: i.sku,
                          qty: i.qty,
                        })),
                        props.values[focusedPackIndex].items.map((s) => ({
                          sku_code: s.sku_code,
                          qty: s.qty,
                        })),
                      );
                      const hasPackagingMaterialChanged =
                        oc.packingDetails?.packages?.[focusedPackIndex]?.packagingMaterialId !==
                        packageMaterial.id;
                      if (!xmlResult || hasPackagingMaterialChanged || hasItemQtyChanged) {
                        try {
                          const { data } = await client.query({
                            query: PACK_SKU_ITEMS,
                            variables: {
                              warehouseAccountId,
                              skuQty: props.values[focusedPackIndex].items.map((item) => ({
                                sku: item.sku_code,
                                qty: item.qty,
                              })),
                              packagingMaterialId: packageMaterial.id,
                            },
                          });
                          xmlResult = data.packSkuItems.xmlResult;
                          // single package
                          packIdxToDraw = 0;
                          props.setFieldValue(
                            `${focusedPackIndex}.weight`,
                            weightGramToKg(false)(data.packSkuItems.weight),
                          );

                          if (data.packSkuItems.error) {
                            unifiedAlert(
                              data.packSkuItems.error +
                                '\nPlease check weight and dimensions manually',
                            );
                          }
                        } catch (e) {
                          unifiedAlert(e + '\nPlease check weight and dimensions manually');
                        }
                      }
                    }
                  }
                  const { drawPackage } = await import('../../utils/package-drawer');
                  const div = document.getElementById('wrapper_view');
                  drawPackage(xmlResult, canvasWidth, canvasHeight, div, packIdxToDraw);
                };

                if (shippingChannelSupportPacking) {
                  // Only draw package if the channel supports packing
                  drawWrapper();
                }
              }, [
                props.values[focusedPackIndex].packagingMaterialId,
                props.values[focusedPackIndex].items,
                oc.packingDetails,
              ]);
              return (
                <>
                  <Card
                    header={(props) => (
                      <Layout
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-evenly',
                        }}>
                        <Text {...props} category="h5">
                          Packing {oc.consignment_no}
                        </Text>
                        <Button
                          appearance="ghost"
                          size="large"
                          onPress={() => {
                            document?.activeElement.blur();
                          }}>
                          Click to start scanning
                        </Button>
                        <Text category="p2" appearance="hint">
                          {scannedBarcode || ''}
                        </Text>
                      </Layout>
                    )}>
                    <Layout
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}>
                      <Layout
                        style={{
                          paddingHorizontal: 10,
                          borderRightColor: 'lightgrey',
                          borderRightWidth: 0.1,
                        }}>
                        <List
                          style={{
                            backgroundColor: 'white',
                          }}
                          data={oc && oc.outboundlist_sku}
                          renderItem={({ item, index }) => (
                            <ListItem
                              title={() => {
                                const packedQty = flatten(props.values.map((v) => v.items)).reduce(
                                  (acc, cur) =>
                                    cur.sku_code === item.sku_code
                                      ? acc + (cur.packedQty || 0)
                                      : acc,
                                  0,
                                );
                                return (
                                  <Layout style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Text
                                      category="s1"
                                      status={packedQty === item.qty ? 'success' : 'danger'}>
                                      {' '}
                                      {packedQty}
                                    </Text>
                                    /<Text category="s1">{item.qty}</Text>
                                    <Text category="s1"> {item.sku_code}</Text>
                                  </Layout>
                                );
                              }}
                            />
                          )}
                        />
                        <Layout
                          style={{
                            flexDirection: 'row',
                            marginHorizontal: 10,
                            justifyContent: 'space-evenly',
                          }}>
                          {shippingChannelSupportPacking ? (
                            <Button
                              disabled={!props.isValid || props.isSubmitting}
                              status="basic"
                              onPress={() => {
                                props.setValues([
                                  ...props.values,
                                  {
                                    weight: '',
                                    height: '',
                                    length: '',
                                    width: '',
                                    items: [],
                                  },
                                ]);
                                setFocusedPackIndex(props.values.length);
                              }}>
                              Add package
                            </Button>
                          ) : null}
                          <Button
                            disabled={!props.isValid || props.isSubmitting}
                            status="primary"
                            onPress={props.handleSubmit}>
                            Submit
                          </Button>
                        </Layout>
                      </Layout>
                      <Layout
                        style={{
                          paddingHorizontal: 10,
                          borderRightColor: 'lightgrey',
                          borderRightWidth: 0.1,
                          minWidth: 400,
                        }}>
                        <List
                          style={{ backgroundColor: 'white' }}
                          data={props.values}
                          ItemSeparatorComponent={() => null}
                          ListFooterComponent={() => null}
                          ListEmptyComponent={() => null}
                          ListHeaderComponent={() => null}
                          renderItem={({ item: pack, index: packIndex }) => {
                            return (
                              <Card
                                style={styles.item}
                                status="info"
                                onPress={() => setFocusedPackIndex(packIndex)}
                                // accent={() => null}
                                header={(headerProps) => (
                                  <Layout
                                    {...headerProps}
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      justifyContent: 'space-around',
                                      marginVertical: 3,
                                    }}>
                                    <Text category="s1">Package {packIndex + 1}</Text>
                                    <Text category="s1">
                                      <Text
                                        category="s1"
                                        status={
                                          sum(pack.items.map((i) => i.qty || 0)) ===
                                          sum(pack.items.map((i) => i.packedQty || 0))
                                            ? 'success'
                                            : 'danger'
                                        }>
                                        {sum(pack.items.map((i) => i.packedQty || 0))}
                                      </Text>
                                      /
                                      <Text category="s1">
                                        {sum(pack.items.map((i) => i.qty || 0))}
                                      </Text>
                                    </Text>
                                    <Button
                                      style={{ marginLeft: 200 }}
                                      size="small"
                                      appearance="ghost"
                                      status="danger"
                                      accessoryLeft={(props) => (
                                        <Icon {...props} name="trash-2-outline" />
                                      )}
                                      disabled={props.values.length === 1}
                                      onPress={() => {
                                        props.setValues(remove(packIndex, 1, props.values));
                                        if (packIndex === focusedPackIndex) {
                                          setFocusedPackIndex(0);
                                        }
                                      }}
                                    />
                                  </Layout>
                                )}>
                                {packIndex === focusedPackIndex ? (
                                  <>
                                    <Button
                                      size="small"
                                      appearance="ghost"
                                      status="danger"
                                      onPress={() => {
                                        props.setFieldValue(
                                          `${packIndex}.items`,
                                          pack.items.map((item) => ({
                                            ...item,
                                            packedQty: item.qty,
                                          })),
                                        );
                                      }}>
                                      <Text>Mark as packed without scanning</Text>
                                    </Button>
                                    <InlineTable
                                      items={pack.items}
                                      columnByKey={{
                                        sku_code: { title: 'SKU code' },
                                        qty: { title: 'QTY', size: 'small' },
                                      }}
                                      onRemoveItem={(index) =>
                                        props.setFieldValue(
                                          `${packIndex}.items`,
                                          remove(index, 1, pack.items),
                                        )
                                      }
                                    />
                                    <SkuQtyInput
                                      style={{ marginVertical: '0.4em', width: '100%' }}
                                      skuList={((oc && oc.outboundlist_sku) || [])
                                        .map((s) => s.sku_code)
                                        .map((sku_code) => ({ sku_code }))}
                                      itemLabel="SKU"
                                      qtyLabel="Qty"
                                      validateStock={false}
                                      onSubmit={(sku) => {
                                        props.setFieldValue(`${packIndex}.items`, [
                                          ...pack.items,
                                          { sku_code: sku.sku, qty: sku.qty },
                                        ]);
                                      }}
                                    />

                                    {shippingChannelSupportPacking ? (
                                      <Layout style={{ marginTop: 20 }}>
                                        <TextInputField
                                          size="small"
                                          name={`${packIndex}.weight`}
                                          style={styles.field}
                                          label="Weight (kg)"
                                          keyboardType="phone-pad"
                                          {...props}
                                        />
                                        <TextInputField
                                          size="small"
                                          name={`${packIndex}.length`}
                                          style={styles.field}
                                          label="Length (cm)"
                                          keyboardType="phone-pad"
                                          {...props}
                                        />
                                        <TextInputField
                                          size="small"
                                          name={`${packIndex}.width`}
                                          style={styles.field}
                                          label="Width (cm)"
                                          keyboardType="phone-pad"
                                          {...props}
                                        />
                                        <TextInputField
                                          size="small"
                                          name={`${packIndex}.height`}
                                          style={styles.field}
                                          label="Height (cm)"
                                          keyboardType="phone-pad"
                                          {...props}
                                        />
                                      </Layout>
                                    ) : null}
                                  </>
                                ) : null}
                              </Card>
                            );
                          }}
                        />
                      </Layout>
                      {shippingChannelSupportPacking ? (
                        <Layout
                          style={{
                            paddingHorizontal: 10,
                            borderRightColor: 'lightgrey',
                            borderRightWidth: 0.1,
                            alignItems: 'center',
                          }}>
                          <Autocomplete
                            label="Packaging material"
                            defaultValue={
                              packagingMaterialOptions.find(
                                (p) => p.id === props.values[focusedPackIndex].packagingMaterialId,
                              )?.title || props.values[focusedPackIndex].packagingMaterialId
                            }
                            options={packagingMaterialOptions}
                            getValue={(p) => p.id}
                            getTitle={(p) => p.title}
                            size="small"
                            onSelect={props.handleChange(`${focusedPackIndex}.packagingMaterialId`)}
                            onBlur={props.handleBlur(`${focusedPackIndex}.packagingMaterialId`)}
                          />
                          <View
                            style={{
                              width: canvasWidth,
                              height: canvasHeight,
                              overflow: 'hidden',
                            }}
                            nativeID="wrapper_view"
                          />
                        </Layout>
                      ) : null}
                    </Layout>
                  </Card>
                </>
              );
            }}
          </Formik>
        </Layout>
      </UIStatusWrapper>
    </ScrollView>
  );
});

const styles = StyleSheet.create({
  field: {
    marginVertical: 2,
  },
  smallColumn: {
    maxWidth: 40,
  },
});

export default memo(OcPackingScreen);
