import React, { useState, useRef, useMemo } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { ezTheme } from 'src/core/theme';

const TooltipCell = ({ content, showTooltipOnRight }) => {
  const [isHovered, setIsHovered] = useState(false);
  const contentRef = useRef(null);

  const isOverflowing = useMemo(() => {
    const contentElement = contentRef.current;
    if (contentElement) {
      return contentElement.scrollWidth > contentElement.clientWidth;
    }
    return false;
  }, [contentRef.current]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <View onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={styles.container}>
      <Text numberOfLines={1} ref={contentRef}>
        {content}
      </Text>
      {isHovered && isOverflowing && (
        <View
          style={[styles.tooltipContainer, showTooltipOnRight && styles.tooltipContainerSingleRow]}>
          <View style={styles.tooltipArrow} />
          <View style={styles.tooltip}>
            <Text style={styles.tooltipText}>{content}</Text>
          </View>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    position: 'relative',
    zIndex: 'auto',
  },
  tooltipContainer: {
    position: 'relative',
  },
  tooltipContainerSingleRow: {
    marginTop: -23,
    zIndex: 'auto',
  },
  tooltipArrow: {
    position: 'absolute',
    bottom: '100%',
    left: '50%',
    borderStyle: 'solid',
    borderBottomWidth: 5,
    borderBottomColor: ezTheme.black,
    borderLeftWidth: 3,
    borderLeftColor: 'transparent',
    borderRightWidth: 3,
    borderRightColor: 'transparent',
  },
  tooltip: {
    position: 'fixed',
    backgroundColor: ezTheme.black,
    borderRadius: 5,
    borderColor: ezTheme.white,
    padding: 6,
    zIndex: 1,
    maxHeight: 100,
    overflow: 'auto',
  },
  tooltipText: {
    color: ezTheme.white,
  },
});

export default TooltipCell;
