import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { Layout, Button, Text, Divider } from '@ui-kitten/components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Autocomplete from './Autocomplete';
import { useIsMobile } from '../core/responsive.utils';
import Select from './Select';
import DateRangeInput from './DateRangeInput';
import { TextInputField } from './input/TextInputField';
import { OC_STATUS_MAP } from '@ezom/library/lib/cjs/constants';
import { observer } from 'mobx-react';
import { inventoryStore, warehousesStore } from '../store';
import { UIStatusWrapper } from './ui-status';

const DEFAULT_VALUES = {
  refNumber: undefined,
  consignmentNumber: undefined,
  shippingNumber: undefined,
  status: undefined,
  sku_code: undefined,
  from_warehouse_code: undefined,
  logistics_product_code: undefined,
  seller_id: undefined,
  sales_platform: undefined,
  sales_no: undefined,
  country: undefined,
  create_time_start: undefined,
  create_time_end: undefined,
  complete_time_start: undefined,
  complete_time_end: undefined,
  page_no: undefined,
  page_size: undefined,
  customer_code: undefined,
};

export default observer(({ onSubmit, filterParams, onDismiss }) => {
  const isMobile = useIsMobile();
  const skus = inventoryStore.items?.map((s) => s.sku_code) || [];
  const warehouses = warehousesStore.warehouses;
  const warehouseNames = warehousesStore.warehouseNames;

  return (
    <Layout style={styles.form}>
      <Text category="h5" style={styles.title}>
        Search consignments
      </Text>
      <Divider />
      <UIStatusWrapper
        status={{
          error: inventoryStore.error || warehousesStore.error,
          indeterminate: inventoryStore.loading || warehousesStore.loading,
        }}>
        <Formik
          initialValues={filterParams || DEFAULT_VALUES}
          validationSchema={Yup.object({
            status: Yup.string(),
            consignmentNumber: Yup.string().trim(),
            refNumber: Yup.string().trim(),
            shippingNumber: Yup.string().trim(),
            sku_code: Yup.string().trim(),
            from_warehouse_code: Yup.string(),
            create_time_start: Yup.date(),
            create_time_end: Yup.date(),
            complete_time_start: Yup.date(),
            complete_time_end: Yup.date(),
          })}
          onSubmit={(values, formikActions) => {
            onSubmit(values);
            // Important: Make sure to setSubmitting to false so our loading indicator
            // goes away.
            formikActions.setSubmitting(false);
            onDismiss();
          }}>
          {(props) => {
            return (
              <>
                <Select
                  name="status"
                  style={styles.field}
                  options={Object.entries(OC_STATUS_MAP).map((s) => ({
                    key: s[0],
                    val: s[1],
                  }))}
                  label="Status"
                  value={OC_STATUS_MAP[props.values.status]}
                  error={props.errors.status}
                  touched={props.touched.status}
                  onChange={(val) => {
                    props.setFieldValue('status', val.key);
                  }}
                  onBlur={() => props.setFieldTouched('status', true)}
                  {...props}
                />

                <TextInputField
                  placeholder="Enter values (one per line)"
                  label="Consignment No."
                  name="consignmentNumber"
                  style={styles.field}
                  multiline
                  numberOfLines={props.multiline && props.values.consignmentNumber}
                  {...props}
                />
                <TextInputField
                  placeholder="Enter values (one per line)"
                  label="Ref. Order No."
                  name="refNumber"
                  style={styles.field}
                  multiline
                  numberOfLines={props.multiline && props.values.refNumber}
                  {...props}
                />
                <TextInputField
                  placeholder="Enter values (one per line)"
                  label="Tracking No."
                  name="shippingNumber"
                  style={styles.field}
                  multiline
                  numberOfLines={props.multiline && props.values.shippingNumber}
                  {...props}
                />

                <Select
                  name="sku_code"
                  label="SKU"
                  value={props.values.sku_code?.split(',')}
                  touched={props.touched.sku_code}
                  error={props.errors.sku_code}
                  style={styles.field}
                  onChange={(val) => {
                    props.setFieldValue('sku_code', val.map((v) => v.key).toString());
                  }}
                  placeholder="Select an SKU"
                  options={skus.map((sku) => ({
                    key: sku,
                    val: sku,
                  }))}
                  onBlur={() => props.setFieldTouched('sku_code', true)}
                  multiSelect
                  {...props}
                />

                <Autocomplete
                  label="From warehouse"
                  value={
                    warehouses.find((w) => w.warehouse_code === props.values.from_warehouse_code)
                      ?.warehouse_name_en || props.values.from_warehouse_code
                  }
                  error={props.errors.from_warehouse_code}
                  touched={props.touched.from_warehouse_code}
                  placeholder="Select a warehosue"
                  style={styles.field}
                  options={warehouseNames}
                  onSelect={(val) => {
                    const value =
                      warehouses.find((w) => w.warehouse_name_en === val)?.warehouse_code || val;
                    props.setFieldValue('from_warehouse_code', value);
                  }}
                  onBlur={props.handleBlur('from_warehouse_code')}
                />
                <DateRangeInput
                  style={styles.field}
                  label="Create time"
                  minVal={props.values.create_time_start}
                  maxVal={props.values.create_time_end}
                  minTouched={props.touched.create_time_start}
                  maxTouched={props.touched.create_time_end}
                  onMinValueChange={(date) => props.setFieldValue('create_time_start', date)}
                  onMaxValueChange={(date) => props.setFieldValue('create_time_end', date)}
                  onMinValueBlur={() => props.setFieldTouched('create_time_start', true)}
                  onMaxValueBlur={() => props.setFieldTouched('create_time_end', true)}
                  minError={props.errors.create_time_start}
                  maxError={props.errors.create_time_end}
                  minPlaceholder="YYYY-MM-DD"
                  maxPlaceholder="YYYY-MM-DD"
                />
                <DateRangeInput
                  style={styles.field}
                  label="Complete time"
                  minVal={props.values.complete_time_start}
                  maxVal={props.values.complete_time_end}
                  minTouched={props.touched.complete_time_start}
                  maxTouched={props.touched.complete_time_end}
                  onMinValueChange={(date) => props.setFieldValue('complete_time_start', date)}
                  onMaxValueChange={(date) => props.setFieldValue('complete_time_end', date)}
                  onMinValueBlur={() => props.setFieldTouched('complete_time_start', true)}
                  onMaxValueBlur={() => props.setFieldTouched('complete_time_end', true)}
                  minError={props.errors.complete_time_start}
                  maxError={props.errors.complete_time_end}
                  minPlaceholder="YYYY-MM-DD"
                  maxPlaceholder="YYYY-MM-DD"
                />
                <Divider />
                <Layout
                  style={isMobile ? styles.mobileButtonContainer : styles.desktopButtonContainer}>
                  <Button
                    status="primary"
                    loading={props.isSubmitting}
                    disabled={props.isSubmitting || !props.isValid}
                    onPress={props.handleSubmit}
                    style={styles.button}>
                    Submit
                  </Button>
                  <Button
                    status="basic"
                    loading={props.isSubmitting}
                    onPress={onDismiss}
                    disabled={props.isSubmitting}
                    style={styles.button}>
                    Cancel
                  </Button>
                  <Button
                    status="danger"
                    appearance="ghost"
                    disabled={props.isSubmitting}
                    onPress={() => props.setValues(DEFAULT_VALUES)}
                    style={styles.button}>
                    Reset
                  </Button>
                </Layout>
              </>
            );
          }}
        </Formik>
      </UIStatusWrapper>
    </Layout>
  );
});

const styles = StyleSheet.create({
  field: {
    marginVertical: 8,
  },
  title: {
    marginVertical: 10,
  },
  form: {
    alignSelf: 'center',
  },
  button: {
    marginVertical: 10,
    marginHorizontal: 2,
  },
  desktopButtonContainer: {
    flexDirection: 'row-reverse ',
  },
  mobileButtonContainer: {
    flexDirection: 'column',
  },
  subheading: {
    marginVertical: '0.8em',
  },
});
