import gql from 'graphql-tag';
import { flow, makeAutoObservable } from 'mobx';
import { ORDER_CANCEL_REASONS, SHOPIFY_ORDER_CANCEL_REASONS } from '../core/utils/utils';
import { STORE_PROVIDERS } from '@ezom/library/lib/cjs/constants';

const GET_CANCEL_ELIGIBILITY = gql`
  query getOrderCancelEligibility($orderId: ID!, $storeId: ID!) {
    orderCancellationEligibility(orderId: $orderId, storeId: $storeId) {
      isEligible
      possibleCancelReasons
      failureReasons
    }
  }
`;

const CANCEL_ORDER = gql`
  mutation cancelOrder($orderId: ID!, $storeId: ID!, $cancelReason: OrderCancelReason!) {
    cancelOrder(orderId: $orderId, storeId: $storeId, cancelReason: $cancelReason) {
      outcome
      userErrors {
        field
        message
      }
    }
  }
`;

export class OrderCancelStore {
  order = null;
  orderEligibilityData = null;
  isEligible = null;
  possibleCancelReasons = null;
  failureReasons = null;

  loading = true;
  error = null;

  constructor(client) {
    this.client = client;
    makeAutoObservable(
      this,
      {
        client: false,
        clearCached: false,
      },
      { autoBind: true },
    );
  }

  clearCached() {
    this.order = null;
    this.isEligible = false;
    this.possibleCancelReasons = null;

    this.loading = true;
    this.error = null;
  }

  async setOrder(order) {
    this.clearCached();
    this.order = order;
    await this.fetchCancellationEligibility();
  }

  fetchCancellationEligibility = flow(function* () {
    this.loading = true;
    try {
      const { data } = yield this.client.query({
        query: GET_CANCEL_ELIGIBILITY,
        variables: {
          storeId: this.order.storeId,
          orderId: this.order.id,
        },
      });
      this.isEligible = data.orderCancellationEligibility.isEligible;
      this.possibleCancelReasons = data.orderCancellationEligibility.possibleCancelReasons;

      return data.orderCancellationEligibility.isEligible;
    } catch (error) {
      this.error = error;
    } finally {
      this.loading = false;
      return false;
    }
  });

  get cancelReasons() {
    if (!this.order) return [];
    const { provider } = this.order;

    switch (provider) {
      case STORE_PROVIDERS.EBAY:
        return this.possibleCancelReasons
          ? this.possibleCancelReasons.map((key) => ({ key, val: ORDER_CANCEL_REASONS[key] }))
          : [];

      case STORE_PROVIDERS.SHOPIFY:
        return Object.keys(SHOPIFY_ORDER_CANCEL_REASONS).map((key) => ({
          key,
          val: ORDER_CANCEL_REASONS[key],
        }));

      case STORE_PROVIDERS.WOOCOMMERCE:
        return Object.keys(SHOPIFY_ORDER_CANCEL_REASONS).map((key) => ({
          key,
          val: ORDER_CANCEL_REASONS[key],
        }));
      case STORE_PROVIDERS.MAGENTO2:
        return Object.keys(SHOPIFY_ORDER_CANCEL_REASONS).map((key) => ({
          key,
          val: ORDER_CANCEL_REASONS[key],
        }));
      case STORE_PROVIDERS.OTHER:
        return Object.keys(SHOPIFY_ORDER_CANCEL_REASONS).map((key) => ({
          key,
          val: ORDER_CANCEL_REASONS[key],
        }));
      default:
        return [];
    }
  }

  async cancel(cancelReason) {
    const { id, storeId } = this.order;
    const cancelledOrder = await this.client.mutate({
      mutation: CANCEL_ORDER,
      variables: {
        orderId: id,
        storeId,
        cancelReason,
      },
    });
    return cancelledOrder;
  }
}
