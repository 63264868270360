import React, { memo, useLayoutEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Text, Layout, RadioGroup, Radio } from '@ui-kitten/components';
import { useIsMobile } from '../core/responsive.utils';
import { isNil } from 'ramda';

const TextInput = ({
  style = {},
  size = 'medium',
  label,
  options,
  value,
  onChange,
  disabled,
  defaultValue,
}) => {
  const isMobileDevice = useIsMobile();
  const [selectedIndex, setSelectedIndex] = useState(options.map(({ key }) => key).indexOf(value));

  useLayoutEffect(() => {
    setSelectedIndex(
      isNil(defaultValue) ? -1 : options.findIndex((opt) => opt.key === defaultValue),
    );
  }, [defaultValue, options]);

  return (
    <Layout
      style={[
        styles.rowContainer,
        isMobileDevice ? styles.mobileRowFlex : styles.desktopRowFlex,
        style,
      ]}
      level="1">
      {label ? (
        <Text
          category={size === 'large' ? 's1' : size === 'medium' ? 's2' : 'label'}
          style={isMobileDevice ? styles.mobileLabel : styles.desktopLabel}>
          {label}
        </Text>
      ) : null}
      <RadioGroup
        size={size}
        style={[
          styles.input,
          label ? (isMobileDevice ? styles.mobileInput : styles.desktopInput) : { width: '100%' },
        ]}
        selectedIndex={selectedIndex}
        onChange={(index) => {
          setSelectedIndex(index);
          onChange(options[index]);
        }}>
        {options.map(({ val, key }) => (
          <Radio title={val} key={key} style={styles.radio} disabled={disabled}>
            {val}
          </Radio>
        ))}
      </RadioGroup>
    </Layout>
  );
};

const styles = StyleSheet.create({
  input: {
    flex: 1,
    marginLeft: 5,
  },
  mobileLabel: {
    flex: 1,
    maxWidth: '100px',
  },
  desktopLabel: {
    minWidth: '130px',
  },
  rowContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mobileRowFlex: {
    marginHorizontal: 'auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  desktopRowFlex: {
    flexDirection: 'row',
    width: 'auto',
  },
  desktopInput: {
    width: '260px',
    minWidth: '260px',
  },
  mobileInput: {
    width: '200px',
    minWidth: '200px',
  },
});

export default memo(TextInput);
