import React from 'react';
import { Button, Card, Divider, Icon, Layout, ListItem, Text } from '@ui-kitten/components';
import { observer } from 'mobx-react';
import { ScrollView, StyleSheet, useWindowDimensions, View } from 'react-native';
import { sanitize } from '../core/utils/utils';
import { paperNativeTheme } from 'src/core/theme';

const isSubmitOcsEnabled = (ocs, errors) => {
  if (
    errors === undefined ||
    errors === null ||
    (typeof errors === 'object' && Object.keys(errors).length === 0)
  ) {
    return ocs.length > 0;
  }
  return false;
};

export default observer(({ ocs, errors, onSubmit, onClearOcs }) => {
  const windowHeight = useWindowDimensions().height;
  return (
    <View>
      <Header errors={errors} ocs={ocs} onSubmit={onSubmit} onPressBack={onClearOcs} />
      <Divider />
      <ScrollView style={{ maxHeight: windowHeight - 150 }}>
        {ocs.length > 0
          ? ocs.map((oc, index) => (
              <ListItem key={oc.refNo}>
                <ConsignmentDetail {...oc} rowNumber={index + 1} error={errors[index]} />
              </ListItem>
            ))
          : null}
      </ScrollView>
    </View>
  );
});

const ConsignmentDetail = observer(
  ({ rowNumber, refNo, address, courier, warehouse, sales_no, skus, error }) => (
    <Card style={styles.card}>
      <Layout style={styles.row}>
        <Layout style={[styles.column, { width: '5%' }]}>
          <Text category="p1">{rowNumber}</Text>
        </Layout>
        <Layout style={[styles.column, { width: '5%' }]}>
          {error ? <ErrorBadge /> : <TickBadge />}
        </Layout>
        <Layout style={[styles.column, { width: '15%' }]}>
          <Text category="p1">Ref No: {refNo}</Text>
          <Text category="p1">Sales No: {sales_no}</Text>
        </Layout>
        <Layout style={[styles.column, { width: '15%' }]}>
          <Text category="p1">Warehouse: {warehouse}</Text>
          <Text category="p1">Courier: {courier}</Text>
        </Layout>
        <Layout style={[styles.column, { width: '30%' }]}>
          <Text category="p2">
            {sanitize(address?.name)} {sanitize(address?.company)}
          </Text>
          <Text category="p2">
            Address: {sanitize(address?.house_number)} {sanitize(address?.street)}{' '}
            {sanitize(address?.district)} {sanitize(address?.city)} {sanitize(address?.state)}{' '}
            {sanitize(address?.countryCode)} {sanitize(address?.post_code)}
          </Text>
          <Text category="p2">Email: {sanitize(address?.email)} </Text>
          <Text category="p2">Phone: {sanitize(address?.phone)} </Text>
        </Layout>
        <Layout style={[styles.column, { width: '25%' }]}>
          <Text>SKUs</Text>
          {Array.isArray(skus) &&
            skus.map(({ sku, qty }, index) => (
              <Text key={`sku-${index}`}>
                {qty} x {sku}
              </Text>
            ))}
        </Layout>
      </Layout>
      <Layout style={{ alignItems: 'flex-end' }}>
        {error && (
          <Text category="p1" status="danger">
            {error.message}
          </Text>
        )}
        {error &&
          error.errors.map((errMsg, i) => (
            <Text key={i} category="p2" status="danger">
              {errMsg}
            </Text>
          ))}
      </Layout>
    </Card>
  ),
);

const UploadIcon = (props) => <Icon {...props} name="upload-outline" />;
const ReuploadIcon = (props) => <Icon {...props} name="flip-2-outline" />;

const Header = observer(({ errors, onSubmit, onPressBack, ocs }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  return (
    <Layout style={[styles.header]}>
      <Text style={{ marginBottom: '0.2em', width: '40%' }} category="h6">
        {ocs.length || 0} Outbound Consignment(s)
      </Text>
      <Text status="danger" style={styles.error} category="h6">
        {(typeof errors === 'object' && Object.keys(errors).length) || 0} contains error(s)
      </Text>
      <Button
        appearance="ghost"
        status="warning"
        onPress={onPressBack}
        accessoryLeft={ReuploadIcon}>
        Re-upload
      </Button>
      <Button
        status="primary"
        disabled={!isSubmitOcsEnabled(ocs, errors) || isSubmitting}
        appearance="ghost"
        accessoryLeft={UploadIcon}
        onPress={async () => {
          setIsSubmitting(true);
          try {
            await onSubmit();
          } finally {
            setIsSubmitting(false);
          }
        }}>
        Upload
      </Button>
    </Layout>
  );
});

const TickBadge = () => (
  <Icon
    name="checkmark-circle-2-outline"
    style={{ width: 20, height: 20, fill: paperNativeTheme.colors.success }}
  />
);

const ErrorBadge = () => (
  <Icon
    name="alert-circle-outline"
    style={{ width: 20, height: 20, fill: paperNativeTheme.colors.danger }}
  />
);

const styles = StyleSheet.create({
  error: { marginBottom: '0.2em', width: '40%' },
  row: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transpa',
  },
  header: {
    marginHorizontal: '0.8em',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    width: '100%',
  },

  modalLayout: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: { marginHorizontal: '0.5em' },
});
