import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { Autocomplete, AutocompleteItem, Text, Layout } from '@ui-kitten/components';
import { StyleSheet } from 'react-native';
import { paperNativeTheme } from 'src/core/theme';
import { useIsMobile } from '../core/responsive.utils';
import debounce from 'lodash/debounce';

const renderOption = (title, index) => <AutocompleteItem key={index} title={title} />;

const filter = (item, query) => item.toLowerCase().includes(query.toLowerCase());

const AutocompleteInput = ({
  error,
  touched,
  multiline,
  options,
  style = {},
  size = 'medium',
  label,
  onSearch,
  allowFreetext = false,
  getValue = (o) => o,
  getTitle = (o) => o,
  ...props
}) => {
  const isMobileDevice = useIsMobile();
  const [data, setData] = React.useState(options || []);
  useEffect(() => setData(options || []), [options]);
  const onSelect = useCallback(
    (index) => {
      if (multiline) {
        const values = (props.value || props.defaultValue)?.split('\n') || [];
        const newValues = values.concat(getValue(data[index])).join('\n');
        props.onSelect(newValues);
        setTextValue(
          newValues
            .split('\n')
            .map((v) => getTitle(v))
            .join('\n') + '\n',
        );
      } else {
        props.onSelect(getValue(data[index]));
        setTextValue(getTitle(data[index]));
      }
    },
    [props.value, data],
  );

  const [textValue, setTextValue] = React.useState(props.value);

  const onChangeText = useCallback(
    (value) => {
      setTextValue(value);
      if (allowFreetext) {
        props.onSelect(value);
      } else if (!value) {
        props.onSelect(value);
      }
    },
    [options],
  );

  const debouncedOnSearch = useCallback(
    onSearch
      ? debounce(async (query) => {
          const newData = await onSearch(query);
          setData(newData);
        }, 500)
      : () => {},
    [onSearch, setData],
  );

  useEffect(() => {
    const query = (textValue || '').split('\n').pop();
    if (onSearch) {
      debouncedOnSearch(query);
    } else {
      setData((options || []).filter((item) => filter(getTitle(item), query)));
    }
  }, [textValue, options, debouncedOnSearch]);

  return (
    <Layout
      style={[
        styles.rowContainer,
        isMobileDevice ? styles.mobileRowFlex : styles.desktopRowFlex,
        { width: 'fit-content' },
      ]}
      level="1">
      {label ? (
        <Text
          category={size === 'large' ? 's1' : size === 'medium' ? 's2' : 'label'}
          style={isMobileDevice ? styles.mobileLabel : styles.desktopLabel}>
          {label}
        </Text>
      ) : null}

      <Autocomplete
        // defaultValue is used in places llike inside Formik, where value keeps changing
        defaultValue={props.defaultValue}
        value={textValue}
        size={size}
        multiline={multiline}
        textStyle={multiline ? { minHeight: 64 } : {}}
        style={[
          styles.input,
          label ? (isMobileDevice ? styles.mobileInput : styles.desktopInput) : { width: '100%' },
          style,
        ]}
        selectionColor={paperNativeTheme.colors.primary}
        status={touched && error ? 'danger' : 'primary'}
        onChangeText={onChangeText}
        caption={error}
        {...props}
        onSelect={onSelect}>
        {data.map((item, index) => renderOption(getTitle(item), index))}
      </Autocomplete>
    </Layout>
  );
};

const styles = StyleSheet.create({
  input: {
    flex: 1,
    marginLeft: 5,
  },
  mobileLabel: {
    flex: 1,
    flexWrap: 'wrap',
    maxWidth: '100px',
  },
  desktopLabel: {
    minWidth: '130px',
  },
  rowContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  mobileRowFlex: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  desktopRowFlex: {
    flexDirection: 'row',
    width: 'auto',
  },
  desktopInput: {
    width: '260px',
    minWidth: '260px',
  },
  mobileInput: {
    width: '200px',
    minWidth: '200px',
  },
});

export default memo(AutocompleteInput);
