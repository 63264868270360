import { Formik } from 'formik';
import { StyleSheet, View } from 'react-native';
import { Button, Card, Text, Modal, Layout } from '@ui-kitten/components';
import React from 'react';
import { UIStatusWrapper, UIStatus } from './ui-status';
import { unifiedAlert } from '../core/utils/utils';
import Select from './Select';
import { observer } from 'mobx-react';
import { ezTheme } from '../core/theme';

export const WarehouseAccountSelectionModal = observer(
  ({ visible, onSubmit, onCancel, warehouseAccounts, loading, error }) => {
    if (warehouseAccounts.length === 1) {
      if (visible) {
        onSubmit(warehouseAccounts[0]);
      }
      return null;
    }

    return (
      <Modal visible={visible} backdropStyle={styles.backdrop} onBackdropPress={onCancel}>
        <Formik
          initialValues={{
            warehouseAccountId: '',
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              const warehouseAccount = warehouseAccounts.find(
                (w) => w.id === values.warehouseAccountId,
              );
              await onSubmit(warehouseAccount);
            } catch (e) {
              unifiedAlert(JSON.stringify(e));
              throw e;
            }
            setSubmitting(false);
          }}>
          {(props) => {
            const Footer = (footerProps) => (
              <Layout {...footerProps} style={styles.footerControl}>
                <Button
                  loading={props.isSubmitting}
                  disabled={loading}
                  status="info"
                  appearance="outline"
                  size="medium"
                  onPress={() => props.handleSubmit()}>
                  Submit
                </Button>
                <Button
                  loading={props.isSubmitting}
                  disabled={loading}
                  status="basic"
                  appearance="outline"
                  size="medium"
                  onPress={onCancel}>
                  Cancel
                </Button>
              </Layout>
            );

            return (
              <UIStatusWrapper status={new UIStatus(loading, error)}>
                <Card
                  style={styles.card}
                  header={(props) => (
                    <View {...props}>
                      <Text category="h6">Select a warehouse account to proceed</Text>
                    </View>
                  )}
                  footer={Footer}>
                  <Select
                    {...props}
                    style={styles.field}
                    label="Warehouse account"
                    placeholder="Select a warehouse account"
                    error={props.errors.warehouseAccountId}
                    touched={props.touched.warehouseAccountId}
                    value={
                      props.values.warehouseAccountId &&
                      warehouseAccounts.find((w) => w.id === props.values.warehouseAccountId)?.name
                    }
                    options={warehouseAccounts.map((w) => ({
                      key: w.id,
                      val: w.name,
                    }))}
                    onChange={(val) => {
                      props.setFieldValue('warehouseAccountId', val.key);
                    }}
                    onBlur={() => props.setFieldTouched('warehouseAccountId', true)}
                  />
                </Card>
              </UIStatusWrapper>
            );
          }}
        </Formik>
      </Modal>
    );
  },
);

const styles = StyleSheet.create({
  field: {
    marginVertical: 8,
  },
  footerControl: {
    display: 'flex',
    flexDirection: 'row',
    marginVertical: '0.5em',
    paddingHorizontal: '2em',
    justifyContent: 'space-between',
  },
  card: {
    flex: 1,
    margin: 2,
  },
  backdrop: {
    backgroundColor: ezTheme.backdropModalColor,
  },
});
