import React from 'react';
import { Dimensions, ScrollView, StyleSheet, View } from 'react-native';
import { useIsMobile } from '../core/responsive.utils';
import OrderCommentsList from '../screens/order/OrderCommentsList';
import { observer } from 'mobx-react';
import OrderShippingDetailsForm from '../screens/order/OrderShippingDetailsForm';
import LineItemList from './LineItemList';
import { Button, Card, Icon, Layout } from '@ui-kitten/components';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  scrollView: {
    minHeight: '50vh',
    overflowY: 'auto',
  },
  column: {
    marginHorizontal: '0.4em',
    marginVertical: '0.2em',
  },

  desktopContainer: {
    flexDirection: 'row',
  },

  desktopCard: {
    minWidth: '300px',
    width: '21%',
  },

  mobileCard: {
    width: '80%',
    marginBottom: '1em',
  },
});

const windowHeight = Dimensions.get('window').height;

export default observer(({ order, onClose = () => {} }) => {
  const isMobile = useIsMobile();

  return (
    <Card
      accent={() => (
        <Layout style={{ flexFlow: 'row-reverse' }}>
          <Button
            style={styles.button}
            appearance="ghost"
            status="info"
            size="small"
            onPress={onClose}
            accessoryLeft={(props) => (
              <Icon {...props} style={{ width: 35, height: 35 }} name="close-outline" />
            )}
          />
        </Layout>
      )}>
      <ScrollView
        style={{ maxHeight: windowHeight * 0.8, overflowY: 'auto' }}
        contentContainerStyle={styles.container}>
        <OrderShippingDetailsForm order={order} actionsAllowed={false} />
        <LineItemList order={order} actionsAllowed={false} />
        <View style={[styles.column, isMobile ? styles.mobileCard : styles.desktopCard]}>
          <OrderCommentsList order={order} noTitle={true} />
        </View>
      </ScrollView>
    </Card>
  );
});
