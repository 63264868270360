import React, { memo, useState, useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { Chip, Card } from 'react-native-paper';
import { useIsMobile, useLargeScreen } from 'src/core/responsive.utils';
import {
  Layout,
  Button,
  Icon,
  Popover,
  Text,
  RadioGroup,
  Radio,
  CheckBox,
} from '@ui-kitten/components';
import {
  ORDER_FULFILLMENT_STATUSES,
  ORDER_STATUSES,
  ORDER_STOCKS_STATUS,
} from '../core/utils/utils';
import { STORE_PROVIDERS } from '@ezom/library/lib/cjs/constants';
import { storeStore } from '../store';
import { observer } from 'mobx-react';
import SearchBar from './SearchBar';

const PROVIDERS = Object.values(STORE_PROVIDERS);

const SORT_OPTIONS = [
  {
    key: 'number',
    title: 'Order No.',
    order: 'ASC',
  },
  {
    key: 'number',
    title: 'Order No.',
    order: 'DESC',
  },
  {
    key: 'createdAt',
    title: 'Created Date',
    order: 'ASC',
  },
  {
    key: 'createdAt',
    title: 'Created Date',
    order: 'DESC',
  },
  {
    key: 'name',
    title: 'Name',
    order: 'ASC',
  },
  {
    key: 'name',
    title: 'Name',
    order: 'DESC',
  },
];

const OrderControl = observer(
  ({
    stores = [],
    filterParams = {},
    indicators = [],
    onSearchTermChange = () => {},
    onFilterChange = () => {},
    onSortChange = () => {},
    onPressAdvancedSearch = () => {},
    onFilterClear = () => {},
    disableSearch = false,
    requireButtonClickToSearch = false,
  }) => {
    const isMobileDevice = useIsMobile();
    const isLargeScreen = useLargeScreen();
    const [searchTerm, setSearchTerm] = useState('');
    const [sortVisible, setSortVisible] = useState(false);
    const [storeNameVisible, setStoreNameVisible] = useState(false);
    const [providerVisible, setProviderVisible] = useState(false);
    const [statusVisible, setStatusVisible] = useState(false);
    const [countryDropdownVisible, setCountryDropdownVisible] = useState(false);
    const [statusDropdownVisible, setStatusDropdownVisible] = useState(false);
    const [stockVisible, setStockVisible] = useState(false);
    const [sortIndex, setSortIndex] = useState(-1);

    return (
      <Card visible={true} style={styles.bannerContainer} actions={[]}>
        <Layout style={isMobileDevice ? styles.mobileBanner : styles.desktopBanner}>
          <SearchBar
            debounceDelayMs={200}
            disableSearch={disableSearch}
            requireButtonClickToSearch={requireButtonClickToSearch}
            inputPlaceholder="Search by number or customer name"
            inputSize="medium"
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            onSearchTermChange={onSearchTermChange}></SearchBar>
          <Layout style={isMobileDevice ? styles.mobileButtonGroup : styles.desktopButtonGroup}>
            {isLargeScreen && (
              <Popover
                visible={storeNameVisible}
                anchor={() => (
                  <Button
                    status="primary"
                    appearance="ghost"
                    style={styles.button}
                    onPress={() => setStoreNameVisible(true)}
                    accessoryRight={ArrowDownIcon}>
                    Store
                  </Button>
                )}
                onBackdropPress={() => {
                  setStoreNameVisible(false);
                }}
                style={styles.popover}>
                <Layout style={styles.popover}>
                  <Text>Stores</Text>
                  {stores.map(({ id, name }) => (
                    <CheckBox
                      key={id}
                      checked={filterParams.storeNames.includes(name)}
                      onChange={(checked) => {
                        if (checked) {
                          onFilterChange({
                            storeNames: [...filterParams.storeNames, name],
                          });
                        } else {
                          onFilterChange({
                            storeNames: filterParams.storeNames.filter((sName) => sName !== name),
                          });
                        }
                      }}
                      style={styles.checkItem}>
                      {name}
                    </CheckBox>
                  ))}
                </Layout>
              </Popover>
            )}

            {isLargeScreen && (
              <Popover
                visible={providerVisible}
                anchor={() => (
                  <Button
                    status="primary"
                    appearance="ghost"
                    style={styles.button}
                    onPress={() => setProviderVisible(true)}
                    accessoryRight={ArrowDownIcon}>
                    Provider
                  </Button>
                )}
                onBackdropPress={() => {
                  setProviderVisible(false);
                }}
                style={styles.popover}>
                <Layout style={styles.popover}>
                  <Text>Providers</Text>
                  {PROVIDERS.map((provider, index) => (
                    <CheckBox
                      key={`${provider}-${index}`}
                      checked={filterParams.providers.includes(provider)}
                      onChange={(checked) => {
                        if (checked) {
                          onFilterChange({
                            providers: [...filterParams.providers, provider],
                          });
                        } else {
                          onFilterChange({
                            providers: filterParams.providers.filter((s) => s !== provider),
                          });
                        }
                      }}
                      style={styles.checkItem}>
                      {provider}
                    </CheckBox>
                  ))}
                </Layout>
              </Popover>
            )}

            {isLargeScreen && (
              <Popover
                visible={statusVisible}
                anchor={() => (
                  <Button
                    status="primary"
                    appearance="ghost"
                    style={[styles.button]}
                    onPress={() => setStatusVisible(true)}
                    accessoryRight={ArrowDownIcon}>
                    Status
                  </Button>
                )}
                onBackdropPress={() => {
                  setStatusVisible(false);
                }}
                style={styles.popover}>
                <Layout style={styles.popover}>
                  <Text>Status</Text>
                  {Object.values(ORDER_STATUSES).map((status, index) => (
                    <CheckBox
                      key={`${status}-${index}`}
                      checked={filterParams.statuses.includes(status)}
                      onChange={(checked) => {
                        if (checked) {
                          onFilterChange({
                            statuses: [...filterParams.statuses, status],
                          });
                        } else {
                          onFilterChange({
                            statuses: filterParams.statuses.filter((s) => s !== status),
                          });
                        }
                      }}
                      style={styles.checkItem}>
                      {status}
                    </CheckBox>
                  ))}
                </Layout>
              </Popover>
            )}

            {isLargeScreen && (
              <Popover
                visible={statusDropdownVisible}
                anchor={() => (
                  <Button
                    status="primary"
                    appearance="ghost"
                    style={[styles.button]}
                    onPress={() => setStatusDropdownVisible(true)}
                    accessoryRight={ArrowDownIcon}>
                    Fulfillment
                  </Button>
                )}
                onBackdropPress={() => {
                  setStatusDropdownVisible(false);
                }}
                style={styles.popover}>
                <Layout style={styles.popover}>
                  <Text>Select Fulfillment status</Text>
                  {Object.values(ORDER_FULFILLMENT_STATUSES).map((status, index) => (
                    <CheckBox
                      key={`${status}-${index}`}
                      checked={filterParams.fulfillmentStatuses.includes(status)}
                      onChange={(checked) => {
                        if (checked) {
                          onFilterChange({
                            fulfillmentStatuses: [...filterParams.fulfillmentStatuses, status],
                          });
                        } else {
                          onFilterChange({
                            fulfillmentStatuses: filterParams.fulfillmentStatuses.filter(
                              (s) => s !== status,
                            ),
                          });
                        }
                      }}
                      style={styles.checkItem}>
                      {status}
                    </CheckBox>
                  ))}
                </Layout>
              </Popover>
            )}
            {isLargeScreen && (
              <Popover
                visible={countryDropdownVisible}
                anchor={() => (
                  <Button
                    status="primary"
                    appearance="ghost"
                    style={[styles.button]}
                    onPress={() => setCountryDropdownVisible(true)}
                    accessoryRight={ArrowDownIcon}>
                    Country
                  </Button>
                )}
                onBackdropPress={() => {
                  setCountryDropdownVisible(false);
                }}
                style={styles.popover}>
                <Layout style={styles.popover}>
                  <Text>Select country</Text>
                  {storeStore.orderCountryCodes.map((code) => (
                    <CheckBox
                      key={`${code}`}
                      checked={filterParams.countryCodes.includes(code)}
                      onChange={(checked) => {
                        if (checked) {
                          onFilterChange({
                            countryCodes: [...filterParams.countryCodes, code],
                          });
                        } else {
                          onFilterChange({
                            countryCodes: filterParams.countryCodes.filter((s) => s !== code),
                          });
                        }
                      }}
                      style={styles.checkItem}>
                      {code}
                    </CheckBox>
                  ))}
                </Layout>
              </Popover>
            )}
            {isLargeScreen && (
              <Popover
                visible={stockVisible}
                anchor={() => (
                  <Button
                    status="primary"
                    appearance="ghost"
                    style={[styles.button]}
                    onPress={() => setStockVisible(true)}
                    accessoryRight={ArrowDownIcon}>
                    Stock Status
                  </Button>
                )}
                onBackdropPress={() => {
                  setStockVisible(false);
                }}
                style={styles.popover}>
                <Layout style={styles.popover}>
                  <Text>Stock</Text>
                  {Object.values(ORDER_STOCKS_STATUS).map((stock, index) => (
                    <CheckBox
                      key={`${stock}-${index}`}
                      checked={filterParams?.stockStatus?.includes(stock)}
                      onChange={(checked) => {
                        if (checked) {
                          onFilterChange({
                            stockStatus: [...filterParams.stockStatus, stock],
                          });
                        } else {
                          onFilterChange({
                            stockStatus: filterParams.stockStatus.filter((s) => s !== stock),
                          });
                        }
                      }}
                      style={styles.checkItem}>
                      {stock === 'in_stock' ? 'In stock' : 'Out of stock'}
                    </CheckBox>
                  ))}
                </Layout>
              </Popover>
            )}

            <Popover
              visible={sortVisible}
              anchor={() => (
                <Button
                  status="primary"
                  appearance="ghost"
                  style={[styles.button]}
                  onPress={() => setSortVisible(true)}
                  accessoryRight={ArrowDownIcon}>
                  Sort
                </Button>
              )}
              onBackdropPress={() => {
                setSortVisible(false);
              }}
              style={styles.popover}>
              <Layout style={styles.popover}>
                <Text>Sort by</Text>
                <RadioGroup
                  selectedIndex={sortIndex}
                  onChange={(index) => {
                    setSortIndex(index);
                    onSortChange({
                      key: SORT_OPTIONS[index].key,
                      direction: SORT_OPTIONS[index].order,
                    });
                  }}>
                  {SORT_OPTIONS.map(({ key, title, order }) => (
                    <Radio key={`${title}-${order}`}>{`${title} (${order})`}</Radio>
                  ))}
                </RadioGroup>
              </Layout>
            </Popover>
            <Button
              status="primary"
              appearance="ghost"
              onPress={onPressAdvancedSearch}
              accessoryRight={ArrowUpRightIcon}
              style={styles.button}>
              Advanced
            </Button>
            <Button
              status="danger"
              appearance="ghost"
              onPress={() => {
                setSearchTerm('');
                onFilterClear();
              }}
              style={styles.button}>
              Reset filter
            </Button>
          </Layout>
        </Layout>
        <Layout style={styles.indicator}>
          {indicators.map((indicator) => (
            <Chip style={styles.chip} key={indicator}>
              {indicator}
            </Chip>
          ))}
        </Layout>
      </Card>
    );
  },
);

const styles = StyleSheet.create({
  bannerContainer: {
    marginBottom: '0.5em',
    padding: '1em',
  },
  desktopBanner: {
    maxWidth: '1500px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flexWrap: 'wrap',
  },
  mobileBanner: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: '1 1 auto',
  },
  indicator: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  chip: {
    marginTop: '0.4em',
    marginHorizontal: '0.2em',
    opacity: '0.7',
    backgroundColor: '#ff9d5c',
  },
  popover: {
    margin: '0.5em',
    padding: '0.5em',
  },
  rangeInput: {
    width: '100px',
    margin: '0.5em',
  },
  desktopButtonGroup: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mobileButtonGroup: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    height: '45px',
  },
  icon: {
    width: '28px',
    height: '28px',
    stroke: 'white',
    strokeWidth: '0.8',
  },
  checkItem: {
    marginVertical: 4,
    marginHorizontal: 12,
  },
});

const ArrowDownIcon = (props) => (
  <Icon
    {...props}
    name="arrow-ios-downward-outline"
    style={[props.style, styles.icon, { width: '20px', height: '20px' }]}
  />
);

const ArrowUpRightIcon = (props) => (
  <Icon
    {...props}
    name="diagonal-arrow-right-up-outline"
    style={[props.style, styles.icon, { width: '20px', height: '20px' }]}
  />
);

export default memo(OrderControl);
