import React, { useState, useCallback } from 'react';
import { StyleSheet } from 'react-native';
import TextInput from './input/TextInput';
import { useIsMobile } from 'src/core/responsive.utils';
import { Layout, Button, Icon } from '@ui-kitten/components';
import debounce from 'lodash.debounce';

export default ({
  debounceDelayMs = 800,
  disableSearch = true,
  onSearchTermChange,
  inputPlaceholder = 'Search',
  inputSize = 'medium',
  requireButtonClickToSearch = true,
  setSearchTerm,
  searchTerm,
  mobileSearchBar = {
    width: '100%',
  },
  desktopSearchBar = {
    width: '100%',
  },
}) => {
  const searchBarStyles = StyleSheet.create({
    mobileSearchBar,
    desktopSearchBar,
  });
  const _onChangeText = useCallback(
    debounce((text) => {
      if (requireButtonClickToSearch) {
        // require click on the appropriate button trigger search change
      } else {
        const s = text?.trim();
        if (s === '') {
          onSearchTermChange(null);
        } else if (s?.length >= 3) {
          onSearchTermChange(s);
        }
      }
    }, debounceDelayMs),
    [requireButtonClickToSearch],
  );

  let searchBarStyle = searchBarStyles.mobileSearchBar;
  if (isMobileDevice) {
    if (requireButtonClickToSearch) {
      searchBarStyle = styles.mobileSearchBarWithApplyButton;
    } else {
      searchBarStyle = searchBarStyles.mobileSearchBar;
    }
  } else {
    if (requireButtonClickToSearch) {
      searchBarStyle = styles.desktopSearchBarWithApplyButton;
    } else {
      searchBarStyle = searchBarStyles.desktopSearchBar;
    }
  }
  const isMobileDevice = useIsMobile();

  return (
    <Layout style={isMobileDevice ? styles.mobileSearchBarContainer : styles.searchBarContainer}>
      <TextInput
        disabled={disableSearch}
        autoFocus
        value={searchTerm}
        onChangeText={(text) => {
          setSearchTerm(text);
          _onChangeText(text);
        }}
        placeholder={inputPlaceholder}
        size={inputSize}
        style={searchBarStyle}
      />

      {requireButtonClickToSearch && (
        <Button
          disabled={disableSearch}
          onPress={() => {
            onSearchTermChange(searchTerm);
          }}
          accessoryLeft={(iconProps) => <Icon {...iconProps} name="search-outline" />}
          style={isMobileDevice ? styles.mobileApplySearchButton : styles.applySearchButton}>
          Search
        </Button>
      )}
    </Layout>
  );
};

const styles = StyleSheet.create({
  searchBarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: '1',
    gap: '5px',
  },
  mobileSearchBarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5px',
    width: '100%',
  },
  desktopSearchBarWithApplyButton: {
    width: '80%',
  },
  applySearchButton: {
    width: '20%',
  },
  mobileSearchBarWithApplyButton: {
    width: '80%',
  },
  mobileApplySearchButton: {
    width: '20%',
  },
  button: {
    height: '45px',
  },
  icon: {
    width: '28px',
    height: '28px',
    stroke: 'white',
    strokeWidth: '0.8',
  },
});
