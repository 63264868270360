import { flow, makeAutoObservable } from 'mobx';
import { sum, mapObjIndexed, max, clone, forEachObjIndexed } from 'ramda';
import Fuse from 'fuse.js';
import { PRODUCT_SALES_FORCAST } from '../core/utils/utils';
import { weightGramToKg } from '@ezom/library/lib/cjs/utils';

const FORMATTER_BY_KEY = {
  volume: (vol) => vol?.toFixed(0),
  weight: weightGramToKg(false),
};

const ALL_STORE_OPTION = 'ALL';

export class RestockListStore {
  TITLE_BY_KEY = {
    storeSku: 'Store SKU',
    warehouseSku: 'Warehouse SKU',
    available_stock: 'Available',
    available_stock_kept: 'Available for sell',
    onway_stock: 'Incoming',
    onway_stock_kept: 'Incoming available for sell',
    pending_stock: 'Outgoing stock',
    weight: 'Weight (kg)',
    volume: 'Vol. (c㎥)',
    restock: 'Restock*',
  };
  FORMATTER_BY_KEY = FORMATTER_BY_KEY;

  searchOpen = false;
  searchTerm = '';
  forecastPeriod = 12;

  cachedProductSalesForecast = null;
  loadingProductSalesForecast = true;
  loadingProductSalesForecastError = null;

  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  smoothPeriod = 6;
  selectedStore = ALL_STORE_OPTION;

  constructor(client, storeStore, salesStatisticsStore) {
    this.salesStatisticsStore = salesStatisticsStore;
    this.client = client;
    this.storeStore = storeStore;
    makeAutoObservable(
      this,
      {
        salesStatisticsStore: false,
        storeStore: false,
        client: false,
        fetchProductSalesForecast: flow,
      },
      { autoBind: true },
    );
  }

  setSelectedStore = (storeId) => {
    this.selectedStore = storeId;
  };

  get storeOptions() {
    const options = [...this.storeStore.enabledStores, { id: ALL_STORE_OPTION, name: 'All' }].map(
      (s) => ({
        key: s.id,
        val: s.name,
      }),
    );
    return options;
  }

  get loading() {
    return this.loadingProductSalesForecast || this.salesStatisticsStore.loadingStoreSkuStock;
  }

  get error() {
    return (
      this.loadingProductSalesForecastError || this.salesStatisticsStore.loadingStoreSkuStockError
    );
  }

  get displayKeys() {
    const keys = [
      'available_stock',
      'available_stock_kept',
      'onway_stock',
      'onway_stock_kept',
      'pending_stock',
      'restock',
    ];

    return ['storeSku', ...keys, 'weight', 'volume'];
  }

  get storeSkuCodeStock() {
    const storeSkuCodeStock = this.salesStatisticsStore.storeSkuStock?.storeSkuCodeStock || {};
    let ret;
    if (this.selectedStore === ALL_STORE_OPTION) {
      const mergedStoreSkuCodeStock = {};
      Object.values(storeSkuCodeStock).forEach((store) => {
        Object.keys(store).forEach((sku) => {
          if (!mergedStoreSkuCodeStock[sku]) {
            mergedStoreSkuCodeStock[sku] = store[sku];
            return;
          }
          forEachObjIndexed((val, key) => {
            forEachObjIndexed((qty, wSku) => {
              if (mergedStoreSkuCodeStock[sku][key][wSku]) {
                mergedStoreSkuCodeStock[sku][key][wSku] += qty;
              } else {
                mergedStoreSkuCodeStock[sku][key] = { [wSku]: qty };
              }
            }, val);
          }, store[sku]);
        });
      });
      ret = mergedStoreSkuCodeStock;
    } else {
      ret = storeSkuCodeStock[this.selectedStore] || {};
    }
    return ret;
  }

  get storeSkuCodeStockKept() {
    const storeSkuCodeStockKept =
      this.salesStatisticsStore.storeSkuStock?.storeSkuCodeStockKept || {};
    let ret;
    if (this.selectedStore === ALL_STORE_OPTION) {
      const mergedStoreSkuCodeStock = {};
      Object.values(storeSkuCodeStockKept).forEach((store) => {
        Object.keys(store).forEach((sku) => {
          if (!mergedStoreSkuCodeStock[sku]) {
            mergedStoreSkuCodeStock[sku] = store[sku];
            return;
          }
          forEachObjIndexed((val, key) => {
            forEachObjIndexed((qty, wSku) => {
              if (mergedStoreSkuCodeStock[sku][key][wSku]) {
                mergedStoreSkuCodeStock[sku][key][wSku] += qty;
              } else {
                mergedStoreSkuCodeStock[sku][key] = { [wSku]: qty };
              }
            }, val);
          }, store[sku]);
        });
      });
      ret = mergedStoreSkuCodeStock;
    } else {
      ret = storeSkuCodeStockKept[this.selectedStore] || {};
    }
    return ret;
  }

  get items() {
    const storeSkuStock = this.storeSkuCodeStock;
    const storeSkuStockKept = this.storeSkuCodeStockKept;
    const productSalesForecast = mapObjIndexed((data) => {
      return sum([...(data?.forecasted || []).map((f) => f.value), 0]);
    }, this.productSalesForecast);
    const collapsedData = mapObjIndexed((val, key) => {
      const ret = {
        ...val,
        available_stock: val.available_stock,
        available_stock_kept: storeSkuStockKept[key]?.available_stock || 0,
        onway_stock: val.onway_stock || 0,
        onway_stock_kept: storeSkuStockKept[key]?.onway_stock || 0,
        pending_stock: val.pending_stock || 0,
      };
      ret.restock = max(
        (productSalesForecast[key] || 0) - ret.available_stock_kept - ret.onway_stock_kept,
        0,
      );
      return ret;
    }, storeSkuStock);
    return Object.entries(collapsedData).map(([storeSku, val]) => ({ storeSku, ...val }));
  }

  get searchFuse() {
    const fuse = new Fuse([], {
      keys: this.displayKeys,
      threshold: 0.15,
      shouldSort: true,
      ignoreLocation: true,
      minMatchCharLength: 2,
    });
    fuse.setCollection(this.items);
    return fuse;
  }

  get filteredItems() {
    if (this.searchTerm) {
      return this.searchFuse.search(this.searchTerm).map(({ item }) => item);
    }
    return this.items;
  }

  get productSalesForecast() {
    if (!this.cachedProductSalesForecast) {
      this.fetchProductSalesForecast();
    }
    let storeProductSalesForecast;
    if (this.selectedStore === ALL_STORE_OPTION) {
      const productSalesForecastArray = Object.values(this.cachedProductSalesForecast || {});
      const mergedSalesForecast = {};
      productSalesForecastArray.forEach((sales) => {
        Object.keys(sales).forEach((sku) => {
          if (!mergedSalesForecast[sku]) {
            mergedSalesForecast[sku] = {
              forecasted: [],
              past: [],
            };
          }
          Object.keys(sales[sku]).forEach((key) => {
            sales[sku][key].forEach((salesEntry) => {
              const matchingDateIndex = mergedSalesForecast[sku][key].findIndex(
                (s) => s.date === salesEntry.date,
              );
              if (matchingDateIndex !== -1) {
                mergedSalesForecast[sku][key][matchingDateIndex].value += salesEntry.value;
              } else {
                mergedSalesForecast[sku][key].push(clone(salesEntry));
              }
            });
          });
        });
      });
      storeProductSalesForecast = mergedSalesForecast;
    } else {
      storeProductSalesForecast = this.cachedProductSalesForecast?.[this.selectedStore] || {};
    }
    this.selectedProduct = Object.keys(storeProductSalesForecast)?.[0] || null;
    return storeProductSalesForecast;
  }

  *fetchProductSalesForecast() {
    try {
      this.loadingProductSalesForecast = true;
      const { data } = yield this.client.query({
        query: PRODUCT_SALES_FORCAST,
        variables: {
          storeIds: yield this.storeStore.getEnabledStoreIds(),
          timeZone: this.timeZone,
          smoothPeriod: this.smoothPeriod,
          forecastPeriod: this.forecastPeriod,
          interval: 'WEEK',
        },
      });
      this.cachedProductSalesForecast = data.productSalesForecast;
    } catch (error) {
      this.loadingProductSalesForecastError = error;
      console.error(error);
    } finally {
      this.loadingProductSalesForecast = false;
    }
  }

  setSearchTerm = (term) => {
    this.searchTerm = term;
  };

  setForecastPeriod = (period) => {
    this.cachedProductSalesForecast = null;
    this.forecastPeriod = period;
  };

  resetSearch = () => {
    this.setSearchTerm('');
  };
}
