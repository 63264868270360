import '@expo/match-media';
import React, { memo } from 'react';
import { StyleSheet } from 'react-native';
import { Layout, Text, Datepicker, NativeDateService } from '@ui-kitten/components';
import { useIsMobile } from '../core/responsive.utils';

const formatDateService = new NativeDateService('en', { format: 'DD.MMM.YYYY' });

const DateInput = ({ label, size = 'medium', style = {}, error, touched, ...props }) => {
  const isMobileDevice = useIsMobile();
  return (
    <Layout
      style={[
        styles.rowContainer,
        isMobileDevice ? styles.mobileRowFlex : styles.desktopRowFlex,
        style,
      ]}
      level="1">
      <Text
        category={size === 'large' ? 's1' : size === 'medium' ? 's2' : 'label'}
        style={isMobileDevice ? styles.mobileLabel : styles.desktopLabel}>
        {label}
      </Text>
      <Datepicker
        size={size}
        style={[styles.input, isMobileDevice ? styles.mobileInput : styles.desktopInput]}
        status={touched && error ? 'danger' : 'primary'}
        caption={error}
        dateService={formatDateService}
        {...props}
      />
    </Layout>
  );
};

const styles = StyleSheet.create({
  input: {
    flex: 1,
    marginLeft: 5,
  },
  mobileLabel: {
    flex: 1,
    flexWrap: 'wrap',
    maxWidth: '100px',
  },
  desktopLabel: {
    minWidth: '130px',
  },
  rowContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  mobileRowFlex: {
    marginHorizontal: 'auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  desktopRowFlex: {
    flexDirection: 'row',
    width: 'auto',
    marginHorizontal: 'auto',
  },
  desktopInput: {
    width: '260px',
    minWidth: '260px',
  },
  mobileInput: {
    width: '200px',
    minWidth: '200px',
  },
});

export default memo(DateInput);
