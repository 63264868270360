import React, { useMemo } from 'react';
import { StyleSheet, Dimensions, ScrollView, Linking } from 'react-native';
import { Layout, Button, Text, Divider, Card } from '@ui-kitten/components';
import * as Yup from 'yup';
import { useIsMobile } from '../core/responsive.utils';
import { Formik } from 'formik';
import Select from './Select';
import DateInput from './DateInput';
import InlineTable from './InlineTable';
import { TextInputField } from './input/TextInputField';
import { Modal, Portal } from 'react-native-paper';
import { gql, useQuery } from '@apollo/client';
import { UIStatusWrapper } from './ui-status';
import { TO_WAREHOUSE_TRANSPORTATION_TYPE, useUser } from '../core/utils/utils';
import { sum } from 'ramda';
import { warehouseAccountStore } from '../store';

const INBOUNDS = gql`
  query inbounds($warehouseAccountIds: [ID!]!, $consignmentNumbers: [ID!]!) {
    inbounds(warehouseAccountIds: $warehouseAccountIds, consignmentNumbers: $consignmentNumbers) {
      consignments {
        consignment_no
        ref_no
        total_volume
        total_weight
        lstsku {
          plan_qty
        }
      }
    }
  }
`;

const SYSTEM_INFO = gql`
  query SystemInfo {
    systemInfo {
      email
    }
  }
`;

const windowHeight = Dimensions.get('window').height;

const openBookingEmail = ({
  recipientEmail,
  email,
  landTransportCompany,
  numBoxes,
  portDepartureDate,
  portArrivalDate,
  volume,
  weight,
  deliveryMethod,
  eta2Warehouse,
  note,
  user,
  consignments,
}) => {
  const newLine = '%0d%0a';
  const body =
    `Name: ${user.name || ''}${newLine}Email: ${user.email || ''}${newLine}Contact phone: ${
      user.phone || ''
    }${newLine}Contact Email: ${email}${newLine}` +
    `Land Transport Company: ${landTransportCompany || ''}${newLine}Number of Boxes: ${
      numBoxes || ''
    }${newLine}Volume: ${volume || ''}${newLine}` +
    `Weight: ${weight || ''}${newLine}Delivery Method: ${
      deliveryMethod || ''
    }${newLine}ETA to warehouse: ${eta2Warehouse?.toLocaleDateString() || ''}${newLine}` +
    `Port Departure Date: ${
      portDepartureDate?.toLocaleDateString() || ''
    }${newLine}Port Arrival Date: ${portArrivalDate?.toLocaleDateString() || ''}${newLine}Notes: ${
      note || ''
    }${newLine}` +
    `---------------------------------------------------${newLine}` +
    consignments
      .map(
        (consignment) =>
          `Consignment No.: ${consignment.consignment_no || ''}${newLine}Reference No.: ${
            consignment.ref_no || ''
          }${newLine}Volume: ${consignment.total_volume || ''}${newLine}Boxes: ${
            consignment.numOfItems || ''
          }${newLine}` + `############################${newLine}`,
      )
      .join(newLine) +
    `---------------------------------------------------${newLine}` +
    `Business name: ${user.organisation?.name || ''}${newLine}Business address: ${
      user.organisation?.address || ''
    }${newLine}Business Email: ${user.organisation?.email || ''}${newLine}Business phone: ${
      user.organisation.phone || ''
    }${newLine}`;

  Linking.openURL(
    `mailto:${recipientEmail}?subject=${'Request: Warehouse Devanning'}&body=${body}`,
  );
};

export default ({ visible, setVisible, consignmentNumbers }) => {
  const { loading, error, data } = useQuery(INBOUNDS, {
    variables: {
      warehouseAccountIds: warehouseAccountStore.warehouseAccountIds,
      consignmentNumbers,
    },
    skip: consignmentNumbers?.length < 1,
  });

  const {
    loading: loadingSystemInfo,
    error: loadingSystemInfoError,
    data: systemData,
  } = useQuery(SYSTEM_INFO);

  const recipientEmail = (data && data.email) || 'hello@ezom.app';

  const user = useUser();
  const isMobile = useIsMobile();
  const plannedQty = useMemo(() => {
    let totalQty = 0;
    data?.inbounds?.consignments?.forEach((c) => {
      c.lstsku.forEach((sku) => {
        totalQty += Number(sku.plan_qty || 0);
      });
    });
    return totalQty;
  }, [data?.inbounds?.consignments]);

  const plannedVolume = useMemo(() => {
    let totalVolume = 0;
    data?.inbounds?.consignments?.forEach((c) => {
      totalVolume += c.total_volume;
    });
    return totalVolume;
  }, [data?.inbounds?.consignments]);

  const plannedWeight = useMemo(() => {
    let totalWeight = 0;
    data?.inbounds?.consignments?.forEach((c) => {
      totalWeight += c.total_weight;
    });
    return totalWeight;
  }, [data?.inbound?.consignmentss]);

  return (
    <Portal>
      <Modal
        visible={visible}
        onDismiss={() => setVisible(false)}
        contentContainerStyle={styles.modalStyle}>
        <Layout>
          <Text category="h5" style={styles.title}>
            Devanning Appointment
          </Text>
          <UIStatusWrapper
            status={{
              error: error || loadingSystemInfoError,
              emtpy: !!data,
              indeterminate: loading || loadingSystemInfo,
            }}>
            <ScrollView
              keyboardShouldPersistTaps={'handled'}
              contentContainerStyle={styles.formContainer}>
              <Formik
                isInitialValid={false}
                initialValues={{
                  landTransportCompany: '',
                  numBoxes: plannedQty,
                  portDepartureDate: '',
                  portArrivalDate: '',
                  volume: plannedVolume,
                  weight: plannedWeight,
                  deliveryMethod: '',
                  eta2Warehouse: '',
                  email: '',
                  note: '',
                }}
                validationSchema={Yup.object({
                  landTransportCompany: Yup.string().required(),
                  numBoxes: Yup.number().integer().min(1).required(),
                  portDepartureDate: Yup.date().max(new Date()).required(),
                  portArrivalDate: Yup.date().min(Yup.ref('portDepartureDate')).required(),
                  volume: Yup.number().required().min(0),
                  weight: Yup.number().required().min(0),
                  deliveryMethod: Yup.string()
                    .oneOf(Object.values(TO_WAREHOUSE_TRANSPORTATION_TYPE))
                    .required(),
                  eta2Warehouse: Yup.date().min(Yup.ref('portArrivalDate')).required(),
                  email: Yup.string().email().required(),
                  note: Yup.string(),
                })}
                onSubmit={(values, formikActions) => {
                  openBookingEmail({
                    recipientEmail,
                    consignments: data?.inbounds?.consignments,
                    user,
                    ...values,
                  });
                }}>
                {(props) => {
                  return (
                    <>
                      <TextInputField
                        label="Transport Company"
                        style={styles.field}
                        name="landTransportCompany"
                        {...props}
                      />
                      <TextInputField
                        label="Number of boxes"
                        keyboardType="numeric-pad"
                        style={styles.field}
                        name="numBoxes"
                        {...props}
                      />
                      <DateInput
                        label="Port Departure Date"
                        style={styles.field}
                        error={props.errors.portDepartureDate}
                        touched={props.touched.portDepartureDate}
                        date={props.values.portDepartureDate}
                        max={new Date()}
                        onSelect={(date) => props.setFieldValue('portDepartureDate', date)}
                        onBlur={() => props.setFieldTouched('portDepartureDate', true)}
                      />
                      <DateInput
                        label="Port Arrival Date"
                        style={styles.field}
                        error={props.errors.portArrivalDate}
                        touched={props.touched.portArrivalDate}
                        date={props.values.portArrivalDate}
                        min={props.values.portDepartureDate}
                        onSelect={(date) => props.setFieldValue('portArrivalDate', date)}
                        onBlur={() => props.setFieldTouched('portArrivalDate', true)}
                      />

                      <Select
                        label="Delivery Method"
                        value={props.values.deliveryMethod}
                        error={props.errors.deliveryMethod}
                        touched={props.touched.deliveryMethod}
                        style={styles.field}
                        options={Object.values(TO_WAREHOUSE_TRANSPORTATION_TYPE)}
                        onChange={(val) => props.setFieldValue('deliveryMethod', val)}
                        onBlur={() => props.setFieldTouched('deliveryMethod', true)}
                      />
                      <DateInput
                        label="ETA to Warehouse"
                        style={styles.field}
                        error={props.errors.eta2Warehouse}
                        touched={props.touched.eta2Warehouse}
                        date={props.values.eta2Warehouse}
                        min={new Date()}
                        onSelect={(date) => props.setFieldValue('eta2Warehouse', date)}
                        onBlur={() => props.setFieldTouched('eta2Warehouse', true)}
                      />
                      <TextInputField
                        label="Contact email"
                        style={styles.field}
                        name="email"
                        {...props}
                      />
                      <TextInputField
                        label="Notes"
                        style={styles.field}
                        name="note"
                        textStyle={{ minHeight: 64 }}
                        multiline={true}
                        {...props}
                      />
                      <TextInputField
                        label="Volume (M³)"
                        style={styles.field}
                        name="volume"
                        disabled
                        {...props}
                      />
                      <TextInputField
                        label="Weight (KG)"
                        style={styles.field}
                        name="weight"
                        disabled
                        {...props}
                      />
                      <InlineTable
                        items={data?.inbounds?.consignments.map((consignment) => ({
                          ...consignment,
                          numOfItems: sum(consignment.lstsku.map((sku) => sku.plan_qty)),
                        }))}
                        columnByKey={{
                          consignment_no: { title: 'Consignment No.' },
                          ref_no: { title: 'Order No.' },
                          total_volume: { title: 'Vol. (m³)' },
                          numOfItems: { title: 'Boxes', numeric: true, size: 'small' },
                        }}
                      />
                      <Divider />
                      <Layout
                        style={
                          isMobile ? styles.mobileButtonContainer : styles.desktopButtonContainer
                        }>
                        <Button
                          status="primary"
                          loading={props.isSubmitting}
                          onPress={props.handleSubmit}
                          disabled={props.isSubmitting || !props.isValid}
                          style={styles.button}>
                          Submit
                        </Button>
                        <Button
                          status="basic"
                          loading={props.isSubmitting}
                          onPress={() => setVisible(false)}
                          disabled={props.isSubmitting}
                          style={styles.button}>
                          Cancel
                        </Button>
                      </Layout>
                    </>
                  );
                }}
              </Formik>
            </ScrollView>
          </UIStatusWrapper>
        </Layout>
      </Modal>
    </Portal>
  );
};

const styles = StyleSheet.create({
  field: {
    marginVertical: 8,
  },
  title: {
    marginVertical: 10,
  },
  button: {
    marginVertical: 10,
    marginHorizontal: 2,
  },
  desktopButtonContainer: {
    flexDirection: 'row-reverse ',
  },
  mobileButtonContainer: {
    flexDirection: 'column',
  },
  controlContainer: {
    borderRadius: 4,
    margin: 2,
    padding: 6,
    backgroundColor: '#3366FF',
  },
  formContainer: {
    height: 0.75 * windowHeight,
    overflowY: 'auto',
    alignItems: 'center',
  },
  modalStyle: {
    minWidth: 600,
    backgroundColor: 'white',
    padding: 20,
    alignSelf: 'center',
  },
  header: { alignSelf: 'center', paddingVertical: 10 },
});
