import * as React from 'react';
import { StyleSheet } from 'react-native';
import { Card } from 'react-native-paper';
import { Button, Layout } from '@ui-kitten/components';
import { useIsMobile } from '../core/responsive.utils';

export default ({ children, onSubmit, onDismiss }) => {
  const isMobile = useIsMobile();
  return (
    <Card disabled={true} style={styles.dialogueStyle}>
      {children}
      <Layout style={isMobile ? styles.mobileButtonContainer : styles.desktopButtonContainer}>
        <Button status="danger" onPress={onSubmit} style={styles.button}>
          Submit
        </Button>
        <Button status="basic" onPress={onDismiss} style={styles.button}>
          Cancel
        </Button>
      </Layout>
    </Card>
  );
};

const styles = StyleSheet.create({
  modalStyle: { backgroundColor: 'white', margin: 20 },
  dialogueStyle: {
    alignSelf: 'center',
  },
  button: {
    marginVertical: 10,
    marginHorizontal: 2,
  },
  desktopButtonContainer: {
    flexDirection: 'row-reverse ',
  },
  mobileButtonContainer: {
    flexDirection: 'column',
  },
});
