import * as React from 'react';
import { StyleSheet } from 'react-native';
import { Card } from 'react-native-paper';
import { observer } from 'mobx-react';
import Table from '../../components/Table';
import Background from '../../components/Background';
import exportCsv from '../../core/csv';
import RestockListControl from '../../components/RestockListControl';
import { UIStatusWrapper } from '../../components/ui-status';
import { useIsFocused } from '@react-navigation/core';
import { restockListStore, salesStatisticsStore } from '../../store';

const getId = (item) => item.storeSku || item.warehouseSku;
const selectedSkus = (skus, selectedIds) => skus.filter((sku) => selectedIds.has(getId(sku)));
const MAX_NO_OF_ITEMS_AUTO_SEARCH = 200;

export default observer(({ navigation }) => {
  const isFocused = useIsFocused();
  // Refetch data when screen is focused
  React.useEffect(() => {
    if (isFocused) {
      salesStatisticsStore.fetchStoreSkuStock();
      salesStatisticsStore.fetchLineItemWithoutSku();
      salesStatisticsStore.fetchProductSalesForecast();
      salesStatisticsStore.fetchSalesForecast();
    }
  }, [isFocused]);
  return (
    <Background fullWidth={true}>
      <RestockListControl
        disableSearch={salesStatisticsStore.loading || restockListStore.loading}
        requireButtonClickToSearch={
          restockListStore.filteredItems.length > MAX_NO_OF_ITEMS_AUTO_SEARCH
        }
        onSearchTermChange={restockListStore.setSearchTerm}
      />
      <Card>
        <UIStatusWrapper
          status={{
            error: restockListStore.error,
            indeterminate: restockListStore.loading,
          }}>
          <Table
            items={restockListStore.filteredItems}
            getId={getId}
            displayKeys={restockListStore.displayKeys}
            sort="single"
            onSortChange={restockListStore.setSortOption}
            heightOffset={300}
            titleByKey={restockListStore.TITLE_BY_KEY}
            formatterByKey={restockListStore.FORMATTER_BY_KEY}
            isFabVisible={useIsFocused()}
            fabActions={(selectedIds) => {
              const actions = [];
              if (selectedIds.size === 0) {
                actions.push({
                  icon: 'download-outline',
                  label: 'Export all',
                  onPress: () => {
                    const items = restockListStore.filteredItems.map(
                      ({ __typename, ...fields }) => ({
                        ...fields,
                      }),
                    );
                    const csvHeaders = items?.length > 0 ? Object.keys(items[0]) : [];
                    exportCsv(items, csvHeaders, 'restock.csv');
                  },
                });
              }

              if (selectedIds.size > 0) {
                actions.push({
                  icon: 'download-outline',
                  label: `Export selected ${selectedIds.size > 1 ? 'item' : 'items'}`,
                  onPress: () => {
                    const items = selectedSkus(restockListStore.filteredItems, selectedIds);
                    const exportedItems = items.map(({ __typename, ...fields }) => ({
                      ...fields,
                    }));
                    const csvHeaders =
                      exportedItems?.length > 0 ? Object.keys(exportedItems[0]) : [];
                    exportCsv(exportedItems, csvHeaders, 'restock.csv');
                  },
                });
              }

              return actions;
            }}
          />
        </UIStatusWrapper>
      </Card>
    </Background>
  );
});

const styles = StyleSheet.create({
  modalStyle: { backgroundColor: 'white', margin: 20 },
});
