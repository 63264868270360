import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Formik } from 'formik';
import { Input, Button } from '@ui-kitten/components';
import * as Yup from 'yup';
import { Card } from 'react-native-paper';

const styles = StyleSheet.create({
  btnGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
  },
});

export default ({
  onSubmit,
  onCancel,
  initialValues = { notes: '', trackingNumber: '', trackingUrl: 'http://' },
}) => (
  <Formik
    initialValues={initialValues}
    validationSchema={Yup.object({
      trackingNumber: Yup.string()
        .matches(/^[A-Za-z0-9\-\_\#\*\/\&\+\?\.\(\)]+$/, 'No space allowed')
        .required(),
      trackingUrl: Yup.string().url('Not a valid URL'),
      notes: Yup.string(),
    })}
    onSubmit={async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      await onSubmit(values);
      resetForm();
      setSubmitting(false);
    }}>
    {(formProps) => {
      return (
        <View style={{ margin: '0.6em', color: 'black' }}>
          <Input
            value={formProps.values['trackingNumber']}
            size="small"
            label="Tracking number"
            onChangeText={formProps.handleChange('trackingNumber')}
            onBlur={formProps.handleBlur('trackingNumber')}
            caption={formProps.errors.trackingNumber}
            touched={formProps.touched.trackingNumber}
            status={
              formProps.touched.trackingNumber && formProps.errors.trackingNumber
                ? 'danger'
                : 'primary'
            }
            {...formProps}
          />
          <Input
            value={formProps.values['trackingUrl']}
            size="small"
            label="Tracking URL"
            onChangeText={formProps.handleChange('trackingUrl')}
            onBlur={formProps.handleBlur('trackingUrl')}
            caption={formProps.errors.trackingUrl}
            status={
              formProps.touched.trackingUrl && formProps.errors.trackingurl ? 'danger' : 'primary'
            }
            touched={formProps.touched.trackingUrl}
            {...formProps}
          />
          <Input
            value={formProps.values['notes']}
            size="small"
            label="Notes"
            onChangeText={formProps.handleChange('notes')}
            onBlur={formProps.handleBlur('notes')}
            caption={formProps.errors.notes}
            touched={formProps.touched.notes}
            status={formProps.touched.notes && formProps.errors.notes ? 'danger' : 'primary'}
            {...formProps}
          />

          <Card.Actions style={styles.btnGroup}>
            <Button
              appearance="ghost"
              status="primary"
              onPress={formProps.submitForm}
              primary
              loading={formProps.isSubmitting}
              disabled={formProps.isSubmitting || !formProps.dirty || !formProps.isValid}>
              Save
            </Button>
            <Button
              appearance="ghost"
              status="warning"
              onPress={() => {
                formProps.resetForm();
                onCancel && onCancel();
              }}
              basic
              loading={formProps.isSubmitting}
              disabled={formProps.isSubmitting}>
              Cancel
            </Button>
          </Card.Actions>
        </View>
      );
    }}
  </Formik>
);
