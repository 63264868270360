import React from 'react';
import { StyleSheet, Dimensions, ScrollView, Linking } from 'react-native';
import { Layout, Button, Text, Divider, Toggle } from '@ui-kitten/components';
import * as Yup from 'yup';
import { useIsMobile } from '../core/responsive.utils';
import { Formik } from 'formik';
import { UIStatusWrapper } from './ui-status';
import { printerSettingStore } from '../store';
import { unifiedAlert } from '../core/utils/utils';
import { observer } from 'mobx-react';
import { RadioGroupInputField } from './input/RadioGroupInputField';
import Autocomplete from './Autocomplete';
import { PAPER_SIZE_LIST, ORIENTATION_LIST } from '../core/utils/utils';

const windowHeight = Dimensions.get('window').height;

export const getPrinterSettingSchema = () =>
  Yup.object({
    courierLabel: Yup.string().required('Courier label is required'),
    printerName: Yup.string().required('Printer name is required'),
    paperSize: Yup.string()
      .oneOf(PAPER_SIZE_LIST, 'Not a valid paper size')
      .required('Paper size is required'),
    orientation: Yup.string()
      .oneOf(
        ORIENTATION_LIST.map((o) => o.key),
        'Not a valid orientation',
      )
      .required('orientation is required'),
    enable: Yup.boolean()
      .oneOf([true, false], 'Not a valid option')
      .required('Must choose enable option'),
  });

export default observer(({ onDismiss, onSubmit, warehouseCodes, labelList, printerDevices }) => {
  const isMobile = useIsMobile();
  return (
    <ScrollView keyboardShouldPersistTaps={'handled'} style={styles.formContainer}>
      <Layout style={styles.form}>
        <Text category="h5" style={styles.title}>
          Add Printer Setting
        </Text>
        <Divider />
        <UIStatusWrapper
          status={{
            error: printerSettingStore.error,
            indeterminate: printerSettingStore.loading,
          }}>
          <Formik
            isInitialValid={false}
            initialValues={{
              courierLabel: '',
              printerName: '',
              paperSize: '',
              orientation: '',
              warehouseCode: '',
              enable: false,
            }}
            validationSchema={getPrinterSettingSchema()}
            onSubmit={async (values, { setSubmitting, resetForm, setFieldError }) => {
              setSubmitting(true);
              try {
                await printerSettingStore.addItem(values);
                resetForm();
                onSubmit();
                unifiedAlert(`Add new printer setting successfully.`);
              } catch (e) {
                unifiedAlert(`Error:\n${e.message}`);
              }
              setSubmitting(false);
            }}>
            {(props) => {
              return (
                <>
                  <Autocomplete
                    name="warehouseCode"
                    label="Warehouse code"
                    error={props.errors.warehouseCode}
                    touched={props.touched.warehouseCode}
                    style={styles.field}
                    placeholder="Select a warehouse code"
                    options={warehouseCodes}
                    onSelect={props.handleChange('warehouseCode')}
                    onBlur={props.handleBlur('warehouseCode')}
                  />
                  <Autocomplete
                    name="courierLabel"
                    label="Courier label"
                    error={props.errors.courierLabel}
                    touched={props.touched.courierLabel}
                    style={styles.field}
                    placeholder="Select a courier label"
                    options={labelList.map((l) => l.name)}
                    onSelect={props.handleChange('courierLabel')}
                    onBlur={props.handleBlur('courierLabel')}
                  />
                  <Autocomplete
                    name="printerName"
                    label="Select printer"
                    error={props.errors.printerName}
                    touched={props.touched.printerName}
                    style={styles.field}
                    placeholder="Select a printer"
                    options={printerDevices.map((prt) => ({
                      key: prt,
                      val: prt,
                    }))}
                    onSelect={props.handleChange('printerName')}
                    onBlur={props.handleBlur('printerName')}
                    getTitle={(val) => val.val}
                    getValue={(val) => val.key}
                  />
                  <Autocomplete
                    name="paperSize"
                    label="Paper size"
                    error={props.errors.paperSize}
                    touched={props.touched.paperSize}
                    style={styles.field}
                    placeholder="Select paper size"
                    options={PAPER_SIZE_LIST}
                    onSelect={props.handleChange('paperSize')}
                    onBlur={props.handleBlur('paperSize')}
                  />
                  <RadioGroupInputField
                    label="Orientation"
                    name="orientation"
                    style={styles.field}
                    options={ORIENTATION_LIST}
                    {...props}
                  />
                  <Layout
                    style={[
                      styles.rowContainer,
                      isMobile ? styles.mobileRowFlex : styles.desktopRowFlex,
                      { width: 'fit-content' },
                    ]}
                    level="1">
                    <Text category="s2" style={isMobile ? styles.mobileLabel : styles.desktopLabel}>
                      Enable
                    </Text>

                    <Toggle
                      checked={props.values.enable}
                      onChange={(isEnabled) => props.setFieldValue('enable', isEnabled)}
                    />
                  </Layout>
                  <Text status="info" style={{ marginBottom: '12px' }}>
                    The QzTray Desktop App allows you to send labels and documentation directly to
                    your printer in just one click. Configure unique print settings for each
                    courier, including: label dimensions, orientation, and printer preference.{' '}
                    <br />
                    <br />
                    To install and configure QzTray locally, follow this link{' '}
                    <Text
                      status="info"
                      style={{ textDecorationLine: 'underline' }}
                      onPress={() => Linking.openURL('https://qz.io/')}>
                      https://qz.io/
                    </Text>
                  </Text>
                  <Divider />
                  <Layout
                    style={isMobile ? styles.mobileButtonContainer : styles.desktopButtonContainer}>
                    <Button
                      status="primary"
                      loading={props.isSubmitting}
                      onPress={props.handleSubmit}
                      disabled={props.isSubmitting || !props.isValid}
                      style={styles.button}>
                      Create
                    </Button>
                    <Button
                      status="basic"
                      loading={props.isSubmitting}
                      onPress={onDismiss}
                      disabled={props.isSubmitting}
                      style={styles.button}>
                      Cancel
                    </Button>
                    <Button
                      status="danger"
                      appearance="ghost"
                      disabled={props.isSubmitting}
                      onPress={props.handleReset}
                      style={styles.button}>
                      Reset
                    </Button>
                  </Layout>
                </>
              );
            }}
          </Formik>
        </UIStatusWrapper>
      </Layout>
    </ScrollView>
  );
});

const styles = StyleSheet.create({
  field: {
    marginVertical: 8,
  },
  title: {
    marginVertical: 10,
  },
  form: {
    alignSelf: 'center',
    padding: 20,
  },
  button: {
    marginVertical: 10,
    marginHorizontal: 2,
  },
  desktopButtonContainer: {
    flexDirection: 'row-reverse ',
  },
  mobileButtonContainer: {
    flexDirection: 'column',
  },
  controlContainer: {
    borderRadius: 4,
    margin: 2,
    padding: 6,
    backgroundColor: '#3366FF',
  },
  formContainer: {
    maxHeight: 0.9 * windowHeight,
    overflowY: 'auto',
  },
  mobileLabel: {
    flex: 1,
    flexWrap: 'wrap',
    maxWidth: '100px',
  },
  desktopLabel: {
    minWidth: '130px',
  },
  rowContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '12px',
  },
  mobileRowFlex: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  desktopRowFlex: {
    flexDirection: 'row',
    width: 'auto',
  },
});
