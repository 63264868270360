import { flow, makeAutoObservable } from 'mobx';
import gql from 'graphql-tag';
import { unifiedAlert } from '../core/utils/utils';

const CREATE_INBOUND = gql`
  mutation createInboundOrder(
    $warehouseAccountId: ID!
    $ref_no: String!
    $to_warehouse_code: ID!
    $transport_type: String!
    $remark: String
    $iconsignment_sku: [InboundSkuQtyInput!]!
  ) {
    createInboundOrder(
      warehouseAccountId: $warehouseAccountId
      ref_no: $ref_no
      to_warehouse_code: $to_warehouse_code
      transport_type: $transport_type
      remark: $remark
      iconsignment_sku: $iconsignment_sku
    ) {
      consignment_no
      createTransaction {
        amount
      }
    }
  }
`;

const CANCEL_INBOUND = gql`
  mutation cancelInboundOrder($warehouseAccountId: ID!, $consignment_no: ID!) {
    cancelInboundOrder(warehouseAccountId: $warehouseAccountId, consignment_no: $consignment_no) {
      consignment_no
    }
  }
`;

const SYNC_INBOUND = gql`
  mutation syncInboundOrders($warehouseAccountId: ID!) {
    syncInboundOrders(warehouseAccountId: $warehouseAccountId)
  }
`;

const INBOUND = gql`
  query inbound($warehouseAccountId: ID!, $consignmentNumber: ID!) {
    inbound(warehouseAccountId: $warehouseAccountId, consignmentNumber: $consignmentNumber) {
      consignment_no
      ref_no
      to_warehouse_code
      status
      total_volume
      total_weight
      transport_type
      remark
      create_time
      receive_time
      update_time
      lstsku {
        box_no
        sku_id
        sku_code
        plan_qty
        received_qty
        putaway_qty
        exception_qty
        weight
        volume
        length
        width
        height
        product_code
        sku_name
      }
    }
  }
`;

const RECEIVE_INBOUND = gql`
  mutation receiveInboundOrder(
    $warehouseAccountId: ID!
    $consignment_no: ID!
    $receive_time: String!
    $lstsku: [InboundSkuQtyInput!]!
  ) {
    receiveInboundOrder(
      warehouseAccountId: $warehouseAccountId
      consignment_no: $consignment_no
      receive_time: $receive_time
      lstsku: $lstsku
    ) {
      consignment_no
    }
  }
`;

const PUTAWAY_INBOUND = gql`
  mutation putawayInboundOrder(
    $warehouseAccountId: ID!
    $consignment_no: ID!
    $lstsku: [InboundInventoryInput!]!
  ) {
    putawayInboundOrder(
      warehouseAccountId: $warehouseAccountId
      consignment_no: $consignment_no
      lstsku: $lstsku
    ) {
      consignment_no
    }
  }
`;

export class InboundOrderStore {
  cachedItems = null;
  loading = true;
  error = null;
  pollingIntervals = [];

  constructor(client, warehouseAccountStore) {
    this.client = client;
    this.warehouseAccountStore = warehouseAccountStore;
    makeAutoObservable(
      this,
      {
        fetchItems: flow,
        addItem: flow,
        client: false,
        warehouseAccountStore: false,
      },
      { autoBind: true },
    );

    this.startSyncPolling();
  }

  *addItem(warehouseAccountId, inbound) {
    this.loading = true;
    try {
      const { data } = yield this.client.mutate({
        mutation: CREATE_INBOUND,
        variables: {
          ...inbound,
          warehouseAccountId,
        },
      });
      return data.createInboundOrder;
    } catch (error) {
      const msg = 'Error! ' + JSON.stringify(error.message || error);
      unifiedAlert(msg);
      throw error;
    } finally {
      this.loading = false;
    }
  }

  *cancelItem(warehouseAccountId, consignment_no) {
    this.loading = true;
    try {
      const { data } = yield this.client.mutate({
        mutation: CANCEL_INBOUND,
        variables: {
          warehouseAccountId,
          consignment_no,
        },
      });
      return data.cancelInboundOrder;
    } catch (error) {
      const msg = 'Error! ' + JSON.stringify(error.message || error);
      unifiedAlert(msg);
      throw error;
    } finally {
      this.loading = false;
    }
  }

  /**
   * Inoubnd order status will change if warehouse starts to work on it (e.g. approved -> received -> putaway),
   * so we sync inbound orders every 5 minutes to keep our records updated
   * **/
  *startSyncPolling() {
    const pollInterval = 300000; //5 minutes
    const warehouseAccounts = yield this.warehouseAccountStore.getWarehouseAccounts();
    warehouseAccounts.forEach((warehouseAccount) => {
      if (!warehouseAccount?.isMasterAccount) {
        return;
      }
      const intervalId = setInterval(async () => {
        try {
          await this.client.mutate({
            mutation: SYNC_INBOUND,
            fetchPolicy: 'no-cache',
            variables: {
              warehouseAccountId: warehouseAccount.id,
            },
          });
        } catch (error) {
          console.error(error);
        }
      }, pollInterval);

      this.pollingIntervals.push(intervalId);
    });
  }

  /**
   * Ensure that the sync polling intervals are stopped after all tests have finished running,
   * allowing Jest to exit without waiting for them to complete.
   */
  stopSyncPolling() {
    this.pollingIntervals.forEach((intervalId) => clearInterval(intervalId));
    this.pollingIntervals = [];
  }

  *getInboundOrder(warehouseAccountId, consignmentNumber) {
    try {
      const { data } = yield this.client.query({
        query: INBOUND,
        variables: {
          warehouseAccountId,
          consignmentNumber,
        },
        fetchPolicy: 'no-cache',
      });
      return data.inbound;
    } catch (error) {
      const msg = 'Error! ' + JSON.stringify(error.message || error);
      unifiedAlert(msg);
      throw error;
    }
  }

  *receiveInboundOrder(warehouseAccountId, consignment_no, receive_time, lstsku) {
    try {
      const { data } = yield this.client.mutate({
        mutation: RECEIVE_INBOUND,
        variables: {
          warehouseAccountId,
          consignment_no,
          receive_time,
          lstsku,
        },
      });
      this.cachedItems = null;
      return data.receiveInboundOrder;
    } catch (error) {
      const msg = 'Error! ' + JSON.stringify(error.message || error);
      unifiedAlert(msg);
      throw error;
    }
  }

  *putawayInboundOrder(warehouseAccountId, consignment_no, lstsku) {
    try {
      const data = yield this.client.mutate({
        mutation: PUTAWAY_INBOUND,
        variables: {
          warehouseAccountId,
          consignment_no,
          lstsku,
        },
      });
      this.cachedItems = null;
      return data.putawayInboundOrder;
    } catch (error) {
      const msg = 'Error! ' + JSON.stringify(error.message || error);
      unifiedAlert(msg);
      throw error;
    }
  }
}
