import { Formik } from 'formik';
import { URL } from 'react-native-url-polyfill';
import { Linking, StyleSheet, View } from 'react-native';
import { Avatar, Button, Card, Text, Icon } from '@ui-kitten/components';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Select from './Select';
import React from 'react';
import { gql, useApolloClient, useMutation } from '@apollo/client';
import { STORES, unifiedAlert } from '../core/utils/utils';
import { TextInputField } from './input/TextInputField';
import * as Yup from 'yup';
import { STORE_PROVIDERS } from '@ezom/library/lib/cjs/constants';

const EBAY_AUTH_URL = gql`
  query ebayAuthUrl {
    ebayAuthUrl
  }
`;

const ADD_STORE_SHOPIFY = gql`
  mutation addStoreShopify($input: StoreCreateShopifyInput!) {
    addStoreShopify(input: $input) {
      name
    }
  }
`;

const ADD_STORE_OTHER = gql`
  mutation addStoreOther($input: StoreCreateInput!) {
    addStoreOther(input: $input) {
      name
    }
  }
`;

const SHOPIFY_AUTH_URL = gql`
  query shopifyAuthUrl($shop: String!) {
    shopifyAuthUrl(shop: $shop)
  }
`;

const ETSY_AUTH_URL = gql`
  query etsyAuthUrl($redirectUrl: String!) {
    etsyAuthUrl(redirectUrl: $redirectUrl) {
      authUrl
      codeVerifier
    }
  }
`;

export const SHOPIFY_NAME_SUFFIX = '.myshopify.com';

// FIXME: if there are more than a few we should get the list of providers from server
const PROVIDERS = [
  // EBAY Oauth doc: https://developer.ebay.com/api-docs/static/oauth-consent-request.html
  {
    id: STORE_PROVIDERS.EBAY,
    name: 'ebay',
    logoSrc: require('../assets/images/providers/ebay-logo.svg'),
    supported: true,
  },
  // TODO: implement Shopify oauth. Shopify connection details are needed in server.
  {
    id: STORE_PROVIDERS.SHOPIFY,
    name: 'Shopify',
    logoSrc: require('../assets/images/providers/shopify-logo.svg'),
    supported: true,
  },
  {
    id: STORE_PROVIDERS.MAGENTO2,
    name: 'Magento',
    logoSrc: require('../assets/images/providers/magento-logo.svg'),
    supported: true,
  },
  {
    id: STORE_PROVIDERS.WOOCOMMERCE,
    name: 'WooCommerce',
    logoSrc: require('../assets/images/providers/woocommerce-logo.svg'),
    supported: true,
  },
  {
    id: STORE_PROVIDERS.ETSY,
    name: 'Etsy',
    logoSrc: require('../assets/images/providers/etsy-logo.svg'),
    supported: true,
  },
  {
    id: STORE_PROVIDERS.OTHER,
    name: 'Other',
    supported: true,
  },
];

const SHOPIFY_ADD_STORE_INSTRUCTION_URL =
  process.env.SHOPIFY_ADD_STORE_INSTRUCTION_URL ||
  'https://ezom.notion.site/Account-Setup-1a540d3b1e98428e804b4392dd1d5444';

export const AddStoreForm = ({
  provider = '',
  shopUrlSuffix = '',
  providerAuthorised = false,
  onSubmit,
  navigation,
  shopUrl = '',
  consumerKey = '',
  consumerSecret = '',
  accessToken = '',
  accessTokenSecret = '',
}) => {
  const client = useApolloClient();

  const [addStoreShopify, addStoreShopifyResult] = useMutation(ADD_STORE_SHOPIFY, {
    refetchQueries: [{ query: STORES }],
  });

  const [addStoreOther, addStoreOtherResult] = useMutation(ADD_STORE_OTHER, {
    refetchQueries: [{ query: STORES }],
  });

  return (
    <Formik
      isInitialValid={false}
      initialValues={{
        provider,
        shopUrlSuffix,
        name: shopUrlSuffix,
        shopUrl,
        consumerKey,
        consumerSecret,
        accessToken,
        accessTokenSecret,
      }}
      validationSchema={Yup.object({
        name: providerAuthorised ? Yup.string().required('Required') : Yup.string(),
        provider: Yup.string().required('Required'),
        shopUrlSuffix: Yup.string().when('provider', {
          is: (val) => val === STORE_PROVIDERS.SHOPIFY,
          then: Yup.string().required('Required'),
          otherwise: Yup.string(),
        }),
        adminToken: Yup.string().when('provider', {
          is: (val) => val === STORE_PROVIDERS.SHOPIFY,
          then: Yup.string().required('Required'),
          otherwise: Yup.string(),
        }),
        apiSecret: Yup.string().when('provider', {
          is: (val) => val === STORE_PROVIDERS.SHOPIFY,
          then: Yup.string().required('Required'),
          otherwise: Yup.string(),
        }),
        shopUrl: Yup.string().when('provider', {
          is: (val) => [STORE_PROVIDERS.MAGENTO2, STORE_PROVIDERS.WOOCOMMERCE].includes(val),
          then: Yup.string()
            .required('Required')
            .matches(
              /^(?:([a-z0-9+.-]+):\/\/)(?:\S+(?::\S*)?@)?(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/,
              'Incorrect URL',
            ),
          otherwise: Yup.string(),
        }),
        consumerKey: Yup.string().when('provider', {
          is: (val) => [STORE_PROVIDERS.MAGENTO2, STORE_PROVIDERS.WOOCOMMERCE].includes(val),
          then: Yup.string().required('Required'),
          otherwise: Yup.string(),
        }),
        consumerSecret: Yup.string().when('provider', {
          is: (val) => [STORE_PROVIDERS.MAGENTO2, STORE_PROVIDERS.WOOCOMMERCE].includes(val),
          then: Yup.string().required('Required'),
          otherwise: Yup.string(),
        }),
        accessToken: Yup.string().when('provider', {
          is: (val) => val === STORE_PROVIDERS.MAGENTO2,
          then: Yup.string().required('Required'),
          otherwise: Yup.string(),
        }),
        accessTokenSecret: Yup.string().when('provider', {
          is: (val) => val === STORE_PROVIDERS.MAGENTO2,
          then: Yup.string().required('Required'),
          otherwise: Yup.string(),
        }),
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        if (providerAuthorised) {
          onSubmit && onSubmit(values);
        } else {
          if (values.provider === STORE_PROVIDERS.EBAY) {
            const { data } = await client.query({
              query: EBAY_AUTH_URL,
            });

            const url = new URL(data.ebayAuthUrl);
            Linking.canOpenURL(url).then((supported) => {
              if (supported) {
                Linking.openURL(url);
              } else {
                console.log("Don't know how to open URI: " + url);
              }
            });
          } else if (values.provider === STORE_PROVIDERS.ETSY) {
            const { data } = await client.query({
              query: ETSY_AUTH_URL,
              variables: {
                redirectUrl:
                  window.location.protocol + '//' + window.location.host + `/a/store/add/etsy`,
              },
            });
            const url = new URL(data.etsyAuthUrl.authUrl);
            await AsyncStorage.setItem('codeVerifier', data.etsyAuthUrl.codeVerifier);

            Linking.canOpenURL(url).then((supported) => {
              if (supported) {
                Linking.openURL(url);
              } else {
                console.log("Don't know how to open URI: " + url);
              }
            });
          } else if (values.provider === STORE_PROVIDERS.SHOPIFY) {
            try {
              await addStoreShopify({
                variables: {
                  input: {
                    name: values.name,
                    shopUrlSuffix: values.shopUrlSuffix,
                    adminToken: values.adminToken,
                    apiSecret: values.apiSecret,
                  },
                },
              });
              unifiedAlert('Successfully added a new store!');
            } catch (e) {
              unifiedAlert('An error has occurred. ' + JSON.stringify(e.msg || e));
            }
          } else if (values.provider === STORE_PROVIDERS.MAGENTO2) {
            navigation.navigate('AddStoreScreen', {
              shopUrl: values.shopUrl,
              consumerKey: values.consumerKey,
              consumerSecret: values.consumerSecret,
              accessToken: values.accessToken,
              accessTokenSecret: values.accessTokenSecret,
              provider: values.provider,
            });
          } else if (values.provider === STORE_PROVIDERS.WOOCOMMERCE) {
            navigation.navigate('AddStoreScreen', {
              shopUrl: values.shopUrl,
              consumerKey: values.consumerKey,
              consumerSecret: values.consumerSecret,
              provider: values.provider,
            });
          } else if (values.provider === STORE_PROVIDERS.OTHER) {
            try {
              await addStoreOther({
                variables: {
                  input: {
                    name: values.name,
                  },
                },
              });
              unifiedAlert('Successfully added a new store!');
            } catch (e) {
              unifiedAlert('An error has occurred. ' + JSON.stringify(e.msg || e));
            }
          }
          onSubmit && onSubmit(values);
        }
        setSubmitting(false);
      }}>
      {(props) => {
        const selectedProvider = PROVIDERS.find(({ id }) => id === props.values.provider) || {};
        const disableAddButton =
          selectedProvider.supported === false || !props.isValid || props.isSubmitting;

        const Footer = (footerProps) => (
          <View {...footerProps}>
            <Button
              loading={props.isSubmitting}
              disabled={disableAddButton}
              style={styles.footerControl}
              size="small"
              onPress={() => props.handleSubmit()}>
              {selectedProvider.supported === false ? 'Please contact us' : 'Add'}
            </Button>
          </View>
        );

        return (
          <Card
            style={styles.card}
            header={(props) => (
              <View {...props}>
                <Text category="h6">{providerAuthorised ? 'Name your store' : 'Add store'}</Text>
              </View>
            )}
            footer={Footer}>
            {providerAuthorised ? (
              <TextInputField
                label="Name"
                name="name"
                style={styles.field}
                placeholder="Store name"
                {...props}
              />
            ) : null}

            <Select
              disabled={providerAuthorised}
              label="Provider"
              name="provider"
              placeholder="Select provider"
              value={selectedProvider.name}
              error={props.errors.provider}
              touched={props.touched.provider}
              style={styles.field}
              options={PROVIDERS.map((p) => ({
                key: p.id,
                val: p.name,
                accessoryLeft: () => {
                  return (
                    <Avatar
                      resizeMode="contain"
                      size="large"
                      source={p.logoSrc}
                      style={{ width: 50, height: 30 }}
                    />
                  );
                },
              }))}
              onChange={(val) => {
                props.setFieldValue('provider', val.key);
              }}
              onBlur={() => props.setFieldTouched('provider', true)}
            />

            {selectedProvider.id === STORE_PROVIDERS.SHOPIFY ? (
              <React.Fragment>
                <TextInputField
                  disabled={providerAuthorised}
                  label="Shop name"
                  name="name"
                  style={styles.field}
                  placeholder="Shop name"
                  {...props}
                />
                <TextInputField
                  disabled={providerAuthorised}
                  label="Shop URL"
                  name="shopUrlSuffix"
                  style={styles.field}
                  placeholder="Shop URL"
                  {...props}
                />
                <TextInputField
                  name=""
                  label=""
                  disabled={true}
                  placeholder={SHOPIFY_NAME_SUFFIX}
                />
                <TextInputField
                  disabled={providerAuthorised}
                  label="Admin API Token"
                  name="adminToken"
                  style={styles.field}
                  placeholder="Admin API Access Token"
                  {...props}
                />
                <TextInputField
                  disabled={providerAuthorised}
                  label="API Secret Key"
                  name="apiSecret"
                  style={styles.field}
                  placeholder="API Secret Key"
                  {...props}
                />
                <Button
                  onPress={() => {
                    Linking.openURL(SHOPIFY_ADD_STORE_INSTRUCTION_URL);
                  }}
                  appearance="ghost"
                  status="info"
                  accessoryLeft={(props) => <Icon {...props} name="external-link-outline" />}>
                  Instructions to get the Shopify Admin API Token
                </Button>
              </React.Fragment>
            ) : (
              false
            )}

            {[STORE_PROVIDERS.MAGENTO2, STORE_PROVIDERS.WOOCOMMERCE].includes(
              selectedProvider.id,
            ) ? (
              <React.Fragment>
                <TextInputField
                  disabled={providerAuthorised}
                  label="Shop URL"
                  name="shopUrl"
                  style={styles.field}
                  {...props}
                  placeholder="https://yourstore.com"
                />
                <TextInputField
                  disabled={providerAuthorised}
                  name="consumerKey"
                  label="Consumer key"
                  style={styles.field}
                  {...props}
                />
                <TextInputField
                  disabled={providerAuthorised}
                  name="consumerSecret"
                  label="Consumer secret"
                  style={styles.field}
                  {...props}
                />
                {selectedProvider.id === STORE_PROVIDERS.MAGENTO2 ? (
                  <>
                    <TextInputField
                      disabled={providerAuthorised}
                      name="accessToken"
                      label="Access token"
                      style={styles.field}
                      {...props}
                    />
                    <TextInputField
                      disabled={providerAuthorised}
                      name="accessTokenSecret"
                      label="Access token secret"
                      style={styles.field}
                      {...props}
                    />
                  </>
                ) : null}
                <Button
                  onPress={() => {
                    Linking.openURL(
                      selectedProvider.id === STORE_PROVIDERS.MAGENTO2
                        ? 'https://devdocs.magento.com/guides/v2.4/get-started/authentication/gs-authentication-token.html'
                        : 'https://woocommerce.github.io/woocommerce-rest-api-docs/?javascript#rest-api-keys',
                    );
                  }}
                  appearance="ghost"
                  status="info"
                  accessoryLeft={(props) => <Icon {...props} name="external-link-outline" />}>
                  {selectedProvider.id === STORE_PROVIDERS.MAGENTO2
                    ? 'Instructions to setup Magento token-saved authentication'
                    : 'Instructions to setup Woocommernce REST API keys'}
                </Button>
                {selectedProvider.id === STORE_PROVIDERS.WOOCOMMERCE ? (
                  <Text>
                    Next, in WordPress Setting -> Permalinks -> Common Settings section, select
                    "Post name" and save changes.
                  </Text>
                ) : null}
                {selectedProvider.id === STORE_PROVIDERS.MAGENTO2 ? (
                  <Text>
                    Note: in the Magento API tabm make sure you enable all permissions under "Sales"
                  </Text>
                ) : null}
              </React.Fragment>
            ) : (
              false
            )}
            {selectedProvider.id === STORE_PROVIDERS.OTHER ? (
              <React.Fragment>
                <TextInputField
                  disabled={providerAuthorised}
                  label="Shop name"
                  name="name"
                  style={styles.field}
                  placeholder="Shop name"
                  {...props}
                />
              </React.Fragment>
            ) : (
              false
            )}
          </Card>
        );
      }}
    </Formik>
  );
};

const styles = StyleSheet.create({
  field: {
    marginVertical: 8,
  },
  footerControl: {
    marginTop: '0.5em',
  },
  card: {
    flex: 1,
    margin: 2,
  },
});
