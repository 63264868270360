import { Text } from 'react-native';
import { Dialog, Portal } from 'react-native-paper';
import React from 'react';
import { Button } from '@ui-kitten/components';

export const ConfirmDialog = ({
  visible,
  title,
  message,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  onDismiss,
  confirmButtonStatus,
}) => {
  return (
    <Portal>
      <Dialog
        style={{
          marginHorizontal: 'auto',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        visible={visible}
        onDismiss={onDismiss}>
        {title && <Dialog.Title>{title}</Dialog.Title>}
        {message && (
          <Dialog.Content>
            <Text>{message}</Text>
          </Dialog.Content>
        )}
        <Dialog.Actions style={{ justifyContent: 'center' }}>
          <Button style={{ marginHorizontal: 5 }} size="small" status="basic" onPress={onCancel}>
            {cancelText ? cancelText : 'Cancel'}
          </Button>
          <Button
            style={{ marginHorizontal: 5 }}
            size="small"
            status={confirmButtonStatus ? confirmButtonStatus : 'danger'}
            onPress={onConfirm}>
            {confirmText ? confirmText : 'Confirm'}
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};
