import '@expo/match-media';
import React, { memo } from 'react';
import { StyleSheet } from 'react-native';
import { Layout, Input, Text } from '@ui-kitten/components';
import { useIsMobile } from 'src/core/responsive.utils';

const RangeInput = ({
  label,
  minVal,
  maxVal,
  minTouched,
  maxTouched,
  minError,
  maxError,
  minPlaceholder,
  maxPlaceholder,
  size = 'small',
  style = {},
  onMinValueChange,
  onMaxValueChange,
  onMinValueBlur = () => {},
  onMaxValueBlur = () => {},
}) => {
  const isMobileDevice = useIsMobile();
  return (
    <Layout
      style={[
        styles.rowContainer,
        isMobileDevice ? styles.mobileRowFlex : styles.desktopRowFlex,
        style,
      ]}
      level="1">
      <Text category="label" style={isMobileDevice ? styles.mobileLabel : styles.desktopLabel}>
        {label}
      </Text>
      <Layout style={isMobileDevice ? styles.mobileInput : styles.desktopInput}>
        <Input
          size={size}
          style={styles.input}
          value={minVal}
          label="From"
          status={minTouched && minError ? 'danger' : 'primary'}
          placeholder={minPlaceholder}
          caption={minError}
          onChange={onMinValueChange}
          onBlur={onMinValueBlur}
        />
        <Input
          size={size}
          style={styles.input}
          value={maxVal}
          label="To"
          status={maxTouched && maxError ? 'danger' : 'primary'}
          placeholder={maxPlaceholder}
          caption={maxError}
          onChange={onMaxValueChange}
          onBlur={onMaxValueBlur}
        />
      </Layout>
    </Layout>
  );
};

const styles = StyleSheet.create({
  input: {
    flex: 1,
    marginHorizontal: '5px',
  },
  mobileLabel: {
    flex: 1,
    flexWrap: 'wrap',
    maxWidth: '100px',
  },
  desktopLabel: {
    minWidth: '130px',
  },
  rowContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  mobileRowFlex: {
    marginHorizontal: 'auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  desktopRowFlex: {
    flexDirection: 'row',
    width: 'auto',
  },
  desktopInput: {
    flexDirection: 'row',
    width: '260px',
    minWidth: '260px',
  },
  mobileInput: {
    flexDirection: 'column',
    width: '200px',
    minWidth: '200px',
  },
});

export default memo(RangeInput);
