import * as React from 'react';
import qz from 'qz-tray';
import { Avatar, Card, FAB } from 'react-native-paper';
import { ScrollView, StyleSheet, Dimensions, View, Alert } from 'react-native';
import { observer } from 'mobx-react';
import { paperNativeTheme } from 'src/core/theme';
import Table from '../../components/Table';
import jwa from 'jwa';
import { gql, useApolloClient } from '@apollo/client';

import {
  Divider,
  Layout,
  Card as KittenCard,
  Button,
  Icon,
  ModalService,
  Text,
} from '@ui-kitten/components';
import { useIsMobile } from 'src/core/responsive.utils';
import { UIStatusWrapper } from '../../components/ui-status';
import RowDetail from '../../components/RowDetail';
import {
  OC_STATUS_CODE,
  OC_STATUS_MAP,
  PICKING_SKU_STATUS_MAP,
  PICKING_TASK_STATUS_CODE,
  PICKING_TASK_TYPE,
  PICKING_TASK_TYPE_MAP,
} from '@ezom/library/lib/cjs/constants';
import {
  courierShipmentStore,
  inventoryStore,
  outboundOrderStore,
  pickingTaskTableStore,
  printerSettingStore,
  warehousesStore,
} from '../../store';
import { millisecondsToMinutes } from 'date-fns';
import {
  formatInventoryLocation,
  playErrorSoundAsync,
  playSuccessSoundAsync,
  printBase64Pdf,
  sanitize,
  unifiedAlert,
  unifiedConfirm,
  useOnBarcodeScanned,
} from '../../core/utils/utils';
import { all, equals, map } from 'ramda';
import ExceptionReportModal from '../../components/ExceptionReportModal';
import { volumeCmToMetre, weightGramToKg } from '@ezom/library/lib/cjs/utils';
import { ezTheme } from '../../core/theme';

const convertPaperSize = (paper) => {
  switch (paper) {
    case 'A0':
      return { width: 33.11, height: 46.81 }; // width: inches - height: inches
    case 'A1':
      return { width: 23.39, height: 33.11 };
    case 'A2':
      return { width: 16.54, height: 23.39 };
    case 'A3':
      return { width: 11.69, height: 16.54 };
    case 'A4':
      return { width: 8.27, height: 11.69 };
    case 'A5':
      return { width: 5.83, height: 8.27 };
    case 'A6':
      return { width: 4.13, height: 5.83 };
    case 'A7':
      return { width: 2.91, height: 4.13 };
    case 'A8':
      return { width: 2.05, height: 2.91 };
    case 'A9':
      return { width: 1.46, height: 2.05 };
    case 'A10':
      return { width: 1.02, height: 1.46 };
    case 'A11':
      return { width: 0.73, height: 1.02 };
    case 'A12':
      return { width: 0.51, height: 0.73 };
    case 'A13':
      return { width: 0.36, height: 0.51 };
    case 'B0':
      return { width: 39.4, height: 55.7 };
    case 'B1':
      return { width: 28.7, height: 40.6 };
    case 'B2':
      return { width: 20.3, height: 28.7 };
    case 'B3':
      return { width: 14.3, height: 20.3 };
    case 'B4':
      return { width: 10.1, height: 14.3 };
    case 'B5':
      return { width: 7.2, height: 10.1 };
    case 'B6':
      return { width: 5.0, height: 7.2 };
    case 'B7':
      return { width: 3.5, height: 5.0 };
    case 'B8':
      return { width: 2.5, height: 3.5 };
    case 'B9':
      return { width: 1.8, height: 2.5 };
    case 'B10':
      return { width: 1.3, height: 1.8 };
    case 'B11':
      return { width: 0.9, height: 1.3 };
    case 'B12':
      return { width: 0.6, height: 0.9 };
    case 'C0':
      return { width: 36.1, height: 51.1 };
    case 'C1':
      return { width: 25.5, height: 36.1 };
    case 'C2':
      return { width: 18.0, height: 25.5 };
    case 'C3':
      return { width: 12.8, height: 18.0 };
    case 'C4':
      return { width: 9.0, height: 12.8 };
    case 'C5':
      return { width: 6.4, height: 9.0 };
    case 'C6':
      return { width: 4.5, height: 6.4 };
    case 'C7':
      return { width: 3.2, height: 4.5 };
    case 'C8':
      return { width: 2.2, height: 3.2 };
    default:
      return null;
  }
};

const commonInfos = [
  {
    label: 'ID',
    key: 'id',
  },
  {
    label: 'Status',
    key: 'status',
    format: (status) => PICKING_SKU_STATUS_MAP[status],
  },
  {
    label: 'Type',
    key: 'type',
    format: (status) => PICKING_TASK_TYPE_MAP[status],
  },
  {
    label: 'Warehouse',
    key: 'warehouse_code',
    format: (w) => warehousesStore.getWarehouseNameEn(w),
  },
  {
    label: 'Channel',
    key: 'dispatch_channel',
  },
  {
    label: 'Total volume',
    key: 'total_volume',
    format: volumeCmToMetre(true),
  },
  {
    label: 'Total weight',
    key: 'total_weight',
    format: weightGramToKg(true),
  },
  {
    label: '# SKU types',
    key: 'sku_types',
  },
  {
    label: 'SKU Qty to pick',
    key: 'qty_to_pick',
  },
  {
    label: 'SKU Qty picked',
    key: 'qty_picked',
  },
  {
    label: 'Create time',
    key: 'create_time',
    format: (number) => new Date(Number(number)).toLocaleString(),
  },
  {
    label: 'Update time',
    key: 'update_time',
    format: (number) => new Date(Number(number)).toLocaleDateString(),
  },
  {
    label: 'Start time',
    key: 'start_time',
    format: (number) => (number ? new Date(Number(number)).toLocaleString() : ''),
  },
  {
    label: 'Complete time',
    key: 'complete_time',
    format: (number) => (number ? new Date(Number(number)).toLocaleDateString() : ''),
  },
];

const windowHeight = Dimensions.get('window').height;

export default observer(({ route, navigation }) => {
  const { id, warehouseAccountId } = route?.params || {};

  const [detail, setDetail] = React.useState(null);
  const [ocs, setOcs] = React.useState([]);
  const [ocToPackage, setOcToPackage] = React.useState(null);
  const [itemPackingModalVisible, setItemPackingModalVisible] = React.useState(false);
  const [printLoading, setPrintLoading] = React.useState(false);
  const [printerSettings, setPrinterSettings] = React.useState([]);

  const qzSigning = async () => {
    const rsa512 = jwa('RS512');
    const publicCert = process.env.QZTRAY_PUBLIC_CERTIFICATE;
    const privateKey = process.env.QZTRAY_PRIVATE_KEY;

    qz.security.setSignatureAlgorithm('SHA512'); // Since 2.1
    qz.security.setSignaturePromise(function (toSign) {
      return function (resolve, reject) {
        try {
          const hexUrl = rsa512.sign(toSign, privateKey);
          // Crude base64URL to base64 conversion
          const hex = hexUrl.replace(/_/g, '/').replace(/-/g, '+');
          resolve(hex);
        } catch (err) {
          reject(err);
        }
      };
    });

    qz.security.setCertificatePromise((resolve, reject) => {
      resolve(publicCert);
    });
  };

  React.useEffect(() => {
    // This is key signning of Qz-tray to suppress confirmation dialog when click "Print" label
    // TODO: Buy premium license of Qz tray to activate signinging key of qz-tray
    if (process.env.QZTRAY_PUBLIC_CERTIFICATE && process.env.QZTRAY_PRIVATE_KEY) {
      qzSigning();
    }
  }, []);

  const fetchPickingTask = React.useCallback(async () => {
    const task = await pickingTaskTableStore.getPickingTask(id, [warehouseAccountId]);
    setDetail(task);
  });

  const fetchOcs = React.useCallback(async () => {
    const consignmentNumbers = [
      ...new Set(detail.picking_sku_details.map((d) => d.consignment_no)),
    ];
    const outbounds = await Promise.all(
      consignmentNumbers.map((consignmentNumber) =>
        outboundOrderStore.getOutboundOrder(warehouseAccountId, consignmentNumber),
      ),
    );

    setOcs(
      outbounds.sort((a, b) => {
        if (a.pickingTaskType === b.pickingTaskType) {
          return a.consignment_no > b.consignment_no ? 1 : -1;
        }
        return a.pickingTaskType === PICKING_TASK_TYPE.SO ? -1 : 1;
      }),
    );
  });

  React.useEffect(() => {
    (async () => {
      printerSettingStore.setSelectedLabels(ocs.map((oc) => oc.logistics_product_code));
      printerSettingStore.setWarehouseCodes(ocs.map((oc) => oc.from_warehouse_code));
      printerSettingStore.setwarehouseAccountId(warehouseAccountId);
      setPrinterSettings(printerSettingStore.items);
      printerSettingStore.setSelectedLabels(null);
    })();
  }, [ocs]);

  React.useEffect(() => {
    (async () => {
      await fetchPickingTask();
    })();
  }, [warehouseAccountId, id]);

  React.useEffect(() => {
    (async () => {
      if (detail) {
        await fetchOcs();
      }
    })();
  }, [detail]);

  const isMobile = useIsMobile();

  const [fabOpen, setFabOpen] = React.useState(false);

  const [exceptionOc, setExceptionOc] = React.useState(null);

  let pickingModalId = null;
  const showPickingModal = React.useCallback((task) => {
    const contentElement = renderPickingConfirmationContentElement(
      task,
      hidePickingModal,
      async () => {
        try {
          await pickingTaskTableStore.startPickingTask(warehouseAccountId, task.id);
          fetchPickingTask();
          hidePickingModal();
        } catch (e) {
          unifiedAlert(e.message);
        }
      },
    );
    pickingModalId = ModalService.show(contentElement, {
      onBackdropPress: hidePickingModal,
      backdropStyle: {
        backgroundColor: ezTheme.backdropModalColor,
      },
    });
  }, []);

  const hidePickingModal = React.useCallback(() => {
    ModalService.hide(pickingModalId);
  }, []);

  const fabActions = React.useMemo(() => {
    const actions = [];

    if (PICKING_TASK_STATUS_CODE.Waiting === detail?.status) {
      actions.push({
        icon: 'package-variant',
        label: 'Start picking',
        onPress: () => showPickingModal(detail),
      });
    }
    if (PICKING_TASK_STATUS_CODE.Picking === detail?.status) {
      actions.push({
        icon: 'package-variant',
        label: 'Finish picking',
        onPress: async () => {
          /**
           * FIXME: EZOM-135
           * This is a hack to blur the input field when the user clicks on the FAB button.
           * Otherwise, the input field will be focused and when scanning the next avaible FAB button (print picking list) will be pressed.
           * This is a bug in react-native-paper.
           * */
          document?.activeElement?.blur();
          await pickingTaskTableStore.finishPicking(warehouseAccountId, detail.id);
          fetchPickingTask();
        },
      });
    }

    if (PICKING_TASK_STATUS_CODE.Packed === detail?.status) {
      actions.push({
        icon: 'cube-send',
        label: 'Submit manifest',
        onPress: () =>
          unifiedConfirm('Submit manifest', 'This cannot be undone. Are you sure?', async () => {
            try {
              await pickingTaskTableStore.submitPickingTask(warehouseAccountId, detail.id);
              fetchPickingTask();
              fetchOcs();
            } catch (e) {
              unifiedAlert(e.message);
            }
          }),
      });
    }

    if (
      ocs.length > 0 &&
      all(
        equals(OC_STATUS_CODE.Packed),
        ocs.map((oc) => oc.status),
      ) &&
      detail?.status !== PICKING_TASK_STATUS_CODE.Packed
    ) {
      actions.push({
        icon: 'package-variant-closed',
        label: 'Finish packing',
        onPress: async () => {
          try {
            await pickingTaskTableStore.finishPacking(warehouseAccountId, detail.id);
            fetchPickingTask();
          } catch (e) {
            unifiedAlert(e.message);
          }
        },
      });
    }

    actions.push({
      icon: 'printer',
      label: 'Print picking list',
      onPress: () => printPickingList(detail),
    });
    return actions;
  }, [detail, ocs]);

  useOnBarcodeScanned(
    async (barcode) => {
      const sku = await inventoryStore.searchSku(detail.warehouseAccountId, barcode);
      if (!sku) {
        await playErrorSoundAsync();
        unifiedAlert('SKU not found');
        return;
      }
      const oc = ocs
        .filter((oc) => oc.status === OC_STATUS_CODE.Packing)
        .find((oc) => oc.outboundlist_sku.find((s) => s.sku_id === sku.sku_id));
      if (!oc) {
        await playErrorSoundAsync();
        return;
      }
      await playSuccessSoundAsync();
      if (oc.pickingTaskType === PICKING_TASK_TYPE.SO) {
        printLabel(oc);
      } else {
        navigation.navigate('OcPackingScreen', {
          ocNo: oc.consignment_no,
          warehouseAccountId,
          warehouse: detail.warehouse_code,
          pickingTaskId: detail.id,
        });
      }
    },
    [detail?.status, ocs],
  );

  async function printPDFByQZ(label, printer) {
    try {
      await qz.websocket.connect();
      const config = qz.configs.create(printer.printerName, {
        size: convertPaperSize(printer.paperSize),
        units: 'in',
        orientation: printer.orientation,
      });
      await qz.print(config, [
        {
          type: 'pixel',
          format: 'pdf',
          flavor: 'base64',
          data: label,
        },
      ]);
    } catch (err) {
      unifiedAlert(err.message);
    } finally {
      await qz.websocket.disconnect();
    }
  }

  const printLabel = React.useCallback(
    async (oc) => {
      setPrintLoading(true);
      try {
        const labels = await courierShipmentStore.printLabels(warehouseAccountId, [
          oc.shipmentOrderId,
        ]);
        const printer = printerSettings.find((item) => {
          return (
            item.warehouseCode === oc.from_warehouse_code &&
            item.courierLabel === oc.logistics_product_code &&
            item.enable
          );
        });
        if (!printer) {
          for (let label of labels) {
            printBase64Pdf(label);
          }
        } else {
          for (let label of labels) {
            printPDFByQZ(label, printer);
          }
        }
        if (oc.pickingTaskType === PICKING_TASK_TYPE.SO && oc.status === OC_STATUS_CODE.Packing) {
          const skuId = oc.outboundlist_sku[0].sku_id;
          const sku = await inventoryStore.searchSku(detail.warehouseAccountId, skuId);
          if (!sku) {
            throw new Error(`SKU ${skuId} not found`);
          }
          await pickingTaskTableStore.finishPackingOc(
            warehouseAccountId,
            detail.id,
            oc.consignment_no,
            {
              weight: sku.weight,
              length: sku.length,
              width: sku.width,
              height: sku.height,
              description: sku.sku_name,
              // SO tasks has SKU qty 1
              skuQty: { sku: sku.sku_code, qty: 1 },
            },
          );
          await fetchOcs();
        }
      } catch (e) {
        unifiedAlert(e.message);
      }
      setPrintLoading(false);
    },
    [detail, printerSettings],
  );

  return (
    <ScrollView>
      <UIStatusWrapper
        status={{
          indeterminate: !detail,
        }}>
        <ScrollView
          style={[styles.container, isMobile ? styles.mobileContainer : styles.desktopContainer]}>
          <Card style={[styles.card, isMobile ? styles.mobileCard : styles.desktopCard]}>
            <Card.Title
              title="Information"
              left={(props) => <Avatar.Icon {...props} icon="information-outline" />}
            />
            <Divider />
            <Card.Content style={styles.content}>
              <Layout style={isMobile ? styles.mobileColumn : styles.desktopColumn}>
                {commonInfos
                  .slice(0, Math.ceil(commonInfos.length / 2))
                  .map(({ key, label, format }) => (
                    <RowDetail label={label} value={detail && detail[key]} format={format} />
                  ))}
              </Layout>
              <Layout style={isMobile ? styles.mobileColumn : styles.desktopColumn}>
                {commonInfos
                  .slice(Math.ceil(commonInfos.length / 2))
                  .map(({ key, label, format }) => (
                    <RowDetail label={label} value={detail && detail[key]} format={format} />
                  ))}
              </Layout>
            </Card.Content>
          </Card>

          <Card style={[styles.card, isMobile ? styles.mobileCard : styles.desktopCard]}>
            <Card.Title
              title="Consignments to pack"
              left={(props) => <Avatar.Icon {...props} icon={'table'} />}
            />
            <Divider />
            <Card.Content>
              <Table
                items={ocs}
                displayKeys={[
                  'consignment_no',
                  'ref_no',
                  'logistics_product_code',
                  'shipping_no',
                  'status',
                  'action',
                ]}
                styleByKey={{
                  consignment_no: { minWidth: 100 },
                  ref_no: { minWidth: 60 },
                  shipping_no: { minWidth: 100 },
                  status: { maxWidth: 90 },
                  action: { minWidth: 320 },
                }}
                titleByKey={{
                  consignment_no: 'OC No.',
                  ref_no: 'Ref No.',
                  logistics_product_code: 'Courier',
                  shipping_no: 'Tracking No.',
                  status: 'Status',
                  action: 'Action',
                }}
                formatterByKey={{
                  status: (value) => {
                    return OC_STATUS_MAP[value];
                  },
                  action: (value, oc) => (
                    <Layout style={{ flexDirection: 'row' }}>
                      {oc.shipmentOrderId ? (
                        <Button
                          style={{ marginHorizontal: 2 }}
                          appearance="outline"
                          status="primary"
                          size="tiny"
                          disabled={printLoading}
                          onPress={() => printLabel(oc)}>
                          Print label
                        </Button>
                      ) : null}
                      {oc.status === OC_STATUS_CODE.Packing ? (
                        <Button
                          style={{ marginHorizontal: 2 }}
                          appearance="outline"
                          status="primary"
                          size="tiny"
                          onPress={() => {
                            navigation.navigate('OcPackingScreen', {
                              ocNo: oc.consignment_no,
                              warehouseAccountId,
                              warehouse: detail.warehouse_code,
                              pickingTaskId: detail.id,
                            });
                          }}>
                          Select to Pack
                        </Button>
                      ) : null}
                      {![OC_STATUS_CODE.Exception].includes(oc.status) && (
                        <Button
                          style={{ marginHorizontal: 2 }}
                          appearance="outline"
                          status="danger"
                          size="tiny"
                          onPress={() => {
                            setExceptionOc(oc);
                          }}>
                          Report Exception
                        </Button>
                      )}
                    </Layout>
                  ),
                }}
              />
            </Card.Content>
          </Card>

          <Card style={[styles.card, isMobile ? styles.mobileCard : styles.desktopCard]}>
            <Card.Title
              title="Picking task detals"
              left={(props) => <Avatar.Icon {...props} icon={'table'} />}
            />
            <Divider />
            <Card.Content>
              <Table
                items={detail?.picking_sku_details || []}
                displayKeys={[
                  'sku_id',
                  'sku_code',
                  'consignment_no',
                  'status',
                  'batch_no',
                  'qty_to_pick',
                  'qty_picked',
                  'location_aisle',
                  'start_time',
                  'complete_time',
                  'time_spent',
                ]}
                styleByKey={{
                  sku_id: { minWidth: 180 },
                  consignment_no: { minWidth: 180 },
                  sku_code: { minWidth: 140 },
                  location_aisle: { minWidth: 70 },
                }}
                titleByKey={{
                  sku_id: 'SKU ID',
                  sku_code: 'SKU Code',
                  consignment_no: 'OC No.',
                  status: 'Status',
                  batch_no: 'Batch No.',
                  qty_to_pick: 'Qty to pick',
                  qty_picked: 'Qty picked',
                  location_aisle: 'Location',
                  start_time: 'Start time',
                  complete_time: 'Complete time',
                  time_spent: 'Time spent',
                }}
                formatterByKey={{
                  location_aisle: (_, item) => formatInventoryLocation(item),
                  status: (v) => PICKING_SKU_STATUS_MAP[v],
                  start_time: (v) => (v ? new Date(Number(v)).toLocaleString() : ''),
                  complete_time: (v) => (v ? new Date(Number(v)).toLocaleString() : ''),
                  time_spent: (v) => (v ? `${millisecondsToMinutes(v)} min` : ''),
                }}
              />
            </Card.Content>
          </Card>
        </ScrollView>
        <FAB.Group
          visible={true}
          isFabVisible={true}
          fabStyle={{ backgroundColor: paperNativeTheme.colors.primary }}
          open={fabOpen}
          icon={fabOpen ? 'chevron-down' : 'chevron-up'}
          actions={fabActions}
          onStateChange={({ open }) => setFabOpen(open)}
        />
      </UIStatusWrapper>
      <ExceptionReportModal
        visible={!!exceptionOc}
        onDismiss={() => setExceptionOc(null)}
        onSubmit={async (reason) => {
          try {
            await pickingTaskTableStore.reportOutboundOrderException(
              warehouseAccountId,
              exceptionOc.consignment_no,
              reason,
            );
          } catch (e) {
            unifiedAlert(e.message);
            throw e;
          }
          fetchPickingTask();
        }}
      />
    </ScrollView>
  );
});

const printPickingList = async (task) => {
  const printJs = (await import('print-js')).default;
  const bwipjs = await import('bwip-js');
  const data = task.picking_sku_details.map(map(sanitize)).map((d) => ({
    ...d,
    qty_picked: '',
    note: '',
    location: formatInventoryLocation(d),
  }));
  const canvas = document.createElement('canvas');
  bwipjs.toCanvas(canvas, {
    bcid: 'code128',
    text: task.id,
    scale: 1.5,
    height: 10,
    includetext: false, // Show human-readable text
  });
  const barCodeDataUrl = canvas.toDataURL('image/png');
  printJs({
    printable: data,
    type: 'json',
    documentTitle: 'Picking List: ' + task.id,
    properties: [
      { field: 'sku_id', displayName: 'SKU ID' },
      { field: 'sku_code', displayName: 'SKU Code' },
      { field: 'sku_name', displayName: 'SKU Name' },
      { field: 'consignment_no', displayName: 'OC No.' },
      { field: 'batch_no', displayName: 'Batch No.' },
      { field: 'location', displayName: 'Location' },
      { field: 'qty_to_pick', displayName: 'Qty to pick' },
      { field: 'qty_picked', displayName: 'Qty picked' },
      { field: 'note', displayName: 'Note' },
    ],
    header: `
      <h2>Picking List</h2>
      <div class="container">
        <div class="sub-container">
          <img src=${barCodeDataUrl} class="image">
          <h4>Task No.: ${task.id}</h4>
          <h4>Task type: ${task.type}</h4>
          <h4>Order date: ${new Date(parseInt(task.create_time)).toLocaleDateString()}</h4>
          <h4>Channel: ${task.dispatch_channel}</h4>
        </div>
        <div class="sub-container">
          <h4>SKU Qty: ${task.qty_to_pick}</h4>
          <h4>#SKU types: ${task.sku_types}</h4>
          <h4>Total weight: ${weightGramToKg(true)(task.total_weight)}</h4>
          <h4>Total volume: ${volumeCmToMetre(true)(task.total_volume)}</h4>
        </div>
      </div>
      `,
    style:
      '.container { width: 100%; display: flex; flex-direction: row; justify-content: space-between; }; .sub-container { width : 50%; }',
    gridStyle: 'padding-right: 5px; padding-left: 5px; border: 0.5px solid lightgray;',
    font_size: '8pt',
  });
};

const renderPickingConfirmationContentElement = (task, onCancel, onSubmit) => {
  const Footer = (props) => (
    <View {...props} style={[props.style, styles.footerContainer]}>
      <Button style={styles.footerControl} status="basic" size="small" onPress={onCancel}>
        CANCEL
      </Button>
      <Button style={styles.footerControl} size="small" onPress={onSubmit}>
        ACCEPT
      </Button>
      <Button
        style={styles.footerControl}
        size="small"
        status="info"
        appearance="ghost"
        accessoryLeft={(props) => <Icon {...props} name="printer-outline" />}
        onPress={() => printPickingList(task)}>
        PRINT
      </Button>
    </View>
  );
  return (
    <View
      style={{
        width: 'fit-content',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '90%',
      }}>
      <KittenCard style={styles.card} footer={Footer}>
        <Text category="H6">
          A pick task cannot be cancelled once started. Are you sure you want to start picking for{' '}
          {task.id}?
        </Text>
      </KittenCard>
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    marginHorizontal: '1em',
    marginVertical: '1em',
  },
  desktopCard: {
    marginHorizontal: '0.4em',
    minWidth: '300px',
  },
  mobileCard: {
    marginHorizontal: '1em',
    marginBottom: '1em',
  },

  desktopColumn: {
    width: '50%',
    flex: 1,
    flexDirection: 'column',
  },

  mobileColumn: {
    width: '100%',
  },

  content: {
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },

  container: {
    overflowY: 'auto',
  },

  mobileContainer: {
    height: 0.82 * windowHeight,
  },

  desktopContainer: {
    height: 0.9 * windowHeight,
  },

  footerControl: {
    marginHorizontal: 2,
  },

  footerContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  header: { alignSelf: 'center', paddingVertical: 10 },
});
