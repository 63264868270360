import { gql } from '@apollo/client';
import { flow, makeAutoObservable } from 'mobx';
import { unifiedAlert } from '../core/utils/utils';

const PRINTER_SETTINGS = gql`
  query PrinterSettings(
    $warehouseAccountIds: [ID!]
    $warehouseCodes: [String]
    $courierLabels: [String]
  ) {
    printerSettings(
      warehouseAccountIds: $warehouseAccountIds
      warehouseCodes: $warehouseCodes
      courierLabels: $courierLabels
    ) {
      printerSettings {
        id
        courierLabel
        printerName
        paperSize
        orientation
        warehouseCode
        enable
      }
    }
  }
`;

const CREATE_PRINTER_SETTING = gql`
  mutation createPrinterSetting($input: CreatePrinterSettingInput!, $warehouseAccountId: ID!) {
    createPrinterSetting(input: $input, warehouseAccountId: $warehouseAccountId) {
      id
      courierLabel
      printerName
      paperSize
      orientation
      warehouseCode
      enable
    }
  }
`;

const UPDATE_PRINTER_SETTING = gql`
  mutation updatePrinterSetting($input: UpdatePrinterSettingInput!, $warehouseAccountId: ID!) {
    updatePrinterSetting(input: $input, warehouseAccountId: $warehouseAccountId) {
      id
      courierLabel
      printerName
      paperSize
      orientation
      warehouseCode
      enable
    }
  }
`;

const DELETE_PRINTER_SETTING = gql`
  mutation deletePrinterSetting($id: ID!, $warehouseCode: String!, $warehouseAccountId: ID!) {
    deletePrinterSetting(
      id: $id
      warehouseCode: $warehouseCode
      warehouseAccountId: $warehouseAccountId
    )
  }
`;

export class PrinterSettingTableStore {
  isOpenAddPrinterSettingForm = false;
  isOpenEditPrinterSettingModal = false;
  selectedSetting = null;
  warehouseCodes = null;
  selectedLabels = null;
  loading = false;
  error = null;
  cachedItems = null;

  constructor(client, warehouseAccountId) {
    this.client = client;
    this.warehouseAccountId = warehouseAccountId;
    makeAutoObservable(
      this,
      {
        fetchItems: flow,
        deleteItem: flow,
        client: false,
        addItem: flow,
        updateItem: flow,
        warehouseAccountId: false,
      },
      { autoBind: true },
    );
  }

  setAddPrinterSettingFormVisible = (visible) => {
    this.isOpenAddPrinterSettingForm = visible;
  };

  toggleOpenEditPrinterSettingModal = (visible) => {
    this.isOpenEditPrinterSettingModal = visible;
  };

  setSelectedSetting = (setting) => {
    this.selectedSetting = setting;
  };

  setWarehouseCodes = (warehouseCodes) => {
    this.warehouseCodes = warehouseCodes;
  };

  setSelectedLabels = (labels) => {
    this.selectedLabels = labels;
  };

  setwarehouseAccountId = (id) => {
    this.warehouseAccountId = id;
  };

  *fetchItems() {
    this.loading = true;
    this.error = null;

    try {
      this.loading = false;
      let queryOptions = {
        query: PRINTER_SETTINGS,
        variables: {
          warehouseAccountIds: [this.warehouseAccountId],
          warehouseCodes: this.warehouseCodes,
        },
        fetchPolicy: 'network-only',
      };
      if (this.selectedLabels && this.selectedLabels.length > 0) {
        queryOptions.variables.courierLabels = this.selectedLabels;
      }
      const { data } = yield this.client.query(queryOptions);
      this.cachedItems = data.printerSettings.printerSettings;
    } catch (error) {
      this.error = error;
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  *updateItem(printerSetting, warehouseAccountId) {
    this.loading = true;
    this.error = null;

    try {
      const { data } = yield this.client.mutate({
        mutation: UPDATE_PRINTER_SETTING,
        variables: {
          warehouseAccountId: this.warehouseAccountId,
          input: printerSetting,
        },
      });

      return data.printerSettings;
    } catch (error) {
      this.error = error;
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  *addItem(printerSetting) {
    this.error = null;
    try {
      const { data } = yield this.client.mutate({
        mutation: CREATE_PRINTER_SETTING,
        variables: {
          warehouseAccountId: this.warehouseAccountId,
          input: printerSetting,
        },
      });
      if (this.cachedItems) {
        this.cachedItems.push(data.createPrinterSetting);
      }
    } catch (error) {
      throw error;
    }
  }

  *deleteItem(id, warehouseCode) {
    this.loading = true;
    this.error = null;
    try {
      yield this.client.mutate({
        mutation: DELETE_PRINTER_SETTING,
        variables: {
          id,
          warehouseCode,
          warehouseAccountId: this.warehouseAccountId,
        },
      });
      unifiedAlert('Delete printer setting successfully.');
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }

  get items() {
    if (!this.cachedItems) {
      this.fetchItems();
    }
    return this.cachedItems || [];
  }
}
