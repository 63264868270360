import { gql } from '@apollo/client';
import { flow, makeAutoObservable } from 'mobx';
import { flatten } from 'ramda';
import { GET_STORES } from '../core/utils/utils';
import { parseWarehouseCourierWhitelist } from '@ezom/library/lib/cjs/store';

const DELETE_STORE = gql`
  mutation DeleteStore($id: ID!) {
    deleteStore(id: $id)
  }
`;

const GET_ORDER_COUNTRY_CODES = gql`
  query orderCountryCodes($storeIds: [ID!]!) {
    orderCountryCodes(storeIds: $storeIds)
  }
`;

export class StoreStore {
  loading = false;
  error = null;
  cachedEnabledStores = null;
  cachedOrderCountryCodes = null;

  constructor(client) {
    this.client = client;
    makeAutoObservable(
      this,
      {
        fetchItems: flow,
        client: false,
      },
      { autoBind: true },
    );
  }

  clearCache() {
    this.cachedEnabledStores = null;
    this.cachedOrderCountryCodes = null;
  }

  *getEnabledStoreIds() {
    if (!this.cachedEnabledStores) {
      yield this.fetchItems();
    }
    return (this.cachedEnabledStores || []).map((s) => s.id);
  }

  *getEnabledStores() {
    if (!this.cachedEnabledStores) {
      yield this.fetchItems();
    }
    return this.cachedEnabledStores || [];
  }

  get orderCountryCodes() {
    if (!this.cachedOrderCountryCodes && this.enabledStoreIds.length > 0) {
      this.fetchOrderCountryCodes();
    }
    return this.cachedOrderCountryCodes || [];
  }

  get enabledStores() {
    if (!this.cachedEnabledStores) {
      this.fetchItems();
    }
    return this.cachedEnabledStores || [];
  }

  get enabledStoreIds() {
    if (!this.cachedEnabledStores) {
      this.fetchItems();
    }
    return (this.cachedEnabledStores || []).map((s) => s.id);
  }

  getStoreById(id) {
    return this.enabledStores.find(({ id: storeId }) => storeId === id);
  }

  getStoreWarehouseCourierById(id) {
    const store = this.getStoreById(id);
    return parseWarehouseCourierWhitelist(store?.preference?.warehouseCourierSelection);
  }

  getStoreWarehouseCodesById(id) {
    const whitelist = this.getStoreWarehouseCourierById(id) || {};
    return flatten(Object.values(whitelist)).map((w) => w.warehouseCode);
  }

  getStoreWarehouseAccountsById(id) {
    const whitelist = this.getStoreWarehouseCourierById(id) || {};
    return flatten(Object.values(whitelist)).map((w) => w.warehouseAccountId);
  }

  *fetchItems() {
    try {
      this.loading = true;
      const { data: storesData } = yield this.client.query({ query: GET_STORES });
      this.cachedEnabledStores = storesData?.stores?.filter((s) => !s.isDisabled);
    } catch (error) {
      this.error = error;
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  *deleteStore(id) {
    try {
      yield this.client.mutate({
        mutation: DELETE_STORE,
        variables: {
          id,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

  *fetchOrderCountryCodes() {
    try {
      const { data } = yield this.client.query({
        query: GET_ORDER_COUNTRY_CODES,
        variables: { storeIds: this.enabledStoreIds },
      });
      this.cachedOrderCountryCodes = data?.orderCountryCodes;
    } catch (error) {
      console.error(error);
    }
  }
}
