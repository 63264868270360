import '@expo/match-media';
import React, { memo, useState, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { Layout, Text, Button, ButtonGroup, Icon, Divider } from '@ui-kitten/components';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { UIStatusWrapper } from './ui-status';
import { countryStateCityCurrencyStore, warehousesStore } from '../store';
import { observer } from 'mobx-react-lite';
import { unifiedAlert } from '../core/utils/utils';
import Autocomplete from './Autocomplete';
import { TextInputField } from './input/TextInputField';

const WarehouseLocationInput = observer(
  ({
    onSubmit,
    size = 'small',
    style = {},
    onCancel,
    warehouse = null,
    warehouseCodeEditable = true,
    allowDuplicateName = false,
    title = 'Add warehouse location',
  }) => {
    const COUNTRIES = countryStateCityCurrencyStore.countryCodes;
    const existingCodes = warehousesStore.warehouseCodes;
    const existingNames = warehousesStore.warehouseNames;

    return (
      <Layout style={style} level="1">
        <UIStatusWrapper
          status={{
            error: warehousesStore.error,
            indeterminate: warehousesStore.loading,
          }}>
          <Formik
            initialValues={{
              code: warehouse?.warehouse_code || '',
              name: warehouse?.warehouse_name_en || '',
              country: warehouse?.country || '',
              state: warehouse?.state || '',
              city: warehouse?.city || '',
              district: warehouse?.district || '',
              postCode: warehouse?.post_code || '',
              street: warehouse?.street || '',
              contact: warehouse?.contact || '',
              remark: warehouse?.remark || '',
              aisles: warehouse?.aisles || '',
              units_per_aisle: warehouse?.units_per_aisle || '',
              shelves_per_unit: warehouse?.shelves_per_unit || '',
              sections_per_shelf: warehouse?.sections_per_shelf || '',
            }}
            isInitialValid={false}
            validationSchema={Yup.object({
              code: warehouseCodeEditable
                ? Yup.string()
                    .trim()
                    .required()
                    .matches(
                      /^[A-Z0-9\-\_\#\/\&\+\(\)]+$/,
                      'Only allow uppercase letters, numbers, -, _, #, /, &, +, ( and ).',
                    )
                    .notOneOf(existingCodes, 'Code has already been used')
                : Yup.string(),
              name: allowDuplicateName
                ? Yup.string().trim().required()
                : Yup.string()
                    .trim()
                    .required()
                    .notOneOf(existingNames, 'Name has already been used'),
              country: Yup.string().required(),
              state: Yup.string().required(),
              city: Yup.string().required(),
              district: Yup.string(),
              postCode: Yup.string().required(),
              street: Yup.string().required(),
              contact: Yup.string().required(),
              remark: Yup.string(),
              aisles: Yup.number('must be a number')
                .integer('must be an integer')
                .min(warehouse?.aisles || 1)
                .max(9999)
                .required(),
              units_per_aisle: Yup.number('must be a number')
                .integer('must be an integer')
                .min(warehouse?.units_per_aisle || 1)
                .max(9999)
                .required(),
              shelves_per_unit: Yup.number('must be a number')
                .integer('must be an integer')
                .min(warehouse?.shelves_per_unit || 1)
                .max(999)
                .required(),
              sections_per_shelf: Yup.number('must be a number')
                .integer('must be an integer')
                .min(warehouse?.sections_per_shelf || 1)
                .max(99)
                .required(),
            })}
            onSubmit={async (values, formikActions, ...props) => {
              try {
                await onSubmit({
                  code: values.code.trim(),
                  name: values.name.trim(),
                  country: values.country.trim(),
                  state: values.state.trim(),
                  city: values.city.trim(),
                  district: values.district.trim(),
                  postCode: values.postCode.trim(),
                  street: values.street.trim(),
                  contact: values.contact.trim(),
                  remark: values.remark.trim(),
                  aisles: parseInt(values.aisles.toString().trim()),
                  units_per_aisle: parseInt(values.units_per_aisle.toString().trim()),
                  shelves_per_unit: parseInt(values.shelves_per_unit.toString().trim()),
                  sections_per_shelf: parseInt(values.sections_per_shelf.toString().trim()),
                });
                formikActions.resetForm();
              } catch (e) {
                unifiedAlert(e);
              }
              // Important: Make sure to setSubmitting to false so our loading indicator
              // goes away.
              formikActions.setSubmitting(false);
            }}>
            {(props) => {
              const [states, setStates] = useState([]);
              useEffect(() => {
                (async () => {
                  if (props.values.country) {
                    const states = await countryStateCityCurrencyStore.getStateCodesOfCountry(
                      props.values.country,
                    );
                    setStates(states);
                  }
                })();
              });

              const [cities, setCities] = useState([]);
              useEffect(() => {
                (async () => {
                  if (props.values.country && props.values.state) {
                    const cities = await countryStateCityCurrencyStore.getCitiesOfState(
                      props.values.country,
                      props.values.state,
                    );
                    setCities(cities);
                  }
                })();
              });

              return (
                <Layout style={styles.formContainer}>
                  <Text category="s1" style={styles.title}>
                    {title}
                  </Text>
                  <Divider />
                  <TextInputField
                    label="Code"
                    name="code"
                    size={size}
                    disabled={!warehouseCodeEditable}
                    style={styles.field}
                    placeholder="SYD_AU_1"
                    {...props}
                  />
                  <TextInputField
                    label="Name"
                    name="name"
                    size={size}
                    style={styles.field}
                    placeholder="Sydney warehouse 1"
                    {...props}
                  />
                  <Autocomplete
                    label="Country"
                    name="country"
                    size={size}
                    defaultValue={props.values.country}
                    error={props.errors.country}
                    touched={props.touched.country}
                    style={styles.field}
                    placeholder="Select a country"
                    options={COUNTRIES}
                    onSelect={props.handleChange('country')}
                    onBlur={props.handleBlur('country')}
                  />
                  <Autocomplete
                    label="State"
                    name="state"
                    size={size}
                    defaultValue={props.values.state}
                    error={props.errors.state}
                    touched={props.touched.state}
                    style={styles.field}
                    placeholder="Select a state"
                    options={states}
                    allowFreetext
                    onSelect={props.handleChange('state')}
                    onBlur={props.handleBlur('state')}
                  />
                  <Autocomplete
                    label="City"
                    name="city"
                    size={size}
                    defaultValue={props.values.city}
                    error={props.errors.city}
                    touched={props.touched.city}
                    placeholder="Select a city"
                    style={styles.field}
                    disabled={!props.values.state}
                    options={cities}
                    allowFreetext
                    onSelect={props.handleChange('city')}
                    onBlur={props.handleBlur('city')}
                  />
                  <TextInputField
                    label="District"
                    name="district"
                    size={size}
                    style={styles.field}
                    placeholder="District 51"
                    {...props}
                  />
                  <TextInputField
                    label="Post Code"
                    name="postCode"
                    style={styles.field}
                    textContentType="postalCode"
                    keyboardType="number-pad"
                    {...props}
                  />
                  <TextInputField label="Address" name="street" style={styles.field} {...props} />
                  <TextInputField
                    label="Contact"
                    name="contact"
                    size={size}
                    style={styles.field}
                    placeholder="02344213433 John Smith"
                    {...props}
                  />
                  <TextInputField
                    label="Number of Aisles"
                    name="aisles"
                    size={size}
                    style={styles.field}
                    placeholder="99"
                    keyboardType="number-pad"
                    {...props}
                  />
                  <TextInputField
                    label="Units per Aisle"
                    name="units_per_aisle"
                    size={size}
                    style={styles.field}
                    placeholder="99"
                    keyboardType="number-pad"
                    {...props}
                  />
                  <TextInputField
                    label="Shelves per Unit"
                    name="shelves_per_unit"
                    size={size}
                    style={styles.field}
                    placeholder="10"
                    keyboardType="number-pad"
                    {...props}
                  />
                  <TextInputField
                    label="Sections per Shelf"
                    name="sections_per_shelf"
                    size={size}
                    style={styles.field}
                    placeholder="5"
                    keyboardType="number-pad"
                    {...props}
                  />
                  <TextInputField
                    label="Note"
                    name="remark"
                    multiline={false}
                    size={size}
                    style={styles.field}
                    textStyle={{ minHeight: 34 }}
                    placeholder="This is a note"
                    {...props}
                  />
                  <Divider />
                  <ButtonGroup appearance="ghost" size={size} style={styles.btnContainer}>
                    <Button
                      appearance="outline"
                      status="primary"
                      accessoryLeft={(props) => <Icon {...props} name="save-outline" />}
                      loading={props.isSubmitting}
                      disabled={props.isSubmitting || !props.isValid}
                      onPress={props.handleSubmit}>
                      Save
                    </Button>
                    <Button
                      appearance="outline"
                      status="danger"
                      accessoryLeft={(innerProps) => (
                        <Icon {...innerProps} name="close-circle-outline" />
                      )}
                      loading={props.isSubmitting}
                      disabled={props.isSubmitting}
                      onPress={onCancel}>
                      Cancel
                    </Button>
                  </ButtonGroup>
                </Layout>
              );
            }}
          </Formik>
        </UIStatusWrapper>
      </Layout>
    );
  },
);

const styles = StyleSheet.create({
  btnContainer: {
    maxHeight: 32,
    marginHorizontal: '5px',
    justifyContent: 'space-evenly',
    marginVertical: 8,
  },
  field: {
    marginVertical: 8,
  },
  formContainer: {
    alignSelf: 'center',
    overflowY: 'auto',
  },
  title: {
    marginVertical: 10,
  },
});

export default memo(WarehouseLocationInput);
