import React, { useCallback, useMemo, useState } from 'react';
import { Button, Card, Divider, Icon, Layout, ListItem, Text } from '@ui-kitten/components';
import { observer } from 'mobx-react';
import { ScrollView, useWindowDimensions, View } from 'react-native';
import { reject, isEmpty, isNil } from 'ramda';
import { OrderTitle } from './OrderTitle';
import { ShippingProgressListModal } from './ShippingProgressListModal';
import {
  outboundOrderStore,
  orderTableStore,
  storeStore,
  warehouseAccountStore,
  countryStateCityCurrencyStore,
  outboundOrderTableStore,
} from '../store';
import { UIStatusWrapper } from './ui-status';
import { ImportedOrderDispatchStore } from '../store/ImportedOrderDispatchStore';
import { AddConsignmentPhoneNumberModal } from './AddConsignmentPhoneNumberModal';

export default observer(({ orders, setOrders }) => {
  const windowHeight = useWindowDimensions().height;
  const orderDispatchStore = useMemo(
    () =>
      new ImportedOrderDispatchStore(
        orderTableStore.client,
        outboundOrderStore,
        storeStore,
        orders,
        warehouseAccountStore,
        countryStateCityCurrencyStore,
      ),
    [orders, outboundOrderStore, storeStore, warehouseAccountStore],
  );

  return (
    <View>
      <Title orderDispatchStore={orderDispatchStore} />
      <Divider />
      <UIStatusWrapper
        status={{
          indeterminate:
            orderDispatchStore.loadingInventories || orderDispatchStore.loadingSkuMappings,
          error: orderDispatchStore.error,
        }}>
        <ScrollView style={{ maxHeight: windowHeight - 150 }}>
          {orderDispatchStore.orders.length > 0
            ? orderDispatchStore.orders.map((order, index) => (
                <ListItem key={order.id}>
                  <Card style={{ width: 'calc(100%)' }}>
                    <OrderTitle
                      applyCourierToAll={(courier) =>
                        orderDispatchStore.setOutboundConsignmentSettings(
                          orderDispatchStore.outboundConsignmentSettings.map((s) => ({
                            ...s,
                            courier,
                          })),
                        )
                      }
                      applyWarehouseToAll={(warehouse) =>
                        orderDispatchStore.setOutboundConsignmentSettings(
                          orderDispatchStore.outboundConsignmentSettings.map((s) => ({
                            ...s,
                            warehouse: warehouse.warehouse_code,
                            warehouseAccountId: warehouse.warehouseAccountId,
                          })),
                        )
                      }
                      orderNumber={order.number}
                      courier={orderDispatchStore.mergedOutboundConsignments[index].courier}
                      setCourier={(courier) =>
                        orderDispatchStore.setOutboundConsignmentSettingByIndex(index, {
                          ...orderDispatchStore.outboundConsignmentSettings[index],
                          courier,
                        })
                      }
                      warehouse={orderDispatchStore.mergedOutboundConsignments[index].warehouse}
                      setWarehouse={(warehouse) =>
                        orderDispatchStore.setOutboundConsignmentSettingByIndex(index, {
                          ...orderDispatchStore.outboundConsignmentSettings[index],
                          warehouse: warehouse.warehouse_code,
                          warehouseAccountId: warehouse.warehouseAccountId,
                        })
                      }
                      storeId={order.storeId}
                      skuQuantity={orderDispatchStore.skuQuantity[index]}
                      validationError={orderDispatchStore.validationErrors[index]}
                      outOfStockWarehouseSkus={orderDispatchStore.outOfStockWarehouseSkus[index]}
                      unmappedStoreSkus={orderDispatchStore.unmappedStoreSkus[index]}
                      totalWeight={orderDispatchStore.totalWeight[index]}
                      totalVolume={orderDispatchStore.totalVolume[index]}
                      order={orderDispatchStore.orders[index]}
                      onCancel={() => {
                        setOrders(reject(({ id }) => order.id === id, orders));
                      }}
                    />
                  </Card>
                </ListItem>
              ))
            : null}
        </ScrollView>
      </UIStatusWrapper>
    </View>
  );
});

const PaperPlaneIcon = (props) => <Icon {...props} name="paper-plane" />;

const Title = observer(({ orderDispatchStore }) => {
  const errors = orderDispatchStore.orders.filter(
    (_, i) =>
      !isEmpty(orderDispatchStore.outOfStockWarehouseSkus[i]) ||
      orderDispatchStore.skuQuantity[i] === 0 ||
      orderDispatchStore.validationErrors[i],
  );

  const [progressModalVisible, setProgressModalVisible] = useState(false);
  const [phoneNumberModalVisible, setPhoneNumberModalVisible] = useState(false);

  const submitConsignments = useCallback(async () => {
    setProgressModalVisible(true);
    await orderDispatchStore.createConsignments();
    setTimeout(() => outboundOrderTableStore.fetchItems(), 2000);
  }, [orderDispatchStore, setProgressModalVisible]);

  const onShip = useCallback(() => {
    if (orderDispatchStore.mergedOutboundConsignments.find((c) => !c.address.phone)) {
      setPhoneNumberModalVisible(true);
    } else {
      submitConsignments();
    }
  }, []);

  return (
    <>
      <Layout
        style={{
          padding: '0.8em',
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Text style={{ marginBottom: '0.2em', width: '40%' }} category="h6">
          {orderDispatchStore.orders?.length || 0} Order(s)
        </Text>
        <Text status="danger" style={{ marginBottom: '0.2em', width: '40%' }} category="h6">
          {errors.length} contains error(s)
        </Text>
        <Button
          status="primary"
          disabled={errors.length > 0}
          appearance="outline"
          size="large"
          accessoryLeft={PaperPlaneIcon}
          onPress={onShip}>
          Ship
        </Button>
      </Layout>
      <ShippingProgressListModal
        visible={progressModalVisible}
        setVisible={setProgressModalVisible}
        consignments={orderDispatchStore.orders.map((order, i) => ({
          orderNumber: order.number,
          status: orderDispatchStore.consignmentStatuses[i]?.status,
          errMsg: orderDispatchStore.consignmentStatuses[i]?.error,
          fourpxTracking: orderDispatchStore.consignmentStatuses[i]?.fourpxTracking,
        }))}
      />
      <AddConsignmentPhoneNumberModal
        consignments={orderDispatchStore.mergedOutboundConsignments}
        setVisible={setPhoneNumberModalVisible}
        visible={phoneNumberModalVisible}
        onSubmit={() => {
          setPhoneNumberModalVisible(false);
          submitConsignments();
        }}
      />
    </>
  );
});
