import AsyncStorage from '@react-native-async-storage/async-storage';

/* This cache is meant to be used only when data needs to be preserved across different sessions */
const EZ_AUTH_TOKEN = 'ezAuthToken';

export const setAuthToken = async (value) => {
  await AsyncStorage.setItem(EZ_AUTH_TOKEN, value);
};

export const removeAuthToken = async () => {
  await AsyncStorage.setItem(EZ_AUTH_TOKEN, '');
};

export const getAuthToken = async () => {
  return await AsyncStorage.getItem(EZ_AUTH_TOKEN);
};
