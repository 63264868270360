import { Card, Button, Divider, Avatar } from 'react-native-paper';
import React from 'react';
import { reverse, prop, sortBy } from 'ramda';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { UIStatusWrapper } from '../../components/ui-status';
import Trackings from '../../components/Trackings';
import TrackingEditor from '../../components/TrackingEditor';
import { unifiedAlert } from '../../core/utils/utils';

const GET_TRACKINGS = gql`
  query trackings($orderId: ID!, $storeId: ID!) {
    trackings(orderId: $orderId, storeId: $storeId) {
      id
      authorName
      notes
      trackingUrl
      trackingNumber
      createdTime
      updatedTime
    }
  }
`;

const CREATE_TRACKING = gql`
  mutation createTracking(
    $orderId: ID!
    $storeId: ID!
    $trackingNumber: String!
    $trackingUrl: String
    $notes: String
  ) {
    createTracking(
      orderId: $orderId
      storeId: $storeId
      trackingNumber: $trackingNumber
      trackingUrl: $trackingUrl
      notes: $notes
    ) {
      id
    }
  }
`;

export default ({ order, noTitle = false, canAddTracking = true }) => {
  const [addingTracking, setAddingTracking] = React.useState(false);

  const {
    data: trackingsData,
    loading: loadingTrackings,
    error: trackingsLoadingError,
    refetch: refetchTrackings,
  } = useQuery(GET_TRACKINGS, {
    variables: {
      orderId: order.id,
      storeId: order.storeId,
    },
  });

  const [addTracking, { loading: mutationLoading, error: mutationError }] =
    useMutation(CREATE_TRACKING);

  const trackings = useMemo(() => {
    return reverse(
      sortBy(
        prop('createdTime'),
        (trackingsData?.trackings || []).map((c) => ({
          ...c,

          storeId: order.storeId,
          createdTime: new Date(Number(c.createdTime)).toISOString(),
          updatedTime: new Date(Number(c.updatedTime)).toISOString(),
        })),
      ),
    );
  }, [trackingsData]);

  return (
    <UIStatusWrapper
      status={{
        error: trackingsLoadingError,
        emtpy: !!trackingsData,
        indeterminate: loadingTrackings,
      }}>
      <Card>
        {noTitle ? null : (
          <Card.Title
            title="Additional trackings"
            left={(props) => <Avatar.Icon {...props} icon="foot-print" />}
          />
        )}
        <Card.Content>
          <Trackings
            trackings={trackings}
            onTrackingsChanged={refetchTrackings}
            storeId={order.storeId}
          />
          <Divider />
          {canAddTracking ? (
            addingTracking ? (
              <TrackingEditor
                onCancel={() => setAddingTracking(false)}
                onSubmit={async (values) => {
                  try {
                    await addTracking({
                      variables: {
                        orderId: order.id,
                        storeId: order.storeId,
                        notes: values.notes,
                        trackingNumber: values.trackingNumber,
                        trackingUrl: values.trackingUrl,
                      },
                    });
                    await refetchTrackings();
                    setAddingTracking(false);
                  } catch (e) {
                    const msg = 'Error! ' + JSON.stringify(e);
                    unifiedAlert(msg);
                  }
                }}
              />
            ) : (
              <Button onPress={() => setAddingTracking(true)} primary>
                Add tracking
              </Button>
            )
          ) : null}
        </Card.Content>
      </Card>
    </UIStatusWrapper>
  );
};
