import React, { useMemo } from 'react';
import { Button, Card, Divider, Layout, List, ListItem, Modal, Text } from '@ui-kitten/components';
import { observer } from 'mobx-react';
import { StyleSheet, View } from 'react-native';
import { useIsMobile } from '../core/responsive.utils';
import { TextInputField } from './input/TextInputField';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ezTheme } from '../core/theme';

export const AddConsignmentPhoneNumberModal = observer(
  ({ consignments, setVisible, visible, onSubmit }) => {
    const isMobile = useIsMobile();

    const consignmentsWithoutPhone = useMemo(
      () => consignments.filter(({ address }) => !address.phone),
      [consignments],
    );

    return (
      <Modal
        visible={visible}
        backdropStyle={{
          backgroundColor: ezTheme.backdropModalColor,
        }}
        style={{
          backgroundColor: 'none',
          minWidth: '400px',
          alignSelf: 'center',
        }}>
        <Card
          style={styles.card}
          header={(props) => (
            <View {...props}>
              <Text category="h6">Contact phone nunber missing</Text>
              <Divider />
              <Text category="s1">Please add contact number below for each consignment</Text>
            </View>
          )}>
          <Formik
            initialValues={{ phones: consignmentsWithoutPhone.map(() => []) }}
            isInitialValid={false}
            validationSchema={Yup.object({
              phones: Yup.array()
                .of(Yup.number().required())
                .required()
                .length(consignmentsWithoutPhone.length),
            })}
            onSubmit={(values) => {
              consignmentsWithoutPhone.forEach((c, i) => (c.address.phone = values.phones[i]));
              onSubmit();
            }}
            render={({ handleSubmit, ...props }) => (
              <>
                <List
                  data={props.values.phones}
                  renderItem={({ item, index }) => (
                    <ListItem key={index}>
                      <TextInputField
                        name={`phones.${index}`}
                        style={styles.field}
                        label={`${consignmentsWithoutPhone[index].refNo} ${consignmentsWithoutPhone[index].address.name}`}
                        keyboardType="phone-pad"
                        {...props}
                      />
                    </ListItem>
                  )}
                  ItemSeparatorComponent={Divider}
                />
                <Layout
                  style={isMobile ? styles.mobileButtonContainer : styles.desktopButtonContainer}>
                  <Button
                    status="primary"
                    onPress={handleSubmit}
                    style={styles.button}
                    disabled={!props.isValid}>
                    Submit
                  </Button>
                  <Button status="warning" onPress={() => setVisible(false)} style={styles.button}>
                    Cancel
                  </Button>
                </Layout>
              </>
            )}
          />
        </Card>
      </Modal>
    );
  },
);
const styles = StyleSheet.create({
  button: {
    marginHorizontal: 10,
    marginVertical: 10,
  },
  desktopButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  mobileButtonContainer: {
    flexDirection: 'column',
  },
});
