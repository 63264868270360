import { Button as UiKittenButton } from '@ui-kitten/components/ui/button/button.component';
import { Spinner } from '@ui-kitten/components';
import { View, StyleSheet } from 'react-native';
import React from 'react';

const LoadingIndicator = (props) => (
  <View style={[props.style, styles.indicator]}>
    <Spinner size="small" />
  </View>
);

export const StripePaymentButton = ({ onPress, title, style, loading, disabled }) => {
  return (
    <UiKittenButton
      style={style}
      status="success"
      appearance="outline"
      onPress={onPress}
      accessoryLeft={loading ? LoadingIndicator : undefined}
      disabled={disabled}
      // Uncomment to display a logo
      // accessoryLeft={(props) => (
      //   <Avatar
      //     shape="square"
      //     size="large"
      //     source={require('../../assets/images/POLi logo-37.png')}
      //     style={{ width: 50, height: 30 }}
      //   />
      // )}
    >
      {title}
    </UiKittenButton>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  button: {
    margin: 2,
  },
  indicator: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});
