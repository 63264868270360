import React, { memo, useState } from 'react';
import Background from 'src/components/Background';
import Logo from 'src/components/Logo';
import Header from 'src/components/Header';
import Button from 'src/components/Button';
import Paragraph from 'src/components/Paragraph';
import { removeAuthToken } from 'src/core/cache';
import { gql, useMutation } from '@apollo/client';
import { StyleSheet } from 'react-native';
import { useKeycloak } from '@react-keycloak/web';
import { Card as KittenCard } from '@ui-kitten/components';
import { TextInputField } from '../../components/input/TextInputField';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { UIStatus, UIStatusWrapper } from '../../components/ui-status';

const styles = StyleSheet.create({
  card: {
    marginTop: '0.5em',
    width: '100%',
  },

  field: {
    marginVertical: 8,
  },
});

const CREATE_ORG = gql`
  mutation CreateOrg(
    $name: String!
    $email: String!
    $abn: String
    $acn: String
    $tfn: String
    $address: String
    $phone: String!
  ) {
    createOrg(
      name: $name
      email: $email
      abn: $abn
      acn: $acn
      tfn: $tfn
      address: $address
      phone: $phone
    ) {
      id
    }
  }
`;

const AddOrganisationScreen = ({ navigation, user, refetchUser }) => {
  const { keycloak } = useKeycloak();
  const [createOrg, createOrgResult] = useMutation(CREATE_ORG);
  const [status, setStatus] = useState(new UIStatus());

  return (
    <Background>
      <Logo />
      <Header>Create a new organisation</Header>

      <Paragraph>or ask the organisation admin to add your email address.</Paragraph>

      <UIStatusWrapper
        status={
          new UIStatus(
            createOrgResult.loading || (status.indeterminate && !createOrgResult.error),
            createOrgResult.error,
          )
        }>
        <Formik
          initialValues={{
            name: '',
            email: '',
            phone: '',
          }}
          validationSchema={Yup.object({
            name: Yup.string().required(),
            email: Yup.string().email().required(),
            phone: Yup.number().required(),
          })}
          onSubmit={(values) => {
            setStatus((p) => p.setIndeterminate(true));

            createOrg({
              variables: {
                name: values.name,
                email: values.email,
                phone: values.phone,
              },
            }).then(() => {
              refetchUser().then(() => setStatus((p) => p.setIndeterminate(false)));
            });
          }}>
          {(formProps) => (
            <KittenCard style={styles.card}>
              <TextInputField
                name="name"
                label="Name"
                style={styles.field}
                placeholder="Organisation name"
                {...formProps}
              />
              <TextInputField
                name="email"
                label="Email"
                style={styles.field}
                placeholder="e.g contact@company.com"
                {...formProps}
              />
              <TextInputField
                name="phone"
                label="Phone"
                style={styles.field}
                placeholder="Main contact number"
                {...formProps}
              />
              <Button mode="contained" onPress={() => formProps.handleSubmit()}>
                Create
              </Button>
            </KittenCard>
          )}
        </Formik>
      </UIStatusWrapper>

      <Button
        mode="outlined"
        onPress={() => {
          removeAuthToken();
          keycloak.logout();
        }}>
        Log out
      </Button>
    </Background>
  );
};

export default memo(AddOrganisationScreen);
