export class UIStatus {
  indeterminate;
  error;
  empty;

  constructor(indeterminate, error, empty) {
    this.indeterminate = indeterminate || false;
    this.error = error;
    this.empty = empty;
  }

  setIndeterminate(loading) {
    this.indeterminate = loading;
    this.error = undefined;
    this.empty = undefined;
    return this;
  }

  setEmpty(e) {
    this.indeterminate = false;
    this.error = undefined;
    this.empty = e;
    return this;
  }

  setError(e) {
    this.indeterminate = false;
    this.error = e;
    this.empty = undefined;
    return this;
  }
}
