import { flow, makeAutoObservable } from 'mobx';
import { PACKAGING_MATERIAL_MAP, PACKAGING_TYPE_MAP } from '@ezom/library/lib/cjs/constants';
import { gql } from '@apollo/client';
import { weightGramToKg } from '@ezom/library/lib/cjs/utils';

const DEFAUL_PARAMS = {
  searchTerm: undefined,
  material: undefined,
  type: undefined,
};

const DELETE_PACKAGING_MATERIAL = gql`
  mutation deletePackagingMaterial($warehouseAccountId: ID!, $id: ID!) {
    deletePackagingMaterial(warehouseAccountId: $warehouseAccountId, id: $id) {
      id
    }
  }
`;

const ADD_PACKAGING_MATERIAL = gql`
  mutation addPackagingMaterial(
    $warehouseAccountId: ID!
    $material: PackagingMaterialMaterial!
    $type: PackagingMaterialType!
    $warehouse_code: ID!
    $weight: Float!
    $length: Float!
    $height: Float!
    $width: Float!
    $thickness: Float!
    $quantity: Int!
    $price: Float!
    $organisationId: ID
    $barcode: ID
  ) {
    addPackagingMaterial(
      warehouseAccountId: $warehouseAccountId
      material: $material
      type: $type
      warehouse_code: $warehouse_code
      weight: $weight
      length: $length
      height: $height
      width: $width
      thickness: $thickness
      quantity: $quantity
      price: $price
      organisationId: $organisationId
      barcode: $barcode
    ) {
      id
    }
  }
`;

const UPDATE_PACKAGING_MATERIAL = gql`
  mutation updatePackagingMaterial(
    $warehouseAccountId: ID!
    $id: ID!
    $material: PackagingMaterialMaterial
    $type: PackagingMaterialType
    $weight: Float
    $length: Float
    $width: Float
    $height: Float
    $thickness: Float
    $quantity: Int
    $price: Float
    $barcode: ID
  ) {
    updatePackagingMaterial(
      warehouseAccountId: $warehouseAccountId
      id: $id
      material: $material
      type: $type
      weight: $weight
      length: $length
      height: $height
      thickness: $thickness
      width: $width
      quantity: $quantity
      price: $price
      barcode: $barcode
    ) {
      id
    }
  }
`;

const PACKAGING_MATERIAL_QUERY = gql`
  query packagingMaterials(
    $warehouseAccountIds: [ID!]!
    $ids: [ID!]
    $warehouses: [ID!]
    $barcodes: [ID!]
    $searchTerm: String
    $material: PackagingMaterialMaterial
    $type: PackagingMaterialType
    $organisationId: ID
    $page_no: Int
    $page_size: Int
  ) {
    packagingMaterials(
      warehouseAccountIds: $warehouseAccountIds
      ids: $ids
      warehouses: $warehouses
      barcodes: $barcodes
      searchTerm: $searchTerm
      material: $material
      type: $type
      organisationId: $organisationId
      page_no: $page_no
      page_size: $page_size
    ) {
      packagingMaterials {
        id
        warehouseAccountId
        warehouse_code
        material
        type
        weight
        height
        width
        thickness
        quantity
        price
        length
        barcode
        organisationId
      }
      total
      page_no
      page_size
    }
  }
`;

export class PackagingMaterialTableStore {
  filterParams = DEFAUL_PARAMS;
  deleteDialogueOpen = false;
  sortOption = [];
  selectedIds = new Set();
  currentPage = 0;
  pageSize = 20;
  total = null;
  loading = true;
  error = null;
  cachedItems = null;
  itemsToDelete = new Set();

  warehouseSelectionVisible = false;
  onWarehouseSelectionSubmit = () => {};

  DISPLAY_KEYS = [
    'id',
    'barcode',
    'material',
    'type',
    'weight',
    'length',
    'width',
    'height',
    'thickness',
    'quantity',
    'price',
    'warehouse_code',
    'organisationId',
  ];

  TITLE_BY_KEY = {
    id: 'ID',
    barcode: 'Barcode',
    material: 'Material',
    type: 'Type',
    weight: 'Weight (kg)',
    length: 'Length (cm)',
    width: 'Width (cm)',
    height: 'Height (cm)',
    thickness: 'Thickness (cm)',
    quantity: 'QTY',
    price: 'Price',
    warehouse_code: 'Warehouse',
    organisationId: 'Organisation',
  };

  FORMATTER_BY_KEY = {
    material: (s) => PACKAGING_MATERIAL_MAP[s],
    type: (s) => PACKAGING_TYPE_MAP[s],
    warehouse_code: (w) => this.warehousesStore.getWarehouseNameEn(w),
    organisationId: (id) => this.organisationStore.orgNameItems.find((org) => org.id === id)?.name,
    weight: weightGramToKg(false),
  };

  constructor(client, warehousesStore, warehouseAccountStore, organisationStore) {
    this.client = client;
    this.warehousesStore = warehousesStore;
    this.warehouseAccountStore = warehouseAccountStore;
    this.organisationStore = organisationStore;

    makeAutoObservable(
      this,
      {
        client: false,
        warehousesStore: false,
        warehouseAccountStore: false,
        organisationStore: false,
        addItem: flow,
        updateItem: flow,
        deleteItem: flow,
        fetchItems: flow,
        fetchPackagingMaterials: flow,
      },
      { autoBind: true },
    );
  }

  closeDeleteDialogue = () => {
    this.deleteDialogueOpen = false;
  };

  openDeleteDialogue = () => {
    this.deleteDialogueOpen = true;
  };

  setFilterParams = (params) => {
    this.filterParams = params;
    this.cachedItems = null;
  };

  resetFilterParams = () => {
    this.filterParams = DEFAUL_PARAMS;
    this.cachedItems = null;
  };

  setSortOption = (option) => {
    this.sortOption = option;
  };

  setSelectedIds = (selectedIds) => {
    this.selectedIds = selectedIds;
  };

  setCurrentPage(page) {
    this.currentPage = page;
    this.cachedItems = null;
  }

  *fetchItems() {
    try {
      this.loading = true;
      const {
        data: { packagingMaterials },
      } = yield this.client.query({
        query: PACKAGING_MATERIAL_QUERY,
        variables: {
          ...this.filterParams,
          page_size: this.pageSize,
          page_no: this.currentPage + 1,
          warehouseAccountIds: this.warehouseAccountStore.warehouseAccountIds,
        },
        fetchPolicy: 'no-cache',
      });
      this.cachedItems = packagingMaterials.packagingMaterials;
      this.total = packagingMaterials.total;
      this.error = null;
    } catch (error) {
      this.error = error;
    } finally {
      this.loading = false;
    }
  }

  *fetchPackagingMaterials(warehouseAccountId, warehouse, pageSize, pageNo) {
    try {
      const {
        data: { packagingMaterials },
      } = yield this.client.query({
        query: PACKAGING_MATERIAL_QUERY,
        variables: {
          page_size: pageSize,
          page_no: pageNo,
          warehouses: [warehouse],
          warehouseAccountIds: [warehouseAccountId],
        },
        fetchPolicy: 'no-cache',
      });
      return packagingMaterials;
    } catch (error) {
      throw error;
    }
  }

  *deleteItem(warehouseAccountId, id) {
    this.loading = true;
    try {
      const { data } = yield this.client.mutate({
        mutation: DELETE_PACKAGING_MATERIAL,
        variables: {
          warehouseAccountId,
          id,
        },
      });
      return data.deletePackagingMaterial;
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }

  get items() {
    if (!this.cachedItems) {
      this.fetchItems();
    }
    return this.cachedItems || [];
  }

  *addItem({
    warehouseAccountId,
    material,
    type,
    warehouse_code,
    weight,
    length,
    height,
    width,
    thickness,
    quantity,
    price,
    organisationId,
    barcode,
  }) {
    this.loading = true;
    try {
      const { data } = yield this.client.mutate({
        mutation: ADD_PACKAGING_MATERIAL,
        variables: {
          warehouseAccountId,
          warehouse_code,
          material,
          type,
          weight,
          height,
          width,
          length,
          thickness,
          barcode: barcode || null,
          quantity,
          price,
          organisationId: organisationId || null,
        },
      });
      return data.addPackagingMaterial;
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }

  *updateItem({
    warehouseAccountId,
    id,
    material,
    type,
    weight,
    height,
    width,
    thickness,
    length,
    quantity,
    price,
    barcode,
  }) {
    this.loading = true;
    try {
      const { data } = yield this.client.mutate({
        mutation: UPDATE_PACKAGING_MATERIAL,
        variables: {
          id,
          warehouseAccountId,
          material,
          type,
          weight,
          height,
          width,
          length,
          thickness,
          quantity,
          price,
          barcode,
        },
      });
      return data.updatePackagingMaterial;
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }

  setWarehouseSelectionVisible = (visible) => {
    this.warehouseSelectionVisible = visible;
  };

  setOnWarehouseSelectionSubmit = (onSubmit) => {
    this.onWarehouseSelectionSubmit = onSubmit;
  };
}
