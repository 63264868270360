import React, { memo } from 'react';
import { StyleSheet } from 'react-native';
import { Input, Text, Layout } from '@ui-kitten/components';
import { paperNativeTheme } from 'src/core/theme';
import { useIsMobile } from 'src/core/responsive.utils';

const TextInput = ({ error, touched, style = {}, size = 'medium', label, ...props }) => {
  const isMobileDevice = useIsMobile();
  return (
    <Layout
      style={[
        styles.rowContainer,
        isMobileDevice ? styles.mobileRowFlex : styles.desktopRowFlex,
        style,
      ]}
      level="1">
      {label !== undefined ? (
        <Text
          category={size === 'large' ? 's1' : size === 'medium' ? 's2' : 'label'}
          style={isMobileDevice ? styles.mobileLabel : styles.desktopLabel}>
          {label}
        </Text>
      ) : null}
      <Input
        size={size}
        style={[
          label ? (isMobileDevice ? styles.mobileInput : styles.desktopInput) : { width: '100%' },
          styles.input,
          style.input,
        ]}
        selectionColor={paperNativeTheme.colors.primary}
        status={touched && error ? 'danger' : 'primary'}
        caption={error}
        {...props}
      />
    </Layout>
  );
};

const styles = StyleSheet.create({
  input: {
    flex: 1,
    marginLeft: 5,
  },
  mobileLabel: {
    flex: 1,
    maxWidth: '100px',
  },
  desktopLabel: {
    minWidth: '130px',
  },
  rowContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mobileRowFlex: {
    marginHorizontal: 'auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  desktopRowFlex: {
    flexDirection: 'row',
    width: 'auto',
  },
  desktopInput: {
    width: '260px',
    minWidth: '260px',
  },
  mobileInput: {
    width: '200px',
    minWidth: '200px',
  },
});

export default memo(TextInput);
